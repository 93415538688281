import ApiExtensions from '../../factories/ApiExtensions'

export default {
  async getExtensionTypes({ commit, state }, reset = state.globalReset) {
    if (state.extensions.types.length > 0 && !reset) return

    const { types } = await ApiExtensions.getTypes()
    commit('SET_EXTENSION_TYPES', types)
  },

  async getAllExtensions({ commit, state }, reset = state.globalReset) {
    if (state.extensions.length > 0 && !reset) return

    const { extensions } = await ApiExtensions.getAll()
    commit('SET_EXTENSIONS', extensions)
  },

  // async getTemplateExtensions({ commit, state }, reset = state.globalReset) {
  //   if (state.extensionTemplates.length > 0 && !reset) return

  //   const { templates } = await ApiExtensions.getTemplates()
  //   commit('SET_EXTENSION_TEMPLATES', templates)
  // },

  async initConnectedApp({ dispatch }, appId) {
    await Promise.all([
      dispatch('getConnectedAppInstances', appId),
      dispatch('getConnectedAppMetadata', appId),
    ])
  },

  async getConnectedAppInstances({ commit }, appId) {
    const { appInstances } = await ApiExtensions.getAppInstances(appId)
    commit('SET_CURRENT_APP_INSTANCES', appInstances)
  },

  async getConnectedAppMetadata({ commit }, appId) {
    const { vendorLogo, appType } = await ApiExtensions.getAppMetadata(appId)
    commit('SET_CURRENT_APP_LOGO_FILE', vendorLogo)
    commit('SET_CURRENT_APP_TYPE', appType)
    commit('SET_CURRENT_APP_EXTENSION_ID', appId)
  },

  async getAppExecutionHistory({ commit, state }, instanceId) {
    if (
      state.extensions.currentApp.currentInstance.id == instanceId &&
      state.extensions.currentApp.currentInstance.executions?.length > 0
    )
      return
    const executions = await ApiExtensions.getExecutionHistory(instanceId)

    commit('SET_CURRENT_APP_EXECUTIONS', {
      executionData: executions,
      instanceId: instanceId,
    })
  },

  async getAppExecutionSummary({ commit }, executionId) {
    if (executionId) {
      const { executionSummary, executionRecord } =
        await ApiExtensions.getAppExecutionSummary(executionId)
      commit('SET_CURRENT_APP_EXECUTION_SUMMARY', {
        id: executionId,
        summary: executionSummary?.summary || {},
        date: executionRecord.start_time,
        status: executionRecord.status,
        output_status: executionRecord.output_status,
      })
    } else {
      commit('SET_CURRENT_APP_EXECUTION_SUMMARY', {
        id: executionId,
        summary: {},
        date: null,
        status: null,
        output_status: null,
      })
    }
  },

  async getAppExecutionServiceResults(
    { state, commit, dispatch },
    { executionId, serviceKey }
  ) {
    dispatch('getAppExecutionMetadata', executionId)
    if (
      state.extensions.currentApp.currentInstance.currentExecution.id ==
        executionId &&
      state.extensions.currentApp.currentInstance.currentExecution.results[
        serviceKey
      ] &&
      Object.keys(
        state.extensions.currentApp.currentInstance.currentExecution.results[
          serviceKey
        ]
      ).length > 0
    )
      return
    const [exceptions, serviceResults] = await Promise.all([
      ApiExtensions.getAppExecutionDataByKey(executionId, 'exceptions'),
      await ApiExtensions.getAppExecutionDataByKey(
        executionId,
        `results.services.${serviceKey}`
      ),
    ])

    commit('SET_CURRENT_APP_EXECUTION_SERVICE', { serviceKey, serviceResults })
    commit('SET_CURRENT_APP_EXECUTION_EXCEPTIONS', exceptions)
  },

  async resetCurrentExecution({ commit }) {
    await commit('RESET_CURRENT_APP_EXECUTION')
  },

  async getAppExecutionMetadata({ state, commit }, executionId) {
    if (
      state.extensions.currentApp.currentInstance.currentExecution.id ==
        executionId &&
      state.extensions.currentApp.currentInstance.currentExecution.metadata &&
      Object.keys(
        state.extensions.currentApp.currentInstance.currentExecution.metadata
      ).length > 0
    )
      return
    const executionMetadata = await ApiExtensions.getAppExecutionDataByKey(
      executionId,
      'results.metadata'
    )
    commit('SET_CURRENT_APP_EXECUTION_METADATA', executionMetadata)
  },

  async getAppExecutionInventory({ state, commit }, executionId) {
    if (
      state.extensions.currentApp.currentInstance.currentExecution.id ==
        executionId &&
      state.extensions.currentApp.currentInstance.currentExecution.inventory &&
      Object.keys(
        state.extensions.currentApp.currentInstance.currentExecution.inventory
      ).length > 0
    )
      return
    const { inventory } = await ApiExtensions.getExecutionInstanceInventory(
      executionId
    )
    commit('SET_CURRENT_APP_EXECUTION_INVENTORY', inventory)
  },

  async getAppInstanceExceptions(
    { state, commit },
    { instanceId, reset = false }
  ) {
    if (
      !reset &&
      state.extensions.currentApp.currentInstance.id == instanceId &&
      state.extensions.currentApp.currentInstance.exceptions.fetched
    )
      return
    const exceptions = await ApiExtensions.getInstanceExceptions(instanceId)
    commit('SET_CURRENT_APP_EXCEPTIONS', exceptions)
  },
}
