import AssessmentsListSummary from './assessments/AssessmentsListSummary'
import AssessmentsRequestTaskList from './assessments/AssessmentsRequestTaskList'
import ProgramChecklist from '../../programchecklist/ProgramChecklist'
import R3sContent from './R3sContent'
// import PotentialFrameworks from './assessments/PotentialFrameworks'
import OrganizationSettingsLink from './card-links/OrganizationSettingsLink'
import RiskRegisterListSummary from './riskregister/RiskRegisterListSummary'
import RiskRegisterOpenItemsSummary from './riskregister/OpenItemsSummary'
import TrustCenterDownloadSummary from './trustcenter/TrustCenterDownloadSummary'
import TrustCenterDownloadUserSummary from './trustcenter/TrustCenterDownloadUserSummary'
import UserSettingsLink from './card-links/UserSettingsLink'
import VulnerabilityScansLink from './card-links/VulnerabilityScansLink'
import VulnerabilityHostSummary from './vulnerability/VulnerabilityHostSummary'
import VulnerabilityOpenTrendOverTimeSummary from './vulnerability/VulnerabilityOpenTrendOverTimeSummary'
import VulnerabilityOwnerSummary from './vulnerability/VulnerabilityOwnerSummary'
import VulnerabilitySeveritySummary from './vulnerability/VulnerabilitySeveritySummary'
import VulnerabilitySeverityDonutSummary from './vulnerability/VulnerabilitySeverityDonutSummary'
import VulnerabilitySLASummaryCards from './vulnerability/VulnerabilitySLASummaryCards'
import VulnerabilitySLASummaryTable from './vulnerability/VulnerabilitySLASummaryTable'

export default [
  {
    module: 'assessments',
    component: AssessmentsRequestTaskList,
    val: 'AssessmentsRequestTaskList',
    name: 'Evidence Requests',
    desc: `Active assessment evidence requests assigned to you.`,
  },
  {
    module: 'checklist',
    component: ProgramChecklist,
    val: 'ProgramChecklist',
    name: 'Compliance Program Checklist',
    desc: `Checklist to track your compliance program completion journey.`,
  },
  {
    module: 'assessments',
    component: AssessmentsListSummary,
    val: 'AssessmentsListSummary',
    name: 'Org Assessments',
    desc: `Active assessments for your organization.`,
  },
  {
    module: 'settings',
    component: R3sContent,
    val: 'R3sContent',
    name: 'Fun Facts',
    desc: `Fun facts brought to you by risk3sixty!`,
  },
  // {
  //   module: 'assessments',
  //   component: PotentialFrameworks,
  //   val: 'PotentialFrameworks',
  //   name: 'Potential Frameworks',
  //   desc: `Potential framework progress for your organization.`,
  // },
  {
    module: 'settings',
    component: OrganizationSettingsLink,
    val: 'OrganizationSettingsLink',
    name: 'Org Settings',
    desc: `A shortcut link to manage your top-level team settings`,
  },
  {
    module: 'risk_register',
    component: RiskRegisterListSummary,
    val: 'RiskRegisterListSummary',
    name: 'Org Risk Registers',
    desc: `Active risk registers for your organization.`,
  },
  {
    module: 'risk_register',
    component: RiskRegisterOpenItemsSummary,
    val: 'RiskRegisterOpenItemsSummary',
    name: 'Org Risks',
    desc: `A summary of all outstanding/open risk register items for your organization.`,
  },
  {
    module: 'template_center',
    component: TrustCenterDownloadSummary,
    val: 'TrustCenterDownloadSummary',
    name: 'Template Center Downloads',
    desc: `Information about the most downloaded files in the template center.`,
  },
  {
    module: 'template_center',
    component: TrustCenterDownloadUserSummary,
    val: 'TrustCenterDownloadUserSummary',
    name: 'Template Center Downloads Per User',
    desc: `Information about the most downloaded files in the template center by selected user.`,
  },
  {
    module: 'settings',
    component: UserSettingsLink,
    val: 'UserSettingsLink',
    name: 'User Settings',
    desc: `A shortcut link to manage your personal account information.`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityHostSummary,
    val: 'VulnerabilityHostSummary',
    name: 'Host Vulnerabilities',
    desc: `A summary of vulnerabilities specific to the affected hosts`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityOpenTrendOverTimeSummary,
    val: 'VulnerabilityOpenTrendOverTimeSummary',
    name: 'Vulnerabilities Opened Over Time',
    desc: `Line graph showing how many open items there are over time.`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityOwnerSummary,
    val: 'VulnerabilityOwnerSummary',
    name: 'Vulnerability Scans Per Owner',
    desc: `A summary of all of your vulnerability scans around the item's owners`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityScansLink,
    val: 'VulnerabilityScansLink',
    name: 'Vulnerability Scans',
    desc: 'A shortcut link to your vulnerability scans',
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySeverityDonutSummary,
    val: 'VulnerabilitySeverityDonutSummary',
    name: 'Vulnerabilities By Severity Chart',
    desc: `A doughnut chart of severity of items across all of you vulnerabilities`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySeveritySummary,
    val: 'VulnerabilitySeveritySummary',
    name: 'Vulnerability Scans By Severity',
    desc: `A summary of all of your vulnerability scans by severity`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySLASummaryCards,
    val: 'VulnerabilitySLASummaryCards',
    name: 'Vulnerability SLAs By Severity',
    desc: `A summary of how long it takes issues by severity to be completed.`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySLASummaryTable,
    val: 'VulnerabilitySLASummaryTable',
    name: 'Vulnerability SLAs By Severity Table',
    desc: `SLA information for how long it takes to close out issues by severity.`,
  },
]
