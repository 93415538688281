import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  /** @deprecated  */
  async getJiraProjects() {
    const response = await R3sFetch(`/api/1.0/integrations/jira/projects/get`)
    return await handleFetchResponse(response)
  },

  /** @deprecated  */
  async getJiraIssuetypes(projectId) {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/issuetypes/get?projectId=${projectId}`
    )
    return await handleFetchResponse(response)
  },

  /** @deprecated  */
  async getJiraCreateUpdateIssue(info) {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/issues/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getJiraAppInstallationLink() {
    const response = await R3sFetch(`/api/1.0/integrations/jira/installLink`)
    return await handleFetchResponse(response)
  },

  async getAvailableJiraProjects() {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/projects/available`
    )
    return await handleFetchResponse(response)
  },

  async getTasksInProject(jiraProjectId, searchString) {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/project/tasks?jiraProjectId=${jiraProjectId}&searchString=${encodeURIComponent(
        searchString
      )}`
    )
    return await handleFetchResponse(response)
  },

  async createSyncedJiraTask(info) {
    const response = await R3sFetch(`/api/1.0/integrations/jira/task/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },
  async linkJiraTask(info) {
    const response = await R3sFetch(`/api/1.0/integrations/jira/task/link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },
  async unlinkSyncedJiraTask(info) {
    const response = await R3sFetch(`/api/1.0/integrations/jira/task/unlink`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async createSyncedJiraProject(info) {
    const response = await R3sFetch(`/api/1.0/integrations/jira/project/sync`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async unsyncJiraProject(info) {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/project/unsync`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async checkJiraConfiguration(webTriggerUrl) {
    const response = await R3sFetch(
      `/api/1.0/integrations/jira/configuration/test${
        webTriggerUrl
          ? `?webTriggerUrl=${encodeURIComponent(webTriggerUrl)}`
          : ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getSlackChannels() {
    const response = await R3sFetch(`/api/1.0/integrations/slack/channels/get`)
    return await handleFetchResponse(response)
  },

  async deleteSlackChannel(id) {
    const response = await R3sFetch(
      `/api/1.0/integrations/slack/channel/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async testSlackChannel(id) {
    const response = await R3sFetch(
      `/api/1.0/integrations/slack/channel/test`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },
}
