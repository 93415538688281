import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllBias(showTemplates = false) {
    const response = await R3sFetch(
      `/api/1.0/inventories/list?templates=${showTemplates || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getBia(id, fullInfo = false) {
    const response = await R3sFetch(
      `/api/1.0/inventories/get?id=${id}&full=${fullInfo || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createBia(record) {
    const response = await R3sFetch(`/api/1.0/inventories/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(record),
    })
    return await handleFetchResponse(response)
  },

  async createBiaCopy({ spreadsheetId, targetTeamId, shouldBecomeTemplate }) {
    const response = await R3sFetch(`/api/1.0/inventories/create/copy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        spreadsheetId,
        targetTeamId,
        shouldBecomeTemplate,
      }),
    })
    return await handleFetchResponse(response)
  },

  async getCategory(categoryId) {
    const response = await R3sFetch(
      `/api/1.0/inventories/category/get?id=${categoryId}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateCategory(info) {
    const response = await R3sFetch(`/api/1.0/inventories/category/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async deleteCategory(categoryId) {
    const response = await R3sFetch(`/api/1.0/inventories/category/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: categoryId }),
    })
    return await handleFetchResponse(response)
  },
}
