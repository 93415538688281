import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async downloadFileBlob(fileName, prefix = '/file/s3/') {
    const response = await R3sFetch(`${prefix}${fileName}`)
    return await handleFetchResponse(response, 'blob')
  },

  async getFile(fileId) {
    const response = await R3sFetch(`/api/1.0/files/get?id=${fileId}`)
    return await handleFetchResponse(response)
  },

  async getFiles(
    status = '',
    search = '',
    filters = undefined,
    page = 1,
    perPage = 20,
    sortBy = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/files/getall?status=${status || ''}&search=${
        search || ''
      }&filters=${
        filters ? encodeURIComponent(JSON.stringify(filters)) : ''
      }&page=${page || ''}&perPage=${perPage || ''}&sort=${encodeURIComponent(
        JSON.stringify(sortBy)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDownloadableFiles(
    status = '',
    search = '',
    filters = undefined,
    page = 1,
    perPage = 20,
    sortBy = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/files/downloadable/getall?status=${status || ''}&search=${
        search || ''
      }&filters=${
        filters ? encodeURIComponent(JSON.stringify(filters)) : ''
      }&page=${page || ''}&perPage=${perPage || ''}&sort=${sortBy || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFileVersions(extId) {
    const response = await R3sFetch(
      `/api/1.0/files/versions/get?extId=${extId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async saveFile(files, visibility = null) {
    files = files instanceof Array ? files : [files]
    const response = await R3sFetch(`/api/1.0/files/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ files, visibility }),
    })
    return await handleFetchResponse(response)
  },

  async update(file) {
    const response = await R3sFetch(`/api/1.0/files/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ file }),
    })
    return await handleFetchResponse(response)
  },

  async addFileVersionAndActivate({ extId, filename }) {
    const response = await R3sFetch(`/api/1.0/files/version/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ extId, filename }),
    })
    return await handleFetchResponse(response)
  },

  async activateVersion(fileId) {
    const response = await R3sFetch(`/api/1.0/files/version/activate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: fileId }),
    })
    return await handleFetchResponse(response)
  },

  async getByEntity(table, id) {
    const response = await R3sFetch(
      `/api/1.0/files/entity/get?table=${table || ''}&entityId=${id || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createOrUpdateAndLinkToEntity(info, deleteFile = false) {
    const response = await R3sFetch(`/api/1.0/files/entity/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...info,
        operation: deleteFile ? 'delete' : 'add',
      }),
    })
    return await handleFetchResponse(response)
  },

  async processCsv(file) {
    const response = await R3sFetch(`/api/1.0/files/process/csv`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ file }),
    })
    return await handleFetchResponse(response)
  },

  async downloadCsv(
    status = '',
    search = '',
    filters = undefined,
    page = 1,
    perPage = 1e9,
    sortBy = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/files/download/csv?status=${status || ''}&search=${
        search || ''
      }&filters=${
        filters ? encodeURIComponent(JSON.stringify(filters)) : ''
      }&page=${page || ''}&perPage=${perPage || ''}&sort=${sortBy || ''}`
    )
    return await handleFetchResponse(response)
  },
}
