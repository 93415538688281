export default {
  myControlsProgressDonutData(state) {
    const allControls = state.mycontrols.controlGroups.data.reduce(
      (acc, curr) => {
        acc.push(curr)
        acc = acc.concat(curr.sub_controls)
        return acc
      },
      []
    )
    return {
      labels: state.mycontrols.implementationStatuses.map(
        (status) => status.text
      ),
      datasets: [
        {
          label: 'Progress',
          backgroundColor: state.mycontrols.implementationStatuses.map(
            (status) => state.dashboard.variantColors()[status.variant]
          ),
          borderColor: '#fff',
          data: state.mycontrols.implementationStatuses.map(
            (status) =>
              allControls.filter(
                (control) => control.implementation_status === status.value
              ).length
          ),
        },
      ],
    }
  },

  myControlsGapDonutData(state) {
    const allControls = state.mycontrols.controlGroups.data.reduce(
      (acc, curr) => {
        acc.push(curr)
        acc = acc.concat(curr.sub_controls)
        return acc
      },
      []
    )
    return {
      labels: state.mycontrols.operationalStatuses.map((status) => status.text),
      datasets: [
        {
          label: 'Gaps',
          backgroundColor: state.mycontrols.operationalStatuses.map(
            (status) => state.dashboard.variantColors()[status.variant]
          ),
          borderColor: '#fff',
          data: state.mycontrols.operationalStatuses.map(
            (status) =>
              allControls.filter(
                (control) => control.operational_status === status.value
              ).length
          ),
        },
      ],
    }
  },

  myControlsProgressBubbleData(state) {
    const allControls = state.mycontrols.controlGroups.data.reduce(
      (acc, curr) => {
        acc.push(curr)
        acc = acc.concat(curr.sub_controls)
        return acc
      },
      []
    )
    return state.mycontrols.implementationStatuses.map((status) => {
      const controlCountWithStatus = allControls.filter((control) => {
        return control.implementation_status === status.value
      }).length

      return {
        key: status.text,
        rawKey: status.value,
        value: controlCountWithStatus,
        subvalue:
          Math.round((100 * controlCountWithStatus) / allControls.length || 0) +
          '%',
        variant: status.variant,
      }
    })
  },

  myControlsGapBubbleData(state) {
    const allControls = state.mycontrols.controlGroups.data.reduce(
      (acc, curr) => {
        acc.push(curr)
        acc = acc.concat(curr.sub_controls)
        return acc
      },
      []
    )
    return state.mycontrols.operationalStatuses.map((status) => {
      const controlCountWithStatus = allControls.filter((control) => {
        return control.operational_status === status.value
      }).length

      return {
        key: status.text,
        rawKey: status.value,
        value: controlCountWithStatus,
        subvalue:
          Math.round((100 * controlCountWithStatus) / allControls.length || 0) +
          '%',
        variant: status.variant,
      }
    })
  },

  myControlsEvidenceBubbleData(state) {
    const evidence = state.mycontrols.evidence.data
    return state.mycontrols.evidenceStatuses.map((status) => {
      const controlCountWithStatus = evidence.filter((evidence) => {
        return evidence.status === status.value
      }).length
      return {
        key: status.text,
        rawKey: status.value,
        value: controlCountWithStatus,
        subvalue:
          Math.round((100 * controlCountWithStatus) / evidence.length || 0) +
          '%',
        variant: status.variant,
      }
    })
  },

  myControlsEvidenceDonutData(state) {
    const evidence = state.mycontrols.evidence.data
    return {
      labels: state.mycontrols.evidenceStatuses.map((status) => status.text),
      datasets: [
        {
          label: 'Gaps',
          backgroundColor: state.mycontrols.evidenceStatuses.map(
            (status) => state.dashboard.variantColors()[status.variant]
          ),
          borderColor: '#fff',
          data: state.mycontrols.evidenceStatuses.map(
            (status) =>
              evidence.filter((evidence) => {
                return evidence.status === status.value
              }).length
          ),
        },
      ],
    }
  },

  /**If user is meets the required permission level, allow
   * if not and assignees are passed in, ensure user is assigned
   * if not and no assignees are passed in, deny
   */
  userCanModify(state) {
    return (meetsPermissionLevel, assignees1, assignees2) => {
      const userId = state.auth.user.id

      if (meetsPermissionLevel) {
        return true
      } else if (!assignees1 && !assignees2) {
        return false
      } else {
        let allAssignees = []
        if (assignees1) {
          assignees1 = assignees1 instanceof Array ? assignees1 : [assignees1]
          allAssignees = assignees1
        }
        if (assignees2) {
          assignees2 = assignees2 instanceof Array ? assignees2 : [assignees2]
          allAssignees = allAssignees.concat(assignees2)
        }

        return allAssignees.some((assignee) => {
          const assigneeId = assignee.user_id || assignee.id
          return assigneeId == userId
        })
      }
    }
  },
}
