import Assessments from './assessments'
import ConnectedApp from './connectedApp'
import MyControls from './mycontrols'
import Projects from './projects'
import Questionnaires from './questionnaires'
import Reporting from './reporting'
import RiskRegister from './riskregister'
import VendorManagement from './vendormanagement'

export default {
  ...Assessments,
  ...ConnectedApp,
  ...MyControls,
  ...Projects,
  ...Questionnaires,
  ...Reporting,
  ...RiskRegister,
  ...VendorManagement,

  routeNamespace(state) {
    return state.router.currentRoute.path.split('/')[1]
  },

  currentModule(_, getters) {
    return {
      assessments: 'assessments',
      compliance: 'compliance_calendar',
      ir: 'incident_response',
      pci: 'pci',
      pentesting: 'penetration_testing',
      risk: 'risk_register',
      template: 'template_center',
      vulnerability: 'vulnerability',
    }[getters.routeNamespace]
  },

  currentModuleHasPadding(_, getters) {
    return getters.routeNamespace === 'settings' ? false : true
  },

  currentUserAllowedModuleList(state) {
    const perms = Object.keys(state.auth.userNavItems).reduce(
      (ary, resource) => {
        const obj = state.auth.userNavItems[resource]
        if (!(obj.canSee && obj.permission)) return ary

        return ary.concat([obj.permission])
      },
      []
    )

    return [...new Set(perms)].sort((p1, p2) => {
      if (p1.toLowerCase() < p2.toLowerCase()) return -1
      return 1
    })
  },

  // See `/root/src/libs/models/TeamsUsersRolesMap.js`
  // for more details on user types and roles
  isInternalUser(state) {
    const minPermissionCode = getPermissionCode(state, 'internal')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  // See `/root/src/libs/models/TeamsUsersRolesMap.js`
  // for more details on user types and roles
  isInternalLeadership(state) {
    const minPermissionCode = getPermissionCode(state, 'internal_leadership')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  // See `/root/src/libs/models/TeamsUsersRolesMap.js`
  // for more details on user types and roles
  isInternalManager(state) {
    const minPermissionCode = getPermissionCode(state, 'internal', 'manager')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  // See `/root/src/libs/models/TeamsUsersRolesMap.js`
  // for more details on user types and roles
  isInternalAdmin(state) {
    const minPermissionCode = getPermissionCode(state, 'internal', 'admin')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  userPermissionLevel(state) {
    return state.auth.permissionCode || 0
  },

  isAdmin(state) {
    const minPermissionCode = getPermissionCode(state, null, 'admin')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  isManager(state) {
    const minPermissionCode = getPermissionCode(state, null, 'manager')
    return (state.auth.permissionCode || 0) >= minPermissionCode
  },

  isAtLeastAdminRole(state) {
    const minPermissionCode = getPermissionCode(state, null, 'admin')
    const userRoleCode = getPermissionCode(state, null, state.auth.user.role)
    return userRoleCode >= minPermissionCode
  },

  isR3s(state) {
    return (
      state.session &&
      state.session.top_team &&
      state.session.top_team.id.toString() === '1'
    )
  },

  isCSOrDev(state) {
    return (
      state.session &&
      ['developer', 'customerSuccess'].includes(
        state.session.internal_user_type
      )
    )
  },

  currentTeamLogo(state) {
    if (
      !(
        state.session &&
        state.session.current_team &&
        state.session.current_team.logo_file
      )
    ) {
      return null
    }

    const normalLogo = state.session.current_team.logo_file
    const whiteLogo = state.session.current_team.logo_file_white
    const finalLogo = state.isDarkMode ? whiteLogo || normalLogo : normalLogo
    return encodeURIComponent(finalLogo)
  },

  stellarLogoSrc(state) {
    const normalLogo = `/public/images/stellar.png`
    const whiteLogo = `/public/images/stellar_white.png`
    return state.isDarkMode ? whiteLogo || normalLogo : normalLogo
  },

  isGrcCustomer(state, getters) {
    return (
      getters.isR3s ||
      (state.session &&
        state.session.current_team &&
        state.session.current_team.type &&
        state.session.current_team.type === 'grc')
    )
  },

  isEssentialsCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'essentials'
    )
  },

  isStartupCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'startup'
    )
  },

  isComplianceCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'compliance'
    )
  },

  isLegacyCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'legacy'
    )
  },

  isScalingCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'scaling'
    )
  },

  isEnterpriseCustomer(state) {
    return (
      state.session &&
      state.session.current_team &&
      state.session.current_team.subscription_type &&
      state.session.current_team.subscription_type === 'enterprise'
    )
  },

  teamInternalUsers(state) {
    return state.team.teamAndInternalUsers.filter(
      (u) => u.user_type !== 'external'
    )
  },

  assessmentUsersAndProjectTeam(state) {
    return [
      ...state.team.users,
      ...state.assessments.currentProjectTeam.map((u) => ({
        ...u,
        id: u.user_id,
      })),
    ]
      .reduce(
        (users, user) =>
          users.map((u) => u.user_id || u.id).includes(user.id)
            ? users
            : users.concat([user]),
        []
      )
      .sort((u1, u2) => {
        const name1 = u1.name || u1.username_email
        const name2 = u2.name || u2.username_email
        if (name1.toLowerCase() < name2.toLowerCase()) return -1
        return 1
      })
  },

  riskRegisterRiskScoreBubbleInfo(state) {
    return state.riskregister.riskRegisterRiskScoreInfo(
      state.riskregister.items.data
    )
  },

  questionnaireScoreBubbleInfo(state) {
    return state.questionnaire.questionnaireScoreBubbleInfo(
      state.questionnaire.scoring
    )
  },

  subscriptionRestrictions(state) {
    return state.session.subscription_restrictions
  },
}

function getPermissionCode(state, type, role) {
  let typeCode = 0
  let roleCode = 0
  if (type) {
    try {
      typeCode = state.userTypes.find((ut) => ut.val === type).permissionCode
    } catch {
      typeCode = 9999
    }
  }
  if (role) {
    try {
      roleCode = state.userRoles.find((ut) => ut.val === role).permissionCode
    } catch {
      roleCode = 9999
    }
  }
  return typeCode + roleCode
}
