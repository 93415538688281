import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getOrgs() {
    const response = await R3sFetch(`/api/1.0/github/orgs/all`)
    return await handleFetchResponse(response)
  },

  async getRepos() {
    const response = await R3sFetch(`/api/1.0/github/repos/all`)
    return await handleFetchResponse(response)
  },

  async getPullRequests(state = 'all') {
    const response = await R3sFetch(
      `/api/1.0/github/pull/requests/all?state=${state}`
    )
    return await handleFetchResponse(response)
  },
}
