import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllTags(teamId) {
    const response = await R3sFetch(`/api/1.0/tags/get?teamId=${teamId || ''}`)
    return await handleFetchResponse(response)
  },

  async getAllTagsByEntityTypes(entityTypes) {
    const response = await R3sFetch(`/api/1.0/tags/getAllByEntityTypes`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ entityTypes: entityTypes }),
    })
    return await handleFetchResponse(response)
  },

  async createNew(tagInfo, overrideTeamId = null) {
    const response = await R3sFetch(`/api/1.0/tags/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...tagInfo, teamId: overrideTeamId }),
    })
    return await handleFetchResponse(response)
  },

  async editTag(tagInfo, overrideTeamId = null) {
    const response = await R3sFetch(`/api/1.0/tags/edit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...tagInfo, teamId: overrideTeamId }),
    })
    return await handleFetchResponse(response)
  },

  async deleteTag(tagId) {
    const response = await R3sFetch(`/api/1.0/tags/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: tagId }),
    })
    return await handleFetchResponse(response)
  },

  async updateEntity({ tagId, table, entityId, operation, overrideTeamId }) {
    const response = await R3sFetch(`/api/1.0/tags/update/entity`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tagId,
        table,
        entityId,
        operation,
        overrideTeamId,
      }),
    })
    return await handleFetchResponse(response)
  },

  async addTagToEntities(tagId, entityIds, table) {
    const response = await R3sFetch(`/api/1.0/tags/add/entities/bulk`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tagId, entityIds, table }),
    })
    return await handleFetchResponse(response)
  },
}
