import ApiIntegrations from '../../factories/ApiIntegrations'

export default {
  /** If Jira Sync is Configured, gets available Jira projects to sync to */
  async getJiraProjects({ state, commit }, reset = false) {
    if (state.jira.availableProjects > 0 && !reset) return

    const availableJiraProjects =
      await ApiIntegrations.getAvailableJiraProjects()
    commit('SET_JIRA_PROJECTS', availableJiraProjects)
  },
}
