export default {
  SET_EXTENSION_TYPES(state, types) {
    state.extensions.types = types
  },

  SET_EXTENSIONS(state, extAry) {
    state.extensions.list = extAry
  },

  SET_EXTENSION_TEMPLATES(state, extAry) {
    state.extensions.templates = extAry
  },

  SET_CURRENT_APP_INSTANCES(state, instances) {
    state.extensions.currentApp.instances = instances
  },

  SET_CURRENT_APP_EXTENSION_ID(state, extensionId) {
    state.extensions.currentApp.extension_id = extensionId
  },

  SET_CURRENT_APP_LOGO_FILE(state, logo) {
    state.extensions.currentApp.logo_file = logo
  },

  SET_CURRENT_APP_TYPE(state, appType) {
    state.extensions.currentApp.extension_type = appType
  },

  SET_CURRENT_APP_EXECUTIONS(state, { executionData, instanceId }) {
    state.extensions.currentApp.currentInstance.id = instanceId
    state.extensions.currentApp.currentInstance.executions = executionData
    state.extensions.currentApp.currentInstance.exceptions = {
      fetched: false,
      audit: {},
    }
  },

  RESET_CURRENT_APP_EXECUTION(state) {
    state.extensions.currentApp.currentInstance.currentExecution = {
      metadata: {},
      summary: {},
      results: {},
      inventory: {},
      exceptions: {},
    }
  },

  SET_CURRENT_APP_EXECUTION_SUMMARY(
    state,
    { id, summary, date, status, output_status }
  ) {
    state.extensions.currentApp.currentInstance.currentExecution = {
      ...state.extensions.currentApp.currentInstance.currentExecution,
      id,
      summary,
      date,
      status,
      output_status,
    }
  },

  SET_CURRENT_APP_EXECUTION_SERVICE(state, { serviceKey, serviceResults }) {
    state.extensions.currentApp.currentInstance.currentExecution.results = {
      ...state.extensions.currentApp.currentInstance.currentExecution.results,
      [serviceKey]: serviceResults,
    }
  },

  SET_CURRENT_APP_EXECUTION_EXCEPTIONS(state, exceptions) {
    state.extensions.currentApp.currentInstance.currentExecution.exceptions =
      exceptions
  },

  SET_CURRENT_APP_EXECUTION_METADATA(state, metadata) {
    state.extensions.currentApp.currentInstance.currentExecution.metadata =
      metadata
  },

  SET_CURRENT_APP_EXECUTION_INVENTORY(state, inventory) {
    state.extensions.currentApp.currentInstance.currentExecution.inventory =
      inventory
  },

  SET_CURRENT_APP_EXCEPTIONS(state, exceptions) {
    state.extensions.currentApp.currentInstance.exceptions = exceptions
    state.extensions.currentApp.currentInstance.exceptions.fetched = true
  },
}
