<template>
  <div id="sticky-notes-board" class="board" :style="boardBg">
    <div class="notes">
      <div class="d-flex align-items-center">
        <h1 @click="addNote('positive')" class="add-sticky-note display-5 mr-3 clickable">
          <i class="fal fa-plus-circle" />
        </h1>
        <h1>{{ teamName }} Notes</h1>
        <div class="ml-4">
          <input
            id="sticky-notes-toggle-deleted"
            type="checkbox"
            v-model="includeDeletedNotes"
            @change="toggleDeletedNotes" />
          <label for="sticky-notes-toggle-deleted">Include Deleted Notes</label>
        </div>
      </div>
      <div style="width: 400px;">
        <custom-slider id="sticky-notes-board-opacity" class="mt-4" v-model="opacity"></custom-slider>
        <label class="m-0">Background Opacity</label>
      </div>
      <div class="empty-state" v-show="!board.notes.length">no sticky notes created yet for {{ teamName }}</div>
      <note
        v-for="note in board.notes"
        :key="note.id"
        :content="note.note"
        :type="note.noteType"
        :position="note.position"
        :id="note.id"
        :note-size="note.noteSize"
        :font-size="note.fontSize"
        :votes="note.votes"
        :order="note.order"
        :active="note.id == activeDrag"
        :created-by="note.created_by_name"
        :created-at="note.created_at"
        :is-deleted="note.is_deleted"
        @update="updateNote"
        @remove-note="removeNote"
        @stop-drag="stopDrag"
        @start-drag="startDrag">
        </note>
    </div>
    <h1 @click="saveAllNotes" class="close-board close-sticky-notes-board display-5 clickable">
      <i class="fal fa-times-circle" />
    </h1>
  </div>
</template>

<script>
// http://pepf.nl/retrospective/
import Note from './Note'
import Positioner from './positioner'
import ApiAdmin from '../../factories/ApiAdmin'

export default {
  props: {
    board: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activeDrag: null,
      includeDeletedNotes: this.$store.state.stickyNotesIncludeDeleted || false,
      opacity: 80
    }
  },

  beforeMount () {
    this.positioner = new Positioner()
    this.$on('reset-active', () => {
      this.activeDrag = null
    })
  },

  watch: {
    'board.notes': function (notes) {
      this.positioner.setGrid(notes)
    }
  },

  computed: {
    boardBg() {
      return { background: `rgba(255, 255, 255, ${this.opacity / 100})` }
    },

    teamName() {
      return ((this.$store.state.session || {}).current_team || {}).name
    }
  },

  methods: {
    startDrag (id) {
      var maxOrder = this.getMaxOrder()
      var note = this.getNoteById(id)

      // Set as active
      this.activeDrag = id

      // This note already is the top one, dont add 1
      if (note.order === maxOrder && maxOrder > 0) {
        return
      } else {
        this.updateNote(id, {order: maxOrder + 1})
      }
    },

    stopDrag (id) {
      this.updateNote(id, {active: false})
    },

    toggleDeletedNotes() {
      this.$store.commit('SET_STICKY_NOTES_DELETED', this.includeDeletedNotes)
      this.$emit('reset')
    },

    getNoteById (id) {
      return this.board.notes.find(function (note) {
        return id === note.id
      })
    },

    updateNote (id, update) {
      var note = this.getNoteById(id)
      
      // The whole board is not "initial" anymore
      if (this.board.initial)
        delete(this.board.initial)

      // Update note properties
      Object.assign(note, update)
      return note
    },

    getMaxOrder () {
      return this.board.notes.reduce(function (prev, value) {
        if (typeof value.order === 'undefined') { return prev }
        return (prev > value.order ? prev : value.order)
      }, 0)
    },

    async addNote(type) {
      var terciary = 0
      let options = {
        noteType: type,
        position: this.positioner.getPositionforNew(terciary),
        noteSize: { w: 350, h: 250 },
        fontSize: 1,
        votes: 0,
        order: this.getMaxOrder() + 1
      }

      const { note } = await ApiAdmin.createOrUpdateOrgStickyNotes({
        note: "This is a new note...",
        options
      })

      this.board.notes.push({ ...options, ...note })
    },

    async removeNote(id) {
      await ApiAdmin.deleteOrgStickyNotes(id)
      const noteIndex = this.board.notes.findIndex(note => id === note.id)
      this.board.notes[noteIndex].is_deleted = true
      // this.board.notes.splice(noteIndex, 1)
    },

    async saveAllNotes() {
      this.$store.commit('TOGGLE_STICKY_NOTES')
      await ApiAdmin.updateAllOrgStickyNotes(this.board.notes)
    }
  },

  components: {
    Note
  }
}
</script>

<style src="./common.scss" lang="scss" scoped></style>