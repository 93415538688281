import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllTeams() {
    const response = await R3sFetch(`/api/1.0/admin/teams`)
    return await handleFetchResponse(response)
  },

  async getIntegrations() {
    const response = await R3sFetch(`/api/1.0/admin/master/integrations/get`)
    return await handleFetchResponse(response)
  },

  async getTimeTrackingData(
    startDate = null,
    endDate = null,
    customerId = null,
    employeeId = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/admin/timetracking?startDate=${startDate ||
        ''}&endDate=${endDate || ''}&customerId=${customerId ||
        ''}&employeeId=${employeeId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getContributionData(
    customerId = null,
    startDate = null,
    endDate = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/admin/contribution/data?customerId=${customerId ||
        ''}&startDate=${startDate || ''}&endDate=${endDate || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getUserTeamsPermissions(
    teamId = null,
    onlyInternal = false,
    allRoles = false
  ) {
    const response = await R3sFetch(
      `/api/1.0/admin/users/module/permissions?teamId=${teamId ||
        ''}&onlyInternal=${onlyInternal || ''}&allRoles=${allRoles || ''}`
    )
    return await handleFetchResponse(response)
  },

  async assumeUser(userId) {
    const response = await R3sFetch(`/api/1.0/admin/assume/user?id=${userId}`)
    return await handleFetchResponse(response)
  },

  async unassumeUser() {
    const response = await R3sFetch(`/api/1.0/admin/unassume/user`)
    return await handleFetchResponse(response)
  },

  async getLoggedInUsers() {
    const response = await R3sFetch(`/api/1.0/admin/users`)
    return await handleFetchResponse(response)
  },

  async getCustomerExternalScans() {
    const response = await R3sFetch(`/api/1.0/admin/customer/scans/external`)
    return await handleFetchResponse(response)
  },

  async getExternalScanNode() {
    const response = await R3sFetch(`/api/1.0/admin/scans/external/node`)
    return await handleFetchResponse(response)
  },

  async getGapsByControl(engagementType, page, perPage) {
    const response = await R3sFetch(
      `/api/1.0/admin/assessments/top/gaps/by/control?type=${engagementType ||
        ''}&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getGapsByCategory(engagementType, page, perPage) {
    const response = await R3sFetch(
      `/api/1.0/admin/assessments/top/gaps/by/category?type=${engagementType ||
        ''}&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getGapsByCategoryAndRisk(engagementType, page, perPage) {
    const response = await R3sFetch(
      `/api/1.0/admin/assessments/top/gaps/by/category/riskrank?type=${engagementType ||
        ''}&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getOrgProgramMaturity(engagementType, page, perPage) {
    const response = await R3sFetch(
      `/api/1.0/admin/assessments/org/program/maturity?type=${engagementType ||
        ''}&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateExternalNode(id) {
    const response = await R3sFetch(`/api/1.0/admin/update/external/node`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    })
    return await handleFetchResponse(response)
  },

  async scheduleExternalScan(options) {
    const response = await R3sFetch(`/api/1.0/admin/scan/external/schedule`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    })
    return await handleFetchResponse(response)
  },

  async getOrgStickyNotes(includeDeleted = false) {
    const response = await R3sFetch(
      `/api/1.0/admin/sticky/notes/get?all=${includeDeleted || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createOrUpdateOrgStickyNotes(info) {
    const response = await R3sFetch(`/api/1.0/admin/sticky/notes/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async updateAllOrgStickyNotes(notes) {
    const response = await R3sFetch(`/api/1.0/admin/sticky/notes/update/all`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ notes }),
    })
    return await handleFetchResponse(response)
  },

  async deleteOrgStickyNotes(id) {
    const response = await R3sFetch(`/api/1.0/admin/sticky/notes/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    })
    return await handleFetchResponse(response)
  },
}
