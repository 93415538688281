import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllPages(templates = false) {
    const response = await R3sFetch(
      `/api/1.0/wiki/pages/all${templates ? `?templates=true` : ''}`
    )
    return await handleFetchResponse(response)
  },
  async getOnlyPages(templates = false) {
    const response = await R3sFetch(
      `/api/1.0/wiki/only/pages/all${templates ? `?templates=true` : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getPage(id, version) {
    const response = await R3sFetch(
      `/api/1.0/wiki/pages/get?id=${id}${version ? `&version=${version}` : ''}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdatePage(page) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page }),
    })
    return await handleFetchResponse(response)
  },

  async orderPage(page) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/ordering`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page }),
    })
    return await handleFetchResponse(response)
  },

  async createPageFromTemplate(
    templateId,
    versionId,
    name,
    description,
    parentRecordId
  ) {
    const response = await R3sFetch(
      `/api/1.0/wiki/pages/create/from/template`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          templateId,
          versionId,
          name,
          description,
          parentRecordId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async downloadContent(id, version, type = 'docx') {
    const response = await R3sFetch(
      `/api/1.0/wiki/pages/download?id=${id}&version=${version}&type=${type}`
    )
    return await handleFetchResponse(response)
  },

  async publishPage(pageId, versionId, version) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/publish`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pageId, versionId, version }),
    })
    return await handleFetchResponse(response)
  },

  async unpublishPage(pageId, versionId) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/unpublish`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pageId, versionId }),
    })
    return await handleFetchResponse(response)
  },

  async editVersion(pageId, versionId, version) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/version/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pageId, versionId, version }),
    })
    return await handleFetchResponse(response)
  },

  async reviewPages(pages) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/review`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pages }),
    })
    return await handleFetchResponse(response)
  },

  async addPageUser(id, userId) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/user/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, userId }),
    })
    return await handleFetchResponse(response)
  },

  async bulkUploadPageUsers(pageId, filename) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/users/add/bulk`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pageId, filename }),
    })
    return await handleFetchResponse(response)
  },

  async removePageUser(id, userId) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/user/remove`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, userId }),
    })
    return await handleFetchResponse(response)
  },

  async sendPageUserEmail(userMapIds) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/users/email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userMapIds }),
    })
    return await handleFetchResponse(response)
  },

  async exportPages() {
    const response = await R3sFetch(`/api/1.0/wiki/pages/export`)
    return await handleFetchResponse(response)
  },

  async exportPageUsers(pageId, versionId) {
    const response = await R3sFetch(`/api/1.0/wiki/pages/users/export`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pageId, versionId }),
    })
    return await handleFetchResponse(response)
  },
}
