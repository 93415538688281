import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getOauthBindings() {
    const response = await R3sFetch(`/api/1.0/global/oauth/bindings/get`)
    return await handleFetchResponse(response)
  },

  async getMainNotification() {
    const response = await R3sFetch(`/api/1.0/global/main/notification`)
    return await handleFetchResponse(response)
  },

  async getFeatureFlags() {
    const response = await R3sFetch(`/api/1.0/global/feature/flags/enabled`)
    return await handleFetchResponse(response)
  },

  async dismissMainNotification() {
    const response = await R3sFetch(`/api/1.0/global/dismiss/main/notification`)
    return await handleFetchResponse(response)
  },

  async setRedirectUrl(url) {
    const response = await R3sFetch(`/api/1.0/global/redirect/url/set`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url }),
    })
    return await handleFetchResponse(response)
  },

  async getModulePermissionList() {
    const response = await R3sFetch(`/api/1.0/global/module/permissions/list`)
    return await handleFetchResponse(response)
  },

  async getInternalUsers() {
    const response = await R3sFetch(`/api/1.0/global/internal/users/get`)
    return await handleFetchResponse(response)
  },

  async sendFeedback(feedback, files) {
    const response = await R3sFetch(`/api/1.0/global/feedback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ feedback, files }),
    })
    return await handleFetchResponse(response)
  },

  async getAllFeedback(showAll, filters = null, page = 1, perPage = 10) {
    filters = encodeURIComponent(JSON.stringify(filters))
    const response = await R3sFetch(
      `/api/1.0/global/feedback/all?showAll=${showAll || ''}&filters=${
        filters || ''
      }&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFeedback(feebackId) {
    const response = await R3sFetch(
      `/api/1.0/global/feedback/get?id=${feebackId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateFeedback(feedback) {
    const response = await R3sFetch(`/api/1.0/global/feedback/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ feedback }),
    })
    return await handleFetchResponse(response)
  },

  async feedbackNotify(feedback) {
    const response = await R3sFetch(`/api/1.0/global/feedback/notify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ feedback }),
    })
    return await handleFetchResponse(response)
  },

  async linkRequests(r1Id, r2Id) {
    const response = await R3sFetch(`/api/1.0/global/feedback/requests/link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ r1Id, r2Id }),
    })
    return await handleFetchResponse(response)
  },

  async unlinkRequests(r1Id, r2Id) {
    const response = await R3sFetch(
      `/api/1.0/global/feedback/requests/unlink`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ r1Id, r2Id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async linkPullRequest(srId, prId) {
    const response = await R3sFetch(
      `/api/1.0/global/feedback/pull/requests/link`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ srId, prId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async unlinkPullRequest(srId, prId) {
    const response = await R3sFetch(
      `/api/1.0/global/feedback/pull/requests/unlink`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ srId, prId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getR3sContent() {
    const response = await R3sFetch(`/api/1.0/global/r3s/content/get`)
    return await handleFetchResponse(response)
  },

  async getR3sContentTypes() {
    const response = await R3sFetch(`/api/1.0/global/r3s/content/types/get`)
    return await handleFetchResponse(response)
  },

  async createR3sContent(content) {
    const response = await R3sFetch(`/api/1.0/global/r3s/content/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ content }),
    })
    return await handleFetchResponse(response)
  },

  async bulkUploadR3sContent(filename) {
    const response = await R3sFetch(`/api/1.0/global/r3s/content/bulk/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filename }),
    })
    return await handleFetchResponse(response)
  },

  async getECSMetadata() {
    const response = await R3sFetch(`/api/1.0/global/r3s/ecs/metadata`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    return await handleFetchResponse(response)
  },

  async saveGlobalCommunicationPreferences(preferences, targetRoleVal) {
    const response = await R3sFetch(
      `/api/1.0/global/settings/communication/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ preferences, targetRoleVal }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getGlobalCommunicationPreferences(targetRoleVal) {
    const response = await R3sFetch(
      `/api/1.0/global/settings/communication/get${
        targetRoleVal ? `?targetRoleVal=${targetRoleVal}` : ''
      }`
    )
    return await handleFetchResponse(response)
  },
}
