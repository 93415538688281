import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getActivePgpKeys() {
    const response = await R3sFetch(`/api/1.0/messaging/keys/get`)
    return await handleFetchResponse(response)
  },

  async getUnreadCount() {
    const response = await R3sFetch(`/api/1.0/messaging/unread`)
    return await handleFetchResponse(response)
  },

  async getRooms(allOrgs=false) {
    const response = await R3sFetch(`/api/1.0/messaging/rooms/get?all=${allOrgs || ''}`)
    return await handleFetchResponse(response)
  },

  async getRoomMessages(userIds, page=1, perPage=20) {
    const response = await R3sFetch(`/api/1.0/messaging/messages/get?userIds=${JSON.stringify(userIds)}&page=${page || ''}&perPage=${perPage || ''}`)
    return await handleFetchResponse(response)
  },

  async saveMessage(info) {
    const response = await R3sFetch(`/api/1.0/messaging/message/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  },

  async reviewRoom(messageId) {
    const response = await R3sFetch(`/api/1.0/messaging/room/review`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: messageId })
    })
    return await handleFetchResponse(response)
  }
}
