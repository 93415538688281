export default {
  SET_CALENDAR_EVENTS(state, events) {
    state.compliance.calendar.events = events
  },

  SET_CALENDAR_TEMPLATE_EVENTS(state, events) {
    state.compliance.calendar.templateEvents = events
  },

  SET_CALENDAR_TEMPLATE_GROUPS(state, groups) {
    state.compliance.calendar.templateGroups = groups
  },

  SET_CALENDAR_CURRENT_EVENT(state, event) {
    state.compliance.calendar.currentEvent = event
  },

  SET_CALENDAR_EVENT_STATUSES(state, statuses) {
    state.compliance.calendar.statuses = statuses
  },

  SET_CALENDAR_SHOW_ALL_EVENTS(state, show=false) {
    state.compliance.calendar.showAllEvents = show
  },

  SET_CALENDAR_SHOW_TEMPLATE_EVENTS(state, show=false) {
    state.compliance.calendar.showTemplateEvents = show
  },

  SET_CALENDAR_DATES(state, { startDate, endDate }) {
    state.compliance.calendar.startDate = startDate
    state.compliance.calendar.endDate = endDate
  },

  SET_CALENDAR_EVENTS_FILTER(state, { key, val }) {
    state.compliance.calendar.filters[key] = val
  },

  SET_CALENDAR_INTEGRATIONS(state, types) {
    state.compliance.calendar.integrations = types
  },

  SET_CALENDAR_EVENTS_RECENT_COLORS(state, colors) {
    state.compliance.calendar.recentColors = colors
  }
}
