import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getDefaultColumns() {
    const response = await R3sFetch(`/api/1.0/vulnerability/columns/default`)
    return await handleFetchResponse(response)
  },

  async getScans(getArchived=null) {
    const response = await R3sFetch(`/api/1.0/vulnerability/get?archived=${getArchived || ''}`)
    return await handleFetchResponse(response)
  },

  async getSingleScan(id) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getSingleScanOverallKpis(id, onlyOpen=null) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/overall/kpis?id=${id}&open=${onlyOpen || ''}`)
    return await handleFetchResponse(response)
  },

  async getSingleScanItem(id) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/item/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getItemChangeLog(id) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/item/changes?id=${id}`)
    return await handleFetchResponse(response)
  },

  async createScanActionItem(id, itemId, info) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/item/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, itemId, info })
    })
    return await handleFetchResponse(response)
  },

  async bulkAddItemsFromCsv(id, csvInfo) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/bulk/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, info: csvInfo })
    })
    return await handleFetchResponse(response)
  },

  async getFields(cachedFieldInfo=null, reset=false) {
    if (cachedFieldInfo && cachedFieldInfo.length > 0 && !reset)
      return { fields: cachedFieldInfo }

    const response = await R3sFetch(`/api/1.0/vulnerability/fields/get`)
    return await handleFetchResponse(response)
  },

  async createField(name) {
    const response = await R3sFetch(`/api/1.0/vulnerability/fields/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    })
    return await handleFetchResponse(response)
  },

  async getScanItemNotes(id) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/notes?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getItems(vulnId, filters={}, page=1, pageSize=30, sortCol=null, sortDir=null) {
    const response = await R3sFetch(`/api/1.0/vulnerability/items/get?id=${vulnId}&filters=${encodeURIComponent(JSON.stringify(filters))}&page=${page}&pageSize=${pageSize}&sortCol=${sortCol || ''}&sortDir=${sortDir || ''}`)
    return await handleFetchResponse(response)
  },

  async getSeveritySlas() {
    const response = await R3sFetch(`/api/1.0/vulnerability/severity/slas`)
    return await handleFetchResponse(response)
  },

  async updateSeveritySla(severity, numDays) {
    const response = await R3sFetch(`/api/1.0/vulnerability/severity/slas/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ severity, numDays })
    })
    return await handleFetchResponse(response)
  },

  async save(data, id=null, pentestId=null) {
    const response = await R3sFetch(`/api/1.0/vulnerability/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data, id, pentestId })
    })
    return await handleFetchResponse(response)
  },

  async update(data) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data })
    })
    return await handleFetchResponse(response)
  },

  // async updateItem(item) {
  //   const response = await R3sFetch(`/api/1.0/vulnerability/item/update`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(item)
  //   })
  //   return await handleFetchResponse(response)
  // },

  async bulkUpdateItems(itemIds, bulkInfo) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/update/bulk`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items: itemIds, info: bulkInfo })
    })
    return await handleFetchResponse(response)
  },

  async addNote(id, note, mentions) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/note/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, note, mentions })
    })
    return await handleFetchResponse(response)
  },

  async updateNote(id, note) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/note/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, note })
    })
    return await handleFetchResponse(response)
  },

  async acknowledgeNoteMention(mentionId) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/note/mention/ack`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: mentionId })
    })
    return await handleFetchResponse(response)
  },

  async sendNoteMentionReminder(mentionId) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/note/mention/reminder/send`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: mentionId })
    })
    return await handleFetchResponse(response)
  },

  async sendEmailToAssignedUser(itemId, additionalText='') {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/email/assigned`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: itemId, text: additionalText })
    })
    return await handleFetchResponse(response)
  },

  async changeHostItemMapInfo(itemId, hostId, mapInfo) {
    const response = await R3sFetch(`/api/1.0/vulnerability/item/host/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...mapInfo, itemId, hostId })
    })
    return await handleFetchResponse(response)
  },

  async getSeverityKpisForScans(ids, onlyOpen='') {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/severity/kpis?id=${encodeURIComponent(JSON.stringify(ids))}&open=${onlyOpen}`)
    return await handleFetchResponse(response)
  },

  async getStatusKpisForScans(ids) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scan/status/kpis?id=${encodeURIComponent(JSON.stringify(ids))}`)
    return await handleFetchResponse(response)
  },

  async getDashboardItemSummary(groupBy='status') {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/item/summary?group=${groupBy}`)
    return await handleFetchResponse(response)
  },

  async getDashboardHostSummary(howMany=10) {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/ip/severity/summary?num=${howMany}`)
    return await handleFetchResponse(response)
  },

  async getDashboardOwnerSummary() {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/owner/summary`)
    return await handleFetchResponse(response)
  },

  async getDashboardSeveritySummary() {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/severity/summary`)
    return await handleFetchResponse(response)
  },

  async getDashboardTimeToCompleteSummary() {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/timetocompletion/summary`)
    return await handleFetchResponse(response)
  },

  async getDashboardOpenItemsOverTimeSummary() {
    const response = await R3sFetch(`/api/1.0/vulnerability/dashboard/openitems/overtime`)
    return await handleFetchResponse(response)
  },

  async getSlaSeverityReport(severity='', userId='') {
    const response = await R3sFetch(`/api/1.0/vulnerability/reports/slas/severity?severity=${severity || ''}&user_id=${userId || ''}`)
    return await handleFetchResponse(response)
  },

  async getSlaOverdueReport({ severity, userId }={}) {
    const response = await R3sFetch(`/api/1.0/vulnerability/reports/slas/overdue?severity=${severity || ''}&user_id=${userId || ''}`)
    return await handleFetchResponse(response)
  },

  async getScopingConfig() {
    const response = await R3sFetch(`/api/1.0/vulnerability/scoping/get`)
    return await handleFetchResponse(response)
  },

  async updateScopingConfig(info) {
    const response = await R3sFetch(`/api/1.0/vulnerability/scoping/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  },
}
