export default {
  teamVendorsRiskScoreInfo(state) {
    return state.vendormanagement.teamVendorsRiskScoreInfo(
      state.vendormanagement.teamVendors.data
    )
  },

  questionnairesStatusInfo(state) {
    return state.vendormanagement.questionnairesStatusInfo(
      state.vendormanagement.allQuestionnaires.data,
      state.vendormanagement.questionnaireStatuses
    )
  },

  reviewStatusInfo(state) {
    return state.vendormanagement.reviewsStatusInfo(
      state.vendormanagement.allReviews.data,
      state.vendormanagement.reviewStatuses
    )
  },
}
