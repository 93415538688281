export function addRockyCodeEventListener(callback) {
  document.addEventListener('keydown', rockyCode(callback), false)
}

let keyCodeSequenceCache = correctSequence()

function rockyCode(callback) {
  return function evListener(e) {
    const code = e.keyCode
    const [next] = keyCodeSequenceCache.splice(0, 1)
    if (code !== next) keyCodeSequenceCache = correctSequence()

    if (keyCodeSequenceCache.length === 0) {
      callback()
      keyCodeSequenceCache = correctSequence()
    }
  }
}

function correctSequence() {
  const { y, o, a, d, r, i, n } = keyCodeMap()
  return [y, o, a, d, r, i, a, n]
}

function keyCodeMap() {
  return {
    y: 89,
    o: 79,
    a: 65,
    d: 68,
    r: 82,
    i: 73,
    n: 78,
  }
}
