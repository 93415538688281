export default {
  SET_INVENTORY_SHOW_TEMPLATES(state, templates) {
    state.inventories.showTemplates = templates
  },

  SET_INVENTORY_LIST(state, bias) {
    state.inventories.allBias = bias
  },

  SET_CURRENT_INVENTORY(state, bia) {
    state.inventories.currentInventory = bia
  },

  SET_ASSESSMENT_CONTROLS(state, controlAry) {
    state.inventories.assessmentControls = {
      ...state.inventories.assessmentControls,
      ...controlAry.reduce(
        (obj, control) => ({
          ...obj,
          [control.id]: control,
        }),
        {}
      ),
    }
  },

  SET_RISKS(state, riskAry) {
    state.inventories.risks = {
      ...state.inventories.risks,
      ...riskAry.reduce(
        (obj, risk) => ({
          ...obj,
          [risk.id]: risk,
        }),
        {}
      ),
    }
  },

  SET_INVENTORY_FILTERS(state, { fieldId, values }) {
    const invId = state.inventories.currentInventory.id
    state.inventories.filters = {
      ...state.inventories.filters,
      [invId]: {
        ...state.inventories.filters[invId],
        [fieldId]: values,
      },
    }
  },
}
