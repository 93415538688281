export default {
  inventories: {
    error: null,

    showTemplates: false,
    allBias: [],
    currentInventory: {},

    filters: {
      // [spreadsheetId]: {
      //   [fieldId]: [ary of values to filter on]
      // }
    },

    assessmentControls: {
      // [controlId]: { ...controlRecord }
      // key/val pairs of control information to display in inventory that can
      // be fetched at the time a inventory is navigated to
    },

    risks: {
      // [riskId]: { ...riskRecord }
      // key/val pairs of risk information to display in inventory that can
      // be fetched at the time a inventory is navigated to
    },
  },
}
