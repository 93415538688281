export default {
  SET_ALL_FEEDBACK(state, feedback) {
    state.feedback.allFeedback = feedback
  },

  SET_FEEDBACK_CURRENT_FEEDBACK(state, feedback) {
    state.feedback.currentFeedback = feedback
  },

  SET_FEEDBACK_STATUSES(state, statuses) {
    state.feedback.statuses = statuses
  },

  SET_FEEDBACK_USERS(state, users) {
    state.feedback.allUsers = users
  },

  SET_FEEDBACK_INTERNAL_USERS(state, users) {
    state.feedback.internalUsers = users
  },

  SET_FEEDBACK_SHOW_ALL_FEEDBACK(state, show = false) {
    state.feedback.showAllFeedback = show
  },

  SET_FEEDBACK_FILTER(state, { key, val }) {
    state.feedback.filters[key] = val
  },

  SET_FEEDBACK_PAGE(state, page) {
    state.feedback.allFeedback.currentPage = page
  },

  SET_FEEDBACK_PER_PAGE(state, perPage) {
    state.feedback.allFeedback.perPage = perPage
  },

  SET_FEEDBACK_PULL_REQUESTS(state, pullRequests) {
    state.feedback.allPullRequests = pullRequests
  },
}
