export default {
  SET_FILE_SEARCH(state, search) {
    state.files.fileSearch = search
  },

  SET_FILE_SORTING(state, { key, val }) {
    state.files.fileSorting = {}
    state.files.fileSorting[key] = val
  },

  SET_FILE_FILTERS(state, filters) {
    state.files.filters = filters
  },

  SET_FILE_PAGE(state, newPage) {
    state.files.files.currentPage = newPage
  },

  SET_FILE_PER_PAGE(state, newPerPage) {
    state.files.files.perPage = newPerPage
  },

  SET_FILES(state, files) {
    state.files.files = files
  },

  SET_AVAILABLE_ASSESSMENTS(state, assessments) {
    state.files.availableAssessments = assessments
  },

  SET_AVAILABLE_FRAMEWORKS(state, frameworks) {
    state.files.availableFrameworks = frameworks
  },
}
