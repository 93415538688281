export default {
  SET_PROJECT_ERROR(state, error) {
    state.projects.error = error
  },

  SET_PROJECTS(state, registers) {
    state.projects.projects = registers
  },

  SET_PROJECT(state, record) {
    state.projects.currentProject = record
  },

  SET_PROJECT_GROUPS(state, record) {
    state.projects.groups = record
  },

  SET_PROJECT_STATUSES(state, statuses) {
    state.projects.statuses = statuses
    state.projects.taskFilters.status =
      state.projects?.taskFilters?.status?.length > 0
        ? state.projects.taskFilters.status
        : statuses.map((s) => s.value)
  },

  SET_PROJECT_TASK_TYPES(state, taskTypes) {
    state.projects.taskTypes = taskTypes
    state.projects.taskFilters.taskTypes =
      state.projects?.taskFilters?.taskTypes?.length > 0
        ? state.projects.taskFilters.taskTypes
        : taskTypes.filter((t) => t.id === 'tasks').map((t) => t.id)
  },

  SET_PROJECT_ALL_SCOPE_GROUPS(state, scopeGroups) {
    state.projects.allScopeGroups = scopeGroups
  },

  // SET_PROJECT_POLICIES(state, policiesByKey) {
  //   state.projects.acceptableRisk = policiesByKey.acceptable_risk
  //   state.projects.impactScores = policiesByKey.impact
  //   state.projects.likelihoodScores = policiesByKey.likelihood
  //   state.projects.costScores = policiesByKey.cost
  //   state.projects.effortScores = policiesByKey.effort
  // },

  // SET_PROJECT_ASSESSMENT_MAPPINGS(state, mappings) {
  //   state.projects.currentRegisterAssessmentMappings = mappings
  // },

  SET_PROJECT_TASK(state, record) {
    state.projects.currentTask.record = record
  },

  SET_PROJECT_TASK_COMMENTS(state, comments) {
    state.projects.currentTask.comments = comments
  },

  SET_PROJECT_TASK_FILES(state, files) {
    state.projects.currentTask.files = files
  },

  SET_PROJECT_TASK_OWNERS(state, owners) {
    state.projects.currentTask.owners = owners
  },

  SET_PROJECT_TASK_COLLABORATORS(state, collaborators) {
    state.projects.currentTask.collaborators = collaborators
  },

  SET_PROJECT_TASK_TAGS(state, tags) {
    state.projects.currentTask.tags = tags
  },
  SET_PROJECT_TASK_CHANGELOG(state, changelog) {
    state.projects.currentTask.changelog = changelog
  },

  SET_PROJECT_TASK_SUBTASKS(state, subtasks) {
    state.projects.currentTask.subtasks = subtasks
  },

  SET_PROJECT_TASK_RISK_ITEMS(state, riskItems) {
    state.projects.currentTask.riskItems = riskItems
  },

  SET_PROJECT_TASK_CONTROLS(state, controls) {
    state.projects.currentTask.controls = controls
  },

  SET_PROJECT_TASK_FORMAL_FINDINGS(state, formalFindings) {
    state.projects.currentTask.formalFindings = formalFindings
  },

  SET_PROJECT_TASK_PENTEST_FINDINGS(state, penTestFindings) {
    state.projects.currentTask.penTestFindings = penTestFindings
  },

  SET_PROJECT_TASK_MY_CONTROLS(state, myControls) {
    state.projects.currentTask.myControls = myControls
  },

  SET_PROJECT_TASK_EVIDENCE(state, evidence) {
    state.projects.currentTask.evidence = evidence
  },

  SET_PROJECT_TASK_UPLOAD_USERS(state, uploadUsers) {
    state.projects.currentTask.uploadUsers = uploadUsers
  },

  SET_PROJECT_TASK_SCOPE_GROUPS(state, scopeGroups) {
    state.projects.currentTask.scopeGroups = scopeGroups
  },

  SET_PROJECT_TAGS(state, tags) {
    state.projects.allTagsInProject = tags
  },

  SET_PROJECT_TASKS(state, info) {
    state.projects.tasks = info
    let allTasks = []
    for (let group of info.data) {
      allTasks = allTasks.concat(group.tasks)
    }
    state.projects.allTasks = allTasks
  },

  SET_PROJECT_TASKS_PAGE(state, newPage) {
    state.projects.tasks.currentPage = newPage
  },

  SET_PROJECT_TASKS_PER_PAGE(state, newPerPage) {
    state.projects.tasks.perPage = newPerPage
  },

  CLEAR_PROJECT_TASK_FILTERS(state, excludeComplete = false) {
    Object.keys(state.projects.taskFilters).forEach((key) => {
      state.projects.taskFilters[key] = null
    })
    state.projects.taskFilters.taskTypes = ['tasks']
    if (excludeComplete) {
      state.projects.taskFilters.status =
        state.projects?.taskFilters?.status?.length > 0
          ? state.projects.taskFilters.status
          : state.projects.statuses
              .filter((s) => s.value !== 'complete')
              .map((s) => s.value)
    }
  },

  SET_PROJECT_TASK_FILTER(state, { key, val }) {
    state.projects.taskFilters[key] = val
  },

  CLEAR_PROJECT_TASK_FILTERS(state) {
    state.projects.taskFilters = {
      group: [],
      criticality: [],
      status: [],
      tag: [],
      taskTypes: [],
      user: [],
      // assessment: [],
      dueDateAfter: null,
      dueDateBefore: null,
    }
  },

  SET_PROJECT_TASK_SORTING(state, { key, val }) {
    state.projects.taskSorting = {}
    state.projects.taskSorting[key] = val
  },

  SET_PROJECT_TASK_SEARCH(state, input) {
    state.projects.taskSearch = input
  },

  SET_PROJECT_FRONTEND_PAGINATION_OBJ(state, fields) {
    state.projects.frontendPaginatedData = {
      ...state.projects.frontendPaginatedData,
      ...fields,
    }
  },
}
