export default {
  vulnerability: {
    fields: [
      // All potential fields available
    ],

    scans: [
      // array of all scans in a given org
    ],

    scanKpis: {
      // key/value pairs of vulnerability IDs and values of
      // high level KPIs for a scan
      // { 10: { informational: 0, low: 23, medium: 100, ... }, ... }
    },

    // Info about the last retrieved given scan
    // info returned: {
    //   scan,
    //   files,
    //   hosts,
    //   hostGroups
    // }
    scanInfo: null,

    scanFilters: {
      // cache of key/value pairs of vulnerability scan IDs
      // filters stored for each scan that has been accessed recently
    },

    item: null, // object of item info from the last retrieved item

    slas: {
      // key/value pairs of [severity]: days<integer> of SLAs
      // defined for this organization
    },

    remote: {
      functions: null, // key/value pairs of [command]: command info
    },

    remoteNodes: [
      // array of remote access nodes
    ],

    currentScanId: null,
    currentScanItems: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 15,
      totalCount: 0,
    },

    getArchivedScans: false,
    globalScanBeingEdited: null,

    defaultDisplayColumns: {},
    defaultListColumns: {
      default: null,
      aws_inspector: null,
      burp: null,
      nessus: null,
      nmap: null,
    },

    severities: ['critical', 'high', 'medium', 'low', 'informational'],

    statuses: [
      'open',
      'in_progress',
      'new_from_rescan',
      'on_hold',
      'complete',
      'not_applicable',
    ],

    hosts: {
      // key/value pair of vulnerability scan ID to an array of all affected hosts/IP addresses
    },

    vulnerabilityUpdateRequiresReason({ status, severity }) {
      let requiresReason = false
      if (status)
        requiresReason = ['complete', 'not_applicable'].includes(status)

      if (severity) requiresReason = true

      return requiresReason
    },
  },
}
