import moment from 'moment'

export default {
  dueDateColorClass(dueDate) {
    if (!dueDate) return 'text-gray'

    const daysFromDueDate = moment(dueDate).diff(moment(), 'days')
    if (isNaN(daysFromDueDate)) return 'text-gray'
    if (daysFromDueDate < 0) return 'text-danger'
    if (daysFromDueDate < 7) return 'text-warning'
    if (daysFromDueDate < 30) return 'text-info'
    return 'text-primary'
  },

  getFormattedDate(timestamp, format = 'MMMM D, YYYY', isUtc = false) {
    //'MMMM D, YYYY h:mm a' ex: August 11th, 2017 4:00 pm
    const baseMomentObj = timestamp
      ? isUtc
        ? moment.utc(timestamp)
        : moment(timestamp)
      : isUtc
      ? moment.utc()
      : moment()
    return baseMomentObj.format(format)
  },

  getTimeDifferenceFromUnits(
    dateStart,
    dateEnd = moment.utc(),
    units = 'days'
  ) {
    dateEnd = dateEnd || moment.utc()
    return moment.utc(dateEnd).diff(moment.utc(dateStart), units)
  },

  getTimeDifferenceObj(
    dateStart,
    dateEnd = moment().toDate(),
    calculateNegativeDiff = false
  ) {
    if (typeof dateStart === 'undefined') return false

    dateStart = moment(dateStart).toDate()
    dateEnd = moment(dateEnd).toDate()

    const dateDiff = dateEnd.getTime() - dateStart.getTime()
    const msDiff = calculateNegativeDiff ? Math.abs(dateDiff) : dateDiff
    const duration = moment.duration(msDiff, 'milliseconds')

    return {
      years: duration.years(),
      months: duration.months(),
      weeks: duration.weeks(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    }
  },

  getTimeFromNow(time) {
    return moment(time).fromNow()
  },

  getDaysFromSeconds(seconds, decimalLimit = 1) {
    return (seconds / 60 / 60 / 24).toFixed(decimalLimit)
  },
}
