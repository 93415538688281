import { questionnaireTypes } from '../../constants/questionnaireTypes'

export default {
  isSelfAssessment(state) {
    return (
      state.questionnaire?.record?.type == questionnaireTypes.SELF_ASSESSMENT
    )
  },

  isVendorQuestionnaire(state) {
    return (
      state.questionnaire?.record?.type ==
      questionnaireTypes.VENDOR_QUESTIONNAIRE
    )
  },

  getSelfAssessmentFrameworks(state) {
    const offeredFrameworksKeys = ['iso27001', 'soc2', 'pci']
    return state.assessments.engagementTypes.filter(
      (et) => offeredFrameworksKeys.indexOf(et.key) >= 0
    )
  },

  getSections(state) {
    const sections = (state.questionnaire.record || {}).sections
    if (!sections) return sections

    if (state.questionnaire.filterQuestionsNeedingClarification) {
      return sections.filter((s) => {
        return (
          s.questions.reduce(
            (num, q) => (q.needs_clarification ? num + 1 : num),
            0
          ) > 0
        )
      })
    }

    return sections
  },

  totalNumSections(_, getters) {
    return getters.getSections.length
  },

  currentSectionNumOfTotal(state, getters) {
    return state.questionnaire.record.sections.reduce((tot, sec, sInd) => {
      if (sInd > getters.activeSectionNum - 1) return tot
      return tot + 1
    }, 0)
  },

  numberQuestionsNeedingClarification(state) {
    return ((state.questionnaire.record || {}).sections || []).reduce(
      (tot, s) => {
        return (
          tot +
          s.questions.reduce(
            (num, q) => (q.needs_clarification ? num + 1 : num),
            0
          )
        )
      },
      0
    )
  },

  currentQuestionNumOfTotal(state, getters) {
    return state.questionnaire.record.sections.reduce((tot, sec, sInd) => {
      if (sInd > getters.activeSectionNum - 1) return tot

      if (sInd === getters.activeSectionNum - 1) {
        return tot + getters.activeQuestionNum
      }

      return tot + sec.questions.length
    }, 0)
  },

  canEditQuestionnaire(state, getters) {
    // Only apply these restrictions to self_assessments
    if (getters.isInternalUser || !getters.isSelfAssessment) return true

    // Essentials customers cannot edit self assessments
    if (getters.isEssentialsCustomer || getters.isStartupCustomer) return false

    // Can only edit if in 'draft'
    return state.questionnaire.record.status === 'draft'
  },

  canAnswerQuestionnaire(state, getters) {
    if (!getters.isSelfAssessment) return true

    const status = state.questionnaire.record.status
    return ['draft', 'in_progress', 'under_review'].indexOf(status) > -1
  },

  canRetakeAssessment(state, getters) {
    // verify this is a self assessment
    if (!getters.isSelfAssessment) return false
    // anyone above essentials can retake at any time
    if (!getters.isEssentialsCustomer || getters.isInternalUser) return true

    // check if 365 days have elapsed since created for essentials cusomers
    const daysSinceLastUpdated =
      (new Date().getTime() -
        new Date(state.questionnaire.record.updated_at).getTime()) /
      (1000 * 3600 * 24)
    return daysSinceLastUpdated > 365
  },

  isAssessmentComplete(state) {
    return state.questionnaire.record.status === 'complete'
  },

  // isQuestionnaireReadonly(state) {
  // const q = state.questionnaire.record
  // if (!q) return true

  // if (q.status !== 'in_progress') return true

  // // if the question has already been reviewed, it should not
  // // be able to be updated.
  // if (
  //   state.questionnaire.activeSectionId &&
  //   state.questionnaire.activeQuestionId
  // ) {
  //   const question = state.questionnaire.record.sections
  //     .find((s) => s.id == state.questionnaire.activeSectionId)
  //     .questions.find((q) => q.id == state.questionnaire.activeQuestionId)

  //   if (question.review_status && !question.needs_clarification) return true
  // }
  // },

  unansweredRequiredQuestions(state) {
    return state.questionnaire.record.sections.reduce((ary, sec) => {
      const unansweredQuestions = sec.questions.filter(
        (q) => !q.is_optional && (!q.responses || q.responses.length === 0)
      )
      return ary.concat(unansweredQuestions)
    }, [])
  },

  unreviewedRequiredQuestions(state) {
    return state.questionnaire.record.sections.reduce((ary, sec) => {
      const unreviewedQuestions = sec.questions.filter(
        (q) => !q.is_optional && !q.review_status
      )
      return ary.concat(unreviewedQuestions)
    }, [])
  },

  failedQuestions(state) {
    return state.questionnaire.record.sections.reduce((ary, sec) => {
      const failedQuestions = sec.questions.filter(
        (q) => q.review_status !== 'pass'
      )
      return ary.concat(failedQuestions)
    }, [])
  },

  totalNumQuestions(state) {
    return state.questionnaire.record.sections.reduce((tot, sec) => {
      return tot + sec.questions.length
    }, 0)
  },

  totalNumRequiredQuestions(state) {
    return state.questionnaire.record.sections.reduce((tot, sec) => {
      return tot + sec.questions.filter((q) => !q.is_optional).length
    }, 0)
  },

  activeSectionNum(state) {
    const secId = state.questionnaire.activeSectionId
    return state.questionnaire.getSectionNum(secId)
  },

  activeQuestionNum(state) {
    const secId = state.questionnaire.activeSectionId
    if (!secId) return null

    const qId = state.questionnaire.activeQuestionId
    return state.questionnaire.getQuestionNum(secId, qId)
  },

  getPreviousSection(state, getters) {
    try {
      const currentSecIndex = getters.activeSectionNum - 1

      // If this is the first section in the questionnaire, nothing before
      if (currentSecIndex === 0) return

      const prevSection =
        state.questionnaire.record.sections[currentSecIndex - 1]
      return prevSection.id
    } catch (err) {
      return
    }
  },

  getNextSection(state, getters) {
    try {
      const allSections = state.questionnaire.record.sections
      const currentSecIndex = getters.activeSectionNum - 1

      // If this is the last section in the questionnaire, next is submit
      if (currentSecIndex === allSections.length - 1) return 'submit'

      const nextSection = allSections[currentSecIndex + 1]
      return nextSection.id
    } catch (err) {
      return
    }
  },

  getPreviousSectionQuestion(state, getters) {
    try {
      const activeSectionId = state.questionnaire.activeSectionId
      const currentSecIndex = getters.activeSectionNum - 1
      const currentQueInd = getters.activeQuestionNum - 1

      // If this is the first question in the questionnaire, nothing before
      if (currentSecIndex === 0 && currentQueInd === 0) return []

      // if it's the first question in the section, next "previous" question is
      // the last question in the previous section
      if (currentQueInd === 0) {
        const prevSection =
          state.questionnaire.record.sections[currentSecIndex - 1]
        return [
          prevSection.id,
          prevSection.questions[prevSection.questions.length - 1].id,
        ]
      }

      return [
        activeSectionId,
        state.questionnaire.record.sections[currentSecIndex].questions[
          currentQueInd - 1
        ].id,
      ]
    } catch (err) {
      return []
    }
  },

  getNextSectionQuestion(state, getters) {
    try {
      const allSections = state.questionnaire.record.sections
      const activeSectionId = state.questionnaire.activeSectionId
      const currentSecIndex = getters.activeSectionNum - 1
      const currentQueInd = getters.activeQuestionNum - 1
      const numberQuestions = allSections[currentSecIndex].questions.length

      // If this is the last question in the questionnaire, next is submit
      if (
        currentSecIndex === allSections.length - 1 &&
        currentQueInd === numberQuestions - 1
      )
        return ['submit']

      // if it's the last question in this section, next question
      // will be the first question in the next section
      if (currentQueInd === numberQuestions - 1) {
        const nextSection = allSections[currentSecIndex + 1]
        return [nextSection.id, nextSection.questions[0].id]
      }

      return [
        activeSectionId,
        allSections[currentSecIndex].questions[currentQueInd + 1].id,
      ]
    } catch (err) {
      return []
    }
  },
}
