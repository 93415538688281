import { parse } from 'csv-parse'

/** Creates and downloads an empty CSV with predefined headers
 * @param teamplateData: Object with keys 'columns' and 'rows'. 'columns' is a string[] with column headers. rows is a string[][] where each inner array is the values of each row. For example:
 * 
  const templateData = {
    columns: [
      'domain',
      'control_number',
    ],
    rows: [
      [
        'Domain 1',
        'C-1',
      ],
      [
        'Domain 2',
        'C-2',
      ],
    ],
  }
 */
export function downloadTemplate(templateData) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob(
    [
      templateData.columns.join(','),
      '\n',
      templateData.rows.map((row) => row.join(',')).join('\n'),
    ],
    { type: 'text/csv;charset=UTF-8' }
  )

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a')

  // Passing the blob downloading url
  a.setAttribute('href', url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute(
    'download',
    templateData.filename || 'bulk-upload-template.csv'
  )

  // Performing a download with click
  a.click()
}

/** converts a file into a string and sends to this.parseCsv()
 * @param modifier is a function that takes an individual row and returns a modified
 * @param callback is a function to execute on all modified rows
 * */
export async function getDataFromFile(file, modifier, callback) {
  //PHLX-1544: issue with some special characters in csv files. I think it's related to encoding, but only seeing with \u2019 now
  const fileText = (await file.text()).replaceAll('�', "'")
  parseCsv(fileText, modifier, callback)
  this.fileUploaded = true
}

/** Parses a provided CSV, adds the rownumber as a new column
 * @param modifier is a function that takes an individual row and returns a modified
 * @param callback is a function to execute on all modified rows
 */
export async function parseCsv(fileText, modifier, callback) {
  const records = []
  let count = 1
  // Initialize the parser
  const parser = parse({
    delimiter: ',',
    columns: true,
    skip_empty_lines: true,
    skip_records_with_empty_values: true,
    trim: true,
  })
  // Use the readable stream api to consume records
  parser.on('readable', () => {
    let record
    while ((record = parser.read()) !== null) {
      const newRecord = modifier(record, records)
      records.push({ rowNum: count, ...newRecord })
      count++
    }
  })
  // Catch any error
  parser.on('error', function (err) {
    window.toastr.error(
      'Error parsing uploaded csv. Did you use our template? Error message: ',
      err.message
    )
  })
  // Test that the parsed records matched the expected records
  parser.on('end', () => {
    callback(records)
  })
  // Write data to the stream
  parser.write(fileText)
  // Close the readable stream
  parser.end()
}
