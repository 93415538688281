import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getByEntity(table, id) {
    const response = await R3sFetch(`/api/1.0/comments/entity/get?table=${table || ''}&entityId=${id || ''}`)
    return await handleFetchResponse(response)
  },

  async createOrUpdateAndLink(info, deleteComment=false) {
    const response = await R3sFetch(`/api/1.0/comments/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        ...info,
        operation: deleteComment ? 'delete': 'add'
      })
    })
    return await handleFetchResponse(response)
  }
}
