import domtoimage from 'dom-to-image-improved'
import Utilities from '@risk3sixty/utilities'
import AwsUrlSigner from './AwsUrlSigner'

export default {
  downloadUri(uri, name) {
    const link = document.createElement("a")
    link.id = name
    link.download = name
    link.href = uri
    link.click()
  },

  removeElement(id) {
    (function(elem) {
      elem.parentNode.removeChild(elem)
    })(document.getElementById(id))
  },

  DomImage: {
    async createAndUpload(domNodeId, type) {
      const dataUrl = await this.getDataUrl(domNodeId, type)
      const file = await this.dataUriToFile(dataUrl, `${domNodeId}.${type}`)

      // upload file to AWS S3
      const response = await AwsUrlSigner.sendFile(
        file,
        {
          signingURL: () => `/api/1.0/aws/url/putObject/get`,
          sendFileToServer : false,
          withCredentials: true
        },
        false
      )

      if (!response.success)
        throw response

      const savedFilename = response.message.split('/').reduce((_, piece) => piece, null)
      return savedFilename
    },

    async getDataUrl(domNodeId, type='svg') {
      const node = document.getElementById(domNodeId)
      if (!node || node.offsetParent === null) {
        await Utilities.Promises.sleep(250)
        return await this.getDataUrl(domNodeId, type)
      }
      const method = type === 'png' ? 'toPng' : 'toSvg'
      return await domtoimage[method](node)
    },

    // https://stackoverflow.com/a/30407840/7857707
    async dataUriToFile(dataURI, fileName) {
      const res = await fetch(dataURI)
      const buf = await res.arrayBuffer()
      
      // separate out the mime component
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      return new File([buf], fileName, { type: mimeString })
    },

    createImageElement(dataUrl, parentDomNodeId=null) {
      const img = new Image()
      img.src = dataUrl
      
      let parent = document.body
      if (parentDomNodeId)
        parent = document.getElementById(parentDomNodeId)

      parent.appendChild(img)
      return img
    }
  }
}
