import ApiVendorbase from '../../factories/ApiVendorbase'
import { questionnaireTypes } from '../../constants/questionnaireTypes'

export default {
  async questionnaireInit({ commit, dispatch }, questionnaireId) {
    try {
      commit('SET_QUESTIONNAIRE_GLOBAL_ERROR', null)
      await Promise.all([
        dispatch('getVendorbaseQuestionnaire', questionnaireId),
        // dispatch('getQuestionnaireBreadcrumbs', questionnaireId),
      ])
    } catch (err) {
      commit('SET_QUESTIONNAIRE_GLOBAL_ERROR', err.message)
    } finally {
      commit('APP_NO_LONGER_LOADING')
    }
  },

  async getVendorbaseQuestionnaire({ commit, dispatch }, questionnaireId) {
    const { questionnaire, changeLog } = await ApiVendorbase.getQuestionnaire(
      questionnaireId
    )
    dispatch('getQuestionnaireFlags', {
      questionnaireId: questionnaire.id,
      getGlobalFlags: questionnaire.type == questionnaireTypes.SELF_ASSESSMENT,
    })

    commit('SET_QUESTIONNAIRE_RECORD', questionnaire)
    commit('SET_QUESTIONNAIRE_CHANGE_LOG', changeLog)
  },

  async getQuestionnaireFlags(
    { commit },
    { questionnaireId, getGlobalFlags = false }
  ) {
    const { flags } = await ApiVendorbase.getQuestionnaireFlags(
      questionnaireId,
      getGlobalFlags
    )
    commit('SET_QUESTIONNAIRE_FLAGS', flags)
  },

  async getQuestionnaireScoring({ commit }, questionnaireId) {
    const { scoring, sectionScores, totalScore } =
      await ApiVendorbase.getQuestionnaireScoring(questionnaireId)
    commit('SET_QUESTIONNAIRE_SCORING', scoring)
    commit('SET_QUESTIONNAIRE_SCORING_SECTION_SCORES', sectionScores)
    commit('SET_QUESTIONNAIRE_SCORING_TOTAL_SCORE', totalScore)
  },

  async getQuestionnaireBreadcrumbs({ commit }, questionnaireId) {
    const { crumbs } = await ApiVendorbase.getBreadcrumbs(questionnaireId)
    commit('SET_QUESTIONNAIRE_BREADCRUMBS', crumbs)
  },

  async setQuestionPointValueFilter({ commit }, key) {
    commit('SET_QUESTION_POINT_VALUE_FILTER', key)
  },
}
