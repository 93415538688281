import ApiVendorManagement from '../../factories/ApiVendorManagement'
import { questionnaireTypes } from '../../constants/questionnaireTypes'

export default {
  async initSelfAssessments({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getEngagementTypes', reset),
      dispatch('getAllSelfAssessments', reset),
      dispatch('getSelfAssessmentTemplates', reset),
      dispatch('getQuestionnaires', reset),
      dispatch('getAllQuestionnaires', reset),
      dispatch('getQuestionnaireTemplates', reset),
      dispatch('getQuestionnaireStatuses', reset),
      dispatch('getQuestionnaireChoiceTemplates', reset),
    ])
  },

  async getAllSelfAssessments({ commit, state }, reset = state.globalReset) {
    if (state.selfassessments.allSelfAssessments.totalCount > 0 && !reset)
      return

    const { info } = await ApiVendorManagement.getQuestionnaires(
      questionnaireTypes.SELF_ASSESSMENT,
      {},
      1,
      1e3
    )
    commit('SET_ALL_SELF_ASSESSMENTS', info)
  },

  async getSelfAssessmentTemplates(
    { commit, state },
    reset = state.globalReset
  ) {
    if (
      state.selfassessments.allSelfAssessmentTemplates.totalCount > 0 &&
      !reset
    )
      return

    const { info } = await ApiVendorManagement.getQuestionnaireTemplates(
      questionnaireTypes.SELF_ASSESSMENT,
      {},
      1,
      1e3
    )
    commit('SET_ALL_SELF_ASSESSMENT_TEMPLATES', info)
  },

  async getSelfAssessment({ commit }, assessmentId) {
    const { questionnaire } = await ApiVendorManagement.getQuestionnaire(
      assessmentId
    )
    commit('SET_SELF_ASSESSMENT_RECORD', questionnaire)
  },

  async getSelfAssessmentAndSections({ commit }, questionnaireId) {
    const { questionnaire } =
      await ApiVendorManagement.getQuestionnaireSectionsQuestions(
        questionnaireId
      )
    commit('SET_SELF_ASSESSMENT_AND_SECTIONS', questionnaire)
  },
}
