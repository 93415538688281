export default {
  SET_WIKI_PAGES(state, pages) {
    state.wiki.pages = pages
  },

  SET_WIKI_HIERARCHY(state, hierarchy) {
    state.wiki.hierarchy = hierarchy
  },

  SET_WIKI_STATUSES(state, statuses) {
    state.wiki.statuses = statuses
  },

  SET_WIKI_CURRENT_PAGE(state, currentPage) {
    state.wiki.currentPage = currentPage
  },
}
