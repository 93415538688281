import Utilities from '@risk3sixty/utilities'

const moment = Utilities.Dates.moment

export default {
  compliance: {
    calendar: {
      startDate: moment().utc().startOf('day').toISOString(),
      endDate: moment().utc().endOf('day').add(6, 'month').toISOString(),
      showAllEvents: false,
      showTemplateEvents: false,
      filters: {
        owners: [],
        users: [],
        status: [],
        tags: [],
      },
      events: [],
      templateEvents: [],
      templateGroups: [],
      recentColors: [],
      currentEvent: {
        id: null,
        title: null,
        description: null,
        start: null,
        end: null,
        due_date: null,
        status: null,
        owner: null,
        assignments: [],
        comments: [],
        files: [],
        tags: [],
      },
      statuses: [],
      integrations: [],
    },
  },
}
