import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getScoping(pentestId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/scoping/get?id=${pentestId}`
    )
    return await handleFetchResponse(response)
  },

  async saveScoping(pentestId, fullScope) {
    const response = await R3sFetch(`/api/1.0/pentesting/scoping/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId: pentestId, scoping: fullScope }),
    })
    return await handleFetchResponse(response)
  },

  async attestToScoping(pentestId) {
    const response = await R3sFetch(`/api/1.0/pentesting/scoping/attest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId: pentestId }),
    })
    return await handleFetchResponse(response)
  },

  async getMappedVulnerabilityScans(pentestId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/vulnerability/scans/get?id=${pentestId}`
    )
    return await handleFetchResponse(response)
  },

  async getCredentials() {
    const response = await R3sFetch(`/api/1.0/pentesting/credentials/get`)
    return await handleFetchResponse(response)
  },

  async getCred(id) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/credential/find?id=${id || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateCredentials(info) {
    const response = await R3sFetch(`/api/1.0/pentesting/credentials/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async getFormalFindings(projectId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/formal/findings/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFormalFindingCategory(categoryId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/formal/findings/category/get?id=${categoryId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateFormalFindingCategory(category) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/formal/findings/category/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ category }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateFormalFinding(finding) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/formal/finding/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ finding }),
      }
    )
    return await handleFetchResponse(response)
  },

  async lockFormalFinding(findingId, isLocked = true) {
    const response = await R3sFetch(`/api/1.0/pentesting/formal/finding/lock`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ findingId, isLocked }),
    })
    return await handleFetchResponse(response)
  },

  async getClientsFromGhostwriter() {
    const response = await R3sFetch(
      `/api/1.0/pentesting/ghostwriter/clients/get`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return await handleFetchResponse(response)
  },

  async getProjectsFromGhostwriterClient(clientId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/ghostwriter/projects/get?clientId=${clientId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return await handleFetchResponse(response)
  },

  async getFindingsFromGhostwriterProject(projectId) {
    const response = await R3sFetch(
      `/api/1.0/pentesting/ghostwriter/findings/get?projectId=${projectId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return await handleFetchResponse(response)
  },
}
