import Utilities from '@risk3sixty/utilities'

const moment = Utilities.Dates.moment

export default function InventoryHelpers(context) {
  return {
    createExpressionEvalObj(fieldVariables, columns, row) {
      return Utilities.Inventories.expressionEvalObject(
        fieldVariables,
        columns,
        row,
        context.$store.state.team.enums
      )
    },

    filteredRows(rows, columns, filters) {
      const idColTypes = [
        'assessment_control',
        'enum',
        'risk',
        'tag',
        'vendor',
        'user',
      ]

      if (!filters) return rows

      let rowsCopy = rows.slice(0)
      Object.keys(filters).forEach((fieldId) => {
        if (!filters[fieldId] || filters[fieldId].length === 0) return

        const col = columns.find((c) => c.id == fieldId)
        if (!col) return

        if (col.type === 'expression') {
          const expressionLatex = JSON.parse(col.mod1).expressionLatex
          const getExprVal = (row) => {
            try {
              const computedVarObj = this.createExpressionEvalObj(
                JSON.parse(col.mod1).fieldVariables,
                columns,
                row
              )
              const fn = window.evaluatex(expressionLatex)
              return fn(computedVarObj)
            } catch (err) {
              return null
            }
          }

          rowsCopy = rowsCopy.filter((r) => {
            const val = getExprVal(r)
            return filters[fieldId].includes((val || '').toString())
          })
        } else if (idColTypes.includes(col.type)) {
          rowsCopy = rowsCopy.filter((r) => {
            return (
              (r.values[col.id] || '')
                .split(',')
                .filter((x) => filters[fieldId].includes(x)).length > 0
            )
          })
        } else {
          rowsCopy = rowsCopy.filter((r) => {
            return filters[fieldId].includes(r.values[col.id])
          })
        }
      })

      return rowsCopy
    },

    sortedRows(rows, columns, sortColId = null, sortDir = 'ASC') {
      if (!sortColId) return rows

      const sortColRecord = columns.find((c) => c.id == sortColId)
      if (!sortColRecord) return rows

      const sortInfo = this.sortableFieldTypes().find(
        (t) => t.type === sortColRecord.type
      )
      if (!(sortInfo && sortInfo.sorter)) return rows

      const rowsMappedToSortColValues = rows.map((r, ind) => ({
        ind,
        field: sortColRecord,
        columns: columns,
        row: r,
        val: r.values[sortColRecord.id],
      }))
      const sortedRowIndices = rowsMappedToSortColValues.sort(
        sortInfo.sorter.bind(this)
      )
      const sortedRows = sortedRowIndices.map(({ ind }) => rows[ind])
      return sortDir && sortDir.toLowerCase() === 'desc'
        ? sortedRows.reverse()
        : sortedRows
    },

    sortableFieldTypes() {
      // `sorter` should be false or a function that implements the
      // `compareFunction` in Array.prototype.sort, with the args being:
      // {
      //   ind: indexOfOriginalRow,
      //   field: columnRecordBeingSorted,
      //   columns: allInventoryColumns,
      //   row: allInventoryRows,
      //   val: valueOfRow
      // }
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      return [
        { type: 'date', sorter: this.sortDateField },
        { type: 'text', sorter: this.sortTextField },
        { type: 'number', sorter: this.sortNumberField },
        { type: 'enum', sorter: this.sortEnumField },
        { type: 'expression', sorter: this.sortExpressionField },
        { type: 'vendor', sorter: this.sortVendorField },
      ]
    },

    sortDateField({ val: v1 }, { val: v2 }) {
      if (!v1) return 1
      if (!v2) return -1
      return moment(v1).isSameOrBefore(v2) ? -1 : 1
    },

    sortEnumField({ field, val: v1 }, { val: v2 }) {
      const allEnums = context.$store.state.team.enums
      const ourEnum = allEnums.find((e) => e.id == field.enum_options_id)
      const valObj1 = ourEnum.values.find((v) => v.id == v1)
      const valObj2 = ourEnum.values.find((v) => v.id == v2)

      if (!valObj1) return 1
      if (!valObj2) return -1

      const isText = isNaN(valObj1.value) || isNaN(valObj2.value)
      if (isText) {
        return valObj1.value < valObj2.value ? -1 : 1
      }
      return valObj1.value - valObj2.value
    },

    sortNumberField({ val: v1 }, { val: v2 }) {
      if (!v1) return 1
      if (!v2) return -1
      return v1 - v2
    },

    sortTextField({ val: v1 }, { val: v2 }) {
      if (!v1) return 1
      if (!v2) return -1
      return Utilities.Strings.stripHtmlTags(v1).toLowerCase() <
        Utilities.Strings.stripHtmlTags(v2).toLowerCase()
        ? -1
        : 1
    },

    sortExpressionField({ field, columns, row: r1 }, { row: r2 }) {
      const expressionLatex = JSON.parse(field.mod1).expressionLatex

      const getVal = (row) => {
        try {
          const computedVarObj = this.createExpressionEvalObj(
            JSON.parse(field.mod1).fieldVariables,
            columns,
            row
          )
          const fn = window.evaluatex(expressionLatex)
          return fn(computedVarObj)
        } catch (err) {
          // console.error('Error computing value', err)
          return 'N/A'
        }
      }

      const finalV1 = getVal(r1)
      const finalV2 = getVal(r2)

      if (!finalV1) return 1
      if (!finalV2) return -1

      return finalV1 - finalV2
    },

    sortVendorField({ val: v1 }, { val: v2 }) {
      const vendors = context.$store.state.vendormanagement.teamVendors.data
      const getVendor = (vids) => {
        return vids
          .map((vid) => {
            return vendors.find((ven) => ven.id == vid)
          })
          .filter((v) => !!v)
          .sort((v1, v2) =>
            v1.name.toLowerCase() < v2.name.toLowerCase() ? -1 : 1
          )[0]
      }

      const vendorIds1 = (v1 || '').split(',').filter((i) => !!i)
      const vendorIds2 = (v2 || '').split(',').filter((i) => !!i)

      if (vendorIds1.length === 0) return 1
      if (vendorIds2.length === 0) return -1

      const ven1 = getVendor(vendorIds1)
      const ven2 = getVendor(vendorIds2)

      if (!(ven1.risk_score && ven2.risk_score))
        return ven1.name.toLowerCase() < ven2.name.toLowerCase() ? -1 : 1

      return ven2.risk_score - ven1.risk_score
    },
  }
}
