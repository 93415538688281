export const riskCriticalities = {
  EXTREME: { val: 'extreme', text: 'Extreme', variant: 'danger' },
  HIGH: { val: 'high', text: 'High', variant: 'warning' },
  MEDIUM: { val: 'medium', text: 'Medium', variant: 'info' },
  LOW: { val: 'low', text: 'Low', variant: 'success' },
  NOT_SET: { val: null, text: 'Not Set', variant: 'gray' },
}

export function getRiskScoreCriticality(score) {
  if (score === null) return riskCriticalities.NOT_SET.val
  if (score < 4) return riskCriticalities.LOW.val
  if (score < 8) return riskCriticalities.MEDIUM.val
  if (score < 15) return riskCriticalities.HIGH.val
  return riskCriticalities.EXTREME.val
}

export const taskCriticalities = {
  EXTREME: { val: 'extreme', text: 'Extreme', variant: 'danger' },
  HIGH: { val: 'high', text: 'High', variant: 'warning' },
  MEDIUM: { val: 'medium', text: 'Medium', variant: 'info' },
  LOW: { val: 'low', text: 'Low', variant: 'minimal' },
  INFORMATIONAL: {
    val: 'informational',
    text: 'Informational',
    variant: 'primary',
  },
  NOT_SET: { val: 'not_set', text: 'Not Set', variant: 'gray' },
}

export const assFFsCriticalities = {
  CRITICAL: { val: 'critical', text: 'Critical', variant: 'danger' },
  SERIOUS: { val: 'serious', text: 'Serious', variant: 'warning' },
  MODERATE: { val: 'moderate', text: 'Moderate', variant: 'info' },
  MINOR: { val: 'minor', text: 'Minor', variant: 'minimal' },
  OFI: {
    val: 'ofi',
    text: 'OFI',
    variant: 'success',
  },
  INFORMATIONAL: {
    val: 'informational',
    text: 'Informational',
    variant: 'primary',
  },
  NOT_SET: { val: null, text: 'Not Set', variant: 'gray' },
}

export const penTestFFsCriticalities = {
  CRITICAL: { val: 'critical', text: 'Critical', variant: 'danger' },
  HIGH: { val: 'high', text: 'High', variant: 'warning' },
  MEDIUM: { val: 'medium', text: 'Medium', variant: 'info' },
  LOW: { val: 'low', text: 'Low', variant: 'minimal' },
  NOT_SET: { val: null, text: 'Not Set', variant: 'gray' },
}

export function mapPenTestFFToTaskCriticality(penTestCriticality) {
  switch (penTestCriticality) {
    case penTestFFsCriticalities.CRITICAL.val:
      return taskCriticalities.EXTREME.val

    case penTestFFsCriticalities.HIGH.val:
      return taskCriticalities.HIGH.val

    case penTestFFsCriticalities.MEDIUM.val:
      return taskCriticalities.MEDIUM.val

    case penTestFFsCriticalities.LOW.val:
      return taskCriticalities.LOW.val

    default:
      return taskCriticalities.NOT_SET.val
  }
}

export function mapAssFFToTaskCriticality(assessmentCriticality) {
  switch (assessmentCriticality) {
    case assFFsCriticalities.CRITICAL.text:
      return taskCriticalities.EXTREME.val

    case assFFsCriticalities.SERIOUS.text:
      return taskCriticalities.HIGH.val

    case assFFsCriticalities.MODERATE.text:
      return taskCriticalities.MEDIUM.val

    case assFFsCriticalities.MINOR.text:
      return taskCriticalities.LOW.val

    case assFFsCriticalities.OFI.text:
    case assFFsCriticalities.INFORMATIONAL.text:
      return taskCriticalities.INFORMATIONAL.val

    default:
      return taskCriticalities.NOT_SET.val
  }
}
