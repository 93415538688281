export default {
  extensions: {
    list: [],
    types: [],
    templates: [],

    currentApp: {
      extension_id: null,
      logo_file: null,
      extension_type: null,

      instances: [
        /*
        {
        id: null,
        name: null,
        description: null,
        last_run__id: null,
        last_run_max_level: null,
        last_run_date: null,
        last_run_status: null,
        last_run_output_status: null
        },
        */
      ],

      currentInstance: {
        id: null,
        executions: [
          /*
          {
            id: null,
            date: null
          },
            */
        ],

        currentExecution: {
          // id: null,
          // date: null,
          metadata: {},
          summary: {
            // key: {
            //   checked_items: null,
            //   flagged_items: null,
            //   max_level: null,
            //   resources_count: null,
            //   rules_count: null,
            // },
          },
          results: {
            // serviceKey: {
            //   findings: {
            //     findingKey: {
            //       checked_items: 0,
            //       compliance: '',
            //       dashboard_name: '',
            //       description: '',
            //       flagged_items: 0,
            //       id_suffix: '',
            //       items: [],
            //       level: '',
            //       path: '',
            //       rationale:
            //         '',
            //       references: [],
            //       remediation: '',
            //       service: '',
            //     },
            //   },
            // },
          },
          inventory: {
            // total: {
            //   'us-east-1': 15,
            //   'us-east-2': 10,
            //   .
            //   .
            //   .
            // },
            // 'ec2_network_interfaces': {
            //   'us-east-1': 5,
            //   'us-east-2': 1,
            //   .
            //   .
            //   .
            // },
            // 's3_buects': {
            //   'us-east-1': 10,
            //   'us-east-2': 9,
            //   .
            //   .
            //   .
            // }
          },
          /** Exceptions at the time of executions */
          exceptions: {},
        },

        /** Exceptions going forward */
        exceptions: {
          fetched: false,
          // serviceKey: {
          //   findingKey: [
          //     'itemPath',
          //   ]
          // },
          audit: {},
        },
      },
    },
  },
}
