import ApiPentesting from '../../factories/ApiPentesting'
import ApiRemote from '../../factories/ApiRemote'
import ApiVulnerability from '../../factories/ApiVulnerability'

export default {
  async initVulnerability({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getVulnerabilityScans', reset),
      dispatch('getDefaultVulnerabilityColumns', reset),
    ])
  },

  async getVulnerabilityScans({ commit, state }, reset = state.globalReset) {
    if (state.vulnerability.scans.length > 0 && !reset) return

    const { scans } = await ApiVulnerability.getScans(
      state.vulnerability.getArchivedScans
    )
    const { kpis } = await ApiVulnerability.getSeverityKpisForScans(
      scans.map((s) => s.id),
      true
    )

    commit('SET_VULNERABILITY_SCANS', scans)
    commit('SET_VULNERABILITY_SCAN_KPIS', kpis)
  },

  async getMappedPentetingVulnerabilityScans({ commit }, pentestId) {
    const { scans } = await ApiPentesting.getMappedVulnerabilityScans(pentestId)

    commit('SET_PENTESTING_VULNERABILITY_SCANS', scans)
  },

  async getDefaultVulnerabilityColumns(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.vulnerability.defaultListColumns.default && !reset) return

    const { columns, display } = await ApiVulnerability.getDefaultColumns()
    commit('SET_VULNERABILITY_DEFAULT_COLUMNS', { columns, display })
  },

  async getVulnerabilityScanPage(
    { commit, dispatch, state },
    { filters, sortCol, sortDir }
  ) {
    commit('SET_VULNERABILITY_SCAN_FILTERS', {
      id: state.vulnerability.currentScanId,
      filters,
    })

    const itemsInfo = state.vulnerability.currentScanItems
    const newItemInfo = await ApiVulnerability.getItems(
      state.vulnerability.currentScanId,
      filters,
      itemsInfo.currentPage,
      itemsInfo.perPage,
      sortCol,
      sortDir
    )

    if (
      newItemInfo.totalCount === 0 &&
      parseInt(newItemInfo.currentPage) !== 1
    ) {
      commit('SET_VULNERABILITY_CURRENT_SCAN_ITEMS', { currentPage: 1 })
      return dispatch('getVulnerabilityScanPage', {
        id: state.vulnerability.currentScanId,
        filters,
        sortCol,
        sortDir,
      })
    }

    commit('SET_VULNERABILITY_CURRENT_SCAN_ITEMS', {
      ...state.vulnerability.currentScanItems,
      ...newItemInfo,
    })
  },

  async getRemoteAccessNodes({ commit, state }, reset = state.globalReset) {
    if (state.vulnerability.remoteNodes.length > 0 && !reset) return

    const { nodes } = await ApiRemote.getNodes()
    commit('SET_REMOTE_ACCESS_NODES_LIST', nodes)
  },
}
