export default {
  getCurrentProjectChildren(state) {
    const getChildIds = function(parent) {
      let childIds = []
      if (parent.children) {
        childIds = Object.values(parent.children)
          .map((c) => {
            return getChildIds(c)
          })
          .flat(1)

        return Object.keys(parent.children).concat(childIds)
      }

      return []
    }

    const projectChildIds = getChildIds(state.assessments.currentProject || {})

    const projectChildren = state.assessments.projects.filter((p) => {
      return projectChildIds.includes(p.id)
    })

    return projectChildren
  },
}
