import $ from 'jquery'

export async function handleFetchResponse(response, type = 'json') {
  if (response.status >= 500 || typeof response.status == 'undefined') {
    throw new Error(
      `Sorry, there was an error that we're looking into now. Please refresh the page or try again.`
    )
  }

  const typeParser = type ? await response[type]() : null
  if (response.status >= 400) {
    let errorInfo = null
    try {
      const responseBody = typeParser ? typeParser : await response.json()

      errorInfo = responseBody.error

      // If authentication is not valid, send to login screen
      if (response.status == 401) {
        return (window.location.href = '/logout')
      }
      if (response.status == 403 && responseBody.upgradeRequired) {
        // Update the modal text
        $(`#upgrade-subscription-modal .modal-body`).html(
          responseBody.upgradeReason
        )
        // Update the modal buttons if applicable
        if (responseBody.jqueryButton) {
          const { method, html } = responseBody.jqueryButton
          $(`#upgrade-subscription-modal .modal-footer`)[method](html)
        }
        // Finally, show the modal
        $(`#upgrade-subscription-modal`).modal('show')
      }
    } finally {
      errorInfo = errorInfo || response
    }

    throw new Error(errorInfo)
  } else {
    return typeParser
  }
}

export function serialize(obj) {
  let a = []
  for (const _key in obj) {
    if (obj[_key] instanceof Array) {
      for (let _i = 0; _i < obj[_key].length; _i++) {
        a.push(
          encodeURIComponent(_key) + '=' + encodeURIComponent(obj[_key][_i])
        )
      }
    } else {
      a.push(encodeURIComponent(_key) + '=' + encodeURIComponent(obj[_key]))
    }
  }
  return a.join('&')
}

export function unserialize(string) {
  string = /^\?/.test(string) ? string.substring(1) : string
  const a = string.split('&')
  let obj = {}
  for (let _i = 0; _i < a.length; _i++) {
    var _a = a[_i].split('=')
    obj[decodeURIComponent(_a[0])] = decodeURIComponent(_a[1])
  }
  return obj
}
