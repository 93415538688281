import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getApiKeys() {
    const response = await R3sFetch(`/api/1.0/aws/keys/get`)
    return await handleFetchResponse(response)
  },

  async saveApiKey(info) {
    const response = await R3sFetch(`/api/1.0/aws/key/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  },

  async deleteApiKey(key) {
    const response = await R3sFetch(`/api/1.0/aws/key/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(key)
    })
    return await handleFetchResponse(response)
  },

  async getRegions(vendorId) {
    const response = await R3sFetch(`/api/1.0/aws/regions?vendor_data_id=${vendorId || ''}`)
    return await handleFetchResponse(response)
  },

  async getInspectorAssessmentTargets(info) {
    const response = await R3sFetch(`/api/1.0/aws/inspector/targets`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  },

  async getInspectorAssessmentTemplates(info) {
    info.targets = (info.targets instanceof Array) ? info.targets : [ info.targets ]
    const response = await R3sFetch(`/api/1.0/aws/inspector/templates`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  },

  async getInspectorAssessmentRuns(info) {
    info.templates = (info.templates instanceof Array) ? info.templates : [ info.templates ]
    const response = await R3sFetch(`/api/1.0/aws/inspector/runs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info)
    })
    return await handleFetchResponse(response)
  }
}
