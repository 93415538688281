export default {
  SET_ALL_SELF_ASSESSMENTS(state, assessments) {
    state.selfassessments.allSelfAssessments = assessments
  },

  SET_ALL_SELF_ASSESSMENT_TEMPLATES(state, templates) {
    state.selfassessments.allSelfAssessmentTemplates = templates
  },

  SET_SELF_ASSESSMENT_RECORD(state, assessment) {
    state.selfassessments.currentAssessment = assessment
  },

  SET_SELF_ASSESSMENT_AND_SECTIONS(state, assessment) {
    state.selfassessments.currentAssessmentAndSections = assessment
  },

  SET_SELF_ASSESSMENT_SHOW_TEMPLATES(state, show) {
    state.selfassessments.showSelfAssessmentTemplates = show
  },
}
