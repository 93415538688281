export default {
  feedback: {
    showAllFeedback: false,
    filters: {
      assignees: [],
      users: [],
      status: ['prioritizing', 'in_progress', 'on_hold', 'pending'],
      types: [],
      tags: [],
      priority: [],
      search: null,
    },
    allFeedback: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 10,
      totalCount: 0,
    },
    currentFeedback: {
      id: null,
      user_id: null,
      feedback: null,
      status: null,
      updated_by: null,
      assignments: [],
      comments: [],
      files: [],
    },
    statuses: [],
    allUsers: [],
    internalUsers: [],
    allPullRequests: [],
    feedbackTypes: [
      { value: 'bug', variant: 'danger' },
      { value: 'enhancement', variant: 'success' },
      { value: 'question', variant: 'primary' },
    ],
    feedbackPriorities: [
      { value: 'low', variant: 'gray' },
      { value: 'medium', variant: 'primary' },
      { value: 'high', variant: 'warning' },
      { value: 'critical', variant: 'danger' },
    ],
  },
}
