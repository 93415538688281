import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getComplianceCalendarEvents(
    allEvents = false,
    startDate = null,
    endDate = null,
    filters = null,
    notRecurring = false
  ) {
    filters = encodeURIComponent(JSON.stringify(filters))
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/all?allEvents=${
        allEvents || ''
      }&startDate=${startDate || ''}&endDate=${endDate || ''}&filters=${
        filters || ''
      }&notRecurring=${notRecurring || ''}`
    )
    return await handleFetchResponse(response)
  },

  async downloadComplianceCalendarEvents(
    getAllEvents = false,
    startDate = null,
    endDate = null,
    filters = null
  ) {
    filters = encodeURIComponent(JSON.stringify(filters))
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/download?all=${
        getAllEvents || ''
      }&startDate=${startDate || ''}&endDate=${endDate || ''}&filters=${
        filters || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getComplianceCalendarTemplateEvents() {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/all`
    )
    return await handleFetchResponse(response)
  },

  async getComplianceCalendarTemplateEventGroups() {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/groups/all`
    )
    return await handleFetchResponse(response)
  },

  async getComplianceCalendarEvent(eventId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/get?id=${eventId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getComplianceCalendarEventStatuses() {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/statuses`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateComplianceCalendarEvent(event) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createComplianceCalendarTemplate(eventId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: eventId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateComplianceCalendarTemplateGroup(group) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/groups/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ group }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteComplianceCalendarTemplateGroup(id) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/groups/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async mapComplianceCalendarTemplateGroup(groupId, templateId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/groups/map`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupId, templateId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteComplianceCalendarTemplateGroupMap(groupId, templateId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/templates/groups/map/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupId, templateId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteComplianceCalendarEvent(eventId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: eventId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateCalendarEventComment(info) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/comment/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: info.eventId,
          date: info.date,
          mentions: info.mentions,
          comment: info.comment,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteCalendarEventComment(eventId, commentId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/comment/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: eventId,
          commentId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateCalendarEventFile(params) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/events/file/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: params.eventId,
          date: params.date,
          fileUploadId: params.fileUploadId,
          deleteFile: params.deleteFile,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async mapEventRecord(eventId, linkType, mapRecordId, remove = false) {
    const response = await R3sFetch(`/api/1.0/compliance/calendar/event/map`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId,
        linkType,
        linkRecId: mapRecordId,
        remove,
      }),
    })
    return await handleFetchResponse(response)
  },

  async getCalendarIntegrations() {
    const response = await R3sFetch(`/api/1.0/compliance/calendar/integrations`)
    return await handleFetchResponse(response)
  },

  async syncCalendarEvents(type, method, removeExternalEvents, userTimeZone) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/integration/sync`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          method,
          removeExternalEvents,
          userTimeZone,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getIntegrationCalendars(type) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/integration/calendars?type=${type}`
    )
    return await handleFetchResponse(response)
  },

  async setIntegrationCalendar(type, calendarId) {
    const response = await R3sFetch(
      `/api/1.0/compliance/calendar/integration/calendar/set`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          calendarId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },
}
