import ApiWiki from '../../factories/ApiWiki'

export default {
  async initWiki(
    { dispatch, state },
    { reset = state.globalReset, templates }
  ) {
    await Promise.all([dispatch('getAllPages', { reset, templates })])
  },

  async getAllPages(
    { commit, state },
    { templates = false, reset = state.globalReset }
  ) {
    if (state.wiki.pages && state.wiki.pages.length > 0 && !reset) return

    const {
      pages,
      hierarchy,
      statuses,
    } = await ApiWiki.getAllPages(templates)
    commit('SET_WIKI_PAGES', pages)
    commit('SET_WIKI_HIERARCHY', hierarchy)
    commit('SET_WIKI_STATUSES', statuses)
  },

  async getPage({ commit }, { id, version }) {
    const { page } = await ApiWiki.getPage(id, version)
    commit('SET_WIKI_CURRENT_PAGE', page)
  },
}
