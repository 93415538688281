export default {
  pentesting: {
    vulnerabilityScans: [],

    subHeaderTabs(assessmentId) {
      return [
        {
          text: 'All Pentests',
          activeClassArgs: ['ProjectListViewContainer'],
          link: `/pentesting`,
        },
        {
          name: 'planning',
          text: 'Planning',
          activeClassArgs: ['PlanningContainer'],
          link: `/pentesting/planning/${assessmentId}`,
        },
        {
          name: 'scoping',
          text: 'Scoping',
          activeClassArgs: ['PentestingScopingContainer'],
          link: `/pentesting/scoping/${assessmentId}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Reporting',
          activeClassArgs: ['StatusReportingContainer'],
          link: `/pentesting/status/${assessmentId}`,
        },
        {
          name: 'findings',
          text: 'Findings',
          activeClassArgs: ['FormalFindingsContainer'],
          link: `/pentesting/findings/${assessmentId}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Secure Messaging',
          activeClassArgs: ['SecureMessagesContainer'],
          link: `/pentesting/messaging/${assessmentId}`,
        },
        {
          name: 'evidence',
          text: 'File Sharing',
          activeClassArgs: ['ProjectContainer', this.tab === 'evidence'],
          link: `/pentesting/evidence/${assessmentId}`,
        },
        {
          name: 'vulnerability',
          text: 'Scan Results',
          activeClassArgs: ['PentestingVulnerabilitiesContainer'],
          link: `/pentesting/vulnerability/${assessmentId}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Internal',
          activeClassArgs: ['PentestingInternalContainer'],
          link: `/pentesting/internal/${assessmentId}/creds`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Reporting',
          activeClassArgs: ['ReportingContainer'],
          link: `/pentesting/reporting/${assessmentId}`,
        },
      ]
    },
  },
}
