import ApiProgram from '../../factories/ApiProgram'

export default {
  async getAllScopes({ state, commit }, reset = true) {
    if (state?.program?.allScopes?.length > 0 && !reset) return
    const { scopes } = await ApiProgram.getAllScopes()
    commit('SET_ALL_PROGRAM_SCOPES', scopes)
  },

  async getAllContacts({ commit }) {
    const { contacts } = await ApiProgram.getAllContacts()
    commit('SET_ALL_PROGRAM_CONTACTS', contacts)
  },

  async getAllSystemComponents({ commit }) {
    const { components } = await ApiProgram.getAllSystemComponents()
    commit('SET_ALL_PROGRAM_SYSTEM_COMPONENTS', components)
  },

  async getScope({ commit }, scopeId) {
    const { scope } = await ApiProgram.getScope(scopeId)
    commit('SET_CURRENT_PROGRAM_SCOPE', scope)
  },
}
