import Utilities from '@risk3sixty/utilities'
import defaultSecret from '../constants/defaultSecret'
import $ from 'jquery'

export function getExecutionStatus(execution) {
  if (execution.status == 'complete')
    return execution.output_status || execution.status

  return execution.status
}

export function getSeverityCircleColor(severity) {
  const variant = getVariantFromLevel(severity)
  return `bg-${variant} border-${variant}`
}

export function getClassFromStatus(status) {
  switch (status) {
    case 'complete':
    case 'success':
      return `text-success`
    case 'pending':
      return `text-primary`
    case 'warning':
      return `text-info`
    case 'error':
      return `text-danger`
    case 'stopped':
    case 'killed':
    default:
      return `text-grey`
  }
}

export function getClassFromLevel(level) {
  const variant = getVariantFromLevel(level)

  switch (level) {
    case null:
    case '':
      return `text-${variant} fa-check-triangle`
    default:
      return `text-${variant} fa-exclamation-triangle`
  }
}

export function getVariantFromLevel(level) {
  const fullCircleVariant = scoutSuiteLevelMap[level || 'success'] || 'gray'
  return fullCircleVariant
}

// Get fullCircle variant from ScoutSuite level. i.e. const fullCircleVariant = scoutSuiteLevelMap[scoutSuiteLevel]
export const scoutSuiteLevelMap = {
  warning: 'warning',
  danger: 'danger',
  success: 'success',
  gray: 'gray',
}

export function sortScoutSuiteSummaryObject(scoutSuiteObject) {
  var sortedFindings = []

  //1. sort the keys
  var sortedFindingsKeys = Object.keys(scoutSuiteObject).sort((a, b) => {
    // sort first by max level, then by number of findings
    let result = 0
    result +=
      levelToPointMap.get(scoutSuiteObject[b].max_level) -
      levelToPointMap.get(scoutSuiteObject[a].max_level)
    result +=
      scoutSuiteObject[b].flagged_items - scoutSuiteObject[a].flagged_items
    return result
  })

  //2. populate the values
  sortedFindingsKeys.forEach(function (key) {
    var obj = scoutSuiteObject[key]
    obj['key'] = key
    sortedFindings.push(obj)
  })

  return sortedFindings
}

export function sortScoutSuiteFindingsObject(scoutSuiteObject) {
  var sortedFindings = []

  var sortedFindingsKeys = Object.keys(scoutSuiteObject).sort(function (a, b) {
    if (
      scoutSuiteObject[a].flagged_items === 0 &&
      scoutSuiteObject[b].flagged_items === 0
    ) {
      if (
        scoutSuiteObject[a].checked_items === 0 &&
        scoutSuiteObject[b].checked_items !== 0
      )
        return 1
      if (
        scoutSuiteObject[a].checked_items !== 0 &&
        scoutSuiteObject[b].checked_items === 0
      )
        return -1
      if (
        scoutSuiteObject[a].description.toLowerCase() <
        scoutSuiteObject[b].description.toLowerCase()
      )
        return -1
      if (
        scoutSuiteObject[a].description.toLowerCase() >
        scoutSuiteObject[b].description.toLowerCase()
      )
        return 1
    }
    if (
      (scoutSuiteObject[a].flagged_items == 0 &&
        scoutSuiteObject[b].flagged_items > 0) ||
      (scoutSuiteObject[a].flagged_items > 0 &&
        scoutSuiteObject[b].flagged_items === 0)
    ) {
      if (scoutSuiteObject[a].flagged_items > scoutSuiteObject[b].flagged_items)
        return -1
      return 1
    }
    if (
      scoutSuiteObject[a].flagged_items > 0 &&
      scoutSuiteObject[b].flagged_items > 0
    ) {
      if (scoutSuiteObject[a].level === scoutSuiteObject[b].level) {
        if (
          scoutSuiteObject[a].description.toLowerCase() <
          scoutSuiteObject[b].description.toLowerCase()
        )
          return -1
        if (
          scoutSuiteObject[a].description.toLowerCase() >
          scoutSuiteObject[b].description.toLowerCase()
        )
          return 1
      } else {
        if (scoutSuiteObject[a].level.toLowerCase() === 'danger') return -1
        if (scoutSuiteObject[b].level.toLowerCase() === 'danger') return 1
        if (scoutSuiteObject[a].level.toLowerCase() === 'warning') return -1 // FIXME - these are duplicated for nothing?
        if (scoutSuiteObject[b].level.toLowerCase() === 'warning') return 1
        if (scoutSuiteObject[a].level.toLowerCase() === 'warning') return -1
        if (scoutSuiteObject[b].level.toLowerCase() === 'warning') return 1
      }
    }
    return 0
  })

  sortedFindingsKeys.forEach(function (key) {
    var obj = scoutSuiteObject[key]
    obj['key'] = key
    sortedFindings.push(obj)
  })

  return sortedFindings
}

let levelToPointMap = new Map([
  ['', 0],
  ['warning', 100000],
  ['danger', 200000],
  ['critical', 300000],
])

export function getBaseTrustPolicy(teamId) {
  // this format should not be changed per PHLX-1153
  return `{
    "Version": "2012-10-17",
    "Statement": [
      {
        "Sid": "Phalanx",
        "Effect": "Allow",
        "Principal": {
          "AWS": "arn:aws:iam::272426304063:root"
        },
        "Action": "sts:AssumeRole",
        "Condition": {
          "StringEquals": {
            "sts:ExternalId": "PHLX-${teamId}"
          }
        }
      }
    ]
  }`
}

export function getTrustPolicy(teamId, asHtml = true) {
  // this format should not be changed per PHLX-1153
  const prefix = `${asHtml ? '<pre>' : null}${asHtml ? '<code>' : null}`
  const suffix = `${asHtml ? '</code>' : null}${asHtml ? '</pre>' : null}`
  const trustPolicy = getBaseTrustPolicy(teamId)

  return prefix + trustPolicy + suffix
}

export function getHTMLFromJSON(datum) {
  let html = ''
  if (!datum) {
    return html
  } else if (Array.isArray(datum)) {
    html += '<ul class="ccm-array-list">'
    datum.forEach(
      (item) =>
        (html += `<li class="ccm-array-list-item">${getHTMLFromJSON(
          item
        )}</li>`)
    )
    html += '</ul>'
  } else if (typeof datum === 'object') {
    Object.keys(datum).forEach((key) => {
      html += '<ul class="ccm-object-list">'
      html += `<li class="ccm-object-list-item"><b>${key}:</b> ${getHTMLFromJSON(
        datum[key]
      )}</li>`
      html += '</ul>'
    })
  } else {
    html += (JSON.stringify(datum) || '').replace(/"/g, '')
  }
  return html
}

export function getTaskObjectFromFinding(finding) {
  const name = finding.description

  let description = ``
  description += `
    <div>
      <strong>Summary:</strong>
    </div>
    <p>
      ${finding.checked_items} ${finding.dashboard_name} were checked and ${finding.flagged_items} ${finding.dashboard_name} were flagged: <a href="//${window.location.host}/automations/apps/${this.appId}/${this.instanceId}/${this.executionId}/${this.serviceKey}/${finding.key}">${window.location.host}/automations/apps/${this.appId}/${this.instanceId}/${this.executionId}/${this.serviceKey}/${finding.key}</a>
    </p>
    </br>
  `
  if (finding.rationale)
    description += `
    <div><strong>Description:</strong></div>
      <p>${finding.rationale}</p>
    </br>
  `

  if (finding.remediation)
    description += `
    <div>
      <strong>Remediation:</strong>
    </div>
    <p>${finding.remediation}</p>
    </br>
    `

  if (finding.compliance) {
    description += `
    <div>
      <strong>Compliance:</strong>
    </div>
    <ul>`
    finding.compliance.forEach((item) => {
      description += `<li>${item.name} version ${item.version}, reference ${item.reference}</li>`
    })
    description += '</ul></br>'
  }
  if (finding.references) {
    description += `
    <div>
      <strong>References:</strong>
    </div>
    <ul>`
    finding.references.forEach((reference) => {
      description += `<li><a href="${reference}" target="_blank">${reference}</a></li>`
    })
    description += '</ul></br>'
  }

  let criticality
  switch (finding.level) {
    case 'danger':
      criticality = 'extreme'
      break
    case 'warning':
      criticality = 'medium'
      break
    default:
      criticality = 'not_set'
  }
  return { name, description, criticality }
}

export function getObjectFromDict(dictionary, key1, key2) {
  if (dictionary && dictionary[key1] && dictionary[key1][key2])
    return dictionary[key1][key2]

  return {}
}

export function prettyName(name) {
  if (!name) return name
  return name.includes('_')
    ? name
        .split('_')
        .map((n) => {
          return Utilities.Strings.capitalize(n.toLowerCase())
        })
        .join(' ')
    : Utilities.Strings.capitalize(name)
}

/** If the secret was not changed, delete it from the config */
export function deleteSecretsFromConfig(obj) {
  const secretConfigs = obj.configs
    .filter((config) => config.value_type === 'secret')
    .map((config) => config.variable)

  for (let configKey of Object.keys(obj.env_config)) {
    if (
      secretConfigs.indexOf(configKey) >= 0 &&
      obj.env_config[configKey] === defaultSecret
    ) {
      delete obj.env_config[configKey]
    }
  }

  return obj
}

/** Populates env_config and replaces config fields with type 'secret' with the default secret ('*****') */
export function getEnvConfigFromMapping(mapping) {
  return {
    ...mapping,
    env_config: (mapping.configs || []).reduce((obj, c) => {
      if (c.value_type === 'secret') {
        return {
          ...obj,
          [c.variable]: defaultSecret,
        }
      } else {
        return {
          ...obj,
          [c.variable]: (mapping.env_config || {})[c.variable] || null,
        }
      }
    }, mapping.env_config),
  }
}

/** Updates exception object by removing the specified resource */
export function removeExceptionFromConfig(
  config,
  serviceKey,
  findingKey,
  resourceKey
) {
  // Copy the exceptions object without reference
  let mutableExceptions = $.extend(true, {}, config)

  // delete the audit entry
  delete mutableExceptions.audit[serviceKey][findingKey][resourceKey]

  // delete the exception
  const findingIndex =
    mutableExceptions[serviceKey][findingKey].indexOf(resourceKey)
  if (findingIndex > -1) {
    mutableExceptions[serviceKey][findingKey].splice(findingIndex, 1)
  }

  //clean up by deleting empty finding objects
  if (mutableExceptions[serviceKey][findingKey].length === 0) {
    delete mutableExceptions[serviceKey][findingKey]
  }

  if (Object.keys(mutableExceptions.audit[serviceKey]).length === 0) {
    delete mutableExceptions[serviceKey]
  }

  //clean up by deleting empty audit objects
  if (
    Object.keys(mutableExceptions.audit[serviceKey][findingKey]).length === 0
  ) {
    delete mutableExceptions.audit[serviceKey][findingKey]
  }
  if (Object.keys(mutableExceptions.audit[serviceKey]).length === 0) {
    delete mutableExceptions.audit[serviceKey]
  }

  //delete fetched inidicator used in state
  delete mutableExceptions.fetched

  return mutableExceptions
}
