// with Vue3, this._uid was removed (it's technically not a public variable and should have never been used)
// Vue 3.5 promises to offer a replacement for this https://twitter.com/youyuxi/status/1745379112456429688
// In the meantime, create our own .uuid and inject into every component: https://github.com/vuejs/vue/issues/5886#issuecomment-308647738
let uuid = 0

export default {
  beforeCreate() {
    this.uuid = uuid.toString()
    uuid += 1
  },
}
