import moment from 'moment'

const startTime = moment().startOf('day')

export default {
  times: new Array(24).fill(0).map((_, i) => {
    const newTime = startTime.clone().add(i, 'hours')
    return {
      text: `${newTime.format('h:mma UTCZ')}`,
      val: newTime.utc().format('hh:mm:ss')
    }
  })
}
