import ApiAssessments from '../../factories/ApiAssessments'
import ApiFiles from '../../factories/ApiFiles'
import ApiMyControls from '../../factories/ApiMyControls'

export default {
  async getFiles({ commit, state }, { reset = state.globalReset }) {
    if (state.files.files.length > 0 && !reset) return

    const files = await ApiFiles.getFiles(
      state.files.status,
      state.files.fileSearch,
      state.files.filters,
      state.files.files.currentPage,
      state.files.files.perPage,
      state.files.fileSorting
    )
    commit('SET_FILES', files)
  },

  async fileChangePage({ commit, dispatch }, newPage) {
    commit('SET_FILE_PAGE', newPage)
    await dispatch('getFiles', { reset: true })
  },

  async fileChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_FILE_PER_PAGE', newPerPage)
    await dispatch('getFiles', { reset: true })
  },

  async fileSetSorting({ commit, dispatch }, { key, val }) {
    commit('SET_FILE_SORTING', { key, val })
    await dispatch('getFiles', { reset: true })
  },

  async fileSetSearch({ commit, dispatch }, search) {
    commit('SET_FILE_SEARCH', search)
    commit('SET_FILE_PAGE', 1)
    await dispatch('getFiles', { reset: true })
  },

  async fileSetFilters({ commit, dispatch }, filters) {
    commit('SET_FILE_FILTERS', filters)
    commit('SET_FILE_PAGE', 1)
    await dispatch('getFiles', { reset: true })
  },

  async getAvailableAssessments({ commit }) {
    const { projects } = await ApiAssessments.getProjectNames()
    commit('SET_AVAILABLE_ASSESSMENTS', projects)
  },

  async getAvailableFrameworks({ commit }) {
    const { frameworks } = await ApiMyControls.getTeamFrameworks()
    commit('SET_AVAILABLE_FRAMEWORKS', frameworks)
  },
}
