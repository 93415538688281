export default {
  SET_VENDOR_MANAGEMENT_ERROR(state, error) {
    state.vendormanagement.error = error
  },

  SET_VENDOR_MANAGEMENT_VENDORS(state, vendors) {
    state.vendormanagement.vendors = vendors
  },

  SET_VENDOR_MANAGEMENT_CATEGORIES(state, categories) {
    state.vendormanagement.categories = categories
  },

  SET_VENDOR_MANAGEMENT_POLICIES(state, policies) {
    state.vendormanagement.impactScores = policies.impact
    state.vendormanagement.likelihoodScores = policies.likelihood
    state.vendormanagement.costScores = policies.cost
    state.vendormanagement.criticalityScores = policies.criticality
    state.vendormanagement.reviewCadences = policies.review_cadence
  },

  SET_VENDOR_MANAGEMENT_SHOW_ALL_VENDORS(state, show) {
    state.vendormanagement.showAllVendors = show
  },

  SET_VENDOR_MANAGEMENT_TEAM_VENDORS(state, info) {
    state.vendormanagement.teamVendors = info
  },

  SET_VENDOR_MANAGEMENT_VENDOR_STATUSES(state, statuses) {
    state.vendormanagement.vendorStatuses = statuses
  },

  CLEAR_VENDOR_MANAGEMENT_TEAM_VENDOR_FILTERS(state) {
    Object.keys(state.vendormanagement.teamVendorFilters).forEach((key) => {
      state.vendormanagement.teamVendorFilters[key] = null
    })
  },

  SET_VENDOR_MANAGEMENT_TEAM_VENDOR_FILTER(state, { key, val }) {
    state.vendormanagement.teamVendorFilters[key] = val
  },

  SET_VENDOR_MANAGEMENT_TEAM_VENDOR_SORTING(state, { key, val }) {
    state.vendormanagement.teamVendorSorting[key] = val
  },

  SET_VENDOR_MANAGEMENT_TEAM_VENDORS_PAGE(state, page) {
    state.vendormanagement.teamVendors.currentPage = page
  },

  SET_VENDOR_MANAGEMENT_TEAM_VENDORS_PER_PAGE(state, perPage) {
    state.vendormanagement.teamVendors.perPage = perPage
  },

  SET_VENDOR_MANAGEMENT_CURRENT_VENDOR(state, vendor) {
    state.vendormanagement.currentVendor = vendor
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRES(state, info) {
    state.vendormanagement.questionnaires = info
  },

  SET_VENDOR_MANAGEMENT_ALL_QUESTIONNAIRES(state, info) {
    state.vendormanagement.allQuestionnaires = info
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_TEMPLATES(state, info) {
    state.vendormanagement.questionnaireTemplates = info
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_FILTER(state, { key, val }) {
    state.vendormanagement.questionnaireFilters[key] = val
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_PAGE(state, page) {
    state.vendormanagement.questionnaires.currentPage = page
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRES_PER_PAGE(state, perPage) {
    state.vendormanagement.questionnaires.perPage = perPage
  },

  SET_VENDOR_MANAGEMENT_CURRENT_QUESTIONNAIRE(state, questionnaire) {
    state.vendormanagement.currentQuestionnaire = questionnaire
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_AND_SECTIONS(state, info) {
    state.vendormanagement.currentQuestionnaireAndSections = info
  },

  SET_VENDOR_MANAGEMENT_QUESTIONNAIRE_STATUSES(state, statuses) {
    state.vendormanagement.questionnaireStatuses = statuses
  },

  SET_VENDOR_MANAGEMENT_SHOW_QUESTIONNAIRE_TEMPLATES(state, show) {
    state.vendormanagement.showQuestionnaireTemplates = show
  },

  SET_VENDOR_MANAGEMENT_CHOICE_TEMPLATES(state, templates) {
    state.vendormanagement.questionnaireChoiceTemplates = templates
  },

  SET_VENDOR_MANAGEMENT_REVIEWS(state, info) {
    state.vendormanagement.reviews = info
  },

  SET_VENDOR_MANAGEMENT_ALL_REVIEWS(state, info) {
    state.vendormanagement.allReviews = info
  },

  SET_VENDOR_MANAGEMENT_CURRENT_REVIEW(state, review) {
    state.vendormanagement.currentReview = review
  },

  SET_VENDOR_MANAGEMENT_REVIEWS_FILTER(state, { key, val }) {
    state.vendormanagement.reviewFilters[key] = val
  },

  SET_VENDOR_MANAGEMENT_REVIEWS_PAGE(state, page) {
    state.vendormanagement.reviews.currentPage = page
  },

  SET_VENDOR_MANAGEMENT_REVIEWS_PER_PAGE(state, perPage) {
    state.vendormanagement.reviews.perPage = perPage
  },

  SET_VENDOR_MANAGEMENT_REVIEW_STATUSES(state, statuses) {
    state.vendormanagement.reviewStatuses = statuses
  },
}
