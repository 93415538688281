import ApiIntegrations from './ApiIntegrations'
export default function JiraHelpers(context) {
  return {
    /** If createSync is false, the link is deleted */
    async handleJiraTaskSync({
      createSync,
      jiraProjectId,
      jiraIssueId,
      fullCircleTaskId,
    }) {
      try {
        context.$emit('syncing')
        if (createSync) {
          if (jiraIssueId) {
            await ApiIntegrations.linkJiraTask({
              taskId: fullCircleTaskId,
              jiraIssueId,
            })
          } else {
            await ApiIntegrations.createSyncedJiraTask({
              taskId: fullCircleTaskId,
              jiraProjectId,
            })
          }
        } else {
          await ApiIntegrations.unlinkSyncedJiraTask({
            taskId: fullCircleTaskId,
          })
        }
        window.toastr.success(
          `Succesfully ${createSync ? 'created linked' : 'unlinked'} issue!`
        )
      } catch (err) {
        window.toastr.error(
          `Error ${createSync ? 'creating linked' : 'unlinking'} issue. ${
            err.message
          }`
        )
      } finally {
        context.$emit('done')
      }
    },

    /** If createSync is false, the link is deleted */
    async handleJiraProjectSync({
      createSync,
      jiraProjectId,
      applyToProjectTasks,
      fullCircleProjectId,
    }) {
      try {
        context.$emit('syncing')
        if (createSync) {
          await ApiIntegrations.createSyncedJiraProject({
            projectId: fullCircleProjectId,
            jiraProjectId,
            applyToProjectTasks,
          })
        } else {
          await ApiIntegrations.unsyncJiraProject({
            projectId: fullCircleProjectId,
            applyToProjectTasks,
          })
        }
        window.toastr.success(
          `Succesfully ${createSync ? 'created linked' : 'unlinked'} project!`
        )
      } catch (err) {
        window.toastr.error(
          `Error ${createSync ? 'creating linked' : 'unlinking'} project. ${
            err.message
          }`
        )
      } finally {
        context.$emit('done')
      }
    },
  }
}
