export function addContraCodeEventListener(callback) {
  document.addEventListener("keydown", contraCode(callback), false)
}

let keyCodeSequenceCache = correctSequence()

function contraCode(callback) {
  return function evListener(e) {
    const code = e.keyCode
    const [ next ] = keyCodeSequenceCache.splice(0, 1)
    if (code !== next)
      keyCodeSequenceCache = correctSequence()

    if (keyCodeSequenceCache.length === 0) {
      callback()
      keyCodeSequenceCache = correctSequence()
    }
  }
}

function correctSequence() {
  const { up, down, left, right, a, b } = keyCodeMap()
  return [ up, up, down, down, left, right, left, right, b, a ]
}

function keyCodeMap() {
  return {
    up: 38,
    down: 40,
    left: 37,
    right: 39,
    b: 66,
    a: 65
  }
}
