import ApiProjects from '../../factories/ApiProjects'
import { taskTypes } from '../../constants/taskTypes'

export default {
  async initProject({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      // dispatch('getProjectPolicies', reset),
      dispatch('getProjectStatuses', reset),
      dispatch('getProjectTaskTypes', reset),
      dispatch('getAllScopes', reset),
    ])
  },

  async getProjects(
    { commit, state },
    {
      isDeleted = false,
      reset = state.globalReset,
      showArchivedProjects = false,
    }
  ) {
    if (state.projects.projects.length > 0 && !reset) return

    const { projects } = await ApiProjects.getAllProjects(
      isDeleted,
      showArchivedProjects
    )
    commit('SET_PROJECTS', projects)
  },

  async getProjectGroups({ commit, state }, id = null) {
    try {
      if (!id) id = state.projects.currentProject.id
      const { groups } = await ApiProjects.getProjectGroups(id)
      commit('SET_PROJECT_GROUPS', groups)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getProject({ commit }, id) {
    try {
      const {
        // assessmentMappings,
        project,
      } = await ApiProjects.getProject(id)
      commit('SET_PROJECT', project)
      // commit('SET_PROJECT_ASSESSMENT_MAPPINGS', assessmentMappings)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getProjectTasks(
    { commit, state },
    { reset = state.globalReset, projectId = null }
  ) {
    try {
      if (state.projects.tasks.length > 0 && !reset) return
      const { tasks, tags } = await ApiProjects.getAllTasks(
        projectId ? projectId : state.projects.currentProject.id,
        state.projects.taskFilters,
        state.projects.taskSorting,
        state.projects.tasks.currentPage,
        state.projects.tasks.perPage
      )
      commit('SET_PROJECT_TASKS', tasks)
      commit('SET_PROJECT_TAGS', tags)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getTaskSubtasks({ commit, state }, reset = state.globalReset) {
    try {
      if (state.projects.currentTask.subtasks.length > 0 && !reset) return
      const info = await ApiProjects.getSubtasks(
        state.projects.currentTask.record.id
      )

      commit('SET_PROJECT_TASK_SUBTASKS', info)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  // async getProjectPolicies({ commit, state } /* , reset = false */) {
  //   // if (state.projects.impactScores.length > 0 && !reset) return
  //   if (!state.projects.currentRegister.id) return

  //   const policies = await ApiProjects.getPolicies(
  //     state.projects.currentRegister.id
  //   )
  //   commit('SET_PROJECT_POLICIES', policies)
  // },

  async getProjectStatuses({ commit, state }, reset = state.globalReset) {
    if (state.projects.statuses.length > 0 && !reset) return

    const { statuses } = await ApiProjects.getStatuses()
    commit('SET_PROJECT_STATUSES', statuses)
  },

  async getProjectTaskTypes({ commit }) {
    commit('SET_PROJECT_TASK_TYPES', taskTypes)
  },

  async projectTaskChangePage({ commit, dispatch }, newPage) {
    commit('SET_PROJECT_TASKS_PAGE', newPage)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_PROJECT_TASKS_PAGE', 1)
    commit('SET_PROJECT_TASKS_PER_PAGE', newPerPage)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskFrontendChangePage({ commit, dispatch }, newPage) {
    await dispatch('getProjectTasks', { reset: false })
    commit('SET_PROJECT_FRONTEND_PAGINATION_OBJ', {
      currentPage: newPage,
    })
  },

  async projectTaskFrontendChangePerPage(
    { commit, state, dispatch },
    { newPerPage, reset = false }
  ) {
    newPerPage = newPerPage ?? state.projects.frontendPaginatedData.perPage
    await dispatch('getProjectTasks', { reset })
    commit('SET_PROJECT_FRONTEND_PAGINATION_OBJ', { perPage: newPerPage })
  },

  async getProjectTask(
    { commit, state },
    taskId = state.projects.currentTask.record.id
  ) {
    try {
      const {
        task,
        comments,
        files,
        owners,
        collaborators,
        taskTags,
        changelog,
        controls,
        formalFindings,
        riskItems,
        penTestFindings,
        myControls,
        evidence,
        uploadUsers,
        scopeGroups,
      } = await ApiProjects.getTask(taskId)

      commit('SET_PROJECT_TASK', task)
      commit('SET_PROJECT_TASK_COMMENTS', comments)
      commit('SET_PROJECT_TASK_FILES', files)
      commit('SET_PROJECT_TASK_OWNERS', owners)
      commit('SET_PROJECT_TASK_COLLABORATORS', collaborators)
      commit('SET_PROJECT_TASK_TAGS', taskTags)
      commit('SET_PROJECT_TASK_CHANGELOG', changelog)
      commit('SET_PROJECT_TASK_CONTROLS', controls)
      commit('SET_PROJECT_TASK_FORMAL_FINDINGS', formalFindings)
      commit('SET_PROJECT_TASK_RISK_ITEMS', riskItems)
      commit('SET_PROJECT_TASK_PENTEST_FINDINGS', penTestFindings)
      commit('SET_PROJECT_TASK_MY_CONTROLS', myControls)
      commit('SET_PROJECT_TASK_EVIDENCE', evidence)
      commit('SET_PROJECT_TASK_UPLOAD_USERS', uploadUsers)
      commit('SET_PROJECT_TASK_SCOPE_GROUPS', scopeGroups)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async setProjectFilterAndGetTasks({ commit, dispatch }, { key, val }) {
    commit('SET_PROJECT_TASK_FILTER', { key, val })
    await dispatch('getProjectTasks', { reset: true })
  },

  async setTaskSorting({ commit, dispatch }, { key, val }) {
    commit('SET_PROJECT_TASK_SORTING', { key, val })
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectGroupOrderingUpdate(
    { dispatch },
    { projectId, groupIds, startIndex }
  ) {
    await ApiProjects.updateGroupOrdering(projectId, groupIds, startIndex)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskOrderingUpdate(
    { dispatch },
    { projectId, taskIds, startIndex }
  ) {
    await ApiProjects.updateTaskOrdering(projectId, taskIds, startIndex)
    await dispatch('getProjectTasks', { reset: true })
  },
}
