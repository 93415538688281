import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getSectionsAndFiles(status = '', includeInternal = '') {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/sections/files/get?status=${status ||
        ''}&internal=${includeInternal || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getConfig() {
    const response = await R3sFetch(`/api/1.0/trustcenter/config/get`)
    return await handleFetchResponse(response)
  },

  async updateConfig(userId, bookingLink) {
    const response = await R3sFetch(`/api/1.0/trustcenter/config/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId, bookingLink }),
    })
    return await handleFetchResponse(response)
  },

  async fileDownload(sectionId, fileId) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/file/download/audit`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sectionId, fileId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createSection(section) {
    const response = await R3sFetch(`/api/1.0/trustcenter/section/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ section }),
    })
    return await handleFetchResponse(response)
  },

  async updateSection(section) {
    const response = await R3sFetch(`/api/1.0/trustcenter/section/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ section }),
    })
    return await handleFetchResponse(response)
  },

  async reorderSectionFiles(groupId, fileMapIds) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/section/files/reorder`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupId, fileMapIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateFilePublishState(teamId, fileMapId, isPublished = true) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/file/publish/in/org`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamId, fileMapId, isPublished }),
      }
    )
    return await handleFetchResponse(response)
  },

  async addFileToSection({ fileExtId, groupId, internalOnly }) {
    const response = await R3sFetch(`/api/1.0/trustcenter/section/add/file`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileExtId, groupId, internalOnly }),
    })
    return await handleFetchResponse(response)
  },

  async removeFileFromSection(mapId) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/section/remove/file`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: mapId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getDashboardFileSummary(limit = null) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/dashboard/file/summary?limit=${limit || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardFileSummaryByUser(userId, limit = null) {
    const response = await R3sFetch(
      `/api/1.0/trustcenter/dashboard/file/user/summary?userId=${userId ||
        ''}&limit=${limit || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardUniqueUsersWhoDownloaded() {
    const response = await R3sFetch(`/api/1.0/trustcenter/dashboard/users`)
    return await handleFetchResponse(response)
  },
}
