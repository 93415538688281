import Utilities from '@risk3sixty/utilities'
import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getSession() {
    const response = await R3sFetch(`/api/1.0/auth/session`)
    return await handleFetchResponse(response)
  },

  async resendVerification() {
    const response = await R3sFetch(`/api/1.0/auth/resend/verification`)
    return await handleFetchResponse(response)
  },

  async forgotPassword(email) {
    const response = await R3sFetch(`/api/1.0/auth/password/forgot`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
    return await handleFetchResponse(response)
  },

  async resetPassword({ current_password, new_password }) {
    const response = await R3sFetch(`/api/1.0/auth/password/reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ current_password, new_password }),
    })
    return await handleFetchResponse(response)
  },

  async verifyPasswordReset(email, tempPasswordHash) {
    const response = await R3sFetch(
      `/api/1.0/auth/password/forgot/verify?email=${encodeURIComponent(
        email
      )}&hash=${encodeURIComponent(tempPasswordHash)}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
    )
    return await handleFetchResponse(response)
  },

  async generateMfaSecret() {
    const response = await R3sFetch(`/api/1.0/auth/mfa/generate`)
    return await handleFetchResponse(response)
  },

  async validateCreationMfaToken(token, disable = false, user_id = null) {
    const response = await R3sFetch(`/api/1.0/auth/mfa/init/validate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, disable, user_id }),
    })
    return await handleFetchResponse(response)
  },

  async validateMfaToken(token) {
    const response = await R3sFetch(`/api/1.0/auth/mfa/validate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    })
    return await handleFetchResponse(response)
  },

  isValidEmail(text = '') {
    return Utilities.Regexp.email.test(text || '')
  },

  async acceptEula() {
    const response = await R3sFetch(`/api/1.0/auth/eula/accept`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
    return await handleFetchResponse(response)
  },
}
