export const evidenceTypes = [
  {
    name: 'Documentation',
    value: 'documentation',
  },
  {
    name: 'Observation',
    value: 'observation',
  },
]
