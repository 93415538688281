import $ from 'jquery'
import Utilities from '@risk3sixty/utilities'

const Promises = Utilities.Promises

export async function sleep(timeoutMs = 1e3) {
  return await Promises.sleep(timeoutMs)
}

export function checkAndDisplayIndependenceConfirmationToastr(
  queryStringObj,
  message = `You successfully attested your independence!`
) {
  const checkQueryStringKeyValue = (k, v = null) =>
    v
      ? queryStringObj[k] === v
      : Object.prototype.hasOwnProperty.call(queryStringObj, k)

  if (checkQueryStringKeyValue('independence')) window.toastr.success(message)
}

export function checkUserNavAndPossiblyRedirect(navInfo) {
  navInfo = navInfo || this.$store.state.auth.userNavItems
  if (!navInfo) return

  let allowedLinks = []
  let isCurrentPathAllowed = false
  Object.keys(navInfo)
    .sort((i1) => {
      return navInfo[i1].canSee ? -1 : 1
    })
    .forEach((key) => {
      if (navInfo[key].link) {
        const linkRegexp = new RegExp(`^${navInfo[key].link}`)
        isCurrentPathAllowed = linkRegexp.test(this.$route.path)
      }

      if (isCurrentPathAllowed) return

      if (!navInfo[key].redirect) return

      if (navInfo[key].canSee && !navInfo[key].isGated)
        return allowedLinks.push(navInfo[key].redirect)

      // If we're on a page that we're not supposed to be able
      // to be on, redirect the user to a valid page now
      const regexp = new RegExp(`^${navInfo[key].redirect}`)
      if (regexp.test(this.$route.path)) {
        if (navInfo.home.canSee) return this.$router.push(navInfo.home.redirect)

        return this.$router.push(allowedLinks[0])
      }
    })
}

export function isImage(imageFile) {
  const splitName = imageFile.split('.')
  return ['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'svg', 'webp'].includes(
    splitName[splitName.length - 1].toLowerCase()
  )
}

export async function checkFileAscii(fileBlob) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (function (theReader) {
      return function (/*evt*/) {
        try {
          const fileContents = theReader.result
          // if (fileContents.match(/[^\u0000-\u007f]/)) return resolve(false)
          // https://stackoverflow.com/questions/1677644/detect-non-printable-characters-in-javascript
          if (fileContents.match(/[\x00-\x08\x0E-\x1F]/)) return resolve(false)
          resolve(fileContents)
        } catch (err) {
          reject(err)
        }
      }
    })(reader)
    reader.readAsBinaryString(fileBlob)
  })
}

// Resolution for tabs within modal
// https://github.com/udacity/ud891/tree/gh-pages/lesson2-focus/07-modals-and-keyboard-traps/solution
export function tabListener(elId) {
  const modal = document.querySelector(`#${elId}`)

  // Listen for and trap the keyboard
  modal.addEventListener('keydown', trapTabKey)

  // Find all focusable children
  var focusableElementsString =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])'
  var focusableElements = modal.querySelectorAll(focusableElementsString)
  // Convert NodeList to Array
  focusableElements = Array.prototype.slice.call(focusableElements)

  var firstTabStop = focusableElements[0]
  var lastTabStop = focusableElements[focusableElements.length - 1]

  // Show the modal and overlay
  modal.style.display = 'block'

  // Focus first child
  firstTabStop.focus()

  function trapTabKey(e) {
    e.stopPropagation()

    // Check for TAB key press
    if (e.keyCode === 9) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e.preventDefault()
          lastTabStop.focus()
        }

        // TAB
      } else {
        if (document.activeElement === lastTabStop) {
          e.preventDefault()
          firstTabStop.focus()
        }
      }
    }

    // ESCAPE
    if (e.keyCode === 27) {
      $(`#${elId}`).modal('hide')
    }
  }
}

export var fileExtensionIconClasses = {
  default: ['fa', 'fa-file'],

  avi: ['fa', 'fa-file-video'],
  bmp: ['fa', 'fa-image'],
  csv: ['fa', 'fa-file-csv'],
  doc: ['fa', 'fa-file-word'],
  docx: ['fa', 'fa-file-word'],
  gif: ['fa', 'fa-image'],
  img: ['fa', 'fa-image'],
  jpg: ['fa', 'fa-image'],
  jpeg: ['fa', 'fa-image'],
  mov: ['fa', 'fa-file-video'],
  mp4: ['fa', 'fa-file-video'],
  mpeg: ['fa', 'fa-file-video'],
  mpg: ['fa', 'fa-file-video'],
  nessus: ['fa', 'fa-file-code'],
  pdf: ['fa', 'fa-file-pdf'],
  png: ['fa', 'fa-image'],
  wmv: ['fa', 'fa-file-video'],
  xls: ['fa', 'fa-file-excel'],
  xlsx: ['fa', 'fa-file-excel'],
  xml: ['fa', 'fa-file-code'],
  zip: ['fa', 'fa-file-archive'],
}

export async function scrollToElement(
  elementId,
  sleepTime = 500,
  behavior = 'smooth'
) {
  await Utilities.Promises.sleep(sleepTime)
  let element = document.getElementById(elementId)
  if (element) {
    element.scrollIntoView({
      behavior,
      block: 'center',
      inline: 'start',
    })
  }
}
