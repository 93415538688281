import ApiAssessments from '../../factories/ApiAssessments'
import ApiBia from '../../factories/ApiBia'
import ApiRiskRegister from '../../factories/ApiRiskRegister'

export default {
  async getAllBias(
    { commit, state },
    showTemplates = state.inventories.showTemplates
  ) {
    const { bias } = await ApiBia.getAllBias(showTemplates)
    commit('SET_INVENTORY_LIST', bias)
  },

  async getBiaInfo({ commit }, { id, full = true }) {
    const { bia } = await ApiBia.getBia(id, full)
    commit('SET_CURRENT_INVENTORY', bia)
  },

  async getBiaAssessmentControls({ commit }, controlIds) {
    const { controls } = await ApiAssessments.getControlsByIds(controlIds)
    commit('SET_ASSESSMENT_CONTROLS', controls)
  },

  async getBiaRisks({ commit }, riskIds) {
    const { risks } = await ApiRiskRegister.getRisksByIds(riskIds)
    commit('SET_RISKS', risks)
  },
}
