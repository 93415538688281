export default {
  questionnaire: {
    globalError: null,
    breadcrumbs: null,
    // uuid: null,
    activeSectionId: null,
    activeQuestionId: null,
    filterQuestionsNeedingClarification: false,

    record: null,
    flags: [],
    changeLog: [],
    scoring: [],
    sectionScores: {},
    totalScore: 0,

    questionPointValueFilter: null,

    questionPointValues: [
      { val: 5, text: 'Extreme', variant: 'danger' },
      { val: 4, text: 'High', variant: 'warning' },
      { val: 3, text: 'Medium', variant: 'info' },
      { val: 2, text: 'Low', variant: 'minimal' },
      { val: -2, text: 'No Gap', variant: 'success' },
      { val: 1, text: 'Informational', variant: 'primary' },
      { val: 0, text: 'Not Applicable', variant: 'darkgray' },
      { val: -1, text: 'Not Answered', variant: 'gray' },
    ],

    getSectionNum(secId) {
      if (secId === 'submit') return
      const stateQuestionnaire = this
      const sections = stateQuestionnaire.record.sections
      return sections.findIndex((s) => s.id == secId) + 1
    },

    getQuestionNum(secId, qId) {
      if (secId === 'submit') return
      const { questions } = this.record.sections.find((s) => s.id == secId)

      const topLevelQuestions = questions.filter(
        (q) =>
          !(q.trigger_question_choices && q.trigger_question_choices.length > 0)
      )

      const matchingToplevelQuestionIndex = topLevelQuestions.findIndex(
        (q) => q.id == qId
      )

      // If a top-level question, just return the index + 1
      if (matchingToplevelQuestionIndex >= 0) {
        return matchingToplevelQuestionIndex + 1
        // Must be a branched question
      } else {
        const branchedQuestionIndex = questions.findIndex((q) => q.id == qId)
        const branchedQuestion = questions[branchedQuestionIndex]

        if (!branchedQuestion) return

        const parentQuestion = branchedQuestion.trigger_question_choices[0]
        const parentQuestionIndex = questions.findIndex(
          (q) => q.id == parentQuestion.question_id
        )

        const branchedQuestionCountAfterParent =
          branchedQuestionIndex - parentQuestionIndex

        const parentQuestionNum = this.getQuestionNum(
          secId,
          parentQuestion.question_id
        )
        return parentQuestionNum + '.' + branchedQuestionCountAfterParent
      }
    },

    questionnaireScoreBubbleInfo(responses) {
      const displayedResponses = responses.filter((r) =>
        this.questionIsDisplayed(
          r,
          responses.filter((res) => res.group_id === r.group_id)
        )
      )
      return this.questionPointValues.map((score) => {
        return {
          key: score.text,
          value:
            displayedResponses
              .filter((r) => r.questionScore == score.val)
              .length.toString() || '0',
          variant: score.variant,
          val: score.val,
        }
      })
    },

    getCountCompletedAndTotalQuestions(questionArray) {
      if (!questionArray) return { completedQuestions: 0, totalQuestions: 0 }
      const completedQuestions = this.getCompletedQuestions(questionArray)
      const totalQuestions = this.getTotalQuestions(questionArray)
      return { completedQuestions, totalQuestions }
    },

    getCompletedQuestions(questionArray) {
      if (!questionArray) return 0
      const displayedQuestionsWithAnswer = questionArray.filter((q) =>
        this.questionIsDisplayed(q, questionArray)
          ? this.questionHasAnswer(q)
          : false
      )
      return displayedQuestionsWithAnswer.length
    },

    getTotalQuestions(questionArray) {
      if (!questionArray) return 0
      const displayedQuestions = questionArray.filter((q) =>
        this.questionIsDisplayed(q, questionArray)
      )
      return displayedQuestions.length || 0
    },

    questionHasAnswer(question) {
      if (!question || !question.responses) return false
      return (
        (question.type !== 'free_form' &&
          question.responses &&
          question.responses.length > 0) ||
        (question.responses &&
          question.responses.some((response) => response.text.length > 0))
      )
    },

    questionIsDisplayed(question, questionArray) {
      const questionIsOnBranch =
        question &&
        question.trigger_question_choices &&
        question.trigger_question_choices.length > 0
      if (!questionIsOnBranch) return true //If Question isn't on a branch, it will be displayed

      const qAndAsToTrigger = question.trigger_question_choices

      for (let i in qAndAsToTrigger) {
        const triggerQuestionId = qAndAsToTrigger[i].question_id
        const triggerQuestion = questionArray.find(
          (q) => q.id == triggerQuestionId
        )
        if (
          triggerQuestion &&
          this.questionIsDisplayed(triggerQuestion, questionArray) && // Use recursion to check that the trigger question is displayed
          triggerQuestion.responses &&
          triggerQuestion.responses.length > 0 &&
          triggerQuestion.responses.some(
            (response) => response.choice_id == qAndAsToTrigger[i].id
          )
        ) {
          return true // If a trigger question is marked with the trigger answer, it will be displayed
        }
      }

      return false // Question on a branch, but branch isn't triggered. It won't be displayed
    },

    getParentQuestions(question, allQuestions) {
      if (question.trigger_question_choices?.length > 0) {
        return allQuestions.filter((q) =>
          question.trigger_question_choices
            .flatMap((tqc) => tqc.question_id)
            .includes(q.id)
        )
      }
      return null
    },

    getChildrenQuestions(question, allQuestions) {
      return allQuestions.filter((q) =>
        q.trigger_question_choices
          .flatMap((tqc) => tqc.question_id)
          .includes(question.id)
      )
    },

    moveChildrenUnderParents(allQuestions) {
      allQuestions.forEach((q, ind) => {
        const parentQuestions = this.getParentQuestions(q, allQuestions)
        if (parentQuestions && parentQuestions.length > 0) {
          const siblingQuestions = this.getChildrenQuestions(
            parentQuestions[0],
            allQuestions
          )

          const parentQuestionIndex = allQuestions.findIndex(
            (q) => q.id == parentQuestions[0].id
          )

          if (
            ind <= parentQuestionIndex ||
            ind > siblingQuestions.length + parentQuestionIndex
          ) {
            // if a question is before it's parent or beyond the length of parent + count of siblings
            allQuestions.splice(ind, 1)
            allQuestions.splice(parentQuestionIndex + 1, 0, q)
            this.moveChildrenUnderParents(allQuestions)
          }
        }
      })
    },
  },
}
