export default {
  itemContextClass(item) {
    const severity = (typeof item === 'object') ? item.severity : item
    switch (severity) {
      case 'critical':
        return 'text-danger'
      case 'high':
        return 'text-warning'
      case 'medium':
        return 'text-info'
      case 'low':
        return 'text-success'
      case 'informational':
        return 'text-gray'
    }
  },

  itemRowContextClass(item) {
    const severity = (typeof item === 'object') ? item.severity : item
    switch (severity) {
      case 'critical':
        return 'danger'
      case 'high':
        return 'warning'
      case 'medium':
        return 'info'
      case 'low':
        return 'success'
      case 'informational':
        return 'gray'
    }
  },

  vendorLogoImageMap: {
    aws_inspector: 'aws_logo.png',
    burp: 'burp_suite_logo.png',
    manual: 'favicon.png',
    nessus: 'nessus_logo.png',
    nmap: 'nmap_logo.png'
  }
}
