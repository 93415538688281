import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getTypes() {
    const response = await R3sFetch(`/api/1.0/extensions/types`)
    return await handleFetchResponse(response)
  },

  async getAll() {
    const response = await R3sFetch(`/api/1.0/extensions/all`)
    return await handleFetchResponse(response)
  },

  async getTemplates() {
    const response = await R3sFetch(`/api/1.0/extensions/templates`)
    return await handleFetchResponse(response)
  },

  async getExtension(id) {
    const response = await R3sFetch(`/api/1.0/extensions/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getExtensionConfig(extensionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/configs/get?extensionId=${extensionId}`
    )
    return await handleFetchResponse(response)
  },

  async getMapping(id) {
    const response = await R3sFetch(`/api/1.0/extensions/mapping/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getAllMappingsForRecord(type, recordId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/record/mappings/get?type=${type}&recordId=${recordId}`
    )
    return await handleFetchResponse(response)
  },

  async getApps(extensionTypes) {
    const response = await R3sFetch(
      `/api/1.0/extensions/apps?extensionTypes=${encodeURIComponent(
        JSON.stringify(extensionTypes)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async deleteMappingForRecord(recMapId, mapType, mapRecId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/record/mappings/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: recMapId,
          mapping_type: mapType,
          mapping_record_id: mapRecId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getExecutionsForMapping(mappingId, page = 1, perPage = 20) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/executions/get?mappingId=${mappingId}&page=${
        page || ''
      }&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getAssessmentAutomations(projectId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/assessment/extensions/get?projectId=${projectId}`
    )
    return await handleFetchResponse(response)
  },

  async lastExecutionForExtension(extensionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/last/execution?extensionId=${extensionId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async downloadLatestResults(extensionId, type) {
    const response = await R3sFetch(
      `/api/1.0/extensions/last/execution/download?extensionId=${extensionId}&type=${type}`
    )
    return await handleFetchResponse(response)
  },

  async saveExtension(info) {
    const response = await R3sFetch(`/api/1.0/extensions/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async approveExtension(extId, isApproved) {
    const response = await R3sFetch(`/api/1.0/extensions/approval`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: extId, is_approved: isApproved }),
    })
    return await handleFetchResponse(response)
  },

  async mapExtension(info) {
    const response = await R3sFetch(`/api/1.0/extensions/mapping/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async scheduleExtensionMapping(info) {
    const response = await R3sFetch(`/api/1.0/extensions/mapping/schedule`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async triggerExtensionMapping(mappingId, configOverrides = null) {
    const response = await R3sFetch(`/api/1.0/extensions/mapping/trigger`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: mappingId, configOverrides }),
    })
    return await handleFetchResponse(response)
  },

  async stopExtensionMappingExecution(executionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/execution/stop`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: executionId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async triggerMappingsByMappingRecord(mappingType, recordId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/trigger/by/record`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: mappingType, recordId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async triggerMappingsByAssessment(projectId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/trigger/by/assessment`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getAppMetadata(appId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/app/metadata?appId=${appId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getAppInstances(appId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/app/instances?appId=${appId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getAppExecutionDataByKey(executionId, path) {
    const response = await R3sFetch(
      `/api/1.0/extensions/app/instance/execution/file/data/get?executionId=${executionId}&path=${path}`
    )
    return await handleFetchResponse(response)
  },

  async getAppExecutionSummary(executionId, uploadId = null) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/executions/summary/get?executionId=${
        executionId || ''
      }&uploadId=${uploadId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async downloadAppExecutionEvidence(
    executionId,
    path,
    signal = null,
    filename = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/execution/evidence/get`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          executionId,
          path,
          filename,
        }),
        signal,
      }
    )
    return await handleFetchResponse(response)
  },

  async uploadAppExecutionEvidenceToRequest(
    executionId,
    path,
    assessmentId,
    assessmentRequestId,
    signal = null,
    filename = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/execution/evidence/upload/to/request`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          executionId,
          path,
          assessmentId,
          assessmentRequestId,
          filename,
        }),
        signal,
      }
    )
    return await handleFetchResponse(response)
  },

  async testAWSRoleARNConfig(roleARN) {
    const response = await R3sFetch(
      `/api/1.0/extensions/role/config/test?roleARN=${roleARN || ''}`
    )
    return await handleFetchResponse(response)
  },

  async testAzureConfig(config) {
    const response = await R3sFetch(`/api/1.0/extensions/azure/config/test`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        config,
      }),
    })
    return await handleFetchResponse(response)
  },

  async testGcpConfig(config) {
    const response = await R3sFetch(`/api/1.0/extensions/gcp/config/test`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        config,
      }),
    })
    return await handleFetchResponse(response)
  },

  async getExecutionInstanceConfiguration(instanceId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/executions/configuration/get?instanceId=${instanceId}`
    )
    return await handleFetchResponse(response)
  },

  async getExecutionDefaultConfiguration(extensionType) {
    const response = await R3sFetch(
      `/api/1.0/extensions/executions/configuration/default/get?extensionType=${extensionType}`
    )
    return await handleFetchResponse(response)
  },

  async setExecutionConfiguration(instanceId, configuration) {
    const response = await R3sFetch(
      `/api/1.0/extensions/executions/configuration/set`,

      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          instanceId: instanceId,
          configuration: configuration,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getInstanceNextScheduledExecution(instanceId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/schedule/next/get?instanceId=${instanceId}`
    )
    return await handleFetchResponse(response)
  },

  async getExecutionHistory(instanceId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/executions/history/get?instanceId=${instanceId}`
    )
    return await handleFetchResponse(response)
  },

  async getExecutionInstanceInventory(executionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/execution/inventory/get?executionId=${executionId}`
    )
    return await handleFetchResponse(response)
  },

  async getExecutionInstanceInventoryCSV(executionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/execution/inventory/csv/get?executionId=${executionId}`
    )
    return await handleFetchResponse(response)
  },

  async getInstanceExceptions(instanceId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/exceptions/get?instanceId=${instanceId}`
    )
    return await handleFetchResponse(response)
  },

  async updateInstanceExceptions(instanceId, exceptions) {
    const response = await R3sFetch(
      `/api/1.0/extensions/mapping/exceptions/set`,

      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          instanceId,
          exceptions,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getExecutionDashboard(executionId) {
    const response = await R3sFetch(
      `/api/1.0/extensions/app/instance/execution/dashboard/get?executionId=${executionId}`
    )
    return await handleFetchResponse(response)
  },
}
