import ApiMyControls from '../../factories/ApiMyControls'
import Utilities from '@risk3sixty/utilities'
const moment = Utilities.Dates.moment

export default {
  async initMyControls({ dispatch }) {
    await Promise.all([
      dispatch('getStatuses'),
      dispatch('getTeamFrameworks', { reset: true }),
      dispatch('getTeamControlsDomains', { reset: true }),
      dispatch('getAllScopes', { reset: true }),
      dispatch('getTeamFrameworks', { reset: true }),
      dispatch('getFilteredViews'),
    ])
  },

  async getStatuses({ commit }) {
    const {
      operationalStatuses,
      implementationStatuses,
      evidenceStatuses,
      evidenceFileStatuses,
    } = await ApiMyControls.getStatuses()
    commit('SET_MYCONTROLS_STATUSES', {
      operationalStatuses,
      implementationStatuses,
      evidenceStatuses,
      evidenceFileStatuses,
    })
  },

  async getAllFrameworksForPI(
    { commit, state },
    { reset = false, showArchived = false, showDeleted = false }
  ) {
    if (state.mycontrols.frameworks.length > 0 && !reset) return

    const { frameworks } =
      await ApiMyControls.getPlatformIntelligenceFrameworks({
        getArchived: showArchived,
        getDeleted: showDeleted,
      })
    commit('SET_MYCONTROLS_FRAMEWORKS', frameworks)
  },

  async getTeamFrameworks({ commit, state }, { reset = false }) {
    if (state.mycontrols.frameworks.length > 0 && !reset) return

    const { frameworks } = await ApiMyControls.getTeamFrameworks()
    commit('SET_MYCONTROLS_FRAMEWORKS', frameworks)
  },

  async getTeamControlGroups({ commit, state }, reset = true) {
    if (state.mycontrols.controlGroups.data.length > 0 && !reset) return
    const { paginatedControls, tags } =
      await ApiMyControls.getTeamControlGroups(
        state.mycontrols.filters.controlGroups,
        state.mycontrols.controlGroups.currentPage,
        state.mycontrols.controlGroups.perPage
      )
    commit('SET_MYCONTROLS_CONTROL_GROUPS', paginatedControls)
    commit('SET_MYCONTROLS_CONTROL_GROUPS_TAGS', tags)
  },

  async getTeamControlGroupsStatusCountsOverTime({ commit, state }, { dates }) {
    const results = {}

    await Promise.all(
      dates.map(async (date) => {
        const filters = {
          ...state.mycontrols.filters.dashboard,
          date,
        }
        const { dashboardControlGroupStatusCounts } =
          await ApiMyControls.getDashboardStatusOverTime(filters)

        results[date] = dashboardControlGroupStatusCounts
      })
    )
    commit('SET_MYCONTROLS_CONTROL_GROUPS_STATUS_COUNTS_OVER_TIME', results)
  },

  async getOtherFrameworkVersions({ commit }, frameworkVersionId) {
    const versions = await ApiMyControls.getOtherFrameworkVersions(
      frameworkVersionId
    )
    commit('SET_MYCONTROLS_FRAMEWORK_VERSIONS', versions)
  },

  async getCurrentFrameworkByMapId({ commit }, frameworkMapId) {
    const framework = await ApiMyControls.getMappedFrameworkDetails(
      frameworkMapId
    )

    commit('SET_MYCONTROLS_CURRENT_FRAMEWORK', framework)
  },

  async getCurrentFrameworkByVersionId({ commit }, frameworkVersionId) {
    const framework = await ApiMyControls.getFrameworkDetails(
      frameworkVersionId
    )
    commit('SET_MYCONTROLS_CURRENT_FRAMEWORK', framework)
  },

  async getFrameworkControlsByFrameworkVersionId(
    { commit, state },
    frameworkVersionId
  ) {
    if (!frameworkVersionId) {
      if (state.mycontrols.currentFramework?.framework?.frameworks_versions_id)
        frameworkVersionId =
          state.mycontrols.currentFramework.framework.frameworks_versions_id
      else return
    }

    const controls =
      await ApiMyControls.getFrameworkControlsByFrameworkVersionId(
        frameworkVersionId,
        state.mycontrols.filters.frameworkControls,
        state.mycontrols.currentFramework.controls.currentPage,
        state.mycontrols.currentFramework.controls.perPage
      )
    commit('SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROLS', controls)
  },

  async getTeamFrameworkControls({ commit }) {
    const frameworkControls = await ApiMyControls.getFrameworkControls(null, {})
    commit('SET_MYCONTROLS_FRAMEWORK_CONTROLS', frameworkControls)
  },

  /** If frameworkId is not provided, uses the current framework */
  async getFrameworkControls({ commit, state }, frameworkMapId) {
    if (!frameworkMapId) {
      if (state.mycontrols.currentFramework?.framework?.id)
        frameworkMapId =
          state.mycontrols.currentFramework.framework
            .frameworks_versions_teams_map_id
      else return
    }

    const controls = await ApiMyControls.getFrameworkControls(
      frameworkMapId,
      state.mycontrols.filters.frameworkControls
    )
    commit('SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROLS', controls)
  },

  async getFrameworkDomains(
    { commit, state },
    { reset = false, frameworkVersionId, frameworkMapId }
  ) {
    if (state.mycontrols.domains.length > 0 && !reset) return

    const { domains } = await ApiMyControls.getFrameworkDomains({
      frameworkVersionId,
      frameworkMapId,
    })
    commit('SET_MYCONTROLS_DOMAINS', domains)
  },

  async getTeamControlsDomains(
    { commit, state },
    { reset = false, isDeleted = false }
  ) {
    if (state.mycontrols.controlsDomains.length > 0 && !reset) return

    const { domains } = await ApiMyControls.getTeamControlsDomains(isDeleted)
    commit('SET_MYCONTROLS_CONTROL_GROUPS_DOMAINS', domains)
  },

  async setFilterAndGetControlGroups({ commit, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_CONTROL_GROUP_FILTER', { key, val })
    dispatch('getTeamControlGroups')
  },

  async setControlGroupFilter({ commit }, { key, val }) {
    await commit('SET_MYCONTROLS_CONTROL_GROUP_FILTER', { key, val })
  },

  async setEvidenceFilter({ commit }, { key, val }) {
    await commit('SET_MYCONTROLS_EVIDENCE_FILTER', { key, val })
  },

  async setFrameworkControlsFilter({ commit }, { key, val }) {
    await commit('SET_MYCONTROLS_FRAMEWORK_CONTROL_FILTER', { key, val })
  },

  async setFilterAndGetFrameworkControls(
    { commit, dispatch },
    { key, val, action }
  ) {
    await commit('SET_MYCONTROLS_FRAMEWORK_CONTROL_FILTER', { key, val })
    dispatch(action)
  },

  async setMyControlsTaskSorting({ commit, state, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_TASKS_SORTING', { key, val })
    if (Object.keys(state.mycontrols.currentControl.control).length > 0)
      dispatch('getCurrentControlTasks')
    if (Object.keys(state.mycontrols.currentEvidence.evidence).length > 0)
      dispatch('getCurrentEvidenceTasks')
  },

  async setMyControlsEventSorting({ commit, state, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_EVENTS_SORTING', { key, val })
    if (Object.keys(state.mycontrols.currentControl.control).length > 0)
      dispatch('getCurrentControlEvents')
    if (Object.keys(state.mycontrols.currentEvidence.evidence).length > 0)
      dispatch('getCurrentEvidenceEvents')
  },

  async setFilterAndGetTasks({ commit, state, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_TASKS_FILTER', { key, val })
    if (Object.keys(state.mycontrols.currentControl.control).length > 0)
      dispatch('getCurrentControlTasks')
    if (Object.keys(state.mycontrols.currentEvidence.evidence).length > 0)
      dispatch('getCurrentEvidenceTasks')
  },

  async setFilterAndGetEvents({ commit, state, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_EVENTS_FILTER', { key, val })
    if (Object.keys(state.mycontrols.currentControl.control).length > 0)
      dispatch('getCurrentControlEvents')
    if (Object.keys(state.mycontrols.currentEvidence.evidence).length > 0)
      dispatch('getCurrentEvidenceEvents')
  },

  async getControlTasks({ commit, state }, controlId) {
    const { tasks } = await ApiMyControls.getControlTasks(
      controlId,
      state.mycontrols.filters.tasks,
      state.mycontrols.sorting.tasks
    )
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_TASKS', tasks)
  },

  async getCurrentControlTasks({ state, dispatch }) {
    const controlId =
      state.mycontrols.currentControl.control.framework_controls_groups_id
    if (!controlId) return
    await dispatch('getControlTasks', controlId)
  },

  async getCurrentControlScopes({ commit }, controlId) {
    const scopes = await ApiMyControls.getTeamControlGroupScopes(controlId)
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_SCOPES', scopes)
  },

  async getControlSubControls({ commit }, controlGroupId) {
    const { paginatedControls } = await ApiMyControls.getTeamControlGroups(
      { parentId: controlGroupId },
      1,
      1e5
    )
    await commit(
      'SET_MYCONTROLS_CURRENT_CONTROL_SUB_CONTROLS',
      paginatedControls.data
    )
  },

  async getCurrentControlSubControls({ state, dispatch }) {
    const controlGroupId =
      state.mycontrols.currentControl.control.framework_controls_groups_id
    if (!controlGroupId) return
    await dispatch('getControlSubControls', controlGroupId)
  },

  async getEvidenceTasks({ commit, state }, evidenceId) {
    const { tasks } = await ApiMyControls.getCurrentEvidenceTasks(
      evidenceId,
      state.mycontrols.filters.tasks,
      state.mycontrols.sorting.tasks
    )
    await commit('SET_MYCONTROLS_CURRENT_EVIDENCE_TASKS', tasks)
  },

  async getEvidenceEvents({ commit, state }, evidenceId) {
    const events = await ApiMyControls.getCurrentEvidenceEvents(
      evidenceId,
      state.mycontrols.filters.events,
      state.mycontrols.sorting.events
    )
    await commit('SET_MYCONTROLS_CURRENT_EVIDENCE_EVENTS', events)
  },

  async getEvidenceControlGroups({ commit }, evidenceId) {
    const { evidenceControls } = await ApiMyControls.getEvidenceControlGroups(
      evidenceId
    )
    await commit('SET_MYCONTROLS_CURRENT_EVIDENCE_CONTROLS', evidenceControls)
  },

  async getCurrentEvidenceTasks({ dispatch, state }) {
    const evidenceId = state.mycontrols.currentEvidence.evidence.id
    if (!evidenceId) return
    await dispatch('getEvidenceTasks', evidenceId)
  },

  async getControlEvents({ commit, state }, controlId) {
    const events = await ApiMyControls.getControlEvents(
      controlId,
      state.mycontrols.filters.events,
      state.mycontrols.sorting.events
    )
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_EVENTS', events)
  },

  async getCurrentEvidenceEvents({ dispatch, state }) {
    const evidenceId = state.mycontrols.currentEvidence.evidence.id
    if (!evidenceId) return
    await dispatch('getEvidenceEvents', evidenceId)
  },

  async setFilterAndGetEvidence({ commit, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_EVIDENCE_FILTER', { key, val })
    dispatch('getTeamEvidence')
  },

  async getTeamEvidence({ commit, state }, reset = true) {
    if (state.mycontrols.evidence.data.length > 0 && !reset) return
    const [{ paginatedEvidence, tags }, { domains }] = await Promise.all([
      ApiMyControls.getTeamEvidence(
        state.mycontrols.filters.evidence,
        state.mycontrols.evidence.currentPage,
        state.mycontrols.evidence.perPage
      ),
      ApiMyControls.getTeamControlsDomains(
        state.mycontrols.filters.evidence.deletedDomains
      ),
    ])
    commit('SET_MYCONTROLS_CONTROL_GROUPS_DOMAINS', domains)
    commit('SET_MYCONTROLS_EVIDENCE', paginatedEvidence)
    commit('SET_MYCONTROLS_EVIDENCE_TAGS', tags)
  },

  async getControlEvidence({ commit }, controlId) {
    const { controlEvidence } = await ApiMyControls.getControlEvidence(
      controlId
    )

    commit('SET_MYCONTROLS_CURRENT_CONTROL_EVIDENCE', controlEvidence)
  },

  async clearCurrentControl({ commit }) {
    await commit('CLEAR_MYCONTROLS_CURRENT_CONTROL')
  },

  async getCurrentControlOwners({ commit }, groupId) {
    const owners = await ApiMyControls.getControlGroupOwners(groupId)
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_OWNERS', owners)
  },

  async getCurrentControlCollaborators({ commit }, groupId) {
    const collaborators = await ApiMyControls.getControlGroupCollaborators(
      groupId
    )
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_COLLABORATORS', collaborators)
  },

  async getEvidenceAssignees({ commit }, evidenceId) {
    const assignees = await ApiMyControls.getEvidenceAssignees(evidenceId)
    await commit('SET_MYCONTROLS_CURRENT_EVIDENCE_ASIGNEES', assignees)
  },

  async getEvidenceCollaborators({ commit }, evidenceId) {
    const collaborators = await ApiMyControls.getEvidenceCollaborators(
      evidenceId
    )
    await commit('SET_MYCONTROLS_CURRENT_EVIDENCE_COLLABORATORS', collaborators)
  },

  async getCurrentControlEvents({ dispatch, state }) {
    const groupId = state.mycontrols.currentControl.control.id
    if (!groupId) return
    await dispatch('getControlEvents', groupId)
  },

  async getCurrentFrameworkControlDetails(
    { commit },
    { id: controlId, frameworkVersionId }
  ) {
    const control = await ApiMyControls.getFrameworkControlDetails(
      controlId,
      frameworkVersionId
    )
    commit('SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL', control)
  },

  async getFrameworkCurrentControlChangeLog(
    { commit, state },
    {
      controlId,
      frameworkVersionId = null,
      frameworkMapId = null,
      reset = false,
    }
  ) {
    if (state.mycontrols.currentFrameworkControl.changeLog.length > 0 && !reset)
      return
    const changeLog = await ApiMyControls.getFrameworkCurrentControlChangeLog(
      controlId,
      frameworkVersionId,
      frameworkMapId
    )
    commit('SET_MYCONTROLS_FRAMEWORK_CURRENT_CONTROL_CHANGE_LOG', changeLog)
  },

  async getControlGroupDetails({ commit }, controlGroupId) {
    const { controlGroup, mappedControls, owners, collaborators } =
      await ApiMyControls.getControlGroupDetails(controlGroupId)
    commit('SET_MYCONTROLS_CURRENT_CONTROL', {
      controlGroup,
      mappedControls,
      owners,
      collaborators,
    })
  },

  async getControlPolicies({ commit }, controlId) {
    const policies = await ApiMyControls.getControlGroupPoliciesMappings(
      controlId
    )
    commit('SET_MYCONTROLS_CURRENT_CONTROL_POLICIES', policies)
  },

  async getControlTags({ commit }, controlId) {
    const tags = await ApiMyControls.getControlGroupTags(controlId)
    commit('SET_MYCONTROLS_CURRENT_CONTROL_TAGS', tags)
  },

  async getControlComments({ commit }, controlId) {
    const comments = await ApiMyControls.getControlComments(controlId)
    commit('SET_MYCONTROLS_CURRENT_CONTROL_COMMENTS', comments)
  },

  async getEvidenceComments({ commit }, evidenceId) {
    const comments = await ApiMyControls.getEvidenceComments(evidenceId)
    commit('SET_MYCONTROLS_CURRENT_EVIDENCE_COMMENTS', comments)
  },

  async initFrameworkMappingsTab({ dispatch }, controlId) {
    await Promise.all([
      dispatch('getTeamFrameworks', { reset: false }),
      dispatch('getCurrentControlMappedControls', { controlId, reset: true }),
    ])
  },

  async getCurrentControlMappedControls(
    { commit, state },
    { controlId, reset = false }
  ) {
    if (state.mycontrols.currentControl.mappedControls.length > 0 && !reset)
      return
    const mappedControls = await ApiMyControls.getCurrentControlControlMappings(
      controlId
    )
    commit('SET_MYCONTROLS_CURRENT_CONTROL_MAPPED_CONTROLS', mappedControls)
  },

  /**
   * Evidence Primary Tab
   */
  async getEvidenceDetails({ dispatch }, evidenceId) {
    await Promise.all([
      dispatch('getCurrentEvidenceFiles', { evidenceId }),
      dispatch('getEvidenceCollaborators', evidenceId),
      dispatch('getEvidenceAssignees', evidenceId),
      dispatch('getEvidenceRecord', evidenceId),
    ])
  },

  async getEvidenceRecord({ commit }, evidenceId) {
    const { evidence } = await ApiMyControls.getEvidenceDetails(evidenceId)
    commit('SET_MYCONTROLS_CURRENT_EVIDENCE', evidence)
  },

  async getEvidenceTypicalEvidenceFiles({ commit }, evidenceId) {
    const { typicalEvidenceFiles } =
      await ApiMyControls.getEvidenceTypicalEvidenceFiles(evidenceId)
    commit(
      'SET_MYCONTROLS_CURRENT_EVIDENCE_TYPICAL_EVIDENCE_FILES',
      typicalEvidenceFiles
    )
  },

  async clearCurrentEvidence({ commit }) {
    await commit('CLEAR_MYCONTROLS_CURRENT_EVIDENCE')
  },

  async setFilterAndGetRisks({ commit, dispatch }, { key, val }) {
    await commit('SET_MYCONTROLS_RISKS_FILTER', { key, val })
    dispatch('getCurrentControlRiskMappings')
    // dispatch('getCurrentEvidenceRiskMappings')
  },

  async getCurrentControlRiskMappings({ state, dispatch }) {
    const controlId =
      state.mycontrols.currentControl.control.framework_controls_groups_id
    if (!controlId) return
    await dispatch('getControlRiskMappings', controlId)
  },

  async getControlRiskMappings({ state, commit }, groupId) {
    const mappings = await ApiMyControls.getControlRiskMappings(
      groupId,
      state.mycontrols.filters.risks
    )
    await commit('SET_MYCONTROLS_CURRENT_CONTROL_RISK_MAPPINGS', mappings)
  },

  async getCurrentControlGroupChangeLog(
    { commit, state },
    { controlGroupId, reset = false }
  ) {
    if (state.mycontrols.currentControl.changeLog.length > 0 && !reset) return
    const changeLog = await ApiMyControls.getControlGroupChangeLog(
      controlGroupId
    )
    commit('SET_MYCONTROLS_CURRENT_CONTROL_CHANGE_LOG', changeLog)
  },

  async getCurrentEvidenceChangeLog(
    { commit, state },
    { evidenceId, reset = false }
  ) {
    if (state.mycontrols.currentEvidence.changeLog.length > 0 && !reset) return
    const changeLog = await ApiMyControls.getEvidenceChangeLog(evidenceId)
    commit('SET_MYCONTROLS_CURRENT_EVIDENCE_CHANGE_LOG', changeLog)
  },

  async getCurrentEvidenceFiles(
    { commit, state },
    { reset = true, evidenceId }
  ) {
    if (state.mycontrols.currentEvidence.files > 0 && !reset) return
    const { linkedFiles } = await ApiMyControls.getEvidenceLinkedFiles(
      evidenceId
    )

    commit('SET_MYCONTROLS_CURRENT_EVIDENCE_FILES', linkedFiles)
  },

  async getEvidenceTags({ commit }, evidenceId) {
    const tags = await ApiMyControls.getEvidenceTags(evidenceId)
    commit('SET_MYCONTROLS_CURRENT_EVIDENCE_TAGS', tags)
  },

  async getFrameworkControlControlGroups({ commit }, frameworkControlId) {
    const { mappedControlGroups } =
      await ApiMyControls.getFrameworkControlControlGroups(frameworkControlId)
    await commit(
      'SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL_CONTROL_GROUPS',
      mappedControlGroups
    )
  },

  clearCurrentFramework({ commit }) {
    commit('CLEAR_MYCONTROLS_CURRENT_FRAMEWORK')
  },

  clearCurrentFrameworkControl({ commit }) {
    commit('CLEAR_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL')
  },

  async getAllDashboardData({ dispatch }, { reset = false }) {
    await Promise.all([
      dispatch('getTeamControlGroupsForDashboard', { reset }),
      dispatch('getTeamEvidenceForDashboard', { reset }),
      dispatch('getAllTagsForDashboard', { reset }),
      dispatch('getDashboardDonutData', { reset }),
      dispatch('getDashboardStatusCounts', { reset }),
      dispatch('getTeamControlsDomains', { reset }),
    ])
  },

  async setFilterAndGetDashboardData({ commit, dispatch }, value) {
    commit('SET_MYCONTROLS_DASHBOARD_FILTER', value)
    await dispatch('getAllDashboardData', { reset: true })
  },

  async setFilterViewAndGetDashboardData({ dispatch, commit }, view) {
    view.filters = {
      ...view.filters,
      assignee: view.filters.owner,
    }
    commit('RESET_DASHBOARD_FILTERS')
    dispatch('setDashboardFilteredView', view)
    await dispatch('getAllDashboardData', { reset: true })
  },

  setDashboardFilteredView({ commit, dispatch }, view) {
    dispatch('setCurrentViewId', view.id)
    Object.keys(view.filters).forEach((key) => {
      commit('SET_MYCONTROLS_DASHBOARD_FILTER', {
        key,
        val: view.filters[key],
      })
    })
  },

  async getDashboardDonutData({ commit, state }, { reset = false }) {
    if (
      Object.keys(state.mycontrols?.dashboard?.domainCounts || {}).length > 0 &&
      !reset
    )
      return

    const dashboardDonutData = await ApiMyControls.getDashboardDonutData(
      state.mycontrols.filters.dashboard
    )
    commit('SET_MYCONTROLS_DASHBOARD_DONUT_DATA', dashboardDonutData)
  },

  async getTeamControlGroupsForDashboard({ commit, state }, { reset = false }) {
    if (state.mycontrols.dashboard.controlGroups.length > 0 && !reset) return
    const { paginatedControls } = await ApiMyControls.getTeamControlGroups(
      state.mycontrols.filters.dashboard,
      1,
      10e5
    )
    commit('SET_MYCONTROLS_DASHBOARD_CONTROL_GROUPS', paginatedControls)
  },

  async getTeamEvidenceForDashboard({ commit, state }, { reset = false }) {
    if (state.mycontrols.dashboard.evidence.length > 0 && !reset) return
    const filters = {
      ...state.mycontrols.filters.dashboard,
      date_end: moment().add(14, 'day'),
    }
    const { paginatedEvidence } = await ApiMyControls.getTeamEvidence(
      filters,
      1,
      10e5
    )
    commit('SET_MYCONTROLS_DASHBOARD_EVIDENCE_DATA', paginatedEvidence)
  },

  async getAllTagsForDashboard({ commit, state }, { reset = false }) {
    if (
      Object.keys(state.mycontrols.dashboard.allTags || {}).length > 0 &&
      !reset
    )
      return
    const { myControlsTags } = await ApiMyControls.getAllTags()
    commit('SET_MYCONTROLS_DASHBOARD_TAGS', myControlsTags)
  },

  async getDashboardStatusCounts({ commit, state }, { reset = false }) {
    if (
      (Object.keys(state.mycontrols.dashboard.controlStatusCounts || {})
        .length > 0 ||
        Object.keys(state.mycontrols.dashboard.evidenceStatusCounts).length >
          0) &&
      !reset
    )
      return
    const res = await ApiMyControls.getDashboardStatusCounts(
      state.mycontrols.filters.dashboard
    )
    commit('SET_MYCONTROLS_DASHBOARD_STATUS_COUNTS', res)
  },

  setLastSelectedProject({ commit }, projectId) {
    commit('SET_MYCONTROLS_LAST_SELECTED_PROJECT', projectId)
  },

  setLastSelectedProjectSection({ commit }, section) {
    commit('SET_MYCONTROLS_LAST_SELECTED_PROJECT_SECTION', section)
  },

  async getFilteredViews({ commit }, type = null) {
    const { views } = await ApiMyControls.getFilteredViews(type)
    commit('SET_MYCONTROLS_FILTERED_VIEWS', views)
  },

  async setFilteredViewAndGetControls({ dispatch, commit }, view) {
    commit('RESET_CONTROL_GROUP_FILTERS')
    dispatch('setFilteredView', view)
    await dispatch('getTeamControlGroups')
  },

  async resetControlGroupFilters({ dispatch, commit }) {
    commit('RESET_CONTROL_GROUP_FILTERS')
    await dispatch('getTeamControlGroups')
  },

  async setFilteredViewAndGetEvidence({ dispatch, commit }, view) {
    commit('RESET_EVIDENCE_FILTERS')
    dispatch('setEvidenceFilteredView', view)
    await dispatch('getTeamEvidence')
  },

  async resetEvidenceFilters({ dispatch, commit }) {
    commit('RESET_EVIDENCE_FILTERS')
    await dispatch('getTeamEvidence')
  },

  setFilteredView({ commit, dispatch }, view) {
    dispatch('setCurrentViewId', view.id)
    Object.keys(view.filters).forEach((key) => {
      commit('SET_MYCONTROLS_CONTROL_GROUP_FILTER', {
        key,
        val: view.filters[key],
      })
    })
  },

  setEvidenceFilteredView({ commit, dispatch }, view) {
    dispatch('setCurrentViewId', view.id)
    Object.keys(view.filters).forEach((key) => {
      commit('SET_MYCONTROLS_EVIDENCE_FILTER', {
        key,
        val: view.filters[key],
      })
    })
  },

  setCurrentViewId({ commit }, viewId) {
    commit('SET_MYCONTROLS_CURRENT_VIEW_ID', viewId)
  },

  resetMyControlsFilters({ commit }) {
    commit('RESET_MYCONTROLS_FILTERS')
  },
}
