export const scoutSuiteDictionary = {
  scoutsuite_aws: {
    acm: {
      name: 'ACM',
      description:
        'Amazon Certificate Manager (ACM) is used to create, manage, and deploy SSL/TLS certificates for use with AWS services.',
      controlArea: 'Communication Protection',
    },
    awslambda: {
      name: 'Lambda',
      description:
        'Lambda is a serverless compute service that lets you run code without provisioning or managing servers.',
      controlArea: 'Infrastructure Protection',
    },
    cloudformation: {
      name: 'CloudFormation',
      description:
        'CloudFormation is used to create, update, and delete AWS resources in a predictable way.',
      controlArea: 'Infrastructure Protection',
    },
    cloudfront: {
      name: 'CloudFront',
      description:
        'CloudFront is a content delivery network (CDN) service that securely delivers data, videos, applications, and APIs to customers globally.',
      controlArea: 'Infrastructure Protection',
    },
    cloudtrail: {
      name: 'CloudTrail',
      description:
        'CloudTrail is a service that enables governance, compliance, and operational and risk auditing of your AWS accounts.',
      controlArea: 'Auditing',
    },
    cloudwatch: {
      name: 'CloudWatch',
      description:
        'CloudWatch is used to monitor AWS resources and the applications you run on AWS.',
      controlArea: 'Monitoring',
    },
    codebuild: {
      name: 'CodeBuild',
      description:
        'CodeBuild is a fully managed build service that compiles source code, runs tests, and produces software packages that are ready to deploy.',
      controlArea: 'Infrastructure Protection',
    },
    config: {
      name: 'Config',
      description:
        'Config is used to track changes to your AWS resources and provides configuration history of those resources.',
      controlArea: 'Auditing',
    },
    directconnect: {
      name: 'Direct Connect',
      description:
        'Direct Connect is a service that enables you to establish a dedicated network connection from your on-premises data centers to AWS.',
      controlArea: 'Network Protection',
    },
    dynamodb: {
      name: 'DynamoDB',
      description:
        'DynamoDB is a fully managed NoSQL database service that provides fast and predictable performance with seamless scalability.',
      controlArea: 'Data Protection',
    },
    ec2: {
      name: 'EC2',
      description: `Elastic Compute Cloud (EC2) allows users to rent virtual computers on which to run their own computer applications.`,
      controlArea: 'Infrastructure Protection',
    },
    efs: {
      name: 'EFS',
      description:
        'Elastic File System (EFS) is a fully managed, elastic, scalable, file storage service that makes it simple to store and access files in the AWS Cloud.',
      controlArea: 'Data Protection',
    },
    elasticache: {
      name: 'ElastiCache',
      description:
        'ElastiCache is a web service that makes it easy to deploy, operate, and scale an in-memory cache in the cloud.',
      controlArea: 'Data Protection',
    },
    elb: {
      name: 'ELB',
      description:
        'Elastic Load Balancing (ELB) automatically distributes incoming web traffic across multiple Elastic Compute Cloud (EC2) instances.',
      controlArea: 'Infrastructure Protection',
    },
    elbv2: {
      name: 'ELBv2',
      description:
        'Elastic Load Balancing v2 (ELBv2) automatically distributes incoming web traffic across multiple Elastic Compute Cloud (EC2) instances.',
      controlArea: 'Infrastructure Protection',
    },
    emr: {
      name: 'EMR',
      description:
        'Elastic MapReduce (EMR) is a web service that makes it easy to process large amounts of data quickly and cost-effectively.',
      controlArea: 'Data Protection',
    },
    iam: {
      name: 'IAM',
      description:
        'Identity and Access Management (IAM) is used to specify who or what can access services and resources in AWS, centrally manage fine-grained permissions, and analyze access to refine permissions across AWS.',
      controlArea: 'Access Control',
    },
    kms: {
      name: 'KMS',
      description:
        'Key Management Service (KMS) makes it easy for you to create and manage cryptographic keys and control their use across a wide range of AWS services and in your applications.',
      controlArea: 'Cryptographic Protection',
    },
    logs: {
      name: 'CloudWatch',
      description:
        'CloudWatch centralizes the logs from all systems, applications, and AWS services used in a single service.',
      controlArea: 'Monitoring',
    },
    rds: {
      name: 'RDS',
      description:
        'Relational Database Service (RDS) is a web service that makes it easy to set up, operate, and scale a relational database in the cloud.',
      controlArea: 'Data Protection',
    },
    redshift: {
      name: 'Redshift',
      description:
        'Redshift is a fully managed, cloud-based data warehousing service.',
      controlArea: 'Data Protection',
    },
    route53: {
      name: 'Route 53',
      description:
        'Route 53 is a highly available and scalable Domain Name System (DNS) service.',
      controlArea: 'Network Protection',
    },
    s3: {
      name: 'S3',
      description:
        'Simple Storage Service (S3) is a service that provides object storage through a web service interface.',
      controlArea: 'Data Protection',
    },
    secretsmanager: {
      name: 'Secrets Manager',
      description:
        'Secrets Manager is a secrets management service that helps you protect access to your applications, services, and IT resources.',
      controlArea: 'Cryptographic Protection',
    },
    ses: {
      name: 'SES',
      description:
        'Simple Email Service (SES) is a bulk and transactional email-sending service.',
      controlArea: 'Communication Protection',
    },
    sns: {
      name: 'SNS',
      description:
        'Simple Notification Service (SNS) is a fully managed messaging service that enables you to send messages to multiple recipients.',
      controlArea: 'Communication Protection',
    },
    sqs: {
      name: 'SQS',
      description:
        'Simple Queue Service (SQS) is a fully managed message queuing service that enables you to send, store, and receive messages between software components.',
      controlArea: 'Communication Protection',
    },
    vpc: {
      name: 'VPC',
      description: `Virtual Private Cloud (VPC) enables you to launch AWS resources into a virtual network that you've defined.`,
      controlArea: 'Network Protection',
    },
  },
  scoutsuite_azure: {
    aad: {
      name: 'AAD',
      description:
        'Azure Active Directory (AAD) is a cloud-based identity and access management service that helps employees sign in and access resources from any location or device.',
      controlArea: 'Access Control',
    },
    appservice: {
      name: 'App Service',
      description:
        'App Service is a fully managed platform for building, deploying, and scaling web apps, mobile apps, and APIs.',
      controlArea: 'Infrastructure Protection',
    },
    keyvault: {
      name: 'Key Vault',
      description:
        'Key Vault is a cloud-based service that safeguards cryptographic keys and secrets used by cloud applications and services.',
      controlArea: 'Cryptographic Protection',
    },
    logging: {
      name: 'Logging/Monitoring',
      description:
        'Logging/Monitoring is a logging and monitoring service that provides insights into the performance and availability of applications and services.',
      controlArea: 'Monitoring',
    },
    loggingmonitoring: {
      name: 'Logging/Monitoring',
      description:
        'Logging/Monitoring is a logging and monitoring service that provides insights into the performance and availability of applications and services.',
      controlArea: 'Monitoring',
    },
    mysql: {
      name: 'MySQL',
      description:
        'MySQL is a fully managed relational database service that supports open-source database engines, including MySQL.',
      controlArea: 'Data Protection',
    },
    mysqldatabase: {
      name: 'MySQL',
      description:
        'MySQL is a fully managed relational database service that supports open-source database engines, including MySQL.',
      controlArea: 'Data Protection',
    },
    network: {
      name: 'Network',
      description:
        'Network is a cloud-based networking service that connects resources and workloads in a virtual network.',
      controlArea: 'Network Protection',
    },
    postgresql: {
      name: 'PostgreSQL',
      description:
        'PostgreSQL is a fully managed relational database service that supports open-source database engines, including PostgreSQL.',
      controlArea: 'Data Protection',
    },
    postgresqldatabase: {
      name: 'PostgreSQL',
      description:
        'PostgreSQL is a fully managed relational database service that supports open-source database engines, including PostgreSQL.',
      controlArea: 'Data Protection',
    },
    rbac: {
      name: 'RBAC',
      description:
        'Role Based Access Control (RBAC) is a security feature that controls access to resources based on the roles and permissions assigned to users and groups.',
      controlArea: 'Access Control',
    },
    securitycenter: {
      name: 'Security Center',
      description:
        'Security Center is a unified security management service that provides threat protection across hybrid cloud workloads.',
      controlArea: 'Auditing',
    },
    sqldatabase: {
      name: 'SQL Database',
      description:
        'SQL Database is a fully managed relational database service that supports Microsoft SQL Server and other database engines.',
      controlArea: 'Data Protection',
    },
    storageaccount: {
      name: 'Storage Accounts',
      description:
        'Storage Accounts is a cloud-based storage service that provides highly available and scalable object storage for unstructured data.',
      controlArea: 'Data Protection',
    },
    storageaccounts: {
      name: 'Storage Accounts',
      description:
        'Storage Accounts is a cloud-based storage service that provides highly available and scalable object storage for unstructured data.',
      controlArea: 'Data Protection',
    },
    virtual: {
      name: 'Virtual Machines',
      description:
        'Virtual Machines is a cloud-based service that provides on-demand, scalable computing resources for running applications and services.',
      controlArea: 'Infrastructure Protection',
    },
    virtualmachines: {
      name: 'Virtual Machines',
      description:
        'Virtual Machines is a cloud-based service that provides on-demand, scalable computing resources for running applications and services.',
      controlArea: 'Infrastructure Protection',
    },
  },
  scoutsuite_gcp: {
    bigquery: {
      name: 'BigQuery',
      description:
        'BigQuery is a serverless, cost-effective and multicloud data warehouse designed to help you turn big data into valuable business insights.',
      controlArea: 'Data Protection',
    },
    cloudsql: {
      name: 'Cloud SQL',
      description:
        'Cloud SQL is a fully managed service that makes it easy to set up, manage, and administer relational databases.',
      controlArea: 'Data Protection',
    },
    cloudmemorystore: {
      name: 'Memorystore',
      description:
        'Memorystore is a fully managed in-memory data store service for Redis and Memcached.',
      controlArea: 'Data Protection',
    },
    cloudstorage: {
      name: 'Cloud Storage',
      description:
        'Cloud Storage lets you store data with multiple redundancy options, virtually anywhere.',
      controlArea: 'Data Protection',
    },
    computeengine: {
      name: 'Compute Engine',
      description:
        "Compute Engine delivers configurable virtual machines running in Google's data centers with access to high-performance.",
      controlArea: 'Infrastructure Protection',
    },
    dns: {
      name: 'DNS',
      description:
        "Domain Name Service (DNS) is a reliable, resilient, low-latency DNS serving from Google's worldwide network",
      controlArea: 'Network Protection',
    },
    functions: {
      name: 'Cloud Functions',
      description:
        'Cloud Functions is a serverless execution environment for building and connecting cloud services.',
      controlArea: 'Infrastructure Protection',
    },
    iam: {
      name: 'IAM',
      description:
        'Identity and Access Management (IAM) gives admins fine-grained access control and visibility for centrally managing enterprise cloud resources.',
      controlArea: 'Access Control',
    },
    kms: {
      name: 'KMS',
      description:
        'Key Management Service (KMS) allows you to create, import, and manage cryptographic keys and perform cryptographic operations in a single centralized cloud service.',
      controlArea: 'Cryptographic Protection',
    },
    kubernetesengine: {
      name: 'Kubernetes Engine',
      description:
        'Kubernetes Engine is a fully managed Kubernetes service with full Kubernetes API, 4-way autoscaling, release channels, and multi-cluster support.',
      controlArea: 'Infrastructure Protection',
    },
    memorystore: {
      name: 'Memorystore',
      description:
        'Memorystore is a fully managed in-memory data store service for Redis and Memcached at Google Cloud. ',
      controlArea: 'Data Protection',
    },
    stackdriverlogging: {
      name: 'Stackdriver Logging',
      description:
        'Stackdiver Logging empowers customers to manage, analyze, monitor, and gain insights from log data in real time.',
      controlArea: 'Monitoring',
    },
    stackdrivermonitoring: {
      name: 'Stackdriver Monitoring',
      description:
        'Stackdiver Monitoring collects metrics, events, and metadata from Google Cloud, Amazon Web Services (AWS), hosted uptime probes, and application instrumentation.',
      controlArea: 'Monitoring',
    },
  },
}

export const scoutSuiteRulesConfigurationDictionary = {
  scoutsuite_aws: {
    'acm-certificate-with-close-expiration-date.json': {
      description: 'ACM Certificate Expiring in Less than _ARG_0_ Days',
      rationale:
        'Ensure that certificates which are in use are not about to expire.',
      references: [],
      dashboard_name: 'Certificates',
      arg_names: ['Days until expiration'],
    },
    'acm-certificate-with-transparency-logging-disabled.json': {
      description: 'ACM Certificate with Transparency Logging Set to Disabled',
      rationale:
        'Disabling Transparency Logging may result in browsers not trusting your certificate. As of April 30 2018, Google Chrome no longer trusts public SSL/TLS certificates that are not recorded in a certificate transparency log. Transparency Logging should be enabled as a best practice.',
      references: [
        'https://aws.amazon.com/blogs/security/how-to-get-ready-for-certificate-transparency/',
      ],
      dashboard_name: 'Certificates',
    },
    'cloudformation-stack-with-role.json': {
      description: 'Role Passed to Stack',
      rationale:
        "Passing a role to CloudFormation stacks may result in privilege escalation because IAM users with privileges within the CloudFormation scope implicitly inherit the stack's role's permissions. Consequently, it should be ensured that the IAM privileges assigned to the stack's role follow the principle of least privilege.",
      references: [
        'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/best-practices.html',
      ],
      dashboard_name: 'Stacks',
    },
    'cloudfront-distribution-cleartext-origin.json': {
      description: 'Content Distribution with Clear-Text Origin TLS Policy',
      rationale:
        'Distributing content between AWS CloudFront distributions and their custom origins over clear-text HTTP, without using AWS encryption solutions, can potentially expose sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-cloudfront-distribution-customoriginconfig.html',
      ],
      dashboard_name: 'Distributions',
    },
    'cloudfront-distribution-insecure-origin.json': {
      description: 'Content Distribution with Insecure Origin TLS Policy',
      rationale:
        'Distributing content between AWS CloudFront distributions and their custom origins over HTTPS using older SSL/TLS protocols can potentially expose sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-cloudfront-distribution-customoriginconfig.html',
      ],
      dashboard_name: 'Distributions',
    },
    'cloudfront-distribution-insufficient-viewer-security.json': {
      description:
        'Content Distribution with Insufficient Viewer Security Policy',
      rationale:
        'Distributing content between AWS CloudFront distributions and their custom origins without the use of a valid certificate, can potentially expose sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-properties-cloudfront-distribution-viewercertificate.html',
      ],
      dashboard_name: 'Distributions',
    },
    'cloudtrail-duplicated-global-services-logging.json': {
      description: 'Global Service Logging Duplicated',
      rationale:
        'Global service logging is enabled in multiple Trails. While this does not jeopardize the security of the environment, duplicated entries in logs increase the difficulty to investigate potential incidents.',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-concepts.html#cloudtrail-concepts-global-service-events',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-cloudwatch-integration.json': {
      description: 'Trail Is Not Integrated with CloudWatch',
      rationale:
        'The lack of integration with CloudWatch hinders real-time and historic activity logging as well as not allowing the configuration of alarms and notifications for anomalous account activity.',
      remediation:
        'Configure each Trail to have a CloudWatch Logs group attached',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/monitor-cloudtrail-log-files-with-cloudwatch-logs.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-data-logging.json': {
      description: 'Data Events Logging Not Configured',
      rationale:
        'CloudTrail Data Logging is not configured, which means that S3 access and Lambda invocations are not logged. <br><br>Note: S3 bucket logging can be used in place of CloudTrail data events for S3. If that is the case, logs for Lambda invocations may still be missing.',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/logging-data-events-with-cloudtrail.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-encryption-with-kms.json': {
      description:
        'CloudTrail Logs Not Encrypted with KMS Customer Master Keys (CMKs)',
      rationale:
        'Not encrypting CloudTrail logs with SSE-KMS affects the confidentiality of the log data.',
      remediation: 'Ensure each Trail is encrypted with a KMS key',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/encrypting-cloudtrail-log-files-with-aws-kms.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-global-services-logging.json': {
      description: 'Global Service Logging Disabled',
      rationale:
        'API activity for global services such as IAM and STS is not logged. Investigation of incidents will be incomplete due to the lack of information.',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-concepts.html#cloudtrail-concepts-global-service-events',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-log-file-validation.json': {
      description: 'Log File Validation Is Disabled',
      rationale:
        'The lack of log file validation prevents from verifying the integrity of CloudTrail log files.',
      remediation:
        'Ensure that each Trail has Enable log file validation set to Yes',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-log-file-validation-intro.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-no-logging.json': {
      description: 'Disabled Trails',
      rationale:
        'Logging is disabled for a given Trail. Depending on the configuration, logs for important API activity may be missing.',
      remediation:
        'Configure all Trails to enable Logging, set Apply trail to all regions and ensure that Read/Write Events are set to ALL',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/best-practices-security.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudtrail-not-configured.json': {
      description: 'CloudTrail Service Not Configured',
      rationale:
        'CloudTrail is not configured, which means that API activity is not logged.',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/best-practices-security.html',
      ],
      dashboard_name: 'Regions',
    },
    'cloudtrail-partial-data-logging.json': {
      description: 'Data Logging Configuration Not Covering All Resources',
      rationale:
        'CloudTrail Data Logging is not configured to cover all S3 or Lambda resources, which means that all S3 access and Lambda invocations are not logged. <br><br>Note: S3 bucket logging can be used in place of CloudTrail data events for S3. If that is the case, logs for Lambda invocations may still be missing.',
      references: [
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/logging-data-events-with-cloudtrail.html',
      ],
      dashboard_name: 'Configurations',
    },
    'cloudwatch-alarm-without-actions.json': {
      description: 'Alarm without Action',
      rationale: 'Each alarm should have at least one action',
      references: [
        'https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/AlarmThatSendsEmail.html',
      ],
      dashboard_name: 'Alarms',
    },
    'config-recorder-not-configured.json': {
      description: 'AWS Config Not Enabled',
      rationale:
        'No AWS Config recorders are configured, which means that changes in AWS resource configuration are not logged. This hinders security analysis, resource change tracking and compliance auditing.',
      remediation:
        'Enable AWS Config in all regions, define the resources you want to record in each region and include global resources (IAM resources)',
      references: [
        'https://aws.amazon.com/blogs/mt/aws-config-best-practices/',
      ],
      dashboard_name: 'Regions',
    },
    'ec2-ami-public.json': {
      description: 'Publicly Accessible AMI',
      rationale:
        'AMIs should never intentionally be made public. If they need to be shared this can be done with specific AWS accounts.',
      references: [
        'https://aws.amazon.com/security/security-bulletins/reminder-about-safely-sharing-and-using-public-amis/',
      ],
      dashboard_name: 'Images',
    },
    'ec2-default-security-group-in-use.json': {
      description: 'Default Security Groups in Use',
      rationale:
        'The use of default security groups can indicate a lack of intentional enforcement of the principle of least privilege. Use custom security groups to ensure you are properly minimizing privilege and access.',
      remediation:
        'Ensure resources are not within default security groups. Instead, create a custom security group tailored to each resource needs.',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-4.3',
      ],
      dashboard_name: 'Security groups',
    },
    'ec2-default-security-group-with-rules.json': {
      description: 'Non-empty Rulesets for Default Security Groups',
      rationale:
        'The default security group created in each VPC comes with rules allowing all inbound traffic from instances assigned to the same security group, as well as all outbound traffic. In order to improve system hardening, you should remove all rules from the default security groups so that they restricts all traffic. Should an instance be created without custom security groups, it will inherit the default security group and be unable to communicate with other instances within the VPC until the required custom security groups are assigned.',
      remediation:
        'Ensure the default security group of every VPC restricts all traffic',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-4.3',
      ],
      dashboard_name: 'Rulesets',
    },
    'ec2-ebs-snapshot-not-encrypted.json': {
      description: 'EBS Snapshot Not Encrypted',
      rationale: 'Data-at-rest should be encrypted.',
      references: [
        'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/EBSEncryption.html',
      ],
      dashboard_name: 'Snapshots',
    },
    'ec2-ebs-snapshot-public.json': {
      description: 'Public EBS Snapshot',
      rationale:
        'Snapshots should never be public, as this risks exposing sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ebs-modifying-snapshot-permissions.html',
      ],
      dashboard_name: 'Snapshots',
    },
    'ec2-ebs-volume-not-encrypted.json': {
      description: 'EBS Volume Not Encrypted',
      rationale:
        'Enabling encryption of EBS volumes ensures that data is encrypted both at-rest and in-transit (between an instance and its attached EBS storage).',
      references: [
        'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/EBSEncryption.html',
      ],
      dashboard_name: 'Volumes',
    },
    'ec2-instance-in-security-group.json': {
      description: 'EC2 Instance Belongs to Specific Security Group',
      rationale: 'This configuration goes against organizational policies.',
      arg_names: ['ID of the security group EC2 instances may not belong to.'],
      dashboard_name: 'Instances',
    },
    'ec2-instance-type.json': {
      description: 'Use of _ARG_0_ Instances',
      rationale:
        'Policies dictate EC2 instances of type _ARG_0_ should not be used in this environment',
      references: ['https://aws.amazon.com/ec2/instance-types/'],
      dashboard_name: 'Instances',
      arg_names: ['Type of EC2 instance (e.g. t2.micro)'],
    },
    'ec2-instance-types.json': {
      description: 'Use of _ARG_0_ Instances with Display Name',
      rationale:
        'Policies dictate _ARG_0_ EC2 instances should not be used in this environment',
      references: ['https://aws.amazon.com/ec2/instance-types/'],
      dashboard_name: 'Instances',
      arg_names: [
        'Display name of types of instances',
        'Type of EC2 instances that may not be used',
      ],
    },
    'ec2-instance-with-public-ip.json': {
      description: 'Instance with a Public IP Address',
      rationale:
        'It is good practice to maintain a list of known, publicly accessible instances and flag all other instances that meet this criteria.',
      references: [
        'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-instance-addressing.html',
      ],
      dashboard_name: 'Network interfaces',
    },
    'ec2-instance-with-user-data-secrets.json': {
      description: 'Potential Secret in Instance User Data',
      rationale:
        'It was detected that the EC2 instance was configured with user data, which could potentially include secrets. Although user data can only be accessed from within the instance itself, the data is not protected by cryptographic methods. Anyone who can access the instance can view its metadata. It should therefore be ensured that sensitive data, such as passwords and SSH keys, are not stored as user data.',
      references: [
        'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-metadata.html',
      ],
      dashboard_name: 'Instances',
    },
    'ec2-security-group-opens-all-ports-to-all.json': {
      description: 'Security Group Opens All Ports to All',
      rationale:
        'It was detected that all ports in the security group are open, and any source IP address could send traffic to these ports, which creates a wider attack surface for resources assigned to it. Open ports should be reduced to the minimum needed to correctly operate and, when possible, source address restrictions should be implemented.',
      dashboard_name: 'Rules',
      arg_names: ['Security group name', 'Network transport protocol', 'Port'],
    },
    'ec2-security-group-opens-all-ports-to-self.json': {
      description: 'Unrestricted Network Traffic within Security Group',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Rules',
      arg_names: ['Network transport protocol'],
    },
    'ec2-security-group-opens-all-ports.json': {
      description: 'Security Group Opens All Ports',
      rationale:
        'It was detected that all ports in the security group are open, which creates a wider attack surface for resources assigned to it. Open ports should be reduced to the minimum needed to correctly operate.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-opens-icmp-to-all.json': {
      description: 'Security Group Allows ICMP Traffic to All',
      rationale:
        'ICMP traffic was allowed to the resources assigned to this security group without restriction of the source address. This could potentially be leveraged by an attacker trying to perform an attack or gather information about the deployed infrastructure.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-opens-known-port-to-all.json': {
      description: 'Security Group Opens _ARG_0_ Port to All',
      rationale:
        'The security group was found to be exposing a well-known port to all source addresses. Well-known ports are commonly probed by automated scanning tools, and could be an indicator of sensitive services exposed to Internet. If such services need to be exposed, a restriction on the source address could help to reduce the attack surface of the infrastructure.',
      remediation: 'Remove the inbound rules that expose open ports',
      dashboard_name: 'Rules',
      arg_names: [
        'Network protocol name',
        'Transport protocol name',
        'Port number',
      ],
    },
    'ec2-security-group-opens-plaintext-port.json': {
      description: 'Security Group Opens _ARG_0_ Port',
      rationale:
        'Ports associated with plaintext protocols have been found to be open in this security group. Plaintext protocols should be replaced with more secure alternatives, as the data in transit may be monitored and could, potentially, be subject to tampering.',
      dashboard_name: 'Rules',
      arg_names: [
        'Network protocol name',
        'Transport protocol name',
        'Port number',
      ],
    },
    'ec2-security-group-opens-port-range.json': {
      description: 'Security Group Uses Port Range',
      rationale:
        'It was found that the security group was using port ranges. Sometimes, ranges could include unintended ports that should not be exposed. As a result, when possible, explicit port lists should be used instead',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-opens-port-to-all.json': {
      description: 'Security Group Opens _ARG_0_ Port to All',
      rationale:
        'The security group was found to be exposing a port to all source addresses. Ports are commonly probed by automated scanning tools, and could be an indicator of sensitive services exposed to Internet. If such services need to be exposed, a restriction on the source address could help to reduce the attack surface of the infrastructure.',
      dashboard_name: 'Rules',
      arg_names: ['Network transport protocol'],
    },
    'ec2-security-group-whitelists-aws-ip-from-banned-region.json': {
      description: 'Security Group Whitelists AWS IPs Outside the USA',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-whitelists-aws.json': {
      description: 'Security Group Whitelists AWS CIDRs',
      rationale:
        'The AWS IP ranges contain addresses which can be assigned to EC2 instances in any AWS account, as well as services which can be used to interact with any AWS account. Consequently, allowing these ranges potentially exposes your AWS account to external interactions.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-whitelists-non-elastic-ips.json': {
      description: 'Security Group Whitelists Non-Elastic IP Addresses',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-whitelists-unknown-aws.json': {
      description: 'Security Group Whitelists Unknown AWS CIDRs',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Rules',
    },
    'ec2-security-group-whitelists-unknown-cidrs.json': {
      description: 'Security Group Whitelists Unknown CIDRs',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Rules',
    },
    'ec2-unused-security-group.json': {
      description: 'Unused Security Group',
      rationale:
        ' Non-default security groups were defined which were unused and may not be required. This being the case, their existence in the configuration increases the risk that they may be inappropriately assigned. The unused security groups should be reviewed and removed if no longer required.',
      references: [
        'https://docs.aws.amazon.com/vpc/latest/userguide/VPC_SecurityGroups.html',
      ],
      dashboard_name: 'Security groups',
    },
    'elb-listener-allowing-cleartext.json': {
      description: 'Load Balancer Allowing Clear Text (HTTP) Communication',
      rationale:
        'Use of a secure protocol (HTTPS or SSL) is best practice for encrypted communication. A load balancer without a listener using an encrypted protocol can be vulnerable to eavesdropping and man-in-the-middle attacks.',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/classic/elb-listener-config.html',
      ],
      dashboard_name: 'Load Balancer Listeners',
    },
    'elb-no-access-logs.json': {
      description: 'Lack of ELB Access Logs',
      rationale:
        "Elastic Load Balancing provides access logs that capture detailed information about requests sent to your load balancer. Each log contains information such as the time the request was received, the client's IP address, latencies, request paths, and server responses. You can use these access logs to analyze traffic patterns and identify security issues.",
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/classic/access-log-collection.html',
      ],
      dashboard_name: 'Load Balancers',
    },
    'elb-older-ssl-policy.json': {
      description: 'Older SSL/TLS Policy',
      rationale:
        'Use of the latest TLS policies is best practice. The recommended predefined security policies are:<ul><li>ELBSecurityPolicy-2016-08</li><li>ELBSecurityPolicy-FS-2018-06</li><li>ELBSecurityPolicy-TLS-1-1-2017-01</li><li>ELBSecurityPolicy-TLS-1-2-2017-01</li><li>ELBSecurityPolicy-TLS-1-2-Ext-2018-06</li><li>ELBSecurityPolicy-FS-1-1-2019-08</li><li>ELBSecurityPolicy-FS-1-2-2019-08</li><li>ELBSecurityPolicy-FS-1-2-Res-2019-08</li><li>ELBSecurityPolicy-FS-1-2-Res-2020-10</li></ul>',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/classic/elb-security-policy-table.html',
      ],
      dashboard_name: 'Load Balancer Listeners Security Policy',
    },
    'elbv2-http-request-smuggling.json': {
      description: 'Drop Invalid Header Fields Disabled',
      rationale:
        'Dropping invalid header fields should be enabled in order to mitigate the risk of request smuggling attacks.',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/APIReference/API_LoadBalancerAttribute.html',
        'https://medium.com/@emilefugulin/http-desync-attacks-with-python-and-aws-1ba07d2c860f',
        'https://99designs.com/blog/engineering/request-smuggling/',
        'https://portswigger.net/web-security/request-smuggling',
      ],
      dashboard_name: 'Load Balancer Attributes',
    },
    'elbv2-listener-allowing-cleartext.json': {
      description: 'Load Balancer Allowing Clear Text (HTTP) Communication',
      rationale:
        'Use of a secure protocol (HTTPS or SSL) is best practice for encrypted communication. A load balancer without a listener using an encrypted protocol can be vulnerable to eavesdropping and man-in-the-middle attacks.',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/classic/elb-https-load-balancers.html',
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/application/create-https-listener.html',
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/network/create-tls-listener.html',
      ],
      dashboard_name: 'Load Balancer Listeners',
    },
    'elbv2-no-access-logs.json': {
      description: 'Lack of ELBv2 Access Logs',
      rationale:
        "Elastic Load Balancing provides access logs that capture detailed information about requests sent to your load balancer. Each log contains information such as the time the request was received, the client's IP address, latencies, request paths, and server responses. You can use these access logs to analyze traffic patterns and identify security issues.<br><br>Note that for Network Load Balancers, access logs are created only if the load balancer has a TLS listener.",
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/application/load-balancer-access-logs.html',
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/network/load-balancer-access-logs.html',
      ],
      dashboard_name: 'Load Balancer Attributes',
    },
    'elbv2-no-deletion-protection.json': {
      description: 'Lack of Deletion Protection',
      rationale:
        'Enabling deletion protection on load balancers mitigates risks of accidental deletion.',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/application/application-load-balancers.html#deletion-protection',
      ],
      dashboard_name: 'Load Balancer Attributes',
    },
    'elbv2-older-ssl-policy.json': {
      description: 'Older SSL/TLS Policy',
      rationale:
        'Use of the latest TLS policies is best practice. The recommended predefined security policies are:<ul><li>ELBSecurityPolicy-2016-08</li><li>ELBSecurityPolicy-FS-2018-06</li><li>ELBSecurityPolicy-TLS-1-1-2017-01</li><li>ELBSecurityPolicy-TLS-1-2-2017-01</li><li>ELBSecurityPolicy-TLS-1-2-Ext-2018-06</li><li>ELBSecurityPolicy-FS-1-1-2019-08</li><li>ELBSecurityPolicy-FS-1-2-2019-08</li><li>ELBSecurityPolicy-FS-1-2-Res-2019-08</li><li>ELBSecurityPolicy-FS-1-2-Res-2020-10</li></ul>',
      references: [
        'https://docs.aws.amazon.com/elasticloadbalancing/latest/application/create-https-listener.html#describe-ssl-policies',
      ],
      dashboard_name: 'Load Balancer Listeners',
    },
    'iam-assume-role-lacks-external-id-and-mfa.json': {
      description: 'Cross-Account AssumeRole Policy Lacks External ID and MFA',
      rationale:
        'When authorizing cross-account role assumption, either an External ID or MFA should be required. If the role is intended for use by a service, an External ID can prevent "confused deputy" attacks. If the role is intended for use by an external user, then MFA will strengthen the authentication by requiring a second factor.',
      references: [
        'https://research.nccgroup.com/2019/12/18/demystifying-aws-assumerole-and-stsexternalid/',
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html',
      ],
      dashboard_name: 'Roles',
    },
    'iam-assume-role-no-mfa.json': {
      description: 'AssumeRole Policy Lacks MFA',
      rationale: 'When authorizing assumption, MFA should be required.',
      references: [
        'https://research.nccgroup.com/2019/12/18/demystifying-aws-assumerole-and-stsexternalid/',
      ],
      dashboard_name: 'Roles',
    },
    'iam-assume-role-policy-allows-all.json': {
      description: 'AssumeRole Policy Allows All Principals',
      rationale:
        'Setting the AssumeRole policy\'s principal attribute to "AWS:*" means that anyone is authorized to assume the role and access the AWS account.',
      references: [
        'https://research.nccgroup.com/2019/12/18/demystifying-aws-assumerole-and-stsexternalid/',
      ],
      dashboard_name: 'Roles',
    },
    'iam-ec2-role-without-instances.json': {
      description: 'Unused Role for EC2 Service',
      rationale:
        'An EC2 role is defined which is unused and may not be required. This being the case, its existence in the configuration increases the risk that it may be inappropriately assigned. The unused role should be reviewed and removed if no longer required.',
      references: [
        'https://aws.amazon.com/about-aws/whats-new/2019/11/identify-unused-iam-roles-easily-and-remove-them-confidently-by-using-the-last-used-timestamp/',
      ],
      dashboard_name: 'Roles',
    },
    'iam-group-with-inline-policies.json': {
      description: 'Group with Inline Policies',
      rationale:
        "An inline policy is a policy that's embedded in an IAM identity (a user, group, or role). These policies are harder to audit and manage, and should be deprecated in favor of managed policies.",
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#best-practice-managed-vs-inline',
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_managed-vs-inline.html',
      ],
      dashboard_name: 'Groups',
    },
    'iam-group-with-no-users.json': {
      description: 'Group with No Users',
      rationale:
        'Groups with no users should be reviewed and deleted if not necessary.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#use-groups-for-permissions',
      ],
      dashboard_name: 'groups',
    },
    'iam-human-user-with-policies.json': {
      description: 'Human User with _ARG_1_ Policies',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Users',
      arg_names: ['Name of IAM group', 'Type of policy', 'Path to policies'],
    },
    'iam-inline-policy-allows-NotActions.json': {
      description: 'Inline _ARG_0_ Policy Allows "NotActions"',
      rationale:
        'The combination of "effect = allow" and "NotAction" results in the policy allowing every action except those listed in the statement. The target policy does not follow the principle of least privilege because thousands of actions exist in AWS and because this policy automatically authorizes users to perform new actions created, regardless of their nature.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_notaction.html',
        'https://aws.amazon.com/blogs/security/back-to-school-understanding-the-iam-policy-grammar/',
      ],
      dashboard_name: 'Statements',
      arg_names: ['IAM entity type'],
    },
    'iam-inline-policy-allows-non-sts-action.json': {
      description: 'Inline _ARG_0_ Policy Allows Non STS Action',
      rationale:
        'When the principle of least privilege is implemented by exclusively using roles for privilege management, users should only be granted permissions to assume roles. This policy may violate that organizational standard.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
      ],
      dashboard_name: 'Statements',
      arg_names: ['IAM entity type', 'Service', 'Action'],
    },
    'iam-inline-policy-for-role.json': {
      description:
        'Inline _ARG_0_ Policy Allows "_ARG_1_:_ARG_2_" For All Resources',
      rationale:
        'Using "*" for the resource field might grant permissions to more resources than necessary, potentially introducing privilege escalation scenarios.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
      ],
      dashboard_name: 'Policies',
      arg_names: ['IAM entity type', 'Service', 'Action'],
    },
    'iam-lightspin-user-action-denied-for-group.json': {
      description: 'Policy with Denied User Actions for Group Objects',
      rationale:
        'When a deny policy is specified for User object actions on a group resource, this will only affect the specific IAM group but not the group members. This could lead to privilege escalation if the user can perform other privileged actions targeting the specific members of the group.',
      remediation:
        "Define all relevant users in the resource field of the affected policies to avoid ineffective IAM actions and deny all group actions. The alternative would be to use the condition 'iam:ResourceTag' in the policy.",
      references: [
        'https://blog.lightspin.io/aws-iam-groups-authorization-bypass',
        'https://github.com/lightspin-tech/red-shadow',
      ],
      dashboard_name: 'Policies',
    },
    'iam-managed-policy-allows-NotActions.json': {
      description: 'Managed Policy Allows "NotActions"',
      rationale:
        'The combination of "effect = allow" and "NotAction" results in the policy allowing every action except those listed in the statement. The target policy does not follow the principle of least privilege because thousands of actions exist in AWS and because this policy automatically authorizes users to perform new actions created, regardless of their nature.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_notaction.html',
        'https://aws.amazon.com/blogs/security/back-to-school-understanding-the-iam-policy-grammar/',
      ],
      dashboard_name: 'Statements',
    },
    'iam-managed-policy-allows-full-privileges.json': {
      description: 'Managed Policy Allows All Actions',
      rationale:
        'Providing full privileges instead of restricting to the minimum set of permissions that the principal requires exposes the resources to potentially unwanted actions.',
      remediation:
        'Ensure no managed policies are configured with <samp>Effect: Allow</samp>, <samp>Action: *</samp> and <samp>Resource: *</samp>',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
        'https://aws.amazon.com/blogs/security/back-to-school-understanding-the-iam-policy-grammar/',
      ],
      dashboard_name: 'Statements',
    },
    'iam-managed-policy-allows-non-sts-action.json': {
      description: 'Managed Policy Allows Non STS Action',
      rationale:
        'When the principle of least privilege is implemented by exclusively using roles for privilege management, users should only be granted permissions to assume roles. This policy may violate that organizational standard.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
      ],
      dashboard_name: 'Statements',
      arg_names: ['Service', 'Action'],
    },
    'iam-managed-policy-for-role.json': {
      description: 'Managed Policy Allows "_ARG_0_:_ARG_1_" For All Resources',
      rationale:
        'Using "*" for the resource field might grant permissions to more resources than necessary, potentially introducing privilege escalation scenarios.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
      ],
      dashboard_name: 'Statements',
      arg_names: ['Service', 'Action'],
    },
    'iam-managed-policy-no-attachments.json': {
      description: 'Managed Policy Not Attached to Any Entity',
      rationale:
        'Customer Managed policies should be reviewed and deleted if not necessary.',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_managed-vs-inline.html#customer-managed-policies',
      ],
      dashboard_name: 'Policies',
    },
    'iam-no-support-role.json': {
      description: 'No Authorized User to Manage Incidents with AWS Support',
      rationale:
        'The <samp>arn:aws:iam::aws:policy/AWSSupportAccess</samp> AWS Managed Policy was not found to be attached to any principal. There should be at least one user authorized to manage incidents with AWS Support.',
      remediation: 'Attach the AWSSupportAccess to a role or group',
      dashboard_name: 'Policies',
    },
    'iam-password-policy-expiration-threshold.json': {
      description: 'Passwords Expire after _ARG_0_ Days',
      rationale:
        'Password expiration is disabled, or expiration time is set to a too high value. As a result, compromised credentials could be used by potential attackers for a larger period of time. Periodic password change enforced with an appropriate password expiration policy could help to mitigate this issue.',
      remediation:
        'Enable password expiration and set the expiration period to 90 days or less',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.11',
      ],
      dashboard_name: 'Password policy',
      arg_names: ['Maximum password age'],
    },
    'iam-password-policy-minimum-length.json': {
      description: 'Minimum Password Length Too Short',
      rationale:
        'The password policy did not enforce a minimum of _ARG_0_ characters. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to require a minimum length',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.9',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_complex-password',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_complex-password',
      ],
      dashboard_name: 'Password policy',
      arg_names: ['Minimum password length'],
    },
    'iam-password-policy-no-expiration.json': {
      description: 'Password Expiration Disabled',
      rationale:
        'Password expiration is disabled. As a result, compromised credentials could be used by potential attackers for a indefinite amount of time. Periodic password change enforced with an appropriate password expiration policy could help to mitigate this issue.',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.11',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-password-policy-no-lowercase-required.json': {
      description: 'Password Policy Lacks Lowercase Requirement',
      rationale:
        'The password policy did not require the use of at least one lowercase character. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to require at least one lowercase letter',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.6',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_complex-password',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_complex-password',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-password-policy-no-number-required.json': {
      description: 'Password Policy Lacks Number Requirement',
      rationale:
        'The password policy did not require the use of at least one number. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to require at least one number',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.8',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_complex-password',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_complex-password',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-password-policy-no-symbol-required.json': {
      description: 'Password Policy Lacks Symbol Requirement',
      rationale:
        'The password policy did not require the use of at least one special character. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to require at least one symbol',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.7',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_complex-password',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_complex-password',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-password-policy-no-uppercase-required.json': {
      description: 'Password Policy Lacks Uppercase Requirement',
      rationale:
        'The password policy did not require the use of at least one uppercase character. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to require at least one uppercase letter',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.5',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_complex-password',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_complex-password',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-password-policy-reuse-enabled.json': {
      description: 'Password Policy Allows the Reuse of Passwords',
      rationale:
        'The password policy allowed password reuse. As a result, password complexity requirements were not in line with security best practice.',
      remediation:
        'Ensure the password policy is configured to prevent password reuse',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.10',
      ],
      dashboard_name: 'Password policy',
    },
    'iam-role-with-inline-policies.json': {
      description: 'Role with Inline Policies',
      rationale:
        "An inline policy is a policy that's embedded in an IAM identity (a user, group, or role). These policies are harder to audit and manage, and should be deprecated in favor of managed policies.",
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#best-practice-managed-vs-inline',
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_managed-vs-inline.html',
      ],
      dashboard_name: 'Roles',
    },
    'iam-root-account-no-hardware-mfa.json': {
      description: 'Root Account without Hardware MFA',
      rationale:
        "The root account is the most privileged user in an account. MFA adds an extra layer of protection on top of a user name and password. With MFA enabled, when a user signs in to an AWS website, they're prompted for their user name and password and for an authentication code from their AWS MFA device.",
      remediation: 'Enable MFA for the root account',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.13',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_mfa',
      ],
      dashboard_name: 'Accounts',
    },
    'iam-root-account-no-mfa.json': {
      description: 'Root Account without MFA',
      rationale:
        "The root account is the most privileged user in an account. MFA adds an extra layer of protection on top of a user name and password. With MFA enabled, when a user signs in to an AWS website, they're prompted for their user name and password and for an authentication code from their AWS MFA device.",
      remediation: 'Enable MFA for the root account',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-1.13',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_mfa',
      ],
      dashboard_name: 'Accounts',
    },
    'iam-root-account-used-recently.json': {
      description: 'Root Account Used Recently',
      rationale:
        'The root account is the most privileged user in an account. As a best practice, the root account should only be used when required for root-only tasks.',
      remediation:
        'Follow the remediation instructions of the Ensure IAM policies are attached only to groups or roles recommendation',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-standards-cis-controls-1.1',
        'https://docs.aws.amazon.com/general/latest/gr/aws_tasks-that-require-root.html',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-use',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_review-access',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_document-processes',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_monitor-access',
      ],
      dashboard_name: 'Root account',
    },
    'iam-root-account-with-active-certs.json': {
      description: 'Root Account Has Active X.509 Certs',
      rationale:
        'Root account X.509 certificates should be deleted as they may be used to make SOAP-protocol requests in the context of the root account.',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-standards-cis-controls-1.1',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-use',
      ],
      dashboard_name: 'Root account',
    },
    'iam-root-account-with-active-keys.json': {
      description: 'Root Account Has Active Keys',
      rationale:
        'AWS root account access keys should be deleted as they provide unrestricted access to the AWS Account.',
      remediation: 'Delete or disable active root account access keys',
      references: [
        'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-standards-cis-controls-1.1',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-use',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_review-access',
        'https://docs.aws.amazon.com/organizations/latest/userguide/orgs_best-practices_mgmt-acct.html#best-practices_mgmt-acct_document-processes',
      ],
      dashboard_name: 'Root account',
    },
    'iam-service-user-with-password.json': {
      description: 'Service User with Password Enabled',
      rationale:
        'A user meant to be used for a service or application not hosted in AWS was found to have password authentication enabled. This goes against security best practice, which recommends that these types of users should only have API authentication enabled, to minimize the likeliness of account compromise.',
      dashboard_name: 'Users',
      arg_names: ['Group for service users'],
    },
    'iam-unused-credentials-not-disabled.json': {
      description:
        'Credentials Unused for _ARG_0_ Days or Greater Are Not Disabled',
      rationale:
        'Disabling or removing unnecessary credentials will reduce the window of opportunity for compromised accounts to be used.',
      remediation:
        'Ensure that all credentials (including passwords and access keys) have been used and changed in the last _ARG_0_ days',
      dashboard_name: 'Users',
      arg_names: ['Period in days'],
    },
    'iam-user-no-key-rotation.json': {
      description: 'Lack of Key Rotation for (_ARG_0_) Days',
      rationale:
        'In case of access key compromise, the lack of credential rotation increases the period during which an attacker has access to the AWS account.',
      remediation: 'Rotate access keys that have not been changed recently',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#rotate-credentials',
      ],
      dashboard_name: 'Access keys',
      arg_names: ['Key status', 'Rotation period'],
    },
    'iam-user-not-in-category-group.json': {
      description: 'User Not in Category Group',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Users',
      arg_names: ['Category groups'],
    },
    'iam-user-not-in-common-group.json': {
      description: 'User Not in Common Group',
      rationale: 'This configuration goes against organizational policies.',
      dashboard_name: 'Users',
      arg_names: ['Common group'],
    },
    'iam-user-unused-access-key-initial-setup.json': {
      description:
        'Users with Access Keys Created during Initial Setup and Not Used',
      rationale:
        'Not creating access keys during initial user setup will avoid unnecessary management work and give more control over keys used somewhere in the organization.',
      remediation:
        'Do not setup access keys during initial user setup. Instead, require users to create the keys themselves or put in a support ticket to have them created',
      dashboard_name: 'Users',
    },
    'iam-user-with-multiple-access-keys.json': {
      description: 'User with Multiple API Keys',
      rationale:
        'The user was configured to have more than one active API keys associated with the account. Redundant or unused API keys should be removed.',
      references: [
        'https://docs.aws.amazon.com/general/latest/gr/aws-access-keys-best-practices.html',
      ],
      dashboard_name: 'Users',
    },
    'iam-user-with-password-and-key.json': {
      description: 'User with Password and Keys Enabled',
      rationale:
        'The user was found to have configured with both password and API keys for authentication. This is considered against security best practice.',
      references: [
        'https://docs.aws.amazon.com/general/latest/gr/aws-access-keys-best-practices.html',
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_passwords_admin-change-user.html',
      ],
      dashboard_name: 'Users',
    },
    'iam-user-with-policies.json': {
      description: 'User with _ARG_0_ Policies',
      rationale:
        'The user was found to be attached to inline policies. This can increase the complexity in user and policy management, and increase the risk of the user being assigned or retaining excessive permissions.',
      remediation: 'Ensure IAM policies are only attached to groups or roles',
      dashboard_name: 'Users',
      arg_names: ['Type of policy', 'Path to policies'],
    },
    'iam-user-without-mfa.json': {
      description: 'User without MFA',
      rationale:
        "All IAM users should have MFA. MFA adds an extra layer of protection on top of a user name and password. With MFA enabled, when a user signs in to an AWS website, they're prompted for their user name and password and for an authentication code from their AWS MFA device.",
      remediation: 'Enable MFA for all users in the AWS account',
      references: [
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html#enable-mfa-for-privileged-users',
        'https://docs.aws.amazon.com/organizations/latest/userguide/best-practices_member-acct.html#best-practices_mbr-acct_mfa',
      ],
      dashboard_name: 'Users',
    },
    'kms-cmk-rotation-disabled.json': {
      description: 'KMS Customer Master Keys (CMKs) with Rotation Disabled',
      rationale:
        'Cryptographic best practices discourage extensive reuse of encryption keys. Consequently, Customer Master Keys (CMKs) should be rotated to prevent usage of compromised keys.<br><br>Note that AWS KMS supports optional automatic key rotation only for customer managed CMKs.',
      remediation:
        'For every KMS Customer Master Keys (CMKs), ensure that Rotate this key every year is enabled',
      references: [
        'https://docs.aws.amazon.com/kms/latest/developerguide/rotate-keys.html',
      ],
      dashboard_name: 'Keys',
    },
    'logs-no-alarm-aws-configuration-changes.json': {
      description: 'No CloudWatch Alarm for "AWS Configuration Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor AWS configuration changes. Monitoring for AWS configuration changes will help ensure sustained visibility to changes performed in the AWS account.',
      remediation:
        'Enable a CloudWatch alarm to detect AWS configuration changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-cloudtrail-configuration-changes.json': {
      description: 'No CloudWatch Alarm for "CloudTrail Configuration Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor CloudTrail configuration changes. Monitoring for CloudTrail policy changes will help ensure sustained visibility to activities performed in the AWS account.',
      remediation:
        'Enable a CloudWatch alarm to monitor CloudTrail configuration changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-cmk-deletion.json': {
      description: 'No CloudWatch Alarm for "Disabled or Deleted Master Keys"',
      rationale:
        'There was no CloudWatch alarm to alert about disabled or deleted master keys. Monitoring for disabled or deleted master keys can prevent permanent loss of data encrypted with such keys.',
      remediation:
        'Enable a CloudWatch alarm to detect recently disabled or deleted master keys.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-console-authentication-failures.json': {
      description: 'No CloudWatch Alarm for "Failed Console Authentications"',
      rationale:
        'There was no CloudWatch alarm for failed console authentication requests. Monitoring for failed console authentications can decrease the lead time to detect brute force attacks.',
      remediation:
        'Enable a CloudWatch alarm to monitor failed authentication attempts.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-iam-policy-changes.json': {
      description: 'No CloudWatch Alarm for "IAM Policy Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor IAM policy changes. Monitoring for IAM policy changes will help ensure authentication and authorization control remain intact.',
      remediation:
        'Enable a CloudWatch alarm to monitor the usage of the root account.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-nacl-changes.json': {
      description:
        'No CloudWatch Alarm for "Network Access Control Lists Changes"',
      rationale:
        'There was no CloudWatch alarm to detect changes to NACL. Monitoring for NACL changes will help ensure that no service or resource is unintentionally exposed.',
      remediation: 'Enable a CloudWatch alarm to detect NACL changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-network-gateways-changes.json': {
      description: 'No CloudWatch Alarm for "Network Gateways Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor changes in network gateways. Monitoring for network gateways changes will help ensure that all traffic traverses the VPC border via a controlled path.',
      remediation:
        'Enable a CloudWatch alarm to detect network gateways changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-root-usage.json': {
      description: 'No CloudWatch Alarm for "Root Account Usage"',
      rationale:
        'There was no CloudWatch alarm for the usage of the root account. Monitoring for root account log ins will provide visibility into the use of a fully privileged account.',
      remediation:
        'Enable a CloudWatch alarm to monitor the usage of the root account.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-route-table-changes.json': {
      description: 'No CloudWatch Alarm for "Route Table Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor changes in route tables. Monitoring for route table changes will help ensure that all VPC traffic flows thorough an expected path.',
      remediation: 'Enable a CloudWatch alarm to detect route table changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-s3-policy-changes.json': {
      description: 'No CloudWatch Alarm for "S3 Bucket Policy Changes"',
      rationale:
        'There was no CloudWatch alarm to detect changes in S3 bucket policy changes. Monitoring for changes in S3 bucket policy changes may reduce the time to detect and fix permissive policies on sensitive S3 buckets.',
      remediation:
        'Enable a CloudWatch alarm to detect policy changes in S3 buckets.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-security-group-changes.json': {
      description: 'No CloudWatch Alarm for "Security Group Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor changes to security groups. Monitoring for security group changes will help ensure that no service or resource is unintentionally exposed.',
      remediation:
        'Enable a CloudWatch alarm to monitor security group changes.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-signin-without-mfa.json': {
      description: 'No CloudWatch Alarm for "Console Logins without MFA"',
      rationale:
        'There was no CloudWatch alarm for console log ins without MFA. Monitoring for console log ins without MFA will provide visibility into the use of accounts not protected by Multi Factor Authentication (MFA).',
      remediation:
        'Enable a CloudWatch alarm to monitor console log ins without MFA.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-unauthorized-api-calls.json': {
      description: 'No CloudWatch Alarm for "Unauthorized API Calls"',
      rationale:
        'There was no CloudWatch alarm for unauthorized API calls. Monitoring for unauthorized API calls helps reveal application errors and detect malicious activity.',
      remediation:
        'Enable a CloudWatch alarm to monitor unauthorized API calls.',
      dashboard_name: 'Alarms',
    },
    'logs-no-alarm-vpc-changes.json': {
      description: 'No CloudWatch Alarm for "VPC Changes"',
      rationale:
        'There was no CloudWatch alarm to monitor VPC changes. Monitoring for VPC changes will help ensure authentication and authorization controls remain intact.',
      remediation: 'Enable a CloudWatch alarm to detect VPC changes.',
      dashboard_name: 'Alarms',
    },
    'rds-instance-backup-disabled.json': {
      description: 'Instance Backups Disabled',
      rationale:
        'The backup retention period is a period of time between 0 and 35 days for which you can perform a point-in-time restore. Setting the backup retention period to 0 disables automated backups. Backups should be enabled to enable disaster recovery.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithAutomatedBackups.html',
        'https://aws.amazon.com/rds/details/backup/',
        'https://aws.amazon.com/rds/faqs/',
      ],
      dashboard_name: 'Instances',
    },
    'rds-instance-ca-certificate-deprecated.json': {
      description: 'Deprecated Certificate Authority Assigned to RDS Instance',
      rationale:
        'Amazon Web Services is deprecating the RDS Certificate Authority created in 2015 (rds-ca-2015). Any endpoints using TLS to protect RDS communications will fail after March 5th, 2020.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/UsingWithRDS.SSL-certificate-rotation.html',
      ],
      dashboard_name: 'Instances',
    },
    'rds-instance-no-minor-upgrade.json': {
      description: 'Auto Minor Version Upgrade Disabled',
      rationale:
        'Auto Minor Version Upgrade is a feature that you can enable to have your database automatically upgraded when a new minor database engine version is available. Minor version upgrades often patch security vulnerabilities and fix bugs, and therefor should be applied.',
      references: [
        'https://aws.amazon.com/blogs/database/best-practices-for-upgrading-amazon-rds-to-major-and-minor-versions-of-postgresql/',
        'https://aws.amazon.com/about-aws/whats-new/2018/12/amazon-rds-enhances-auto-minor-version-upgrades/',
      ],
      dashboard_name: 'Instances',
    },
    'rds-instance-publicly-accessible.json': {
      description: 'RDS Instance publicly accessible',
      rationale:
        'Instances should not be publicly accessible as this risks exposing sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_VPC.WorkingWithRDSInstanceinaVPC.html#USER_VPC.Hiding',
      ],
      dashboard_name: 'Instances',
    },
    'rds-instance-short-backup-retention-period.json': {
      description: 'Short Backup Retention Period',
      rationale:
        'The backup retention period is a period of time between 0 and 35 days for which you can perform a point-in-time restore. Setting the backup retention period to 0 disables automated backups.<br><br>It is recommended that the retention period is set to at least 30 days. Having a short retention period will impact how far back in time the database can be restored to, and may affect integrity and availability of data.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithAutomatedBackups.html',
        'https://aws.amazon.com/rds/details/backup/',
        'https://aws.amazon.com/rds/faqs/',
      ],
      dashboard_name: 'Instances',
    },
    'rds-instance-single-az.json': {
      description: 'Single AZ RDS Instance',
      rationale:
        'In case of failure, with a single-AZ deployment configuration, should an availability zone specific database failure occur, Amazon RDS can not automatically fail over to the standby availability zone.',
      references: ['https://aws.amazon.com/rds/features/multi-az/'],
      dashboard_name: 'Instances',
    },
    'rds-instance-storage-not-encrypted.json': {
      description: 'Instance Storage Not Encrypted',
      rationale: 'Data-at-rest should be encrypted.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Overview.Encryption.html',
      ],
      dashboard_name: 'Instances',
    },
    'rds-postgres-instance-with-invalid-certificate.json': {
      description: 'Legacy SSL Certificate (PostgreSQL)',
      rationale:
        'This instance was created prior to August 5, 2014. Consequently, it uses the DB instance endpoint in the Common Name (CN) field of the SSL certificate. Prior to August 5, 2014, SSL certificate verification was not available for VPC-based SQL Server instances. If you have a VPC-based SQL Server DB instance that was created before August 5, 2014, and you want to use SSL certificate verification and ensure that the instance endpoint is included as the CN for the SSL certificate for that DB instance, then rename the instance. When you rename a DB instance, a new certificate is deployed and the instance is rebooted to enable the new certificate.',
      references: [
        'https://aws.amazon.com/about-aws/whats-new/2017/02/amazon-rds-for-sql-server-supports-forced-ssl/',
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/SQLServer.Concepts.General.SSL.Using.html',
      ],
      dashboard_name: 'Instances',
    },
    'rds-security-group-allows-all.json': {
      description: 'Security Group Allows All IP Addresses',
      rationale:
        'The security group allows access to RDS instances from any source address, which creates a wider attack surface for resources assigned to it. Source address restrictions should be implemented',
      dashboard_name: 'Security Groups',
    },
    'rds-snapshot-public.json': {
      description: 'Publicly Accessible Snapshot',
      rationale:
        'Snapshots should never be public, as this risks exposing sensitive data.',
      references: [
        'https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_ShareSnapshot.html',
      ],
      dashboard_name: 'Snapshots',
    },
    'redshift-cluster-database-not-encrypted.json': {
      description: 'Cluster Database Encryption Disabled',
      rationale: 'Data-at-rest should be encrypted.',
      references: [
        'https://docs.aws.amazon.com/redshift/latest/mgmt/working-with-db-encryption.html',
      ],
      dashboard_name: 'Clusters',
    },
    'redshift-cluster-no-version-upgrade.json': {
      description: 'Version Upgrade Disabled',
      rationale:
        'Version upgrade is necessary to ensure major version upgrades will be applied automatically to the cluster within the maintenance window.',
      references: [
        'https://docs.aws.amazon.com/redshift/latest/APIReference/API_ModifyCluster.html',
      ],
      dashboard_name: 'Clusters',
    },
    'redshift-cluster-publicly-accessible.json': {
      description: 'Cluster Publicly Accessible',
      rationale:
        'Redshift clusters should never be public, as this increases the risk of exposing sensitive data. Public accessibility means that other AWS users can access your cluster and the data stored in it.',
      references: [
        'https://docs.aws.amazon.com/redshift/latest/gsg/getting-started.html',
      ],
      dashboard_name: 'Clusters',
    },
    'redshift-parameter-group-logging-disabled.json': {
      description: 'User Activity Logging Disabled',
      rationale:
        'Audit logging is not enabled by default in Amazon Redshift. A lack of user activity logging could impede the ability to investigate issues involving misuse, malicious access or performance.<br><br><b>Note</b> that this rule will only flag non-default parameter groups, as default parameter groups cannot be modified. It is recommended to use custom groups and configure them according to security best practice.',
      references: [
        'https://docs.aws.amazon.com/redshift/latest/mgmt/db-auditing.html',
      ],
      dashboard_name: 'Parameter Groups',
    },
    'redshift-parameter-group-ssl-not-required.json': {
      description: 'SSL Not Required',
      rationale:
        'Parameter groups associated with Redshift clusters should have the "require_ssl" parameter enabled, to ensure that data in transit is encrypted.<br><br><b>Note</b> that this rule will only flag non-default parameter groups, as default parameter groups cannot be modified. It is recommended to use custom groups and configure them according to security best practice.',
      references: [
        'https://docs.aws.amazon.com/redshift/latest/mgmt/connecting-ssl-support.html',
      ],
      dashboard_name: 'Parameter Groups',
    },
    'redshift-security-group-whitelists-all.json': {
      description: 'Security Group Allows All Traffic',
      rationale:
        'The security group allows access to Redshift instances from any source address, which creates a wider attack surface for resources assigned to it. Source address restrictions should be implemented',
      dashboard_name: 'Security Groups',
    },
    'route53-domain-no-autorenew.json': {
      description: 'Automatic Renewal Not Set for Domain',
      rationale:
        'Enable automatic renewal to remove the risk of losing control over your domain names.',
      references: [
        'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-enable-disable-auto-renewal.html',
      ],
      dashboard_name: 'Domains',
    },
    'route53-domain-no-transferlock.json': {
      description: 'Domain Transfer Not Locked',
      rationale:
        'A domain lock prevents someone from transferring your domain to another registrar without your permission.',
      references: [
        'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/domain-lock.html',
      ],
      dashboard_name: 'Domains',
    },
    'route53-domain-transferlock-not-authorized.json': {
      description: 'Domain Transfer Lock Not Supported by TLD',
      rationale:
        "A domain lock prevents someone from transferring your domain to another registrar without your permission. Your domain's TLD does not support this feature.",
      references: [
        'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/registrar-tld-list.html',
      ],
      dashboard_name: 'Domains',
    },
    's3-bucket-allowing-cleartext.json': {
      description: 'Bucket Allowing Clear Text (HTTP) Communication',
      rationale:
        'If HTTPS is not enforced on the bucket policy, communication between clients and S3 buckets can use unencrypted HTTP. As a result, sensitive information could be transmitted in clear text over the network|Internet.',
      references: [
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html',
      ],
      dashboard_name: 'Buckets',
    },
    's3-bucket-no-default-encryption.json': {
      description: 'Bucket without Default Encryption Enabled',
      rationale:
        'Amazon S3 default encryption provides a way to set the default encryption behavior for an S3 bucket. This will ensure data-at-rest is encrypted',
      references: [
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html',
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/bucket-encryption.html',
      ],
      dashboard_name: 'Buckets',
    },
    's3-bucket-no-logging.json': {
      description: 'Bucket Access Logging Disabled',
      rationale:
        'Server access logging provides detailed records of the requests that are made to a bucket. Server access logs can assist you in security and access audits, help you learn about your customer base, and understand your Amazon S3 bill. <br><br>Note: CloudTrail data events can be used in place of S3 bucket logging. If that is the case, this finding can be considered a false positive.',
      remediation: 'Ensure that S3 buckets have Logging enabled',
      references: [
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html',
      ],
      dashboard_name: 'Buckets',
    },
    's3-bucket-no-mfa-delete.json': {
      description: 'Bucket without MFA Delete',
      rationale:
        'Enable MFA delete to help protect objects from accidental or unauthorized deletion. It should be noted that MFA Delete can only be configured on buckets that have versioning enabled.',
      references: [
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html',
      ],
      dashboard_name: 'Buckets',
    },
    's3-bucket-no-versioning.json': {
      description: 'Bucket without Versioning',
      rationale:
        'Versioning is a means of keeping multiple variants of an object in the same bucket. With versioning, you can easily recover from both unintended user actions and application failures.',
      references: [
        'https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html',
      ],
      dashboard_name: 'Buckets',
    },
    's3-bucket-world-acl.json': {
      description: 'Bucket Read/Write _ARG_2_',
      rationale:
        'An S3 bucket has been found that was accessible for _ARG_0_. If sensitive information is stored in this bucket, it could be at risk of being accessed by potential attackers.',
      dashboard_name: 'Bucket ACLs',
      arg_names: ['Grantee', 'Permission', 'Description', 'Level'],
    },
    's3-bucket-world-policy-arg.json': {
      description: '_ARG_0_ Actions Authorized to All Principals',
      rationale:
        "Allowing IAM actions to all principals is contrary to the principle of least privilege and presents an opportunity for abuse. This policy should be reviewed to ensure it is secure and in line with the resource's intended use.",
      dashboard_name: 'Bucket Policy Statements',
      arg_names: ['Action shortname', 'Service:Action'],
    },
    's3-bucket-world-policy-star.json': {
      description: 'All Actions Authorized to All Principals',
      rationale:
        "Allowing IAM actions to all principals is contrary to the principle of least privilege and presents an opportunity for abuse. This policy should be reviewed to ensure it is secure and in line with the resource's intended use.",
      remediation:
        'Remove any Statement having an Effect set to Allow and a Principal set to "*" or {"AWS":"*"} in the affected bucket policy',
      dashboard_name: 'Bucket Policy Statements',
      arg_names: ['Action shortname', 'Service:Action'],
    },
    'ses-identity-dkim-not-enabled.json': {
      description: 'DKIM Not Enabled',
      rationale:
        'DKIM signing is not enabled for emails sent from the identity.',
      references: [
        'https://docs.aws.amazon.com/ses/latest/DeveloperGuide/send-email-authentication-dkim.html',
      ],
      dashboard_name: 'Identities',
    },
    'ses-identity-dkim-not-verified.json': {
      description: 'DKIM Not Verified',
      rationale:
        "Amazon SES has not verified the DKIM DNS records (tokens) published in the domain name's DNS.",
      references: [
        'https://docs.aws.amazon.com/ses/latest/DeveloperGuide/send-email-authentication-dkim.html',
      ],
      dashboard_name: 'Identities',
    },
    'ses-identity-world-policy.json': {
      description: '_ARG_0_ Authorized to All Principals',
      rationale:
        "Allowing IAM actions to all principals is contrary to the principle of least privilege and presents an opportunity for abuse. This policy should be reviewed to ensure it is secure and in line with the resource's intended use.",
      dashboard_name: 'Statements',
      arg_names: ['SES action'],
    },
    'sns-topic-world-policy.json': {
      description: '_ARG_0_ Authorized to All Principals',
      rationale:
        "Allowing IAM actions to all principals is contrary to the principle of least privilege and presents an opportunity for abuse. This policy should be reviewed to ensure it is secure and in line with the resource's intended use.",
      dashboard_name: 'Statements',
      arg_names: ['SNS action'],
    },
    'sqs-queue-server-side-encryption-disabled.json': {
      description: 'Queue with Encryption Disabled',
      rationale:
        'SQS Server-Side Encryption ensures that the contents of messages in queues are encrypted.',
      references: [
        'https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-server-side-encryption.html',
      ],
      dashboard_name: 'Queues',
    },
    'sqs-queue-world-policy.json': {
      description: '_ARG_0_ Authorized to All Principals',
      rationale:
        "Allowing IAM actions to all principals is contrary to the principle of least privilege and presents an opportunity for abuse. This policy should be reviewed to ensure it is secure and in line with the resource's intended use.",
      dashboard_name: 'Statements',
      arg_names: ['SQS action'],
    },
    'vpc-custom-network-acls-allow-all.json': {
      description: 'Network ACLs Allow All _ARG_0_ Traffic (custom)',
      rationale:
        'Network ACLs are designed to provide a secondary layer of security. Adding a rule that allows all network traffic (all protocols, IPs, and _ARG_1_) prior to any deny rule defeats the purpose of network ACLs.',
      dashboard_name: 'Network ACLs',
      arg_names: [
        'Direction of traffic (ingress or egress)',
        'Corresponding target (source or destination)',
      ],
    },
    'vpc-default-network-acls-allow-all.json': {
      description: 'Network ACLs Allow All _ARG_0_ Traffic (default)',
      rationale:
        'Network ACLs are designed to provide a secondary layer of security. Adding a rule that allows all network traffic (all protocols, IPs, and _ARG_1_) prior to any deny rule defeats the purpose of network ACLs.',
      dashboard_name: 'Network ACLs',
      arg_names: [
        'Direction of traffic (ingress or egress)',
        'Corresponding target (source or destination)',
      ],
    },
    'vpc-network-acl-not-used.json': {
      description: 'Unused Network ACLs',
      rationale:
        'Maintaining unused resources increases risks of misconfigurations and increases the difficulty of audits.',
      dashboard_name: 'Network ACLs',
    },
    'vpc-routing-tables-with-peering.json': {
      description: 'Routing Table with VPC Peering',
      rationale:
        'Being highly selective in peering routing tables minimizes the impact of breach as resources outside of these routes are inaccessible to the peered VPC.',
      remediation:
        'Ensure route tables contain the least number of subnets or hosts as is required to accomplish the purpose for peering',
      dashboard_name: 'Rulesets',
    },
    'vpc-subnet-with-bad-acls.json': {
      description: 'Subnet with "Allow All" _ARG_0_ NACLs',
      rationale:
        'Network ACLs associated with subnets and VPCs should not allow all _ARG_0_ traffic.',
      dashboard_name: 'Subnets',
      arg_names: [
        'Direction of traffic (ingress or egress)',
        'Corresponding target (source or destination)',
      ],
    },
    'vpc-subnet-with-default-acls.json': {
      description: 'Subnet with Default _ARG_0_ NACLs',
      rationale:
        'Default _ARG_0_ network access control lists allow all network traffic (all protocols, IPs, and _ARG_1_), hence do not provide the secondary layer of security network ACLs are supposed to.',
      dashboard_name: 'Subnets',
      arg_names: [
        'Direction of traffic (ingress or egress)',
        'Corresponding target (source or destination)',
      ],
    },
    'vpc-subnet-without-flow-log.json': {
      description: 'Subnet without a Flow Log',
      rationale:
        'Flow logs enable the investigation of incidents involving unauthorized network traffic, such as an attacker exfiltrating data or pivoting to other hosts.',
      remediation: 'Create a flow log for each subnet.',
      references: [
        'https://docs.aws.amazon.com/vpc/latest/userguide/flow-logs.html',
      ],
      dashboard_name: 'Subnets',
    },
  },
  scoutsuite_azure: {
    'network-watcher-not-provisioned.json': {
      dashboard_name: 'Network',
      description: 'Network Watchers Not Provisioned',
      rationale: 'Network watchers should be provisioned to work.',
    },
    'securitycenter-settings-MCAS-integration-disabled.json': {
      dashboard_name: 'Security Settings',
      references: [
        'https://docs.microsoft.com/en-in/azure/security-center/azure-defender#azure-management-layer-azure-resource-manager-preview',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/settings/list',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/settings/update',
      ],
      description: 'Microsoft Cloud App Security (MCAS) Is Disabled',
      rationale:
        'Security Center offers an additional layer of protection by using Azure Resource Manager events, which is considered to be the control plane for Azure. By analyzing the Azure Resource Manager records, Security Center detects unusual or potentially harmful operations in the Azure subscription environment. Several of the preceding analytics are powered by Microsoft Cloud App Security.',
    },
    'storageaccount-public-traffic-allowed.json': {
      dashboard_name: 'Storage Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-network-security',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy',
      ],
      description: 'Storage Accounts Allowing Public Traffic',
      rationale:
        'Restricting default network access helps to provide a new layer of security, since storage accounts accept connections from clients on any network. To limit access to selected networks, the default action must be changed.',
    },
    'sqldatabase-servers-vulnerability-assessments-disabled.json': {
      dashboard_name: 'SQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/sql-vulnerability-assessment',
        'https://docs.microsoft.com/en-us/rest/api/sql/servervulnerabilityassessments/listbyserver',
        'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Update-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
        'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Get-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-posture-vulnerability-management#pv-6-perform-software-vulnerability-assessments',
      ],
      description: 'Vulnerability Assessment (VA) Is Disabled on SQL Servers',
      rationale:
        'Enabling Advanced Data Security on a SQL server does not enables Vulnerability Assessment capability for individual SQL databases unless storage account is set to store the scanning data and reports.',
    },
    'sqldatabase-servers-no-threat-detection.json': {
      dashboard_name: 'SQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/sql-database/sql-advanced-threat-protection',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/azure-defender-for-sql',
      ],
      description: 'Advanced Threat Protection (ATP) Disabled for SQL Servers',
      rationale:
        'SQL Server "Advanced Data Security" (ADS) provides a new layer of security, which enables customers to detect and respond to potential threats as they occur by providing security alerts on anomalous activities. Users will receive an alert upon suspicious database activities, potential vulnerabilities, and SQL injection attacks, as well as anomalous database access patterns.',
    },
    'securitycenter-security-contacts-no-email-notifications.json': {
      dashboard_name: 'Security contacts',
      references: [
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-provide-security-contact-details',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/securitycontacts/list',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-3-define-security-posture-management-strategy',
      ],
      description: '"Sending Email to Administrators on Alert" is "Off"',
      rationale:
        'Enabling securityalert emails ensures that security alert emails are received from Microsoft. This ensures that the right people are aware of any potential security issues and are able to mitigate the risk.',
    },
    'securitycenter-security-contacts-phone-not-set.json': {
      dashboard_name: 'Security contacts',
      description: 'No Security Contact Phone Set',
      rationale: 'Set at least one security contact phone number.',
    },
    'storageaccount-trusted-microsoft-services.json': {
      dashboard_name: 'Storage Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-network-security',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-network-security#ns-1-implement-security-for-internal-traffic',
      ],
      description: 'Trusted Microsoft Services Enabled',
      rationale:
        "Some Microsoft services that interact with storage accounts operate from networks that can't be granted access through network rules. To help this type of service work as intended allow the set of trusted Microsoft services to bypass the network rules. These services will then use strong authentication to access the storage account.<br><br>If the <samp>Allow trusted Microsoft services</samp> exception is enabled the following services are granted access to the storage account:<br> <ul> <li>Azure Backup</li> <li>Azure Site Recovery</li> <li>Azure DevTest Labs</li> <li>Azure Event Grid</li> <li>Azure Event Hubs</li> <li>Azure Networking</li> <li>Azure Monitor</li> <li>Azure SQL Data Warehouse (when registered in the subscription)</li> </ul>",
    },
    'sqldatabase-databases-threat-detection-low-retention.json': {
      dashboard_name: 'SQL Databases',
      description: 'Short Threat Detection Period for SQL Databases',
      rationale:
        'Threat detection retention period should be greater than _ARG_0_ days.',
    },
    'network-security-groups-rule-inbound-service-mssql.json': {
      dashboard_name: 'NSGs',
      description: 'Security Group Rules Allowing Inbound MsSQL Access',
      rationale:
        'MsSQL (1433) inbound access should not be allowed to a network security group.',
    },
    'appservice-ftp-deployment-enabled.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/deploy-ftp',
        'https://docs.microsoft.com/en-us/azure/app-service/overview-security',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-4-encrypt-sensitive-information-in-transit',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-identity-management#im-1-standardize-azure-active-directory-as-the-central-identity-and-authentication-system',
      ],
      description: 'FTP Deployment Enabled',
      rationale:
        'Azure FTP deployment endpoints are public. An attacker listening to traffic on a wifi network used by a remote employee or a corporate network could see login traffic in clear-text which would then grant them full control of the code base of the app or service. This finding is more severe if User Credentials for deployment are set at the subscription level rather than using the default Application Credentials which are unique per App.',
    },
    'appservice-outdated-version-python.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
      ],
      description: 'Web App Running an Outdated Python Version',
      rationale:
        'Periodically, newer versions are released for Python software either due to security flaws or to include additional functionality. Using the latest Python version for web apps is recommended in order to take advantage of security fixes, if any, and/or additional functionalities of the newer version.',
    },
    'network-security-groups-rule-inbound-internet-all.json': {
      dashboard_name: 'NSGs',
      description: 'Security Group Rules Allowing All Inbound Access',
      rationale:
        'It was detected that all ports in the security group are open, and accessible from the Internet, which creates a wider attack surface for resources assigned to it. Open ports should be reduced to the minimum needed to correctly operate and, when possible, source address restrictions should be implemented.',
    },
    'network-security-groups-rule-inbound-service.json': {
      dashboard_name: 'NSGs',
      arg_names: [
        'Protocol (ex: SSH, RDP, etc.)',
        "Protocol's port",
        'Associated CIS rule',
      ],
      references: [
        'https://docs.microsoft.com/en-us/azure/vpn-gateway/vpn-gateway-howto-point-to-site-resource-manager-portal',
        'https://docs.microsoft.com/en-us/azure/vpn-gateway/tutorial-site-to-site-portal',
        'https://docs.microsoft.com/en-us/azure/expressroute/',
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/network-best-practices#disable-rdpssh-access-to-azure-virtual-machines',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-network-security',
      ],
      description: 'Security Group Rules Allowing Inbound _ARG_0_ Access',
      rationale:
        '_ARG_0_ (port _ARG_1_) inbound access should not be allowed to a network security group.',
    },
    'postgresql-database-servers-log-retention-days-less-than-4.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Log Retention Days Less Than 4',
      rationale:
        'Enabling <samp>log_retention_days</samp> helps PostgreSQL Database to <samp>Sets number of days a log file is retained</samp> which in turn generates query and error logs. Query and error logs can be used to identify, troubleshoot, and repair configuration errors and sub-optimal performance.',
    },
    'appservice-managed-service-identities-disabled.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-gb/azure/app-service/app-service-web-tutorial-connect-msi',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-identity-management#im-1-standardize-azure-active-directory-as-the-central-identity-and-authentication-system',
      ],
      description: 'Managed Service Identities Disabled',
      rationale:
        'App Service provides a highly scalable, self-patching web hosting service in Azure. It also provides a managed identity for apps, which is a turn-key solution for securing access to Azure SQL Database and other Azure services.',
    },
    'virtual-machines-os-data-encrypted-cmk.json': {
      dashboard_name: 'Disks',
      references: [
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/azure-disk-encryption-vms-vmss',
        'https://docs.microsoft.com/en-us/azure/security-center/asset-inventory?toc=%2Fazure%2Fsecurity%2Ftoc.json',
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/data-encryption-best-practices#protect-data-at-rest',
        'https://docs.microsoft.com/en-us/rest/api/compute/disks/delete',
        'https://docs.microsoft.com/en-us/rest/api/compute/disks/update#encryptionsettings',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-5-encrypt-sensitive-data-at-rest',
      ],
      description: 'OS And Data Disks Not Encrypted With CMK',
      rationale:
        "Encrypting the IaaS VM's OS disk (boot volume), Data disks (non-boot volume) ensures that the entire content is fully unrecoverable without a key and thus protects the volume from unwarranted reads. CMK is superior encryption although requires additional planning.",
    },
    'appservice-tls-v1-supported.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/app-service-web-tutorial-custom-ssl#enforce-tls-versions',
      ],
      description: 'Insecure TLS Version Supported',
      rationale:
        'The TLS (Transport Layer Security) protocol secures transmission of data over the internet using standard encryption technology. Encryption should be set with the latest version of TLS. App Service allows TLS 1.2 by default, which is the recommended TLS level by industry standards, such as PCI DSS.',
    },
    'sqldatabase-databases-auditing-low-retention.json': {
      dashboard_name: 'SQL Databases',
      references: [
        'https://docs.microsoft.com/en-us/azure/sql-database/sql-database-auditing',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/get-azurermsqlserverauditing?view=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/set-azurermsqlserverauditing?view=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-6-configure-log-storage-retention',
      ],
      description: 'Short Auditing Retention Period for SQL Databases',
      rationale:
        'Auditing retention period should be greater than _ARG_0_ days. Audit Logs can be used to check for anomalies and give insight into suspected breaches or misuse of information and access.',
    },
    'postgresql-database-servers-log-connections-not-on.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Log Connections Not Set To "ON"',
      rationale:
        'Enabling <samp>log_connections</samp> helps PostgreSQL Database to log attempted connection to the server, as well as successful completion of client authentication. Log data can be used to identify, troubleshoot, and repair configuration errors and suboptimal performance.',
    },
    'config-parser.py': {},
    'sqldatabase-databases-threat-detection-send-alerts-disabled.json': {
      dashboard_name: 'SQL Databases',
      description: 'Send Threat Detection Alerts Disabled for SQL Databases',
      rationale:
        'Specify email addresses and ensure that alerts are sent to them.',
    },
    'virtual-machines-unattached-disks-encrypted-cmk.json': {
      dashboard_name: 'Disks',
      references: [
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/azure-disk-encryption-vms-vmss',
        'https://docs.microsoft.com/en-us/azure/security-center/asset-inventory?toc=%2Fazure%2Fsecurity%2Ftoc.json',
        'https://docs.microsoft.com/en-us/rest/api/compute/disks/delete',
        'https://docs.microsoft.com/en-us/cli/azure/disk?view=azure-cli-latest#az-disk-delete',
        'https://docs.microsoft.com/en-us/rest/api/compute/disks/update#encryptionsettings',
        'https://docs.microsoft.com/en-us/cli/azure/disk?view=azure-cli-latest#az-disk-update',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-5-encrypt-sensitive-data-at-rest',
      ],
      description: 'Unattached Disks Not Encrypted With CMK',
      rationale:
        "Managed disks are encrypted by default with Platform-managed keys. Using Customer-managed keys may provide an additional level of security or meet an organization's regulatory requirements. Encrypting managed disks ensures that its entire content is fully unrecoverable without a key and thus protects the volume from unwarranted reads. Even if the disk is not attached to any of the VMs, there is always a risk where a compromised user account with administrative access to VM service can mount/attach these data disks which may lead to sensitive information disclosure and tampering.",
    },
    'sqldatabase-databases-no-auditing.json': {
      dashboard_name: 'SQL Databases',
      references: [
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-enable-auditing-on-sql-servers',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/get-azurermsqlserverauditing?view=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/set-azurermsqlserverauditingpolicy?view=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/azure/sql-database/sql-database-auditing',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Auditing Disabled for SQL Databases',
      rationale:
        'Auditing tracks database events and writes them to an audit log in the Azure storage account. It also helps to maintain regulatory compliance, understand database activity, and gain insight into discrepancies and anomalies that could indicate business concerns or suspected security violations.',
    },
    'securitycenter-settings-WDATP-integration-disabled.json': {
      dashboard_name: 'Security Settings',
      references: [
        'https://docs.microsoft.com/en-in/azure/security-center/security-center-wdatp',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/settings/list',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/settings/update',
      ],
      description: 'Windows Defender ATP (WDATP) Is Disabled',
      rationale:
        'WDATP integration brings comprehensive Endpoint Detection and Response (EDR) capabilities within security center. This integration helps to spot abnormalities, detect and respond to advanced attacks on Windows server endpoints monitored by Azure Security Center. Windows Defender ATP in Security Center supports detection on Windows Server 2016, 2012 R2, and 2008 R2 SP1 operating systems in a Standard service subscription.',
    },
    'logging-monitoring-log-alert-not-exist-create-policy-assignment.json': {
      dashboard_name: 'Alert Rules',
      references: [
        'https://azure.microsoft.com/en-us/updates/classic-alerting-monitoring-retirement/',
        'https://docs.microsoft.com/en-in/azure/azure-monitor/alerts/alerts-activity-log',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/createorupdate',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/listbysubscriptionid',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description:
        'Activity Log Alert Does Not Exist For Create Policy Assignment',
      rationale:
        'Monitoring for "Create Policy Assignment" events gives insight into changes done in "azure policy -assignments" and can reduce the time it takes to detect unsolicited changes.',
    },
    'network-watcher-not-enabled.json': {
      dashboard_name: 'Network Configurations',
      references: [
        'https://docs.microsoft.com/en-us/azure/network-watcher/network-watcher-monitoring-overview',
        'https://docs.azure.cn/zh-cn/cli/network/watcher?view=azure-cli-latest',
        'https://docs.microsoft.com/en-us/azure/network-watcher/network-watcher-create',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-asset-management#am-2-ensure-security-team-has-access-to-asset-inventory-and-metadata',
      ],
      description: 'Network Watchers Not Enabled',
      rationale: 'Network watchers should be enabled.',
    },
    'virtual-machines-extensions-installed.json': {
      dashboard_name: 'Instances',
      references: [
        'https://docs.microsoft.com/en-us/azure/virtual-machines/windows/extensions-features',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-endpoint-security',
      ],
      description: 'Virtual Machine Extensions Installed',
      rationale:
        'Azure virtual machine extensions are small applications that provide post-deployment configuration and automation tasks on Azure virtual machines. These extensions run with administrative privileges and could potentially access anything on a virtual machine. The Azure Portal and community provide several such extensions.',
    },
    'sqldatabase-servers-auditing-low-retention.json': {
      dashboard_name: 'SQL Servers',
      description: 'Short Auditing Retention Period for SQL Servers',
      rationale:
        'Auditing retention period should be greater than _ARG_0_ days.',
    },
    'sqldatabase-servers-vulnerability-recurring-scans-disabled.json': {
      dashboard_name: 'SQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/sql-vulnerability-assessment',
        'https://docs.microsoft.com/en-us/rest/api/sql/servervulnerabilityassessments/listbyserver',
        'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Update-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
        'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Get-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-posture-vulnerability-management#pv-6-perform-software-vulnerability-assessments',
      ],
      description: 'Periodic Recurring Scans Is Disabled on SQL Servers',
      rationale:
        'ADS -VA setting "Periodic recurring scans" schedules periodic (weekly) vulnerability scanning for the SQL server and corresponding Databases. Periodic and regular vulnerability scanning provides risk visibility based on updated known vulnerability signatures and best practices.',
    },
    'logging-monitoring-log-alert-not-exist-nsg.json': {
      arg_names: ['Event', 'Associated CIS rule', 'Dictionary Value'],
      dashboard_name: 'Alert Rules',
      references: [
        'https://azure.microsoft.com/en-us/updates/classic-alerting-monitoring-retirement/',
        'https://docs.microsoft.com/en-in/azure/azure-monitor/alerts/alerts-activity-log',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/createorupdate',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/listbysubscriptionid',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Activity Log Alert Does Not Exist For _ARG_0_',
      rationale:
        'Monitoring for "_ARG_0_" events gives insight into network access changes and may reduce the time it takes to detect suspicious activity.',
    },
    'aad-users-create-security-groups-disabled.json': {
      dashboard_name: 'Policies',
      description: 'Users Can Create Security Group Enabled',
      rationale:
        'When creating security groups is enabled, all users in the directory are allowed to create new security groups and add members to those groups. Security group creation should be restricted to administrators only.',
    },
    'appservice-webapp-using-outdated-progamming-language-version.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
      ],
      description:
        '(WIP) Web App Not Using Latest Programming Language Version',
      rationale: '',
    },
    'sqldatabase-servers-threat-detection-low-retention.json': {
      dashboard_name: 'SQL Servers',
      description: 'Short Threat Detection Retention Period for SQL Servers',
      rationale:
        'Threat detection retention period should be greater than _ARG_0_ days.',
    },
    'postgresql-database-servers-log-duration-not-on.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Log Duration Not Set To "ON"',
      rationale:
        'Enabling <samp>log_duration</samp> helps the PostgreSQL Database to <samp>Logs the duration of each completed SQL statement</samp> which in turn generates query and error logs. Query and error logs can be used to identify, troubleshoot, and repair configuration errors and sub-optimal performance.',
    },
    'aad-guest-users.json': {
      dashboard_name: 'Users',
      description: 'Guest Users in Use',
      rationale:
        'Avoid creating guest users, as they are typically added outside your employee on-boarding/off-boarding process and could potentially be overlooked indefinitely leading to a potential vulnerability.',
    },
    'sqldatabase-servers-threat-detection-send-alerts-disabled.json': {
      dashboard_name: 'SQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/sql-database/sql-advanced-threat-protection',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/azure-defender-for-sql',
      ],
      description:
        'Send Advanced Threat Protection Alerts Disabled for SQL Servers',
      rationale:
        'Providing the email address and enable Administrator and subscription owner to receive alerts ensures that any detection of anomalous activities is reported as soon as possible, making it more likely to mitigate any potential risk sooner.',
    },
    'storageaccount-account-allowing-clear-text.json': {
      dashboard_name: 'Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-security-guide',
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-require-secure-transfer',
        'https://docs.microsoft.com/en-us/azure/storage/blobs/security-recommendations#encryption-in-transit',
        'https://docs.microsoft.com/en-us/cli/azure/storage/account?view=azure-cli-latest#az_storage_account_list',
        'https://docs.microsoft.com/en-us/cli/azure/storage/account?view=azure-cli-latest#az_storage_account_update',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-4-encrypt-sensitive-information-in-transit',
      ],
      description: 'Secure Transfer (HTTPS) Not Enforced',
      rationale:
        'The secure transfer option enhances the security of a storage account by only allowing requests to the storage account by a secure connection.<br><br>For example, when calling REST APIs to access storage accounts, the connection must use HTTPS. Any requests using HTTP will be rejected when "secure transfer required" is enabled. When using the Azure files service, connection without encryption will fail, including scenarios using SMB 2.1, SMB 3.0 without encryption, and some flavors of the Linux SMB client.<br><br>Because Azure storage does not support HTTPS for custom domain names, this option is not applied when using a custom domain name.',
    },
    'postgresql-database-servers-connection-throttling-not-on.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Connection Throttling Not Set To "ON"',
      rationale:
        'Enabling <samp>connection_throttling</samp> helps the PostgreSQL Database to <samp>Set the verbosity of logged messages</samp> which in turn generates query and error logs with respect to concurrent connections, that could lead to a successful Denial of Service (DoS) attack by exhausting connection resources. A system can also fail or be degraded by an overload of legitimate users. Query and error logs can be used to identify, troubleshoot, and repair configuration errors and sub-optimal performance.',
    },
    'securitycenter-auto-provisioning-off.json': {
      dashboard_name: 'Security Center',
      references: [
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-data-security',
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-enable-data-collection',
        'https://docs.microsoft.com/en-us/previous-versions/azure/reference/mt704062(v=azure.100)?redirectedfrom=MSDN',
        'https://docs.microsoft.com/en-us/previous-versions/azure/reference/mt704063(v=azure.100)?redirectedfrom=MSDN',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/autoprovisioningsettings/list',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/autoprovisioningsettings/create',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-posture-vulnerability-management#pv-6-perform-software-vulnerability-assessments',
      ],
      description: 'No Automatic Provisioning of Monitoring Agent',
      rationale:
        'When Automatic provisioning of monitoring agentis turned on, Azure Security Center provisions the Microsoft Monitoring Agent on all existing supported Azure virtual machines and any new ones that are created. The Microsoft Monitoring Agent scans for various security-related configurations and events such as system updates, OS vulnerabilities, endpoint protection, and provides alerts.',
    },
    'sqldatabase-servers-vulnerability-email-notif-to-admins-owners-not-set.json':
      {
        dashboard_name: 'SQL Servers',
        references: [
          'https://docs.microsoft.com/en-us/azure/azure-sql/database/sql-vulnerability-assessment',
          'https://docs.microsoft.com/en-us/rest/api/sql/servervulnerabilityassessments/listbyserver',
          'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Update-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
          'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Get-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
          'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-posture-vulnerability-management#pv-6-perform-software-vulnerability-assessments',
        ],
        description:
          'Send Email Notifications To Admins And Subscription Owners Is Not Set on SQL Servers',
        rationale:
          'ADS -VA scan reports and alerts will be sent to admins and subscription owners by enabling setting "Also send email notifications to admins and subscription owners". This may help in reducing time required for identifying risks and taking corrective measures.',
      },
    'securitycenter-security-contacts-not-set.json': {
      dashboard_name: 'Security Center',
      description: 'No Security Contact Set',
      rationale: 'Set at least one security contact.',
    },
    'mysql-database-servers-ssl-enforcement-disabled.json': {
      dashboard_name: 'MySQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/postgresql/concepts-ssl-connection-security',
        'https://docs.microsoft.com/en-us/azure/mysql/howto-configure-ssl',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-4-encrypt-sensitive-information-in-transit',
      ],
      description:
        'Enforce SSL Connection Is Disabled For MySQL Database Server',
      rationale:
        '<samp>SSL connectivity</samp> helps to provide a new layer of security, by connecting database server to client applications using Secure Sockets Layer (SSL). Enforcing SSL connections between database server and client applications helps protect against "man in the middle" attacks by encrypting the data stream between the server and application.',
    },
    'appservice-outdated-version-java.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-endpoint-security',
      ],
      description: 'Web App Running an Outdated Java Version',
      rationale:
        'Periodically, newer versions are released for Java software either due to security flaws or to include additional functionality. Using the latest Java version for web apps is recommended in order to to take advantage of security fixes, if any, and/or new functionalities of the latest version.',
    },
    'storageaccount-encrypted-not-customer-managed.json': {
      dashboard_name: 'Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-service-encryption',
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/data-encryption-best-practices#protect-data-at-rest',
      ],
      description: 'Storage not encrypted with Customer Managed Key',
      rationale:
        'By default, data in the storage account is encrypted using Microsoft Managed Keys at rest.<br><br>If sensitive information is stored, it should be encrypted using either Server-side Customer-Managed keys or Client-side Encryption. In the case of Client-side Encryption, it is difficult to decipher if the customer loses the key.<br><br>',
    },
    'sqldatabase-databases-no-threat-detection.json': {
      dashboard_name: 'SQL Databases',
      description: 'Threat Detection Disabled for SQL Databases',
      rationale: 'Enable threat detection for all of SQL databases.',
    },
    'sqldatabase-servers-no-ad-admin-configured.json': {
      dashboard_name: 'SQL Databases',
      references: [
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/authentication-aad-configure?tabs=azure-powershell',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/authentication-aad-overview',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/get-azurermsqlserveractivedirectoryadministrator?view=azurermps-6.13.0&viewFallbackFrom=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/set-azurermsqlserveractivedirectoryadministrator?view=azurermps-6.13.0&viewFallbackFrom=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-identity-management#im-1-standardize-azure-active-directory-as-the-central-identity-and-authentication-system',
      ],
      description:
        'Azure Active Directory Admin Not Configured for SQL Servers',
      rationale:
        'Azure Active Directory authentication is a mechanism to connect to Microsoft Azure SQL Database and SQL Data Warehouse by using identities in Azure Active Directory (Azure AD). With Azure AD authentication, identities of database users and other Microsoft services can be managed in one central location. Central ID management provides a single place to manage database users and simplifies permission management.',
    },
    'logging-monitoring-log-alert-not-exist-security-solution.json': {
      arg_names: ['Event', 'Associated CIS rule', 'Dictionary Value'],
      dashboard_name: 'Alert Rules',
      references: [
        'https://azure.microsoft.com/en-us/updates/classic-alerting-monitoring-retirement/',
        'https://docs.microsoft.com/en-in/azure/azure-monitor/alerts/alerts-activity-log',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/createorupdate',
        'https://docs.microsoft.com/en-in/rest/api/monitor/activitylogalerts/listbysubscriptionid',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Activity Log Alert Does Not Exist For _ARG_0_',
      rationale:
        'Monitoring for "_ARG_0_" events gives insight into changes to the active security solutions and may reduce the time it takes to detect suspicious activity.',
    },
    'network-security-groups-rule-inbound-service-udp.json': {
      dashboard_name: 'NSGs',
      references: [
        'https://docs.microsoft.com/en-us/azure/vpn-gateway/vpn-gateway-howto-point-to-site-resource-manager-portal',
        'https://docs.microsoft.com/en-us/azure/vpn-gateway/tutorial-site-to-site-portal',
        'https://docs.microsoft.com/en-us/azure/expressroute/',
        'https://docs.microsoft.com/en-us/azure/security/fundamentals/network-best-practices',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-network-security#ns-1-implement-security-for-internal-traffic',
      ],
      description: 'Security Group Rules Allowing Inbound UDP Access',
      rationale:
        'UDP inbound access should not be allowed to a network security group.',
    },
    'virtual-machines-managed-disks.json': {
      dashboard_name: 'Instances',
      references: [
        'https://docs.microsoft.com/en-us/azure/virtual-machines/windows/convert-unmanaged-to-managed-disks',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-1-define-asset-management-and-data-protection-strategy',
      ],
      description: 'Virtual Machines Not Utilizing Managed Disks',
      rationale:
        'Managed disks are by default encrypted on the underlying hardware so no additional encryption is required for basic protection, it is available if additional encryption is required. Managed disks are by design more resilient that storage accounts.',
    },
    'logging-monitoring-logging-key-vault-disabled.json': {
      dashboard_name: 'Key Vaults',
      references: [
        'https://docs.microsoft.com/en-us/azure/key-vault/general/logging?tabs=Vault',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Logging For Azure Key Vault Is Disabled',
      rationale:
        'Monitoring how and when key vaults are accessed, and by whom enables an audit trail of interactions with confidential information, keys and certificates managed by Azure Keyvault. Enabling logging for Key Vault saves information in an Azure storage account that the user provides. This creates a new container named insights-logs-auditevent automatically for the specified storage account, andthis same storage account can be used for collecting logs for multiple key vaults.',
    },
    'virtual-machines-disk-encryption.json': {
      dashboard_name: 'Disks',
      references: [
        'https://docs.microsoft.com/en-us/azure/security/azure-security-disk-encryption-overview',
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-apply-disk-encryption',
      ],
      description: 'Disks Lacking Encryption',
      rationale:
        'Encrypting disks ensures that their entire content is fully unrecoverable without a key and thus protects the volume from unwarranted reads.',
    },
    'appservice-outdated-version-php.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-endpoint-security',
      ],
      description: 'Web App Running an Outdated PHP Version',
      rationale:
        'Periodically newer versions are released for PHP software either due to security flaws or to include additional functionality. Using the latest PHP version for web apps is recommended in order to take advantage of security fixes, if any, and/or additional functionalities of the newer version.',
    },
    'sqldatabase-servers-TDE-not-encrypted-with-customer-managed-key.json': {
      dashboard_name: 'SQL Databases',
      references: [
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/transparent-data-encryption-byok-overview?view=sql-server-ver15',
        'https://azure.microsoft.com/en-in/blog/preview-sql-transparent-data-encryption-tde-with-bring-your-own-key-support/',
        'https://winterdom.com/2017/09/07/azure-sql-tde-protector-keyvault',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/transparent-data-encryption-byok-overview?view=sql-server-ver15',
      ],
      description:
        'SQL Server TDE Protector Not Encrypted With Customer-Managed Key',
      rationale:
        'Customer-managed key support for Transparent Data Encryption (TDE) allows user control of TDE encryption keys and restricts who can access them and when. Azure Key Vault, Azure’s cloud-based external key management system is the first key management service where TDE has integrated support for Customer-managed keys. With Customer-managed key support, the database encryption key is protected by an asymmetric key stored inthe Key Vault. The asymmetric key is set at the server level and inherited by all databases under that server.',
    },
    'securitycenter-security-contacts-email-not-set.json': {
      dashboard_name: 'Security contacts',
      references: [
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-provide-security-contact-details',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/securitycontacts/list',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-3-define-security-posture-management-strategy',
      ],
      description: 'No Security Contact Email Set',
      rationale:
        'Azure Security Center emails the Subscription Owner to notify them about security alerts. Adding your Security Contact\'s email address to the "Additional email addresses" field ensures that your organization\'s Security Team is included in these alerts. This ensures that the proper people are aware of any potential compromise in order to mitigate the risk in a timely fashion.',
    },
    'appservice-authentication-disabled.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/app-service-authentication-overview',
        'https://docs.microsoft.com/en-us/azure/role-based-access-control/built-in-roles#website-contributor',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-privileged-access#pa-5-automate-entitlement-management',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-6-define-identity-and-privileged-access-strategy',
      ],
      description: 'App Service Authentication Disabled',
      rationale:
        'Azure App Service Authentication is a feature that can prevent anonymous HTTP requests from reaching the API app, or authenticate those that have tokens before they reach the API app. If an anonymous request is received from a browser, App Service will redirect to a logon page. To handle the logon process, a choice from a set of identity providers can be made, or a custom authentication mechanism can be implemented.',
    },
    'sqldatabase-servers-threat-detection-disabled-alerts.json': {
      dashboard_name: 'SQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/sql-database/sql-advanced-threat-protection',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/azure-defender-for-sql',
      ],
      description: 'Advanced Threat Protection Disabled Types for SQL servers',
      rationale:
        'Enabling all threat protection types protects against SQL injection, database vulnerabilities, and any other anomalous activities.',
    },
    'keyvault-not-recoverable.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/key-vault/key-vault-soft-delete-cli',
        'https://blogs.technet.microsoft.com/kv/2017/05/10/azure-key-vault-recovery-options/',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-8-define-backup-and-recovery-strategy',
      ],
      description: 'Key Vault Not Recoverable',
      rationale:
        'There could be scenarios where users accidently run delete/purge commands on key vault or attacker/malicious user does it deliberately to cause disruption. Deleting or purging a key vault leads to immediate data loss as keys encrypting data and secrets/certificates allowing access/services will become non-accessible.',
    },
    'postgresql-database-servers-log-checkpoints-not-on.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Log Checkpoints Not Set To "ON"',
      rationale:
        'Enabling <samp>log_checkpoints</samp> helps the PostgreSQL Database to Log each checkpoint in turn generates query and error logs. However, access to transaction logs is not supported. Query and error logs can be used to identify, troubleshoot, and repair configuration errors and sub-optimal performance.',
    },
    'sqldatabase-databases-no-transparent-data-encryption.json': {
      dashboard_name: 'SQL Databases',
      references: [
        'https://docs.microsoft.com/en-us/sql/relational-databases/security/encryption/transparent-data-encryption-with-azure-sql-database',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-5-encrypt-sensitive-data-at-rest',
      ],
      description: 'Data Encryption Disabled for SQL Databases',
      rationale:
        'Azure SQL Database transparent data encryption helps protect against the threat of malicious activity by performing real-time encryption and decryption of the database, associated backups, and transaction log files at rest without requiring changes to the application.',
    },
    'logging-monitoring-diagnostic-setting-does-not-exist.json': {
      dashboard_name: 'Diagnostic Settings',
      references: [
        'https://docs.microsoft.com/en-us/azure/azure-monitor/essentials/platform-logs-overview#export-the-activity-log-with-a-log-profile',
        'https://docs.microsoft.com/en-us/cli/azure/monitor/log-profiles?view=azure-cli-latest#az_monitor_log_profiles_create',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-5-centralize-security-log-management-and-analysis',
      ],
      description: 'Diagnostic Setting Does Not Exist',
      rationale:
        'A diagnostic setting controls how a diagnostic log is exported. By default, logs are retained only for 90 days. Diagnostic settings should be defined so that logs can be exported and stored for a longer duration in order to analyze security activities within an Azure subscription.',
    },
    'securitycenter-security-contacts-no-admin-email-notifications.json': {
      dashboard_name: 'Security contacts',
      references: [
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-provide-security-contact-details',
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/securitycontacts/list',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy#gs-3-define-security-posture-management-strategy',
      ],
      description: '"Sending Email to Security Contact on Alert" Is "Off"',
      rationale:
        'Enabling security alert emails to subscription owners ensures that they receive security alert emails from Microsoft. This ensures that they are aware of any potential security issues and can mitigate the risk in a timely fashion.',
    },
    'appservice-outdated-version-dotnet.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-endpoint-security',
      ],
      description: 'Web App Running an Outdated .Net Framework Version',
      rationale:
        'Periodically, newer versions are released for .Net Framework software either due to security flaws or to include additional functionality. Using the latest version for web apps is recommended in order to take advantage of security fixes, if any, and/or additional functionalities of the newer version.',
    },
    'securitycenter-standard-tier-not-enabled.json': {
      dashboard_name: 'Pricings',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/securitycenter/pricings/list',
        'https://docs.microsoft.com/en-us/azure/security-center/security-center-alerts-overview',
      ],
      description: 'Standard Tier Not Enabled',
      rationale:
        'Enabling Azure Defender allows for greater defense-in-depth, with threat detection provided by the Microsoft Security Response Center (MSRC).',
    },
    'rbac-administering-resource-locks-assigned.json': {
      dashboard_name: 'Roles',
      references: [
        'https://docs.microsoft.com/en-us/azure/role-based-access-control/custom-roles',
        'https://docs.microsoft.com/en-us/azure/role-based-access-control/check-access',
      ],
      description: 'No Administering Resource Locks Role',
      rationale:
        'Given the resource lock functionality is outside of standard Role Based Access Control(RBAC), it would be prudent to create a resource lock administrator role to prevent inadvertent unlocking of resources.',
    },
    'sqldatabase-servers-vulnerability-send-scan-reports-to-not-configured.json':
      {
        dashboard_name: 'SQL Servers',
        references: [
          'https://docs.microsoft.com/en-us/azure/azure-sql/database/sql-vulnerability-assessment',
          'https://docs.microsoft.com/en-us/rest/api/sql/servervulnerabilityassessments/listbyserver',
          'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Update-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
          'https://docs.microsoft.com/en-in/powershell/module/Az.Sql/Get-AzSqlServerVulnerabilityAssessmentSetting?view=azps-5.5.0&viewFallbackFrom=azps-2.6.0',
          'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-posture-vulnerability-management#pv-6-perform-software-vulnerability-assessments',
        ],
        description: 'Send Scan Report To Is Not Configured on SQL Servers',
        rationale:
          'ADS -VA scan reports and alerts will be sent to email ids configured at "Send scan reports to". This may help in reducing time required for identifying risks and taking corrective measures.',
      },
    'appservice-client-certificates-disabled.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/bs-latn-ba/azure/app-service/app-service-web-configure-tls-mutual-auth',
      ],
      description: 'Client Certificates Disabled',
      rationale:
        'Client certificates allow for the app to request a certificate for incoming requests. Only clients that have a valid certificate will be able to reach the app. The TLS mutual authentication technique in enterprise environments ensures the authenticity of clients to the server. If incoming client certificates are enabled, then only an authenticated client who has valid certificates can access the app.',
    },
    'sqldatabase-databases-threat-detection-disabled-alerts.json': {
      dashboard_name: 'SQL Databases',
      description: 'Threat Detection Alerts Disabled for SQL Databases',
      rationale: 'Enable alerts related to threat detections.',
    },
    'postgresql-database-servers-ssl-enforcement-disabled.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/azure/postgresql/concepts-ssl-connection-security',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-data-protection#dp-4-encrypt-sensitive-information-in-transit',
      ],
      description:
        'Enforce SSL Connection Is Disabled For PostgreSQL Database Server',
      rationale:
        '<samp>SSL connectivity</samp> helps to provide a new layer of security, by connecting database server to client applications using Secure Sockets Layer (SSL). Enforcing SSL connections between database server and client applications helps protect against "man in the middle" attacks by encrypting the data stream between the server and application.',
    },
    'postgresql-database-servers-allow-any-ip.json': {
      dashboard_name: 'PostgreSQL Firewall Rules',
      references: [
        'https://docs.microsoft.com/en-us/azure/postgresql/concepts-firewall-rules',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-manage-firewall-using-cli',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-network-security#ns-1-implement-security-for-internal-traffic',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-network-security#ns-4-protect-applications-and-services-from-external-network-attacks',
      ],
      description: 'PostgreSQL Database Allow Ingress 0.0.0.0/0 (Any IP)',
      rationale:
        "If access from Azure services is enabled, the server's firewall will accept connections from all Azure resources, including resources not in your subscription. This is usually not a desired configuration. Instead, setup firewall rules to allow access from specific network ranges or VNET rules to allow access from specific virtual networks.",
    },
    'storageaccount-public-blob-container.json': {
      dashboard_name: 'Storage Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/blobs/storage-manage-access-to-resources',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-privileged-access',
      ],
      description: 'Blob Containers Allowing Public Access',
      rationale:
        'Anonymous, public read access to a container and its blobs can be enabled in Azure Blob storage. It grants read-only access to these resources without sharing the account key, and without requiring a shared access signature.<br><br>It is recommended not to provide anonymous access to blob containers until, and unless, it is strongly desired. A shared access signature token should be used for providing controlled and timed access to blob containers.',
    },
    'storageaccount-access-keys-not-rotated.json': {
      dashboard_name: 'Storage Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/common/storage-create-storage-account',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-privileged-access',
      ],
      description: 'Access Keys Not Rotated',
      rationale:
        'When a storage account is created, Azure generates two 512-bit storage access keys, which are used for authentication when the storage account is accessed. Rotating these keys periodically ensures that any inadvertent access or exposure does not result in these keys being compromised.<br><br>The access keys storage accounts should be rotated at least every _ARG_0_ days.',
    },
    'storageaccount-soft-delete-enabled.json': {
      dashboard_name: 'Accounts',
      references: [
        'https://docs.microsoft.com/en-us/azure/storage/blobs/storage-blob-soft-delete',
      ],
      description: 'Storage Account Soft Delete Disabled',
      rationale:
        'Enabling this configuration for azure storage ensures that even if blobs/data were deleted from the storage account, Blobs/data objects remain recoverable for a particular time which set in the "Retention policies" ',
    },
    'logging-monitoring-profile-does-not-capture-all-activities.json': {
      dashboard_name: 'Profile',
      references: [
        'https://docs.microsoft.com/en-us/cli/azure/monitor/log-profiles?view=azure-cli-latest#az-monitor-log-profiles-update',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Audit Profile Does Not Capture All Activities',
      rationale:
        'A log profile controls how the activity log is exported. Configuring the log profile to collect logs for the categories "write", "delete" and "action" ensures that all the control/management plane activities performed on the subscription are exported.',
    },
    'sqldatabase-allow-any-ip.json': {
      dashboard_name: 'Firewall Rules',
      references: [
        'https://docs.microsoft.com/en-us/sql/database-engine/configure-windows/configure-a-windows-firewall-for-database-engine-access?view=sql-server-2017',
        'https://docs.microsoft.com/en-us/powershell/module/azurerm.sql/get-azurermsqlserverfirewallrule?view=azurermps-6.13.0&viewFallbackFrom=azurermps-5.2.0',
        'https://docs.microsoft.com/en-us/azure/azure-sql/database/firewall-configure',
        'https://docs.microsoft.com/en-us/sql/relational-databases/system-stored-procedures/sp-set-database-firewall-rule-azure-sql-database?view=azuresqldb-current',
      ],
      description: 'SQL Database Allow Ingress 0.0.0.0/0 (Any IP)',
      rationale:
        'SQL Server includes a firewall to block access to unauthorized connections. More granular IP addresses can be defined by referencing the range of addresses available from specific datacenters. In order to reduce the potential attack surface for a SQL server, firewall rules should be defined with more granular IP addresses by referencing the range of addresses available from specific datacenters.',
    },
    'appservice-http-allowed.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/app-service-web-tutorial-custom-ssl#enforce-https',
      ],
      description: 'HTTP Traffic Allowed',
      rationale:
        'Azure Web Apps allows sites to run under both HTTP and HTTPS by default. Web apps can be accessed by anyone using non-secure HTTP links by default. Non-secure HTTP requests can be restricted and all HTTP requests redirected to the secure HTTPS port.',
    },
    'rbac-custom-subscription-owner-role-not-allowed.json': {
      dashboard_name: 'Roles',
      references: [
        'https://docs.microsoft.com/en-us/azure/cost-management-billing/manage/add-change-subscription-administrator',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-governance-strategy',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-privileged-access',
      ],
      description: 'Custom Subscription Owner Role Not Allowed',
      rationale:
        'Classic subscription admin roles offer basic access management and include Account Administrator, Service Administrator, and Co-Administrators. It is recommended the least necessary permissions be given initially. Permissions can be added as needed by the account holder. This ensures the account holder cannot perform actions which were not intended.',
    },
    'postgresql-database-servers-log-disconnections-not-on.json': {
      dashboard_name: 'PostgreSQL Servers',
      references: [
        'https://docs.microsoft.com/en-us/rest/api/postgresql/configurations/listbyserver',
        'https://docs.microsoft.com/en-us/azure/postgresql/howto-configure-server-parameters-using-portal',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-logging-threat-detection#lt-4-enable-logging-for-azure-resources',
      ],
      description: 'Server Parameter Log Disconnections Not Set To "ON"',
      rationale:
        'Enabling <samp>log_disconnections</samp>helps PostgreSQL Database to <samp>Logs end of a session</samp>, including duration, which in turn generates query and error logs. Query and error logs can be used to identify, troubleshoot, and repair configuration errors and sub-optimal performance.',
    },
    'appservice-http-2-disabled.json': {
      dashboard_name: 'Web Apps',
      references: [
        'https://docs.microsoft.com/en-us/azure/app-service/web-sites-configure#general-settings',
        'https://docs.microsoft.com/en-us/azure/security/benchmarks/security-controls-v2-endpoint-security',
      ],
      description: 'HTTP 2.0 Disabled',
      rationale:
        "Periodically, newer versions are released for HTTP either due to security flaws or to include additional functionality. Using the latest HTTP version for web apps to take advantage of security fixes, if any, and/or new functionalities of the newer version.<br><br>Newer versions may contain security enhancements and additional functionality. Using the latest version is recommended in order to take advantage of enhancements and new capabilities. With each software installation, organizations need to determine if a given update meets their requirements and also verify the compatibility and support provided for any additional software against the update revision that is selected.<br><br>HTTP 2.0 has additional performance improvements on the head-of-line blocking problem of old HTTP version, header compression, and prioritization of requests. HTTP 2.0 no longer supports HTTP 1.1's chunked transfer encoding mechanism, as it provides its own, more efficient, mechanisms for data streaming.",
    },
    'sqldatabase-servers-no-auditing.json': {
      dashboard_name: 'SQL Servers',
      description: 'Auditing Disabled for SQL Servers',
      rationale: 'Enable auditing for all SQL servers.',
    },
  },
  scoutsuite_gcp: {
    'computeengine-firewall-rule-allows-internal-traffic.json': {
      description: 'Firewall Rule Allows Internal Traffic',
      rationale:
        'Firewall rule allows ingress connections for all protocols and ports among instances in the network.',
      dashboard_name: 'Firewall Rule Elements',
    },
    'computeengine-firewall-rule-opens-all-ports-to-all.json': {
      description:
        'Firewall Rule Allows Public Access (0.0.0.0/0) to All Ports (0-65535)',
      rationale:
        'The firewall rule was found to be exposing all ports to all source addresses. Ports are commonly probed by automated scanning tools, and could be an indicator of sensitive services exposed to Internet. If such services need to be exposed, a restriction on the source address could help to reduce the attack surface of the infrastructure.',
      dashboard_name: 'Firewall Rule Elements',
    },
    'computeengine-instance-ip-forwarding-enabled.json': {
      description: 'IP Forwarding Is Enabled',
      rationale:
        "Compute Engine instance cannot forward a packet unless the source IP address of the packet matches the IP address of the instance. Similarly, GCP won't deliver a packet whose destination IP address is different than the IP address of the instance receiving the packet. However, both capabilities are required if you want to use instances to help route packets.",
      references: [
        'https://cloud.google.com/vpc/docs/using-routes#canipforward',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-instance-default-service-account.json': {
      description: 'Instances Configured To Use Default Service Account',
      rationale:
        'The default Compute Engine service account has the Editor role on the project, which allows read and write access to most Google Cloud Services. To defend against privilege escalations if your VM is compromised and prevent an attacker from gaining access to all of your project, it is recommended to not use the default Compute Engine service account. Instead, you should create a new service account and assigning only the permissions needed by your instance.<br> The default Compute Engine service account is named [PROJECT_NUMBER]-compute@developer.gserviceaccount.com.',
      references: [
        'https://cloud.google.com/compute/docs/access/service-accounts',
        'https://cloud.google.com/compute/docs/access/create-enable-service-accounts-for-instances',
        'https://cloud.google.com/sdk/gcloud/reference/compute/instances/set-service-account',
      ],
      dashboard_name: 'Instances',
    },
    'cloudstorage-bucket-no-versioning.json': {
      description: 'Bucket with Versioning Disabled',
      rationale:
        'Enable Object Versioning to protect Cloud Storage data from being overwritten or accidentally deleted.',
      references: [
        'https://cloud.google.com/storage/docs/using-object-versioning',
      ],
      dashboard_name: 'Buckets',
    },
    'stackdrivermonitoring-alerts-does-not-exist-project-ownership-assignment.json':
      {
        description:
          "Alerts Doesn't Exist For Project Ownership Assignments/Changes",
        rationale:
          'Project ownership has the highest level of privileges on a project. To avoid misuse of project resources, the project ownership assignment/change actions mentioned above should be monitored and alerted to concerned recipients.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
        ],
        dashboard_name: 'Monitoring Alerts',
      },
    'kubernetesengine-cluster-network-policy-disabled.json': {
      description: 'Network Policy Disabled',
      rationale:
        'By default, pods are non-isolated; they accept traffic from any source. Pods become isolated by having a NetworkPolicy that selects them. Once there is any NetworkPolicy in a namespace selecting a particular pod, that pod will reject any connections that are not allowed by any NetworkPolicy.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_with_network_policy',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/security-overview#network_security',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-postgresql-instances-log-min-duration-not-set-1.json': {
      description:
        'Log Min Duration Statement Database Flag For PostgreSQL Instance Is Not Set To -1',
      rationale:
        'Logging SQL statements may include sensitive information that should not be recorded in logs. This recommendation is applicable to PostgreSQL database instances.',
      references: [
        'https://www.postgresql.org/docs/current/runtime-config-logging.html#RUNTIME-CONFIG-LOGGING-WHAT',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'stackdrivermonitoring-alerts-does-not-exist-vpc-network-route-changes.json':
      {
        description: "Alerts Doesn't Exist For VPC Network Route Changes",
        rationale:
          'Google Cloud Platform (GCP) routes define the paths network traffic takes from a VM instance to another destination. The other destination can be inside the organization VPC network (such as another VM) or outside of it. Every route consists of a destination and a next hop. Traffic whose destination IP is within the destination range is sent to the next hop for delivery. Monitoring changes to route tables will help ensure that all VPC traffic flows through an expected path.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs/access-control/iam',
        ],
        dashboard_name: 'Monitoring Alerts',
      },
    'iam-role-account-separation-duties-is-false.json': {
      description: 'Separation Of Duties Not Enforced For Service Account',
      rationale:
        'Separation of duties is the concept of ensuring that one individual does not have all necessary permissions to be able to complete a malicious action. In Cloud IAM-service accounts, this could be an action such as using a service account to access resources that user should not normally have access to. No user should have Service Account Admin and Service Account User roles assigned at the same time.',
      references: [
        'https://cloud.google.com/iam/docs/service-accounts',
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/granting-changing-revoking-access',
      ],
      dashboard_name: 'Project',
    },
    'computeengine-network-legacy-in-use.json': {
      description: 'Legacy Network should be removed',
      rationale:
        'Legacy networks have a single network IPv4 prefix range and a single gateway IP address for the whole network. The network is global in scope and spans all cloud regions. Subnetworks cannot be created in a legacy network and are unable to switch from legacy to auto or custom subnet networks. Legacy networks can have an impact for high network traffic projects and are subject to a single point of contention or failure.',
      dashboard_name: 'Networks',
      references: [
        'https://cloud.google.com/vpc/docs/using-legacy#creating_a_legacy_network',
        'https://cloud.google.com/vpc/docs/using-legacy#deleting_a_legacy_network',
      ],
    },
    'functions-v1-public-endpoint.json': {
      description: 'Public Function Endpoint (Gen 1)',
      rationale:
        "The Cloud Function's ingress configuration allowed all traffic, potentially exposing undesired functionality. It is recommended that traffic reaching functions be routed via a load balancer, to minimize the attack surface.",
      dashboard_name: 'Functions',
    },
    'iam-role-assigned-to-user.json': {
      description: 'IAM Role Assigned to User',
      rationale:
        'Best practices recommends granting roles to a Google Suite group instead of to individual users when possible. It is easier to add members to and remove members from a group instead of updating a Cloud IAM policy to add or remove users.',
      references: [
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/using-iam-securely',
      ],
      dashboard_name: 'Bindings',
    },
    'computeengine-vpc-flow-logs-disabled.json': {
      description: 'VPC Flow Logs Not Enabled',
      rationale:
        'VPC Flow Logs were not enabled for this subnet. It is best practice to enable Flow Logs to some degree in order to have network visibility in the event of resource compromise, as well as source data for threat detections.',
      dashboard_name: 'Subnetwork',
      references: [
        'https://cloud.google.com/vpc/docs/using-flow-logs#enabling_vpc_flow_logging',
      ],
    },
    'computeengine-instance-connecting-serial-ports-enabled.json': {
      description: 'Enable Connecting To Serial Ports Is Enabled',
      rationale:
        'The interactive serial console does not support IP-based access restrictions such as IP allow-lists. If you enable the interactive serial console on an instance, clients can attempt to connect to that instance from any IP address. This allows anybody to connect to that instance if they know the correct SSH key, username, project ID, zone, and instance name.',
      references: [
        'https://cloud.google.com/compute/docs/instances/interacting-with-serial-console',
      ],
      dashboard_name: 'Instances',
    },
    'cloudsql-postgresql-instances-log-disconnections-off.json': {
      description:
        'Log Disconnections Database Flag For PostgreSQL Instance Is Off',
      rationale:
        'PostgreSQL does not log session details such as duration and session end by default. Enabling the log_disconnections setting will create log entries at the end of each session which can be useful in troubleshooting issues and determine any unusual activity across a time period. The log_disconnections and log_connections work hand in hand and generally, the pair would be enabled/disabled together. This recommendation is applicable to PostgreSQL database instances.',
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#RUNTIME-CONFIG-LOGGING-WHAT',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-dashboard-enabled.json': {
      description: 'Kubernetes Dashboard Enabled',
      rationale:
        "You should disable the Kubernetes Web UI (Dashboard) when running on Kubernetes Engine. The Kubernetes Web UI (Dashboard) is backed by a highly privileged Kubernetes Service Account. The Cloud Console provides much of the same functionality, so you don't need this functionality.",
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#disable_kubernetes_dashboard',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'kubernetesengine-node-auto-upgrade-disabled.json': {
      description: 'Nodes with Auto-Upgrade Disabled',
      rationale:
        'Auto-upgrades automatically ensures that security updates are applied and kept up to date.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/node-auto-upgrades',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-sqlservers-instances-contained-database-authentication-on.json': {
      description:
        'Contained Database Authentication Database Flag For SQLServers Instance Is On',
      rationale:
        'A contained database includes all database settings and metadata required to define the database and has no configuration dependencies on the instance of the Database Engine where the database is installed. Users can connect to the database without authenticating a login at the Database Engine level. Isolating the database from the Database Engine makes it possible to easily move the database to another instance of SQL Server. Contained databases have some unique threats that should be understood and mitigated by SQL Server Database Engine administrators. Most of the threats are related to the USER WITH PASSWORD authentication process, which moves the authentication boundary from the Database Engine level to the database level, hence this is recommended to disable this flag.',
      references: [
        'https://cloud.google.com/sql/docs/sqlserver/flags',
        'https://docs.microsoft.com/en-us/sql/database-engine/configure-windows/contained-database-authentication-server-configuration-option?view=sql-server-ver15',
        'https://docs.microsoft.com/en-us/sql/relational-databases/databases/security-best-practices-with-contained-databases?view=sql-server-ver15',
      ],
      dashboard_name: 'Instances',
    },
    'bigquery-dataset-member.json': {
      description: 'Datasets Accessible by "_ARG_0_"',
      rationale:
        "Allowing anonymous and/or public access grants permissions to anyone to access the dataset's content. Such access might not be desired if you are storing any sensitive data. Hence, ensure that anonymous and/or public access to a dataset is not allowed.",
      dashboard_name: 'Datasets',
      arg_names: ['Member'],
    },
    'stackdrivermonitoring-alerts-does-not-exist-audit-config-changes.json': {
      description: "Alerts Doesn't Exist For Audit Configuration Changes",
      rationale:
        'Configuring the metric filter and alerts for audit configuration changes ensures the recommended state of audit configuration is maintained so that all activities in the project are audit-able at any point in time.',
      references: [
        'https://cloud.google.com/logging/docs/logs-based-metrics/',
        'https://cloud.google.com/monitoring/custom-metrics/',
        'https://cloud.google.com/monitoring/alerts/',
        'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
        'https://cloud.google.com/logging/docs/audit/configure-data-access#getiampolicy-setiampolicy',
      ],
      dashboard_name: 'Monitoring Alerts',
    },
    'cloudsql-allows-root-login-from-any-host.json': {
      description: 'Instance Allows Root Login from Any Host',
      rationale:
        'Root access to MySQL Database Instances should be allowed only through trusted IPs.',
      references: [
        'https://forsetisecurity.org/docs/latest/concepts/best-practices.html#cloud-sql',
        'https://cloud.google.com/blog/products/gcp/best-practices-for-securing-your-google-cloud-databases',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-instance-disk-not-csek-encrypted.json': {
      description:
        'VM Disks Not Customer-Supplied Encryption Keys (CSEK) Encrypted',
      rationale:
        'By default, Google Compute Engine encrypts all data at rest. Compute Engine handles and manages this encryption for you without any additional actions on your part. However, if you wanted to control and manage this encryption yourself, you can provide your own encryption keys.',
      references: [
        'https://cloud.google.com/compute/docs/disks/customer-supplied-encryption#encrypt_a_new_persistent_disk_with_your_own_keys',
        'https://cloud.google.com/compute/docs/reference/rest/v1/disks/get',
        'https://cloud.google.com/compute/docs/disks/customer-supplied-encryption#key_file',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-cluster-private-endpoint-disabled.json': {
      description: 'Private Cluster Endpoint Disabled',
      rationale:
        "In a private cluster, the master node has two endpoints, a private and public endpoint. The private endpoint is the internal IP address of the master, behind an internal load balancer in the master's VPC network. Nodes communicate with the master using the private endpoint. The public endpoint enables the Kubernetes API to be accessed from outside the master's VPC network.<br>Although Kubernetes API requires an authorized token to perform sensitive actions, a vulnerability could potentially expose the Kubernetes publicly with unrestricted access. Additionally, an attacker may be able to identify the current cluster and Kubernetes API version and determine whether it is vulnerable to an attack.<br>Unless required, disabling public endpoint will help prevent such threats, and require the attacker to be on the master's VPC network to perform any attack on the Kubernetes API.",
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_network_access_to_the_control_plane_and_nodes',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/private-clusters',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-postgresql-instances-log-temp-files-not-set-0.json': {
      description:
        'Log Temp Files Database Flag For PostgreSQL Instance Is Not Set To 0',
      rationale:
        'If all temporary files are not logged, it may be more difficult to identify potential performance issues that may be due to either poor application coding or deliberate resource starvation attempts.',
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#GUC-LOG-TEMP-FILES',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-firewall-rule-opens-sensitive-port-to-all.json': {
      description:
        'Firewall _ARG_0_ Rule Allows Public Access (0.0.0.0/0) to a Sensitive Port',
      rationale:
        'The firewall rule was found to be exposing a well-known port to all source addresses. Well-known ports are commonly probed by automated scanning tools, and could be an indicator of sensitive services exposed to Internet. If such services need to be exposed, a restriction on the source address could help to reduce the attack surface of the infrastructure.',
      dashboard_name: 'Firewall Rule Elements',
      arg_names: ['Ingress/Egress'],
    },
    'cloudsql-sqlservers-instances-cross-db-ownership-chaining-on.json': {
      description:
        'Cross DB Ownership Chaining Database Flag For SQLServers Instance Is On',
      rationale:
        'Use the cross db ownership for chaining option to configure cross-database ownership chaining for an instance of Microsoft SQL Server. This server option allows you to control cross-database ownership chaining at the database level or to allow cross-database ownership chaining for all databases. Enabling cross db ownership is not recommended unless all of the databases hosted by the instance of SQL Server must participate in cross-database ownership chaining and you are aware of the security implications of this setting. This recommendation is applicable to SQL Server database instances.',
      references: [
        'https://cloud.google.com/sql/docs/sqlserver/flags',
        'https://docs.microsoft.com/en-us/sql/database-engine/configure-windows/cross-db-ownership-chaining-server-configuration-option?view=sql-server-ver15',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-cluster-pod-security-policy-config-disabled.json': {
      description: 'Pod Security Policy Disabled',
      rationale:
        'A Pod Security Policy is a cluster-level resource that controls security sensitive aspects of the pod specification. The PodSecurityPolicy objects define a set of conditions that a pod must run with in order to be accepted into the system, as well as defaults for the related fields.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/pod-security-policies',
        'https://kubernetes.io/docs/concepts/policy/pod-security-policy',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'computeengine-instance-os-login-disabled.json': {
      description: 'OS login Disabled',
      rationale:
        'Enabling osLogin ensures that SSH keys used to connect to instances are mapped with IAM users. Revoking access to IAM user will revoke all the SSH keys associated with that particular user. It facilitates centralized and automated SSH key pair management which is useful in handling cases like response to compromised SSH key pairs and/or revocation of external/third-party/Vendor users.',
      references: [
        'https://cloud.google.com/compute/docs/instances/adding-removing-ssh-keys',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-instance-disk-with-no-snapshot.json': {
      description: 'Instance Disk without Snapshots',
      rationale:
        'You should have snapshots of your in-use or available disks taken on a regular basis to enable disaster recovery efforts.',
      references: [
        'https://cloud.google.com/compute/docs/disks/create-snapshots',
        'https://cloud.google.com/compute/docs/disks/scheduled-snapshots',
        'https://cloud.google.com/compute/docs/disks/snapshot-best-practices',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-firewall-rule-allows-all-ports.json': {
      description: 'Firewall Rule Opens All Ports (0-65535)',
      rationale:
        'The firewall rule allows access to all ports. This widens the attack surface of the infrastructure and makes it easier for an attacker to reach potentially sensitive services over the network.',
      dashboard_name: 'Firewall Rule Elements',
    },
    'iam-role-kms-separation-duties-is-false.json': {
      description: 'Separation Of Duties Not Enforced For KMS',
      rationale:
        'Separation of duties is the concept of ensuring that one individual does not have all necessary permissions to be able to complete a malicious action. In Cloud KMS, this could be an action such as using a key to access and decrypt data a user should not normally have access to. Separation of duties is a business control typically used in larger organizations, meant to help avoid security or privacy incidents and errors. It is considered best practice. No user(s) should have Cloud KMS Admin and any of the Cloud KMS CryptoKey Encrypter/Decrypter, Cloud KMS CryptoKey Encrypter, Cloud KMS CryptoKey Decrypter roles assigned at the same time.',
      references: ['https://cloud.google.com/kms/docs/separation-of-duties'],
      dashboard_name: 'Project',
    },
    'kubernetesengine-cluster-alias-ip-disabled.json': {
      description: 'Alias IP Disabled',
      rationale:
        'With Alias IPs ranges enabled, Kubernetes Engine clusters can allocate IP addresses from a CIDR block known to Google Cloud Platform. This makes your cluster more scalable and allows your cluster to better interact with other GCP products and entities.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_network_access_to_the_control_plane_and_nodes',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-postgresql-instances-log-lock-waits-off.json': {
      description:
        'Log Lock Waits Database Flag For PostgreSQL Instance Is Off',
      rationale:
        'The deadlock timeout defines the time to wait on a lock before checking for any conditions. Frequent run overs on deadlock timeout can be an indication of an underlying issue. Logging such waits on locks by enabling the log_lock_waits flag can be used to identify poor performance due to locking delays or if a specially-crafted SQL is attempting to starve resources through holding locks for excessive amounts of time. This recommendation is applicable to PostgreSQL database instances.',
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#GUC-LOG-MIN-DURATION-STATEMENT',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'iam-primitive-role-in-use.json': {
      description: 'Basic Role in Use',
      rationale:
        "Basic roles grant significant privileges. In most cases, usage of these roles is not recommended and does not follow security best practice.<br><br><b>Note: </b>This rule may flag Google-Managed Service Accounts. Google services rely on these Service Accounts having access to the project, and recommends not removing or changing the Service Account's role (see https://cloud.google.com/iam/docs/service-accounts#google-managed).",
      references: [
        'https://cloud.google.com/sdk/gcloud/reference/iam/service-accounts/',
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/understanding-service-accounts',
      ],
      dashboard_name: 'Bindings',
    },
    'kubernetesengine-cluster-master-authorized-networks-disabled.json': {
      description: 'Master Authorized Networks Disabled',
      rationale:
        'Master authorized networks blocks untrusted IP addresses from outside Google Cloud Platform. Addresses from inside GCP can still reach your master through HTTPS provided that they have the necessary Kubernetes credentials.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/authorized-networks',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_network_access_to_the_control_plane_and_nodes',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'computeengine-old-disk-snapshot.json': {
      description: 'Old Instance Disk Snapshot',
      rationale:
        'Disk snapshots that are over 90 days are likely to be outdated.',
      references: [
        'https://cloud.google.com/compute/docs/disks/create-snapshots',
        'https://cloud.google.com/compute/docs/disks/scheduled-snapshots',
        'https://cloud.google.com/compute/docs/disks/snapshot-best-practices',
      ],
      dashboard_name: 'Snapshots',
    },
    'kubernetesengine-scopes-not-limited.json': {
      description: 'Lack of Access Scope Limitation',
      rationale:
        'If you are not creating a separate service account for your nodes, you should limit the scopes of the node service account to reduce the oportunity for privilege escalation. This ensures that the default service account does not have permissions beyond those necessary to run your cluster. While the default scopes are limited, they may include scopes beyond the minimally required ones needed to run your cluster. If you are accessing private images in Google Container Registry, the minimally required scopes are only logging.write, monitoring, and devstorage.read_only.',
      references: [
        'https://cloud.google.com/kubernetes-engine/docs/how-to/access-scopes',
      ],
      dashboard_name: 'Clusters',
    },
    'config.json': {},
    'iam-role-assigned-to-domain.json': {
      description: 'IAM Role Assigned to Domain',
      rationale:
        "Roles granted to Workspace domains grant permissions to all users of the domain's Organization, which goes against the principle of least privilege.",
      references: [
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/using-iam-securely',
      ],
      dashboard_name: 'Bindings',
    },
    'kubernetesengine-cluster-metadata-server-disabled.json': {
      description: 'GKE Metadata Server Disabled',
      rationale:
        "Every GKE node stores its metadata on a metadata server. Some of this metadata, such as kubelet credentials and the VM instance identity token, is sensitive and should not be exposed to a Kubernetes workload.<br>Enabling the GKE Metadata server prevents pods (that are not running on the host network) from accessing this metadata and facilitates Workload Identity.<br>When unspecified, the default setting allows running pods to have full access to the node's underlying metadata server.",
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/protecting-cluster-metadata#concealment',
      ],
      dashboard_name: 'Clusters',
    },
    'kubernetesengine-node-integrity-monitoring-disabled.json': {
      description: 'Nodes with Integrity Monitoring Disabled',
      rationale:
        'The Integrity Monitoring feature should be enabled for GKE cluster nodes in order to monitor and automatically check the runtime boot integrity of shielded cluster nodes using Cloud Monitoring service.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/shielded-gke-nodes',
      ],
      dashboard_name: 'Clusters',
    },
    'kubernetesengine-node-auto-repair-disabled.json': {
      description: 'Nodes with Auto-Repair Disabled',
      rationale:
        'Auto-repair helps maintain the cluster nodes in a healthy, running state.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/node-auto-repair',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-instance-backups-disabled.json': {
      description: 'Instance with Automatic Backups Disabled',
      rationale:
        'Backups provide a way to restore a Cloud SQL instance to recover lost data or recover from a problem with that instance. Automated backups need to be set for any instance that contains data that should be protected from loss or damage.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/backup-recovery/backups',
        'https://cloud.google.com/sql/docs/postgres/backup-recovery/backing-up',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-instance-with-deletion-protection-disabled.json': {
      description: 'Instance without Deletion Protection',
      rationale:
        'It is good practice to enable this feature on production instances, to ensure that they may not be deleted by accident.',
      references: [
        'https://cloud.google.com/compute/docs/instances/preventing-accidental-vm-deletion',
      ],
      dashboard_name: 'Instances',
    },
    'iam-service-account-with-user-managed-keys.json': {
      description: 'User-Managed Service Account Keys',
      rationale:
        'It is recommended to prevent use of user-managed service account keys, as anyone who has access to the keys will be able to access resources through the service account. Best practice recommends using GCP-managed keys, which are used by Cloud Platform services such as App Engine and Compute Engine. These keys cannot be downloaded. Google will keep the keys and automatically rotate them on an approximately weekly basis.',
      references: [
        'https://cloud.google.com/iam/docs/understanding-service-accounts#managing_service_account_keys',
        'https://cloud.google.com/resource-manager/docs/organization-policy/restricting-service-accounts',
      ],
      dashboard_name: 'Service Accounts',
    },
    'functions-v1-member.json': {
      description: 'Functions Accessible by "_ARG_0_"',
      rationale:
        "Allowing anonymous and/or public access grants permissions to anyone to access the function's configuration and content. This configuration should be restricted to follow the principle of least privilege",
      references: [
        'https://cloud.google.com/logging/docs/reference/audit/appengine/rest/Shared.Types/SecurityLevel',
      ],
      dashboard_name: 'Functions',
      arg_names: ['Member'],
    },
    'computeengine-instance-shielded-vm-disabled.json': {
      description: 'Shielded VM Disabled',
      rationale:
        "Shielded VM offers verifiable integrity of your Compute Engine VM instances, so you can be confident your instances haven't been compromised by boot-or kernel-level malware or rootkits. Shielded VM's verifiable integrity is achieved through the use of Secure Boot, virtual trusted platform module (vTPM)-enabled Measured Boot, and integrity monitoring.",
      references: [
        'https://cloud.google.com/compute/docs/instances/modifying-shielded-vm',
        'https://cloud.google.com/shielded-vm',
        'https://cloud.google.com/security/shielded-cloud/shielded-vm#organization-policy-constraint',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-default-service-account-used.json': {
      description: 'Default Service Account in Use',
      rationale:
        'Each GKE node has a Service Account associated with it. By default, nodes are given the Compute Engine default service account. This account has broad access by default, making it useful to wide variety of applications, but it has more permissions than are required to run your Kubernetes Engine cluster. You should create and use a minimally privileged service account to run your GKE cluster instead of using the Compute Engine default service account.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#use_least_privilege_sa',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-instances-public-ips.json': {
      description: 'Database Instances with Public IPs',
      rationale:
        "To lower the organization's attack surface, Cloud SQL databases should not have public IPs. Private IPs provide improved network security and lower latency for your application.",
      references: [
        'https://cloud.google.com/sql/docs/mysql/configure-private-ip',
        'https://cloud.google.com/sql/docs/mysql/private-ip',
        'https://cloud.google.com/resource-manager/docs/organization-policy/org-policy-constraints',
        'https://console.cloud.google.com/iam-admin/orgpolicies/sql-restrictPublicIp',
      ],
      dashboard_name: 'Instances',
    },
    'cloudsql-instance-is-open-to-the-world.json': {
      description: 'Database Instances Allowing Public Access (0.0.0.0/0)',
      rationale:
        'To minimize attack surface on a Database server instance, only trusted/known and required IP(s) should be allow-listed to connect to it. An authorized network should not have IPs/networks configured to 0.0.0.0/0 which will allow access to the instance from anywhere in the world.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/configure-ip',
        'https://console.cloud.google.com/iam-admin/orgpolicies/sql-restrictAuthorizedNetworks',
        'https://cloud.google.com/resource-manager/docs/organization-policy/org-policy-constraints',
        'https://cloud.google.com/sql/docs/mysql/connection-org-policy',
      ],
      dashboard_name: 'Authorized Networks',
    },
    'dns-zones-zone-signing-key-using-rsasha1.json': {
      description: 'DNSSEC zone-signing key uses RSASHA1',
      rationale:
        'The algorithm used for key signing should be a recommended one and it should be strong. When enabling DNSSEC for a managed zone, or creating a managed zone with DNSSEC, the user can select the DNSSEC signing algorithms and the denial-of-existence type. Changing the DNSSEC settings is only effective for a managed zone if DNSSEC is not already enabled. If there is a need to change the settings for a managed zone where it has been enabled, turn DNSSEC off and then re-enable it with different settings.',
      dashboard_name: 'Cloud DNS',
      references: [
        'https://cloud.google.com/dns/dnssec-advanced#advanced_signing_options',
      ],
    },
    'stackdriverlogging-no-export-sinks.json': {
      description: 'Lack of Export Sinks',
      rationale:
        'Export sinks for Stackdriver logging were not found. As a result, logs would be deleted after the configured retention period, and would not be backed up.',
      references: [
        'https://cloud.google.com/logging',
        'https://cloud.google.com/logging/docs/export',
      ],
      dashboard_name: 'Logging Configurations',
    },
    'computeengine-network-default-in-use.json': {
      description: 'Default Network should be removed',
      rationale:
        'The default network has a preconfigured network configuration and automatically generates insecure firewall rules. These automatically created firewall rules do not get audit logged and cannot be configured to enable firewall rule logging.',
      dashboard_name: 'Networks',
      references: [
        'https://cloud.google.com/compute/docs/networking#firewall_rules',
        'https://cloud.google.com/compute/docs/reference/latest/networks/insert',
        'https://cloud.google.com/compute/docs/reference/latest/networks/delete',
        'https://cloud.google.com/vpc/docs/firewall-rules-logging',
        'https://cloud.google.com/vpc/docs/vpc#default-network',
        'https://cloud.google.com/sdk/gcloud/reference/compute/networks/delete',
      ],
    },
    'cloudsql-postgresql-instances-log-checkpoints-off.json': {
      description:
        'Log Checkpoints Database Flag For PostgreSQL Instance Is Off',
      rationale:
        'Enabling log_checkpoints causes checkpoints and restart points to be logged in the server log. Some statistics are included in the log messages, including the number of buffers written and the time spent writing them. This parameter can only be set in the postgresql.conf file or on the server command line. This recommendation is applicable to PostgreSQL database instances.',
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#RUNTIME-CONFIG-LOGGING-WHAT',
        'https://cloud.google.com/sql/docs/postgres/flags#setting_a_database_flag',
      ],
      dashboard_name: 'Instances',
    },
    'stackdrivermonitoring-alerts-does-not-exist-sql-instance-config-changes.json':
      {
        description:
          "Alerts Doesn't Exist For SQL Instance Configuration Changes",
        rationale:
          'Monitoring changes to SQL instance configuration changes may reduce the time needed to detect and correct misconfigurations done on the SQL server.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs',
          'https://cloud.google.com/sql/docs/',
          'https://cloud.google.com/sql/docs/mysql/',
          'https://cloud.google.com/sql/docs/postgres/',
        ],
        dashboard_name: 'Monitoring Alerts',
      },
    'iam-lack-of-service-account-key-rotation.json': {
      description: 'Lack of User-Managed Service Account Key Rotation',
      rationale:
        "Rotating Service Account keys will reduce the window of opportunity for an access key that is associated with a compromised or terminated account to be used. User-managed Service Account keys should be rotated to ensure that data cannot be accessed with an old key which might have been lost, cracked, or stolen. It should be ensured that keys are rotated every 90 days.<br>This issue does not apply to system-managed keys, as they are automatically rotated by Google, and are used for signing for a maximum of two weeks. The rotation process is probabilistic, and usage of the new key will gradually ramp up and down over the key's lifetime.",
      references: [
        'https://cloud.google.com/iam/docs/understanding-service-accounts#managing_service_account_keys',
        'https://cloud.google.com/sdk/gcloud/reference/iam/service-accounts/keys/list',
        'https://cloud.google.com/iam/docs/service-accounts',
        'https://cloud.google.com/iam/docs/reference/rest/v1/projects.serviceAccounts.keys',
      ],
      dashboard_name: 'Service Accounts',
    },
    'kubernetesengine-cluster-binary-authorization-disabled.json': {
      description: 'Binary Authorization Disabled',
      rationale:
        'Binary Authorization provides software supply-chain security for images that you deploy to GKE from Google Container Registry (GCR) or another container image registry.<br>Binary Authorization requires images to be signed by trusted authorities during the development process. These signatures are then validated at deployment time. By enforcing validation, you can gain tighter control over your container environment by ensuring only verified images are integrated into the build-and-release process.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/binary-authorization/',
      ],
      dashboard_name: 'Clusters',
    },
    'kubernetesengine-legacy-abac-enabled.json': {
      description: 'Legacy Authorization (ABAC) Enabled',
      rationale:
        'The legacy authorizer in Kubernetes grants broad, statically defined permissions. To ensure that RBAC limits permissions correctly, you must disable the legacy authorizer. RBAC has significant security advantages, can help you ensure that users only have access to cluster resources within their own namespace and is now stable in Kubernetes.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#leave_abac_disabled_default_for_110',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudstorage-bucket-no-logging.json': {
      description: 'Bucket with Logging Disabled',
      rationale:
        'Enable access and storage logs, in order to capture all events which may affect objects within target buckets.',
      references: ['https://cloud.google.com/storage/docs/access-logs'],
      dashboard_name: 'Buckets',
    },
    'cloudsql-instance-ssl-not-required.json': {
      description: 'Instance Not Requiring SSL for Incoming Connections',
      rationale:
        'SQL database connections if successfully trapped (MITM); can reveal sensitive data like credentials, database queries, query outputs etc. For security, it is recommended to always use SSL encryption when connecting to your instance.',
      references: [
        'https://cloud.google.com/sql/docs/postgres/configure-ssl-instance',
      ],
      dashboard_name: 'Instances',
    },
    'functions-v1-environment-variables-secrets.json': {
      description:
        'Potential Secrets in Function Environment Variables (Gen 1)',
      rationale:
        'Anyone who can access the function can view the configured secrets. Best practice is to store configuration secrets in Secret Manager (or similar).',
      dashboard_name: 'Functions',
    },
    'cloudsql-instance-with-no-backups.json': {
      description: 'Instance with No Backups',
      rationale:
        'Weekly or monthly backups should be created of all databases holding sensitive information.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/backup-recovery/backups',
      ],
      dashboard_name: 'Instances',
    },
    'stackdriverlogging-metric-filter-does-not-exist-audit-config-changes.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For Audit Configuration Changes",
        rationale:
          'Configuring the metric filter and alerts for audit configuration changes ensures the recommended state of audit configuration is maintained so that all activities in the project are audit-able at any point in time.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/logging/docs/audit/configure-data-access#getiampolicy-setiampolicy',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'computeengine-instance-public-ip-adresses.json': {
      description: 'Instances Have Public IP Addresses',
      rationale:
        "To reduce your attack surface, Compute instances should not have public IP addresses. Instead, instances should be configured behind load balancers, to minimize the instance's exposure to the internet.",
      references: [
        'https://cloud.google.com/load-balancing/docs/backend-service#backends_and_external_ip_addresses',
        'https://cloud.google.com/compute/docs/instances/connecting-advanced#sshbetweeninstances',
        'https://cloud.google.com/compute/docs/instances/connecting-to-instance',
        'https://cloud.google.com/compute/docs/ip-addresses/reserve-static-external-ip-address#unassign_ip',
        'https://cloud.google.com/resource-manager/docs/organization-policy/org-policy-constraints',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-instance-block-project-ssh-keys-disabled.json': {
      description: 'Block Project SSH Keys Disabled',
      rationale:
        'Project-wide SSH keys are stored in Compute/Project-meta-data. Project wide SSH keys can be used to login into all the instances within project. Using project-wide SSH keys eases the SSH key management but if compromised, poses the security risk which can impact all the instances within project.',
      references: [
        'https://cloud.google.com/compute/docs/instances/adding-removing-ssh-keys',
      ],
      dashboard_name: 'Instances',
    },
    'computeengine-firewall-rule-allows-port-range.json': {
      description: 'Firewall Rule Allows Port Range(s)',
      rationale:
        'It was found that the firewall rule was using port ranges. Sometimes, ranges could include unintended ports that should not be exposed. As a result, when possible, explicit port lists should be used instead.',
      dashboard_name: 'Firewall Rule Elements',
    },
    'computeengine-instance-full-api-access.json': {
      description:
        'Instances Configured To Use Default Service Account With Full Access To All Cloud APIs',
      rationale:
        'When an instance is configured with Compute Engine default service account with Scope Allow full access to all Cloud APIs, based on IAM roles assigned to the user(s) accessing Instance, it may allow user to perform cloud operations/API calls that user is not supposed to perform leading to successful privilege escalation.',
      references: [
        'https://cloud.google.com/compute/docs/access/create-enable-service-accounts-for-instances',
        'https://cloud.google.com/compute/docs/access/service-accounts',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-cluster-private-google-access-disabled.json': {
      description: 'Private Google Access Disabled',
      rationale:
        'Enabling Private Google Access allows hosts on a subnetwork to use a private IP address to reach Google APIs rather than an external IP address.',
      references: [
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_network_access_to_the_control_plane_and_nodes',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudstorage-bucket-member.json': {
      description: 'Bucket Accessible by "_ARG_0_"',
      rationale:
        'Allowing anonymous and/or public access grants permissions to anyone to access bucket content. Such access might not be desired if you are storing any sensitive data. Hence, ensure that anonymous and/or public access to a bucket is not allowed.',
      references: [
        'https://cloud.google.com/storage/docs/access-control/iam-reference',
        'https://cloud.google.com/storage/docs/access-control/making-data-public',
        'https://cloud.google.com/storage/docs/gsutil/commands/iam',
      ],
      dashboard_name: 'Buckets',
      arg_names: ['Member', 'Description'],
    },
    'kubernetesengine-node-secure-boot-disabled.json': {
      description: 'Nodes with Secure Boot Disabled',
      rationale:
        'The Secure Boot feature should be enabled for GKE cluster nodes in order to protect them against malware and rootkits. Secure Boot helps ensure that the system runs only authentic software by verifying the digital signature of all boot components, and halting the boot process if the signature verification fails.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/shielded-gke-nodes',
      ],
      dashboard_name: 'Clusters',
    },
    'dns-zones-key-signing-key-using-rsasha1.json': {
      description: 'DNSSEC key-signing key uses RSASHA1',
      rationale:
        'The algorithm used for key signing should be a recommended one and it should be strong. When enabling DNSSEC for a managed zone, or creating a managed zone with DNSSEC, the user can select the DNSSEC signing algorithms and the denial-of-existence type. Changing the DNSSEC settings is only effective for a managed zone if DNSSEC is not already enabled. If there is a need to change the settings for a managed zone where it has been enabled, turn DNSSEC off and then re-enable it with different settings.',
      dashboard_name: 'Cloud DNS',
      references: [
        'https://cloud.google.com/dns/dnssec-advanced#advanced_signing_options',
      ],
    },
    'bigquery-encryption-no-cmk.json': {
      description: 'Dataset Not Encrypted with Customer-Managed Keys (CMKs)',
      rationale:
        'Encrypting datasets with Cloud KMS Customer-Managed Keys (CMKs) will allow for a more granular control over data encryption/decryption process.',
      dashboard_name: 'Datasets',
    },
    'cloudsql-mysql-instances-local-infile-on.json': {
      description: 'Local Infile Database Flag For MySQL Instance Is On',
      rationale:
        'The local_infile flag controls the server-side LOCAL capability for LOAD DATA statements. Depending on the local_infile setting, the server refuses or permits local data loading by clients that have LOCAL enabled on the client side. To explicitly cause the server to refuse LOAD DATA LOCAL statements (regardless of how client programs and libraries are configured at build time or runtime), start mysqld with local_infile disabled. local_infile can also be set at runtime.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/flags',
        'https://dev.mysql.com/doc/refman/5.7/en/server-system-variables.html#sysvar_local_infile',
        'https://dev.mysql.com/doc/refman/5.7/en/load-data-local.html',
      ],
      dashboard_name: 'Instances',
    },
    'stackdrivermonitoring-alerts-does-not-exist-cloud-storage-iam-permission-changes.json':
      {
        description:
          "Alerts Doesn't Exist For Cloud Storage IAM Permission Changes",
        rationale:
          'Monitoring changes to cloud storage bucket permissions may reduce the time needed to detect and correct permissions on sensitive cloud storage buckets and objects inside the bucket.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs',
          'https://cloud.google.com/storage/docs/access-control/iam-roles',
        ],
        dashboard_name: 'Monitoring Alerts',
      },
    'memorystore-redis-instance-ssl-not-required.json': {
      description: 'Memory Instance Not Requiring SSL for Incoming Connections',
      rationale:
        'All incoming connections to Cloud Memorystore databases should require the use of SSL.',
      references: [
        'https://cloud.google.com/memorystore/docs/redis/securing-tls-connections',
      ],
      dashboard_name: 'Redis Instances',
    },
    'stackdriverlogging-metric-filter-does-not-exist-vpc-network-changes.json':
      {
        description: "Log Metric Filter Doesn't Exist For VPC Network Changes",
        rationale:
          'It is possible to have more than one VPC within a project. In addition, it is also possible to create a peer connection between two VPCs enablingnetwork traffic to route between VPCs.Monitoring changes to a VPC will help ensure VPC traffic flow is not getting impacted.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/vpc/docs/overview',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'kms-cryptokeys-anonymously-publicly-accessible.json': {
      description: 'Cloud KMS Cryptokeys Anonymously Or Publicly Accessible',
      rationale:
        'Granting permissions to allUsers or allAuthenticatedUsers allows anyone to access the data set. Such access might not be desirable if sensitive data is stored at the location. In this case, ensure that anonymous and/or public access to a Cloud KMS cryptokey is not allowed.',
      references: [
        'https://cloud.google.com/sdk/gcloud/reference/kms/keys/remove-iam-policy-binding',
        'https://cloud.google.com/sdk/gcloud/reference/kms/keys/set-iam-policy',
        'https://cloud.google.com/sdk/gcloud/reference/kms/keys/get-iam-policy',
        'https://cloud.google.com/kms/docs/resource-hierarchy#key_resource_id',
      ],
      dashboard_name: 'KMS Bindings',
    },
    'cloudsql-instance-no-binary-logging.json': {
      description: 'Instance with Binary Logging Disabled',
      rationale:
        'The benefits of enabling binary logs (replication, scalability, auditability, point-in-time data recovery, etc.) can improve the security posture of the Cloud SQL instance.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/instance-settings',
        'https://cloud.google.com/sql/docs/mysql/replication/tips',
      ],
      dashboard_name: 'Instances',
    },
    'iam-service-account-user-member.json': {
      description: '_ARG_1_',
      rationale:
        'Access to the Service Account User role (roles/iam.serviceAccountUser) should be restricted, as members granted this role on a service account can use it to indirectly access all the resources to which the service account has access. ',
      references: [
        'https://cloud.google.com/iam/docs/service-accounts#user-role',
      ],
      dashboard_name: 'Service Accounts',
      arg_names: ['Member', 'Description'],
    },
    'dns-zones-dnssec-not-enabled.json': {
      description: 'DNSSEC is not enabled for Cloud DNS',
      rationale:
        'Domain Name System Security Extensions (DNSSEC) adds security to the DNS protocol by enabling DNS responses to be validated. Having a trustworthy DNS that translates a domain name like www.example.com into its associated IP address is an increasingly important building block of today’s web-based applications. Attackers can hijack this process of domain/IP lookup and redirect users to a malicious site through DNS hijacking and man-in-the-middle attacks. DNSSEC helps mitigate the risk of such attacks by cryptographically signing DNS records. As a result, it prevents attackers from issuing fake DNS responses that may misdirect browsers to nefarious websites.',
      dashboard_name: 'Cloud DNS',
      references: [
        'https://cloudplatform.googleblog.com/2017/11/DNSSEC-now-available-in-Cloud-DNS.html',
        'https://cloud.google.com/dns/dnssec-config#enabling',
        'https://cloud.google.com/dns/dnssec',
      ],
    },
    'computeengine-network-with-no-instances.json': {
      description: 'Network without Instances',
      rationale:
        'Maintaining unused resources increases risks of misconfigurations and increases the difficulty of audits.',
      dashboard_name: 'Networks',
    },
    'stackdriverlogging-metric-filter-does-not-exist-custom-role-changes.json':
      {
        description: "Log Metric Filter Doesn't Exist For Custom Role Changes",
        rationale:
          'Google Cloud IAM provides predefined roles that give granular access to specific Google Cloud Platform resources and prevent unwanted access to other resources. However, to cater to organization-specific needs, Cloud IAM also provides the ability to create custom roles. Project owners and administrators with the Organization Role Administrator role or the IAM Role Administrator role can create custom roles. Monitoring role creation, deletion and updating activities will help in identifying any over-privileged role at early stages.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/iam/docs/understanding-custom-roles',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'kubernetesengine-legacy-metadata-endpoints-enabled.json': {
      description: 'Legacy Metadata Endpoints Enabled',
      rationale:
        'The instance metadata server exposed legacy v0.1 and v1beta1 endpoints, which do not enforce metadata query headers. This is a feature in the v1 APIs that makes it more difficult for a potential attacker to retrieve instance metadata, such as Server-Side Request Forgery (SSRF). Unless specifically required, we recommend you disable these legacy APIs.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#protect_node_metadata_default_for_112',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'iam-gmail-accounts-used.json': {
      description: 'Gmail Account in Use',
      rationale:
        "It is recommended fully-managed corporate Google accounts be used for increased visibility, auditing, and controlling access to Cloud Platform resources. Email accounts based outside of the user's organization, such as personal accounts, should not be used for business purposes.",
      references: [
        'https://cloud.google.com/docs/enterprise/best-practices-for-enterprise-organizations#manage-identities',
        'https://support.google.com/work/android/answer/6371476',
        'https://cloud.google.com/sdk/gcloud/reference/organizations/get-iam-policy',
        'https://cloud.google.com/sdk/gcloud/reference/beta/resource-manager/folders/get-iam-policy',
        'https://cloud.google.com/sdk/gcloud/reference/projects/get-iam-policy',
        'https://cloud.google.com/resource-manager/docs/organization-policy/org-policy-constraints',
        'https://cloud.google.com/resource-manager/docs/organization-policy/restricting-domains',
      ],
      dashboard_name: 'Users',
    },
    'cloudsql-instance-is-open-to-public-range.json': {
      description: 'Database Instances Allowing Access from Public Ranges',
      rationale:
        'To minimize attack surface on a Database server instance, only trusted/known and required IP(s) should be allow-listed to connect to it. An authorized network should not have IPs/networks configured to broad public ranges which will allow access to the instance from arbitrary hosts.',
      references: [
        'https://cloud.google.com/sql/docs/mysql/configure-ip',
        'https://console.cloud.google.com/iam-admin/orgpolicies/sql-restrictAuthorizedNetworks',
        'https://cloud.google.com/resource-manager/docs/organization-policy/org-policy-constraints',
        'https://cloud.google.com/sql/docs/mysql/connection-org-policy',
      ],
      dashboard_name: 'Authorized Networks',
    },
    'kubernetesengine-certificate-authentication-enabled.json': {
      description: 'Certificate Authentication Enabled',
      rationale:
        'Unless applications use the client certificate authentication method, it should be disabled.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_authn_methods',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#evaluation_on',
      ],
      dashboard_name: 'Clusters',
    },
    'functions-v2-environment-variables-secrets.json': {
      description:
        'Potential Secrets in Function Environment Variables (Gen 2)',
      rationale:
        'Anyone who can access the function can view the configured secrets. Best practice is to store configuration secrets in Secret Manager (or similar).',
      dashboard_name: 'Functions',
    },
    'kubernetesengine-cluster-logging-disabled.json': {
      description: 'Cluster Logging Disabled',
      rationale:
        'You should enable cluster logging and use a logging service so your cluster can export logs about its activities.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://kubernetes.io/docs/tasks/debug-application-cluster/audit/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#stackdriver_logging',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'stackdriverlogging-metric-filter-does-not-exist-project-ownership-assignment.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For Project Ownership Assignments/Changes",
        rationale:
          'Project ownership has the highest level of privileges on a project. To avoid misuse of project resources, the project ownership assignment/change actions mentioned above should be monitored and alerted to concerned recipients.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'kms-encryption-keys-not-rotated.json': {
      description: 'KMS Encryption Not Rotated Within 90 Days',
      rationale:
        "Set a key rotation period and starting time. A key can be created with a specified rotation period, which is the time between when new key versions are generated automatically. A key is used to protect some corpus of data. A collection of files could be encrypted with the same key and people with decrypt permissions on that key would be able to decrypt those files. Therefore, it's necessary to make sure the rotation period is set to a specific time.",
      references: [
        'https://cloud.google.com/kms/docs/key-rotation#frequency_of_key_rotation',
        'https://cloud.google.com/kms/docs/re-encrypt-data',
      ],
      dashboard_name: 'Keys',
    },
    'kubernetesengine-private-nodes-disabled.json': {
      description: 'Private Cluster Nodes Disabled',
      rationale:
        'Private Nodes are nodes with no public IP addresses. Disabling public IP addresses on cluster nodes restricts access to only internal networks, forcing attackers to obtain local network access before attempting to compromise the underlying Kubernetes hosts.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_network_access_to_the_control_plane_and_nodes',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/private-clusters',
      ],
      dashboard_name: 'Clusters',
    },
    'stackdriverlogging-metric-filter-does-not-exist-sql-instance-config-changes.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For SQL Instance Configuration Changes",
        rationale:
          'Monitoring changes to SQL instance configuration changes may reduce the time needed to detect and correct misconfigurations done on the SQL server.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs',
          'https://cloud.google.com/sql/docs/',
          'https://cloud.google.com/sql/docs/mysql/',
          'https://cloud.google.com/sql/docs/postgres/',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'stackdrivermonitoring-alerts-does-not-exist-custom-role-changes.json': {
      description: "Alerts Doesn't Exist For Custom Role Changes",
      rationale:
        'Google Cloud IAM provides predefined roles that give granular access to specific Google Cloud Platform resources and prevent unwanted access to other resources. However, to cater to organization-specific needs, Cloud IAM also provides the ability to create custom roles. Project owners and administrators with the Organization Role Administrator role or the IAM Role Administrator role can create custom roles. Monitoring role creation, deletion and updating activities will help in identifying any over-privileged role at early stages.',
      references: [
        'https://cloud.google.com/logging/docs/logs-based-metrics/',
        'https://cloud.google.com/monitoring/custom-metrics/',
        'https://cloud.google.com/monitoring/alerts/',
        'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
        'https://cloud.google.com/iam/docs/understanding-custom-roles',
      ],
      dashboard_name: 'Monitoring Alerts',
    },
    'kubernetesengine-basic-authentication-enabled.json': {
      description: 'Basic Authentication Enabled',
      rationale:
        'Basic authentication allows a user to authenticate to the cluster with a username and password and it is stored in plain text without any encryption. Disabling Basic authentication will prevent attacks like brute force. Its recommended to use either client certificate or IAM for authentication.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#restrict_authn_methods',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#evaluation_on',
      ],
      dashboard_name: 'Clusters',
    },
    'kubernetesengine-cluster-application-layer-encryption-disabled.json': {
      description: 'Application-Layer Secrets Encryption Disabled',
      rationale:
        'By default, GKE encrypts customer content stored at rest, including Secrets. GKE handles and manages this default encryption without any additional action.<br>Application-layer Secrets Encryption provides an additional layer of security for sensitive data, such as user defined Secrets and Secrets required for the operation of the cluster, such as service account keys, which are all stored in etcd.<br>Using this functionality, you can use a key, that you manage in Cloud KMS, to encrypt data at the application layer. This protects against attackers in the event that they manage to gain access to etcd.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/encrypting-secrets',
      ],
      dashboard_name: 'Clusters',
    },
    'stackdriverlogging-metric-filter-does-not-exist-vpc-network-firewall-rule-changes.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For VPC Network Firewall Rule Changes",
        rationale:
          'Monitoring for Create or Update Firewall rule events gives insight to network access changes and may reduce the time it takes to detect suspicious activity.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/vpc/docs/firewalls',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'kubernetesengine-node-container-optimized-os-not-used.json': {
      description: 'Lack of Container-Optimized OS Node Images',
      rationale:
        'It is recommended to use container-optimized OS images, as they provide improved support, security and stability.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/container-optimized-os/docs/concepts/features-and-benefits',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/node-images',
      ],
      dashboard_name: 'Clusters',
    },
    'functions-v1-allowing-http.json': {
      description: 'Functions Allowing HTTP Traffic (Gen 1)',
      rationale:
        'Use of a secure protocol (HTTPS) is best practice for encrypted communication. A function allowing HTTP traffic can be vulnerable to eavesdropping and man-in-the-middle attacks.',
      references: [
        'https://cloud.google.com/logging/docs/reference/audit/appengine/rest/Shared.Types/SecurityLevel',
      ],
      dashboard_name: 'Functions',
    },
    'kubernetesengine-cluster-shielded-nodes-disabled.json': {
      description: 'Shielded GKE Nodes Disabled',
      rationale:
        "Shielded GKE nodes protects clusters against boot- or kernel-level malware or rootkits which persist beyond infected OS.<br>Shielded GKE nodes run firmware which is signed and verified using Google's Certificate Authority, ensuring that the nodes' firmware is unmodified and establishing the root of trust for Secure Boot. GKE node identity is strongly protected via virtual Trusted Platform Module (vTPM) and verified remotely by the master node before the node joins the cluster.",
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/shielded-gke-nodes',
      ],
      dashboard_name: 'Clusters',
    },
    'memorystore-redis-instance-auth-not-enabled.json': {
      description: 'Memory Instance Allows Unauthenticated Connections',
      rationale:
        'All incoming connections to Cloud Memorystore databases should require the use of authentication and SSL.',
      references: [
        'https://cloud.google.com/memorystore/docs/redis/managing-auth',
      ],
      dashboard_name: 'Redis Instances',
    },
    'stackdriverlogging-metric-filter-does-not-exist-vpc-network-route-changes.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For VPC Network Route Changes",
        rationale:
          'Google Cloud Platform (GCP) routes define the paths network traffic takes from a VM instance to another destination. The other destination can be inside the organization VPC network (such as another VM) or outside of it. Every route consists of a destination and a next hop. Traffic whose destination IP is within the destination range is sent to the next hop for delivery. Monitoring changes to route tables will help ensure that all VPC traffic flows through an expected path.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs/access-control/iam',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'functions-v2-public-endpoint.json': {
      description: 'Public Function Endpoint (Gen 2)',
      rationale:
        "The Cloud Function's ingress configuration allowed all traffic, potentially exposing undesired functionality. It is recommended that traffic reaching functions be routed via a load balancer, to minimize the attack surface.",
      dashboard_name: 'Functions',
    },
    'kubernetesengine-cluster-release-channel.json': {
      description: 'Cluster not Subscribed to Release Channel',
      rationale:
        "Release Channels signal a graduating level of stability and production-readiness. These are based on observed performance of GKE clusters running that version and represent experience and confidence in the cluster version.<br>The Regular release channel upgrades every few weeks and is for production users who need features not yet offered in the Stable channel. These versions have passed internal validation, but don't have enough historical data to guarantee their stability. Known issues generally have known workarounds.<br>The Stable release channel upgrades every few months and is for production users who need stability above all else, and for whom frequent upgrades are too risky. These versions have passed internal validation and have been shown to be stable and reliable in production, based on the observed performance of those clusters.<br>Critical security patches are delivered to all release channels.",
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/release-channels',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudstorage-bucket-no-public-access-prevention.json': {
      description: 'Bucket with Private Access Prevention not Enforced',
      rationale:
        'Public access prevention protects Cloud Storage buckets and objects from being accidentally exposed to the public. When you enforce public access prevention, no one can make data in applicable buckets public through IAM policies or ACLs.<br><br>Note that even if a bucket does not have public access prevention explicitly enforced in its settings, it might still inherit public access prevention, which occurs if the organization policy constraint <samp>storage.publicAccessPrevention</samp> is set on the project, folder, or organization that the bucket exists within. For this reason, the bucket state can only be set to <samp>enforced</samp> or <samp>inherited</samp>.',
      references: [
        'https://cloud.google.com/storage/docs/public-access-prevention',
      ],
      dashboard_name: 'Buckets',
    },
    'stackdrivermonitoring-alerts-does-not-exist-vpc-network-firewall-rule-changes.json':
      {
        description:
          "Alerts Doesn't Exist For VPC Network Firewall Rule Changes",
        rationale:
          'Monitoring for Create or Update Firewall rule events gives insight to network access changes and may reduce the time it takes to detect suspicious activity.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/vpc/docs/firewalls',
        ],
        dashboard_name: 'Monitoring Alerts',
      },
    'iam-user-has-sa-user-role.json': {
      description:
        'User with Privileged Service Account Roles at the Project Level',
      rationale:
        'Granting the iam.serviceAccountUser, iam.serviceAccountTokenCreator, or iam.serviceAccountActor role to a user for a project gives the user access to all service accounts in the project, including service accounts that may be created in the future. This can result into elevation of privileges by using service accounts and corresponding Compute Engine instances.',
      references: [
        'https://cloud.google.com/iam/docs/service-accounts',
        'https://cloud.google.com/iam/docs/granting-changing-revoking-access',
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/granting-changing-revoking-access',
        'https://console.cloud.google.com/iam-admin/iam',
      ],
      dashboard_name: 'Bindings',
    },
    'iam-sa-has-admin-privileges.json': {
      description: 'Service Account with Admin Privileges',
      rationale:
        "Service accounts represent service-level security of the Resources (application or a VM) which can be determined by the roles assigned to it. Enrolling Service Accounts with administrative privileges grants full access to assigned application or a VM, Service Account Access holder can user.<br><br><b>Note: </b>This rule may flag Google-Managed Service Accounts. Google services rely on these Service Accounts having access to the project, and recommends not removing or changing the Service Account's role",
      references: [
        'https://cloud.google.com/sdk/gcloud/reference/iam/service-accounts/',
        'https://cloud.google.com/iam/docs/understanding-roles',
        'https://cloud.google.com/iam/docs/understanding-service-accounts',
      ],
      dashboard_name: 'Bindings',
    },
    'cloudsql-postgresql-instances-log-connections-off.json': {
      description:
        'Log Connections Database Flag For PostgreSQL Instance Is Off',
      rationale:
        'PostgreSQL does not log attempted connections by default. Enabling the log_connections setting will create log entries for each attempted connection as well as successful completion of client authentication which can be useful in troubleshooting issues and to determine any unusual connection attempts to the server. This recommendation is applicable to PostgreSQL database instances.',
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#RUNTIME-CONFIG-LOGGING-WHAT',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'stackdriverlogging-metric-filter-does-not-exist-cloud-storage-iam-permission-changes.json':
      {
        description:
          "Log Metric Filter Doesn't Exist For Cloud Storage IAM Permission Changes",
        rationale:
          'Monitoring changes to cloud storage bucket permissions may reduce the time needed to detect and correct permissions on sensitive cloud storage buckets and objects inside the bucket.',
        references: [
          'https://cloud.google.com/logging/docs/logs-based-metrics/',
          'https://cloud.google.com/monitoring/custom-metrics/',
          'https://cloud.google.com/monitoring/alerts/',
          'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
          'https://cloud.google.com/storage/docs',
          'https://cloud.google.com/storage/docs/access-control/iam-roles',
        ],
        dashboard_name: 'Logging Configurations',
      },
    'kubernetesengine-cluster-monitoring-disabled.json': {
      description: 'Cluster Monitoring Disabled',
      rationale:
        'You should enable cluster monitoring and use a monitoring service so your cluster can export metrics about its activities.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#stackdriver_logging',
        'https://cloud.google.com/monitoring/kubernetes-engine#about-skm',
        'https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks#default_values_on',
      ],
      dashboard_name: 'Clusters',
    },
    'cloudsql-postgresql-instances-log-min-messages-not-set.json': {
      description:
        'Log Min Messages Database Flag For PostgreSQL Instance Is Not Set',
      rationale:
        "Auditing helps in troubleshooting operational problems and also permits forensic analysis. If log_min_error_statement is not set to the correct value, messages may not be classified as error messages appropriately. Considering general log messages as error messages would make it difficult to find actual errors, while considering only stricter severity levels as error messages may skip actual errors to log their SQL statements. The log_min_error_statement flag should be set in accordance with the organization's logging policy. This recommendation is applicable to PostgreSQL database instances.",
      references: [
        'https://www.postgresql.org/docs/9.6/runtime-config-logging.html#RUNTIME-CONFIG-LOGGING-WHEN',
        'https://cloud.google.com/sql/docs/postgres/flags',
      ],
      dashboard_name: 'Instances',
    },
    'kubernetesengine-cluster-has-no-labels.json': {
      description: 'Clusters Lacking Labels',
      rationale:
        'Labels enable users to map their own organizational structures onto system objects in a loosely coupled fashion, without requiring clients to store these mappings. Labels can also be used to apply specific security settings and auto configure objects at creation.',
      references: [
        'https://cloud.google.com/kubernetes-engine/docs/how-to/hardening-your-cluster#use_namespaces_and_rbac_to_restrict_access_to_cluster_resources',
      ],
      dashboard_name: 'Clusters',
    },
    'stackdrivermonitoring-alerts-does-not-exist-vpc-network-changes.json': {
      description: "Alerts Doesn't Exist For VPC Network Changes",
      rationale:
        'It is possible to have more than one VPC within a project. In addition, it is also possible to create a peer connection between two VPCs enablingnetwork traffic to route between VPCs. Monitoring changes to a VPC will help ensure VPC traffic flow is not getting impacted.',
      references: [
        'https://cloud.google.com/logging/docs/logs-based-metrics/',
        'https://cloud.google.com/monitoring/custom-metrics/',
        'https://cloud.google.com/monitoring/alerts/',
        'https://cloud.google.com/logging/docs/reference/tools/gcloud-logging',
        'https://cloud.google.com/vpc/docs/overview',
      ],
      dashboard_name: 'Monitoring Alerts',
    },
    'computeengine-firewall-default-rule-in-use.json': {
      description: 'Default Firewall Rule in Use',
      rationale:
        'Some default firewall rules were in use. This could potentially expose sensitive services or protocols to other networks.',
      dashboard_name: 'Firewall Rule',
    },
    'cloudstorage-uniform-bucket-level-access-disabled.json': {
      description: 'Uniform Bucket-Level Access Is Disabled',
      rationale:
        'It is recommended to use uniform bucket-level access to unify and simplify how you grant access to your Cloud Storage resources. In order to support a uniform permissioning system, Cloud Storage has uniform bucket-level access. Using this feature disables ACLs for all Cloud Storage resources: access to Cloud Storage resources then is granted exclusively through Cloud IAM. Enabling uniform bucket-level access guarantees that if a Storage bucket is not publicly accessible, no object in the bucket is publicly accessible either.',
      references: [
        'https://cloud.google.com/storage/docs/uniform-bucket-level-access',
        'https://cloud.google.com/storage/docs/using-uniform-bucket-level-access',
        'https://cloud.google.com/storage/docs/org-policy-constraints#uniform-bucket',
      ],
      dashboard_name: 'Buckets',
    },
    'computeengine-firewall-rule-allows-public-access.json': {
      description: 'Firewall Rule Allows Public Access (0.0.0.0/0)',
      rationale:
        'The firewall rule was found to be exposing potentially open ports to all source addresses. Ports are commonly probed by automated scanning tools, and could be an indicator of sensitive services exposed to Internet. If such services need to be exposed, a restriction on the source address could help to reduce the attack surface of the infrastructure.',
      dashboard_name: 'Firewall Rules',
    },
    'kubernetesengine-cluster-workload-identity-disabled.json': {
      description: 'Workload Identity Disabled',
      rationale:
        'Enabling Workload Identity manages the distribution and rotation of Service account keys for the workloads to use.<br>Kubernetes workloads should not use cluster node service accounts to authenticate to Google Cloud APIs. Each Kubernetes Workload that needs to authenticate to other Google services using Cloud IAM should be provisioned a dedicated Service account.',
      references: [
        'https://www.cisecurity.org/benchmark/kubernetes/',
        'https://cloud.google.com/kubernetes-engine/docs/how-to/workload-identity',
      ],
      dashboard_name: 'Clusters',
    },
  },
}

export const scoutSuiteKnowledgeBaseLinks = {
  scoutsuite_aws: `https://risk3sixty.zendesk.com/hc/en-us/articles/13741608206868`,
  scoutsuite_azure: `https://risk3sixty.zendesk.com/hc/en-us/articles/13741613540500`,
  scoutsuite_gcp: `https://risk3sixty.zendesk.com/hc/en-us/articles/13742194047124`,
}

export const scoutSuiteTypeBasedInstanceName = {
  scoutsuite_aws: 'AWS Account',
  scoutsuite_azure: 'Azure Subscription',
  scoutsuite_gcp: 'GCP Project',
}

export const commonEvidenceFiles = {
  scoutsuite_aws: [
    { serviceKey: 'iam', evidenceName: 'password_policy' },
    { serviceKey: 'iam', evidenceName: 'users' },
    { serviceKey: 'iam', evidenceName: 'groups' },
    { serviceKey: 'ec2', evidenceName: 'instances' },
  ],
  scoutsuite_azure: [
    { serviceKey: 'aad', evidenceName: 'users' },
    { serviceKey: 'aad', evidenceName: 'groups' },
    { serviceKey: 'aad', evidenceName: 'service_principals' },
    { serviceKey: 'appservice', evidenceName: 'web_apps' },
  ],
  scoutsuite_gcp: [
    { serviceKey: 'iam', evidenceName: 'users' },
    { serviceKey: 'iam', evidenceName: 'groups' },
    { serviceKey: 'iam', evidenceName: 'service_accounts' },
    { serviceKey: 'computeengine', evidenceName: 'instances' },
  ],
}

export const evidencePreview = {
  scoutsuite_aws: {
    acm_certificates: {
      columns: [],
      description:
        'Details about SSL/TLS certificates issued by Amazon Certificate Manager (ACM). It includes information such as certificate status, validity period, domain validation options, and issuer. It provides evidence of certificate issuance, domain validation, and expiration, ensuring compliance with information security controls related to secure communications.',
    },
    awslambda_functions: {
      columns: [],
      description:
        "Outline of key attributes and configurations for AWS Lambda functions, including details about the function's access policy, runtime environment, execution role, and other operational parameters.  This file may serve as audit evidence for various information security controls, including access management, system configuration, data protection, and system integrity.",
    },
    cloudformation_stacks: {
      columns: [],
      description:
        'Information about a specific CloudFormation stack, including its name, creation time, description, status, and associated parameters. It provides insights into the configuration and deployment of resources managed by CloudFormation, enabling assessment of compliance with security controls, resource dependencies, and stack-specific settings.',
    },
    cloudfront_distributions: {
      columns: [],
      description:
        'Information about AWS CloudFront distributions, including distribution ID, domain name, status, caching behavior, SSL certificate settings, origin configuration, and access restrictions. It supports controls related to content delivery, caching, SSL/TLS encryption, and access control for web applications and content.',
    },
    cloudtrail_regions: {
      columns: [],
      description:
        'List of AWS regions where CloudTrail is configured, along with the corresponding trail counts. This information assists in evaluating the extent of CloudTrail coverage across regions and supports compliance with regional security requirements.',
    },
    cloudtrail_trails: {
      columns: [],
      description:
        'Configuration details of CloudTrail trails, which are configurations that enable delivery of events.',
    },
    cloudwatch_alarms: {
      columns: [],
      description:
        'Configurations for CloudWatch Alarms associated with CloudTrail, enabling monitoring and alerting for important events and conditions. These alarms contribute to effective security incident detection and compliance management.',
    },
    cloudwatch_metric_filters: {
      columns: [],
      description:
        'Details of CloudWatch metric filters, which transform log events into metrics.',
    },
    cognito_identity_pools: {
      columns: [],
      description:
        'Details of Amazon Cognito identity pools, which are used to create unique identities for users of mobile applications and other unauthenticated users. It provides evidence of identity pool configuration, including supported authentication providers, identity providers, and associated roles.',
    },
    cognito_user_pools: {
      columns: [],
      description:
        'Details of Amazon Cognito user pools, which are user directories used to manage sign-up and sign-in functionality for mobile and web applications. It provides evidence of user pool configuration, including supported authentication providers, identity providers, and associated roles.',
    },
    config_recorders: {
      columns: [],
      description:
        "Details about the configuration recorders used to monitor AWS resource configurations and compliance. It includes information on the recorder's status, last start time, and associated role, which help ensure security controls and track changes in the AWS environment.",
    },
    config_regions: {
      columns: [],
      description:
        'List of AWS regions where AWS Config is enabled. It provides an overview of the number of recorders and rules in each region, helping evaluate the configuration and coverage of AWS Config for compliance monitoring and enforcement.',
    },
    config_rules: {
      columns: [],
      description:
        'List of AWS regions where AWS Config is enabled. It provides an overview of the number of recorders and rules in each region, helping evaluate the configuration and coverage of AWS Config for compliance monitoring and enforcement.',
    },
    directconnect_connections: {
      columns: [],
      description:
        'Details and status of Direct Connect connections, which establish private connectivity between AWS and your datacenter, office, or colocation environment.',
    },
    docdb_clusters: {
      columns: [],
      description:
        'Details about Amazon DocumentDB clusters, which are fully managed MongoDB-compatible database clusters. It includes information such as cluster status, creation time, and associated security groups. It provides evidence of cluster configuration and security controls, including encryption, authentication, and access control.',
    },
    dynamodb_tables: {
      columns: [],
      description:
        'Configuration details of DynamoDB tables, used for storing data. Includes details like provisioned throughput, indexes, and more.',
    },
    'ec2_external attack surface': {
      columns: [],
      description:
        'Outline of the external attack surface of an EC2 instance, detailing public accessibility and open ports. This information can serve as audit evidence for evaluations of network security controls such as firewall configurations and access controls.',
    },
    ec2_images: {
      columns: [],
      description:
        "Summary of an Amazon Machine Image (AMI) used in an EC2 instance. The report includes information about the image's architecture, creation date, and storage details.  The data can be used as audit evidence for security controls, including system configurations and the management of storage encryption settings.",
    },
    ec2_instances: {
      columns: [],
      description:
        'Detailed snapshot of Amazon EC2 instances, including information about instance type, state, IAM roles, subnet IDs, and network interfaces, among others. It offers valuable audit evidence for evaluating controls related to instance configuration, access control, and network security.',
    },
    ec2_network_interfaces: {
      columns: [],
      description:
        'Detailed snapshot of Amazon EC2 instances, including information about instance type, state, IAM roles, subnet IDs, and network interfaces, among others. It offers valuable audit evidence for evaluating controls related to instance configuration, access control, and network security.',
    },
    ec2_security_groups: {
      columns: [],
      description:
        'Details on AWS EC2 security groups, including their ID, name, ownership, and associated rules for ingress and egress traffic. This information can serve as audit evidence when assessing controls related to network access, firewall configurations, and the enforcement of least privilege in network interactions.',
    },
    ec2_snapshots: {
      columns: [],
      description:
        'Details about AWS EC2 snapshots, such as their ID, description, encryption status, creation time, state, and volume size. This data can be used in auditing backup and recovery controls, validating that sensitive data is encrypted, and ensuring adherence to data retention policies.',
    },
    ec2_volumes: {
      columns: [],
      description:
        'Information about AWS EC2 volumes, including their ID, size, creation time, encryption status, and attached state. This evidence can be leveraged to validate data encryption standards, storage capacity management, and the adherence to the policy of deleting data when no longer necessary.',
    },
    ecr_images: {
      columns: [],
      description:
        "Information about Docker container images stored in the Amazon Elastic Container Registry (ECR). It includes data such as the image's media type, digest, size, last recorded pull time, and the repository in which it resides. This file may be used to validate controls related to the management and use of container images, including verifying image integrity through the digest and assessing potential exposure of images based on the last pull time.",
    },
    ecr_repositories: {
      columns: [],
      description:
        "Insights into the Elastic Container Registry (ECR) configurations including details about the repository's creation date, encryption configuration, image scanning settings, and repository's name and URI. This information may be used to ensure compliance with data encryption standards, confirm whether security features like image scanning are enabled, and verify the appropriate use of container images stored in the repositories.",
    },
    ecs_active_task_definitions: {
      columns: [],
      description:
        'Information about active task definitions in Amazon Elastic Container Service (ECS). It includes details such as compatibility with EC2 and Fargate, container definitions specifying CPU, memory, image, environment variables, and secrets, execution role, networking configuration, and required capabilities. This audit evidence helps assess controls related to task definition configurations, secret management, access permissions, and compatibility requirements, ensuring adherence to security policies and best practices in containerized environments.',
    },
    ecs_clusters: {
      columns: [],
      description:
        'Overview of Amazon Elastic Container Service (ECS) clusters. It includes details such as the cluster name, ARN, status, associated services count, running tasks, and specific settings like container insights. This file can assist in verifying controls relating to the management of container orchestration, including monitoring active services, running tasks, and ensuring appropriate cluster-level settings are in place.',
    },
    ecs_services: {
      columns: [],
      description:
        "Details about Amazon Elastic Container Service (ECS) services, including the service's ARN, name, creation date, deployment configuration, and task definitions. It also details deployments, associated network configuration, events, and status of each service. This information can be used to evaluate controls related to task orchestration, network security configurations, the monitoring of service events, and adherence to the principle of least privilege, as exemplified by role assignment.",
    },
    ecs_tasks: {
      columns: [],
      description:
        'Insights about the tasks running within Amazon Elastic Container Service (ECS). It includes specifics like task ARN, definition, status, connectivity, CPU/memory configurations, associated containers and their status, and network interfaces among others. This file may serve as audit evidence for assessing controls around task configuration, resource allocation, network interfaces, and operational status, enabling the monitoring of security controls and best practices in microservice architectures.',
    },
    efs_file_systems: {
      columns: [],
      description:
        'Information about Amazon Elastic File System (EFS) file systems, including the file system ID, creation time, size, performance mode, throughput mode, and more. This evidence can be leveraged to validate data encryption standards, storage capacity management, and the adherence to the policy of deleting data when no longer necessary.',
    },
    eks_clusters: {
      columns: [],
      description:
        "Information about an Amazon Elastic Kubernetes Service (EKS) cluster including details such as the cluster's ARN, certificate authority, endpoint, network configuration, logging settings, resource configurations, and status. This evidence helps assess controls related to EKS cluster configuration, network access, logging, and resource allocation, ensuring compliance with security policies and best practices for Kubernetes-based environments.",
    },
    elasticache_clusters: {
      columns: [],
      description:
        "Information about an Amazon ElastiCache cluster including details such as the cluster's ARN, encryption settings, auto-upgrade capability, cache node configuration, security groups, network configuration, replication details, and maintenance window. This evidence helps assess controls related to data encryption, network access, maintenance schedules, and compliance with best practices for managing ElastiCache clusters.",
    },
    elasticache_parameter_groups: {
      columns: [],
      description:
        "Information about a parameter group used in Amazon ElastiCache including details such as the parameter group's ARN, family, description, and whether it is a global parameter group. This evidence helps assess the configuration and customization of parameter groups for ElastiCache clusters, ensuring that appropriate security controls and settings are in place for optimal performance and security of the cache environment.",
    },
    elasticache_security_groups: {
      columns: [],
      description:
        'Configuration details of ElastiCache security groups, which function as virtual firewalls for your cache clusters.',
    },
    elasticache_subnet_groups: {
      columns: [],
      description:
        "Information about a subnet group used in Amazon ElastiCache including details such as the subnet group's ARN, description, associated subnets, supported network types, and the VPC it belongs to. This evidence helps assess the network configuration and connectivity of ElastiCache clusters, ensuring that the appropriate subnets are utilized and the network security controls are properly implemented within the specified VPC for enhanced data protection and network isolation.",
    },
    elb_elb_policies: {
      columns: [],
      description:
        'Configurations of ELB policies, which define the behavior of your load balancer.',
    },
    elb_elbs: {
      columns: [],
      description:
        'Configuration details and status of Elastic Load Balancers, which automatically distribute incoming traffic across multiple targets.',
    },
    'elb_external attack surface': {
      columns: [],
      description:
        'Potential vulnerabilities that an external attacker could exploit to compromise your ELB instances.',
    },
    'elbv2_external attack surface': {
      columns: [],
      description:
        'Potential vulnerabilities that an external attacker could exploit to compromise your ELBV2 instances.',
    },
    elbv2_lbs: {
      columns: [],
      description:
        'Information about the Elastic Load Balancer version 2 (ELBv2) configurations in use. It includes details about the load balancer state, availability zones, listener protocols, security groups, and other attributes. This data can be used to review and validate load balancing strategies, SSL/TLS policies, security group configurations, and compliance with data transmission and access controls within the cloud infrastructure.',
    },
    emr_clusters: {
      columns: [],
      description:
        'Configuration details of EMR clusters, which are collections of EC2 instances running big data software.',
    },
    'execution_Scan Exceptions': {
      columns: [],
      description:
        'Information about resources that were marked as exceptions and not considered in rule checks.',
    },
    'execution_Scan Metadata': {
      columns: [],
      description:
        'Details about the scan, including permissions for the connection account and ruleset used.',
    },
    guardduty_detectors: {
      columns: [],
      description:
        'Details about the Amazon GuardDuty detectors in your account, which continuously monitor your AWS accounts for malicious activity and unauthorized behavior.',
    },
    guardduty_regions: {
      columns: [],
      description:
        'Information about the regions where Amazon GuardDuty is enabled.',
    },
    iam_credential_reports: {
      columns: [],
      description:
        "Essential information about IAM user credentials, including access key activity, password status, MFA status, and user creation details. This file helps assess the security posture of IAM users' credentials, monitor their activity, and ensure compliance with information security controls related to access management and credential hygiene.",
    },
    iam_groups: {
      columns: [],
      description:
        'Information about IAM groups, including their creation date, ARN, ID, associated policies, and member users. This information is crucial for evaluating the access control structure, managing group permissions, and enforcing security controls related to group membership and policy assignments.',
    },
    iam_password_policy: {
      columns: [],
      description:
        'Settings for enforcing strong password practices, such as minimum length, complexity requirements, expiration, and reuse prevention. This file provides evidence of the controls implemented to enforce strong password practices and enhance the security of user accounts within the AWS environment.',
    },
    iam_permissions: {
      columns: [],
      description:
        'Information about the permissions granted to various roles and users within the AWS environment. It specifies the allowed actions and associated policies for each role or user. This information helps in evaluating and ensuring proper access controls and least privilege principles within the system.',
    },
    iam_policies: {
      columns: [],
      description:
        'Information about IAM policies, including their policy documents, ARNs, IDs, management types, and role attachments. This data helps manage access permissions and enforce information security controls within the AWS environment.',
    },
    iam_roles: {
      columns: ['arn', 'assume_role_policy', 'id', 'inline_policy'],
      description:
        'Information about IAM roles, including their ARNs, assume role policies, associated policies, and role descriptions. These roles define the permissions and access controls for different entities within the AWS environment, contributing to information security controls and proper authorization mechanisms.',
    },
    iam_users: {
      columns: [],
      description:
        'Information about IAM users, including their access keys, creation date, associated policies, and tags. This file provides insights into user identities and their permissions within the AWS environment, enabling the evaluation of information security controls such as user access management, authentication mechanisms, and policy enforcement.',
    },
    kms_keys: {
      columns: [],
      description:
        'Information about AWS Key Management Service (KMS) keys, including their aliases, creation date, description, grants, policy, and rotation status. This file provides insights into the management and security of cryptographic keys used for data encryption, ensuring information security controls such as key lifecycle management, access control, and key rotation can be assessed.',
    },
    'rds_external attack surface': {
      columns: [],
      description:
        'Potential vulnerabilities that an external attacker could exploit to compromise your RDS instances.',
    },
    rds_instances: {
      columns: [],
      description:
        'Information about Amazon RDS database instances, including its status, configuration, endpoint, security groups, and tags. It helps assess the security controls and compliance of the RDS instance, ensuring data protection and adherence to organizational policies and regulatory requirements.',
    },
    rds_parameter_groups: {
      columns: [],
      description:
        'Default parameter group for a specific RDS database engine version, along with its parameters and descriptions. This information helps assess the configuration and security settings of the RDS database, ensuring compliance with desired standards and controls.',
    },
    rds_security_groups: {
      columns: [],
      description:
        'Details about the default security group for an RDS database, including its description, associated EC2 security groups, IP ranges, owner ID, ARN, and name. This information aids in assessing network access and enforcing appropriate security controls for the RDS database.',
    },
    rds_snapshots: {
      columns: [],
      description:
        'Essential details about an RDS database snapshot, such as the instance identifier, encryption status, option group, creation time, ARN, attributes, ID, cluster status, name, and associated VPC ID. This information helps evaluate backup and recovery capabilities and assess the security of the RDS database.',
    },
    rds_subnet_groups: {
      columns: [],
      description:
        'Information about the subnet group for RDS instances, including its name, description, status, associated subnets, supported network types, and VPC ID. This information helps assess the network configuration and security of RDS instances in relation to their subnet placement within the VPC.',
    },
    redshift_clusters: {
      columns: [],
      description:
        'Configuration and status details of Redshift clusters, which are the compute resources where data warehouses are hosted.',
    },
    'redshift_external attack surface': {
      columns: [],
      description:
        'Potential vulnerabilities that an external attacker could exploit to compromise your Redshift instances.',
    },
    redshift_parameter_groups: {
      columns: [],
      description:
        'Configuration details of Redshift parameter groups, which control the runtime settings of a database.',
    },
    redshift_security_groups: {
      columns: [],
      description:
        'Configuration details of Redshift security groups, which control the access to Redshift clusters.',
    },
    route53_domains: {
      columns: [],
      description:
        'Information about registered domains in AWS Route53, including domain name, ARN, expiration date, and transfer lock status. It provides audit evidence for controls related to domain registration, renewal, and transfer lock to enhance the security and integrity of the domain assets.',
    },
    route53_hosted_zones: {
      columns: [],
      description:
        'Information about hosted zones in AWS Route53, including the zone name, ARN, resource record sets, and configuration details. It provides audit evidence for controls related to DNS management, zone configuration, and resource record sets to ensure accurate and secure DNS resolution for the hosted domains.',
    },
    s3_buckets: {
      columns: [],
      description:
        'Information about AWS S3 buckets, including their creation date, ARN, encryption settings, access permissions, logging status, public access block configuration, and associated roles and groups. This data helps assess information security controls related to S3 bucket management, encryption, access control, and public access restrictions.',
    },
    secretsmanager_secrets: {
      columns: [],
      description:
        'Details about secrets managed by AWS Secrets Manager, including their ARN, description, last accessed and changed dates, rotation status, and associated policy. This information enables assessment of information security controls related to secret management and access.',
    },
    ses_identities: {
      columns: [],
      description:
        'Details of SES identities, which are email addresses and domains that you use to send email.',
    },
    sns_topics: {
      columns: [],
      description:
        "Information about AWS Simple Notification Service (SNS) topics, including topic name, owner, policy, and delivery settings. It supports controls related to secure messaging and notification management in an organization's AWS environment.",
    },
    sqs_queues: {
      columns: [],
      description:
        "Information about AWS Simple Queue Service (SQS) queues, which supports controls related to secure and reliable message queuing in an organization's AWS environment.",
    },
    ssm_parameters: {
      columns: [],
      description:
        "Information about AWS Systems Manager (SSM) parameters, which supports controls related to secure storage and management of configuration data in an organization's AWS environment.",
    },
    vpc_flow_logs: {
      columns: [],
      description:
        'Details of VPC Flow Logs, which capture information about the IP traffic going to and from network interfaces in your VPC.',
    },
    vpc_network_acls: {
      columns: [],
      description:
        'Configuration and rules for network access control within a Virtual Private Cloud (VPC).  It provides audit evidence for controls related to network traffic filtering, security boundaries, and access restrictions within the VPC, ensuring the proper enforcement of security policies and protecting network resources from unauthorized access.',
    },
    vpc_peering_connections: {
      columns: [],
      description:
        'Details and status of VPC peering connections, which allow you to route traffic between two VPCs as if they are in the same network.',
    },
    vpc_subnets: {
      columns: [],
      description:
        'Details about the subnets in a Virtual Private Cloud (VPC), including their CIDR blocks, availability zones, and network access control list (NACL) associations. It helps assess network segmentation and compliance with subnet-related security controls.',
    },
    vpc_vpcs: {
      columns: [],
      description:
        'Information about Virtual Private Cloud (VPC) configurations, including their CIDR blocks, associated subnets, and network access control lists (NACLs). It helps assess the network architecture and security controls implemented within the VPC environment.',
    },
  },
  scoutsuite_azure: {
    aad_applications: {
      columns: [],
      description:
        'Details of applications registered in Azure Active Directory. This data provides insights into the application access control measures and how identity management is handled for these applications.',
    },
    aad_groups: {
      columns: [],
      description:
        'Configuration and membership information of groups in Azure Active Directory. The file is useful to understand user grouping and the access policies implemented at group level.',
    },
    aad_policies: {
      columns: [],
      description:
        'Configurations and status of policies applied in Azure Active Directory. This provides insights into how identity and access policies are enforced.',
    },
    aad_service_principals: {
      columns: [],
      description:
        'Configuration and status of service principals in Azure Active Directory. Service principals represent identities for applications, providing a way for them to authenticate and be authorized for access to Azure resources.',
    },
    aad_users: {
      columns: [],
      description:
        'Information about users registered in Azure Active Directory. This file provides insights into user management, access control and the status of user accounts, helping to enforce least privilege and other important security principles.',
    },
    appgateway_app_gateways: {
      columns: [],
      description:
        'Configurations and details of Azure Application Gateways, which are load balancers that enable you to manage traffic to your web applications.',
    },
    appservice_web_apps: {
      columns: [],
      description:
        'Configuration details for applications hosted on Azure App Service. This file provides a snapshot of the application settings, runtime versions, networking configurations, and other important details for GRC analysis.',
    },
    'execution_Scan Metadata': {
      columns: [],
      description:
        'Details about the scan, including permissions for the connection account and ruleset used.',
    },
    'execution_Scan Exceptions': {
      columns: [],
      description:
        'Information about resources that were marked as exceptions and not considered in rule checks.',
    },
    keyvault_vaults: {
      columns: [],
      description:
        'Configuration and status information for Key Vaults, which are used to securely store and manage sensitive information like keys, secrets, and certificates. This data is crucial for understanding how sensitive data is protected.',
    },
    loadbalancer_load_balancers: {
      columns: [],
      description:
        'Details of Azure Load Balancers, which are used to distribute traffic between multiple servers.',
    },
    loggingmonitoring_diagnostic_settings: {
      columns: [],
      description:
        "Settings for diagnostic logging and monitoring. It is essential for understanding the organization's approach to log retention, collection, and analysis, which play a crucial role in incident detection and response.",
    },
    loggingmonitoring_log_alerts: {
      columns: [],
      description:
        'Settings related to log-based alerts, providing insights into how the organization responds to specific incidents, anomalies, or trends found in logs.',
    },
    loggingmonitoring_log_profiles: {
      columns: [],
      description:
        "Details related to log profiles are captured in this file. It defines where logs are stored and helps to determine if the log storage complies with the organization's data security and retention policies.",
    },
    loggingmonitoring_resources_logging: {
      columns: [],
      description:
        "Logs related to resource usage and operations. It's key to understanding the operational aspects of the resources and is useful in identifying potential misuse or unauthorized activities.",
    },
    mysqldatabase_servers: {
      columns: [],
      description:
        'Configuration and status information of MySQL servers. This data is essential for understanding the access controls, encryption, and security settings of the MySQL databases in use.',
    },
    network_application_security_groups: {
      columns: [],
      description:
        'Configurations for application security groups, which control network traffic to and from applications. This data is key to understanding how network access is segmented and controlled.',
    },
    network_security_groups: {
      columns: [],
      description:
        'Configuration and status information for network security groups, which control inbound and outbound traffic to resources. This data provides insights into network access controls.',
    },
    network_virtual_networks: {
      columns: [],
      description:
        "Configuration and status information of virtual networks (VNet). VNets help isolate the organization's cloud resources, and analyzing their settings is crucial to understand network isolation and segmentation strategies.",
    },
    network_watchers: {
      columns: [],
      description:
        "Configuration and status information of virtual networks (VNet). VNets help isolate the organization's cloud resources, and analyzing their settings is crucial to understand network isolation and segmentation strategies.",
    },
    postgresqldatabase_servers: {
      columns: [],
      description:
        'Configuration and status information of PostgreSQL servers. This data is vital for understanding the access controls, encryption, and security settings of the PostgreSQL databases in use.',
    },
    rbac_custom_roles_report: {
      columns: [],
      description:
        'Information about custom roles defined in Azure RBAC. This data is important for understanding how access is controlled and segmented in the Azure environment.',
    },
    rbac_roles: {
      columns: [],
      description:
        'Information about roles defined in Azure RBAC. It provides insights into how access to Azure resources is controlled and managed.',
    },
    rediscache_caches: {
      columns: [],
      description:
        'Information about Redis caches, which are used to store data for quick repeated distribution. This data is crucial for understanding how sensitive data is protected.',
    },
    securitycenter_auto_provisioning_settings: {
      columns: [],
      description:
        'Settings for automatic provisioning of Azure Security Center. This is crucial for understanding how security solutions are automatically deployed and managed.',
    },
    securitycenter_compliance_results: {
      columns: [],
      description:
        "Results from compliance checks run by Azure Security Center. It is key for understanding the organization's cloud compliance posture.",
    },
    securitycenter_pricings: {
      columns: [],
      description:
        'Pricing details for services and features in Azure Security Center. This is crucial for cost management and understanding the financial aspects of cloud security.',
    },
    securitycenter_regulatory_compliance_results: {
      columns: [],
      description:
        "Results from regulatory compliance checks run by Azure Security Center. It is crucial for understanding the organization's regulatory compliance posture in the cloud.",
    },
    securitycenter_security_contacts: {
      columns: [],
      description:
        "Information about security contacts defined in Azure Security Center. This information is crucial for understanding the organization's incident response and communication strategies.",
    },
    securitycenter_settings: {
      columns: [],
      description:
        "Settings for Azure Security Center. This is key to understanding the organization's overall cloud security posture and approach.",
    },
    sqldatabase_servers: {
      columns: [],
      description:
        'Detailed configuration and status information of SQL database servers. It allows GRC analysts to check the security measures like firewall configurations, threat detection settings, and transparent data encryption status.',
    },
    storageaccounts_storage_accounts: {
      columns: [],
      description:
        'Configuration and status information for storage accounts. This data is crucial for understanding how data is stored and managed in Azure, and the access control measures in place.',
    },
    virtualmachines_disks: {
      columns: [],
      description:
        'Configuration and status information of virtual hard disks (VHDs) attached to Azure virtual machines. It is crucial for understanding the data storage and disposal methods, encryption at rest status, and more.',
    },
    virtualmachines_images: {
      columns: [],
      description:
        "Configurations for VM images that are used for creating multiple VMs. It is crucial for analyzing the standard configurations, patches, and security baselines adopted in the organization's cloud environment.",
    },
    virtualmachines_instances: {
      columns: [],
      description:
        'Detailed configuration and status information for each VM instance, which is essential for GRC analysts to understand the access control measures, encryption methods, and patching frequency.',
    },
    virtualmachines_snapshots: {
      columns: [],
      description:
        'Snapshot configurations for VMs. Snapshot details are crucial for understanding backup or replication strategies, and they aid in ensuring that the organization meets its data protection requirements.',
    },
  },
  scoutsuite_gcp: {
    bigquery_datasets: {
      columns: [],
      description:
        'Dataset details like dataset ID, expiration, and access roles. These specifics aid in managing data retention policies, enforcing least privilege access, and maintaining auditable logs, enhancing information security management.',
    },
    cloudmemorystore_redis_instances: {
      columns: [],
      description:
        'Redis instance details like Redis version, memory size, and transit encryption mode for each instance. This information aids in ensuring memory management, network connectivity, and encryption controls, contributing to overall data protection and system security.',
    },
    cloudsql_instances: {
      columns: [],
      description:
        'Details like database version, disk size, and backup configuration. This information supports secure database management, data backup policies, and network access controls, enhancing data security and system resilience.',
    },
    cloudstorage_buckets: {
      columns: [],
      description:
        'Bucket details such as access controls and encryption settings. This information assists in managing data storage, access, and encryption, instrumental for secure data handling and privacy controls.',
    },
    computeengine_firewalls: {
      columns: [],
      description:
        'Details on individual firewall rules. This information is crucial for managing network access controls, maintaining a secure environment through the principle of least privilege, and ensuring traceability of network activities. This data is relevant to security controls concerning network security, system and information integrity, and auditability.',
    },
    computeengine_instances: {
      columns: [],
      description:
        'Instance details such as instance ID, status, network interfaces, disks, and service accounts. It supports establishing secure configurations, managing access controls, and ensuring system and data integrity for effective information security.',
    },
    computeengine_networks: {
      columns: [],
      description:
        'Network information like network ID, auto-creation of subnetworks, routing configurations, and associated firewall rules. This data assists in enforcing network security controls, managing access, and implementing secure network configurations.',
    },
    computeengine_snapshots: {
      columns: [],
      description:
        'Snapshot details like snapshot ID, source disk, and storage location, supports robust data backup and recovery controls. This information aids in ensuring data availability and system resilience, key aspects of information security.',
    },
    computeengine_subnetworks: {
      columns: [],
      description:
        'Subnetwork-specific information including ID, IP CIDR range, and associated network. It supports effective network segmentation controls, helping enforce access policies, maintain system integrity, and minimize potential attack surfaces.',
    },
    dns_managed_zones: {
      columns: [],
      description:
        'Managed zone details like DNS name, visibility, and DNSSEC configuration. This information aids in managing domain names, configuring DNS security, and setting visibility, vital for secure domain management and DNS integrity.',
    },
    'execution_Scan Metadata': {
      columns: [],
      description:
        'Details about the scan, including permissions for the connection account and ruleset used.',
    },
    'execution_Scan Exceptions': {
      columns: [],
      description:
        'Information about resources that were marked as exceptions and not considered in rule checks.',
    },
    functions_functions_v1: {
      columns: [],
      description:
        'Details such as function ID, runtime, service account, and HTTPS trigger. It is vital for managing secure serverless computing configurations, enforcing least privilege principles, and maintaining system and information integrity.',
    },
    functions_functions_v2: {
      columns: [],
      description:
        'Data on Functions v2, including function ID, runtime, and VPC connector. This information aids in serverless computing configurations management, access control enforcement, and system integrity maintenance, enhancing overall information security.',
    },
    iam_bindings: {
      columns: [],
      description:
        'Binding details like role, members, and condition. This data aids in managing user roles, assigning permissions, and setting conditional access, critical for access control and maintaining the principle of least privilege.',
    },
    iam_bindings_separation_duties: {
      columns: [],
      description:
        'User role assignments, indicating potential conflicts in duties. This information is key for enforcing separation of duties, preventing undue accumulation of access, and ensuring proper access control.',
    },
    iam_domains: {
      columns: [],
      description:
        'Domain details, including verification status and associated users. This data supports secure domain management, user allocation, and SSL certificate application, essential for access control and secure communication.',
    },
    iam_groups: {
      columns: [],
      description:
        'Group details like members and roles. This information is important for managing user group permissions and roles, central to enforcing access control and implementing the principle of least privilege.',
    },
    iam_service_accounts: {
      columns: [],
      description:
        'Service account details such as roles and status. This information is pivotal for managing service account permissions and their activation status, crucial for secure automated access control within GCP.',
    },
    iam_users: {
      columns: [],
      description:
        'User details such as roles and last login time. This data aids in managing user permissions, monitoring user activities, and ensuring secure user management practices - crucial for effective access control.',
    },
    kms_keyrings: {
      columns: [],
      description:
        'Keyring details including associated keys. This information is fundamental for managing cryptographic keys, crucial for data encryption, and integrity verification, supporting secure data storage and transmission.',
    },
    kubernetesengine_clusters: {
      columns: [],
      description:
        'Details on Kubernetes Engine Clusters, including cluster ID, node pool configurations, and network policy. This information assists in orchestrating container security, automating system updates, and enforcing network access policies, contributing to information security management.',
    },
    stackdriverlogging_logging_metrics: {
      columns: [],
      description:
        'Details such as metrics descriptor, filter, and value extractor. This information is key in monitoring application performance, identifying anomalies, and maintaining system and data integrity, contributing to proactive security management.',
    },
    stackdriverlogging_metrics: {
      columns: [],
      description:
        'Metrics details like description, filter, and value extractor. This data contributes to system performance monitoring, anomaly detection, and traffic pattern analysis, aiding in effective security monitoring and incident response.',
    },
    stackdriverlogging_sinks: {
      columns: [],
      description:
        'Sink details like destination, filter, and writer identity. This information assists in managing log export destinations, implementing filters for relevant logs, and controlling service account access, contributing to effective log management and access control.',
    },
    stackdrivermonitoring_alert_policies: {
      columns: [],
      description:
        'Alert policies details like conditions, combiner, and notification channels. This information aids in configuring alert thresholds, combining conditions, and setting up notifications, crucial for proactive incident detection and response.',
    },
    stackdrivermonitoring_monitoring_alert_policies: {
      columns: [],
      description:
        'Monitoring policies like conditions, combiner, and notification channels. This data aids in setting thresholds for monitoring, combining conditions, and alert notification setup, critical for effective incident detection and response.',
    },
    stackdrivermonitoring_uptime_checks: {
      columns: [],
      description:
        'Monitoring policies like conditions, combiner, and notification channels. This data aids in setting thresholds for monitoring, combining conditions, and alert notification setup, critical for effective incident detection and response.',
    },
  },
}
