import R3sFetch from '../R3sFetch'
import { handleFetchResponse } from '../ApiHelpers'

export default {
  async generateReport(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/ongoing/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async generateFinalReport(
    projectId,
    type,
    scopeIds,
    childIds,
    achievementsEmail
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/final/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: projectId,
          type,
          scopeIds,
          childIds,
          achievementsEmail,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async generateFormalFindingsReport(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/findings/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getFinalReport(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/final/report/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async finalReportAuditAction(projectId, details) {
    const response = await R3sFetch(`/api/1.0/assessments/final/report/audit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId, details }),
    })
    return await handleFetchResponse(response)
  },

  async finalReportUpdateFile(projectId, fileId, action = 'add') {
    const response = await R3sFetch(
      `/api/1.0/assessments/final/report/file/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, fileId, action }),
      }
    )
    return await handleFetchResponse(response)
  },

  async finalReportSave(info) {
    const response = await R3sFetch(`/api/1.0/assessments/final/report/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  // async getTestingTables(projectId) {
  //   const response = await R3sFetch(`/api/1.0/assessments/testing/tables/get?id=${projectId || ''}`)
  //   return await handleFetchResponse(response)
  // }
}
