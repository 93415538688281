export default {
  SET_QUESTIONNAIRE_BREADCRUMBS(state, crumbs) {
    state.questionnaire.breadcrumbs = crumbs
  },

  SET_QUESTIONNAIRE_GLOBAL_ERROR(state, error) {
    state.questionnaire.globalError = error
  },

  SET_QUESTIONNAIRE_RECORD(state, questionnaire) {
    state.questionnaire.record = questionnaire
  },

  SET_QUESTIONNAIRE_CHANGE_LOG(state, changeLog) {
    state.questionnaire.changeLog = changeLog
  },

  SET_QUESTIONNAIRE_FLAGS(state, flags) {
    state.questionnaire.flags = flags
  },

  SET_QUESTIONNAIRE_SCORING(state, scoring) {
    state.questionnaire.scoring = scoring
  },

  SET_QUESTIONNAIRE_SCORING_SECTION_SCORES(state, sectionScoreObj) {
    state.questionnaire.sectionScores = sectionScoreObj
  },

  SET_QUESTIONNAIRE_SCORING_TOTAL_SCORE(state, score) {
    state.questionnaire.totalScore = score
  },

  SET_QUESTIONNAIRE_SECTION_EXPANDED(state, index) {
    state.questionnaire.record.sections[index].isExpanded = !state.questionnaire
      .record.sections[index].isExpanded
  },

  SET_QUESTIONNAIRE_ACTIVE_SECTION(state, sectionId) {
    state.questionnaire.activeSectionId = sectionId
  },

  SET_QUESTIONNAIRE_ACTIVE_QUESTION(state, questionId) {
    state.questionnaire.activeQuestionId = questionId
  },

  SET_QUESTIONNAIRE_QUESTION_FIELDS(
    state,
    { sectionIndex, questionIndex, fields }
  ) {
    const question =
      state.questionnaire.record.sections[sectionIndex].questions[questionIndex]
    state.questionnaire.record.sections[sectionIndex].questions[
      questionIndex
    ] = {
      ...question,
      ...fields,
    }
  },

  SET_QUESTIONNAIRE_CLARIFICATION_FILTER(state, newVal) {
    state.questionnaire.filterQuestionsNeedingClarification = newVal
  },

  SET_QUESTION_POINT_VALUE_FILTER(state, key) {
    state.questionnaire.questionPointValueFilter = key
  },
}
