import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getQuestionnaire(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/get?id=${questionnaireId}`
    )
    return await handleFetchResponse(response)
  },

  async answerQuestion(questionnaireId, questionId, valueAry) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/answer`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: questionnaireId, questionId, valueAry }),
      }
    )
    return await handleFetchResponse(response)
  },

  async questionClarificationUpdate(
    id,
    clarificationNeeded,
    clarificationNotes
  ) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/question/clarification`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, clarificationNeeded, clarificationNotes }),
      }
    )
    return await handleFetchResponse(response)
  },

  async reviewQuestion(id, reviewStatus, reviewNotes) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/question/review`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, reviewStatus, reviewNotes }),
      }
    )
    return await handleFetchResponse(response)
  },

  async reviewQuestionnaire(id, reviewStatus, reviewNotes) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/review`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, reviewStatus, reviewNotes }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireFlags(questionnaireId, getGlobalFlags = false) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/flags/get?id=${questionnaireId}&global=${getGlobalFlags}`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireScoring(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/scoring/get?id=${questionnaireId}`
    )
    return await handleFetchResponse(response)
  },

  async flagQuestion(questionId, globalFlag = false) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/questionnaire/flag`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId, globalFlag }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getBreadcrumbs(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/breadcrumbs/get?id=${questionnaireId}`
    )
    return await handleFetchResponse(response)
  },

  async addBreadcrumb(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/breadcrumbs/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async submitClarification() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/clarification/submit`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(),
      }
    )
    return await handleFetchResponse(response)
  },

  async submitQuestionnaire() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/submit`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateOwnerToSection(userId, groupId, operation = 'add') {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendorbase/section/owner/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, groupId, operation }),
      }
    )
    return await handleFetchResponse(response)
  },
}
