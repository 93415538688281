import ApiGlobal from '../../factories/ApiGlobal'
import ApiGitHub from '../../factories/ApiGitHub'

export default {
  async getAllFeedback({ commit, /*dispatch,*/ state }) {
    const {
      feedback,
      statuses,
      users,
      internalUsers,
    } = await ApiGlobal.getAllFeedback(
      state.feedback.showAllFeedback,
      state.feedback.filters,
      state.feedback.allFeedback.currentPage,
      state.feedback.allFeedback.perPage
    )
    commit('SET_ALL_FEEDBACK', feedback)
    commit('SET_FEEDBACK_STATUSES', statuses)
    commit('SET_FEEDBACK_USERS', users)
    commit('SET_FEEDBACK_INTERNAL_USERS', internalUsers)

    // await dispatch('getPullRequests')
  },

  async getFeedback({ commit }, feedbackId) {
    const { feedback } = await ApiGlobal.getFeedback(feedbackId)
    commit('SET_FEEDBACK_CURRENT_FEEDBACK', feedback)
  },

  async updateFeedback({ dispatch }, feedback) {
    const { id } = await ApiGlobal.updateFeedbackStatus(feedback)
    await dispatch('getFeedback', id)

    return id
  },

  async getPullRequests({ commit, state }) {
    if (state.feedback.allPullRequests.length > 0) return

    const { pullRequests } = await ApiGitHub.getPullRequests()
    commit('SET_FEEDBACK_PULL_REQUESTS', pullRequests)
  },
}
