import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getField(id) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/field/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async saveField(info) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/field/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async addRow(spreadsheetId) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/row/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ spreadsheet_id: spreadsheetId }),
    })
    return await handleFetchResponse(response)
  },

  async copyRow(rowId) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/row/copy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ rowId }),
    })
    return await handleFetchResponse(response)
  },

  async deleteRow(rowId) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/row/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: rowId }),
    })
    return await handleFetchResponse(response)
  },

  async saveValue(rowId, fieldId, value) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/row/value/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ rowId, fieldId, value }),
    })
    return await handleFetchResponse(response)
  },

  async getCharts(spreadsheetId, chartId) {
    const response = await R3sFetch(
      `/api/1.0/spreadsheet/charting/get?id=${spreadsheetId}&chartId=${chartId ||
        ''}`
    )
    return await handleFetchResponse(response)
  },

  async createChart(info) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/charting/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async updateChartField(info, deleteField = false) {
    const response = await R3sFetch(
      `/api/1.0/spreadsheet/charting/fields/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...info, deleteField }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkUpload(spreadsheetId, fileId) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/import/from/csv`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ spreadsheetId, fileId }),
    })
    return await handleFetchResponse(response)
  },

  async duplicateSpreadsheet({ spreadsheetId, targetTeamId }) {
    const response = await R3sFetch(`/api/1.0/spreadsheet/copy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        spreadsheetId,
        targetTeamId,
      }),
    })
    return await handleFetchResponse(response)
  },
}
