export default {
  SET_VULNERABILITY_GET_ARCHIVED(state, getArchived) {
    state.vulnerability.getArchivedScans = getArchived
  },

  SET_VULNERABILITY_GLOBAL_SCAN_BEING_ASSIGNED(state, scan = null) {
    state.vulnerability.globalScanBeingEdited = scan
  },

  SET_VULNERABILITY_DEFAULT_COLUMNS(state, { columns, display }) {
    state.vulnerability.defaultListColumns = columns
    state.vulnerability.defaultDisplayColumns = display
  },

  SET_REMOTE_ACCESS_FUNCTIONS(state, functions) {
    state.vulnerability.remote.functions = functions
  },

  SET_VULNERABILITY_SLAS(state, slaObj) {
    state.vulnerability.slas = { ...state.vulnerability.slas, ...slaObj }
  },

  SET_VULNERABILITY_SCANS(state, scanList) {
    state.vulnerability.scans = scanList
  },

  SET_VULNERABILITY_SCAN_KPIS(state, kpiObj) {
    state.vulnerability.scanKpis = kpiObj
  },

  SET_VULNERABILITY_SCAN_INFO(state, scanInfo) {
    state.vulnerability.scanInfo = scanInfo
  },

  SET_VULNERABILITY_SCAN_FILTERS(state, { id, filters }) {
    state.vulnerability.scanFilters[id] = filters
  },

  SET_VULNERABILITY_CURRENT_SCAN_ID(state, scanId) {
    state.vulnerability.currentScanId = scanId
  },

  SET_VULNERABILITY_SCAN_ITEM(state, itemInfo) {
    state.vulnerability.item = itemInfo
  },

  SET_VULNERABILITY_FIELDS(state, info) {
    state.vulnerability.fields = info
  },

  SET_VULNERABILITY_CURRENT_SCAN_ITEMS(
    state,
    { currentPage, data, numberPages, perPage, totalCount }
  ) {
    state.vulnerability.currentScanItems = {
      currentPage:
        currentPage || state.vulnerability.currentScanItems.currentPage,
      data: data || state.vulnerability.currentScanItems.data,
      numberPages:
        numberPages || state.vulnerability.currentScanItems.numberPages,
      perPage: perPage || state.vulnerability.currentScanItems.perPage,
      totalCount: totalCount || state.vulnerability.currentScanItems.totalCount,
    }
  },

  SET_REMOTE_ACCESS_NODES_LIST(state, nodes) {
    state.vulnerability.remoteNodes = nodes
  },

  SET_PENTESTING_VULNERABILITY_SCANS(state, scans) {
    state.pentesting.vulnerabilityScans = scans
  },
}
