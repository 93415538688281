<template>
  <div :class="sliderClass">
    <input type="hidden" class="custom-slider-input" />
  </div>
</template>

<script>
  import $ from 'jquery'

  export default {
    props: {
      min: { type: Number, default: 0 },
      max: { type: Number, default: 100 },
      variant: { type: String, default: 'primary' },
      modelValue: { type: Number },
    },

    computed: {
      sliderClass() {
        return `slider-${this.variant}`
      },
    },

    methods: {
      valueChanged([newVal]) {
        this.$emit('input', parseFloat(newVal))
        this.$emit('update:modelValue', parseFloat(newVal))
      },
    },

    mounted() {
      const slider = $(`#${this.$el.id}`).customSlider({
        start: this.modelValue,
        connect: [true, false],
        tooltips: true,
        range: {
          min: this.min,
          max: this.max,
        },
      })

      slider[0].noUiSlider.on('update', this.valueChanged.bind(this))
    },
  }
</script>

<style lang="scss">
  .noUi-target {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
</style>
