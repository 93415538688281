import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getAllScopes() {
    const response = await R3sFetch(`/api/1.0/program/scopes/get`)
    return await handleFetchResponse(response)
  },

  async getAllContacts() {
    const response = await R3sFetch(`/api/1.0/program/contacts/get`)
    return await handleFetchResponse(response)
  },

  async getAllSystemComponents() {
    const response = await R3sFetch(`/api/1.0/program/system/components/get`)
    return await handleFetchResponse(response)
  },

  async getScope(id) {
    const response = await R3sFetch(`/api/1.0/program/scope/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async createUpdateScope(scope) {
    const response = await R3sFetch(`/api/1.0/program/scope/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ scope }),
    })
    return await handleFetchResponse(response)
  },

  async createDuplicateScope(initialScopeId, duplicateScopeName) {
    const response = await R3sFetch(`/api/1.0/program/scope/duplicate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: initialScopeId, name: duplicateScopeName }),
    })
    return await handleFetchResponse(response)
  },

  async updateScopeMappedFrameworks(scopeId, frameworks) {
    const response = await R3sFetch(`/api/1.0/program/scope/frameworks`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ scopeId, frameworks }),
    })
    return await handleFetchResponse(response)
  },

  async addTeamFramework(name) {
    const response = await R3sFetch(`/api/1.0/program/team/framework/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
    })
    return await handleFetchResponse(response)
  },

  async removeTeamFramework(framework) {
    const response = await R3sFetch(`/api/1.0/program/team/framework/remove`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ framework }),
    })
    return await handleFetchResponse(response)
  },
}
