export default {
  SET_ALL_PROGRAM_SCOPES(state, scopes) {
    state.program.allScopes = scopes
  },

  SET_ALL_PROGRAM_CONTACTS(state, contacts) {
    state.program.allContacts = contacts
  },

  SET_ALL_PROGRAM_SYSTEM_COMPONENTS(state, components) {
    state.program.allSystemComponents = components
  },

  SET_CURRENT_PROGRAM_SCOPE(state, scope) {
    state.program.currentScope = scope
  },
}
