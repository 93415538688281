import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getFunctions(cachedFieldInfo) {
    if (cachedFieldInfo)
      return { functions: cachedFieldInfo }

    const response = await R3sFetch(`/api/1.0/remote/functions/get`)
    return await handleFetchResponse(response)
  },

  async getNodes() {
    const response = await R3sFetch(`/api/1.0/remote/nodes/get`)
    return await handleFetchResponse(response)
  },

  async getNodeDetail(id) {
    const response = await R3sFetch(`/api/1.0/remote/node/detail?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getCommandDetail(id) {
    const response = await R3sFetch(`/api/1.0/remote/command/detail?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getCommandHistory(id, page=1, perPage=30) {
    const response = await R3sFetch(`/api/1.0/remote/node/commands/history?id=${id}&page=${page || ''}&pageSize=${perPage || ''}`)
    return await handleFetchResponse(response)
  },

  async enqueueCommand(apiKey, command, params=null) {
    const response = await R3sFetch(`/api/1.0/remote/enqueue`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ key: apiKey, command, params })
    })
    return await handleFetchResponse(response)
  },

  async retryCommand(commandUuid) {
    const response = await R3sFetch(`/api/1.0/remote/command/retry`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uuid: commandUuid })
    })
    return await handleFetchResponse(response)
  },

  async download9lyn(assetId, apiKeyId=null) {
    const response = await R3sFetch(`/api/1.0/remote/download/9lyn`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ asset_id: assetId, api_key_id: apiKeyId })
    })
    return await handleFetchResponse(response)
  },

  async updateParam({ id, host_id, model, col, val }) {
    const response = await R3sFetch(`/api/1.0/remote/param/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, host_id, model, col, val })
    })
    return await handleFetchResponse(response)
  }
}
