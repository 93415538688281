export default {
  files: {
    fileSearch: '',
    filters: {
      assessment: [],
      framework: [],
      module: [],
      user: [],
    },
    fileSorting: {
      uploaded: 'desc',
    },
    files: {
      totalCount: 0,
      data: [],
      numberPages: 0,
      perPage: 25,
    },
    status: '',

    // used for filtering
    availableAssessments: [],
    availableFrameworks: [],
  },
}
