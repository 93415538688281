export function addCreedCodeEventListener(callback) {
  document.addEventListener('keydown', creedCode(callback), false)
}

let keyCodeSequenceCache = correctSequence()

function creedCode(callback) {
  return function evListener(e) {
    const code = e.keyCode
    const [next] = keyCodeSequenceCache.splice(0, 1)
    if (code !== next) keyCodeSequenceCache = correctSequence()

    if (keyCodeSequenceCache.length === 0) {
      callback()
      keyCodeSequenceCache = correctSequence()
    }
  }
}

function correctSequence() {
  const { i, t, s, h, o, w, m, e } = keyCodeMap()
  return [i, t, s, s, h, o, w, t, i, m, e]
}

function keyCodeMap() {
  return {
    i: 73,
    t: 84,
    s: 83,
    h: 72,
    o: 79,
    w: 87,
    m: 77,
    e: 69,
  }
}
