import { Chart } from 'chart.js'

function jitter(data, offsetRatio = 1) {
  return data.map(function (e) {
    var xJitter = Math.random() * offsetRatio // * ((-1 - 1) + 1)
    var yJitter = Math.random() * offsetRatio // * ((-1 - 1) + 1)

    return {
      ...e,
      x: e.x + xJitter,
      y: e.y + yJitter,
    }
  })
}

Chart.register({
  id: 'custom-jitter',
  afterInit: function (chartInstance) {
    if (chartInstance.config.options.jitter) {
      // const helpers = Chart.helpers
      // const ctx = chartInstance.chart.ctx

      chartInstance.data.datasets.forEach(function (dataset) {
        dataset.data = jitter(
          dataset.data,
          chartInstance.config.options.jitterOffset || 1
        )
      })
    }
  },
})
