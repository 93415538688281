import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getStatusesAndDecisions() {
    const response = await R3sFetch(`/api/1.0/riskregister/statuses`)
    return await handleFetchResponse(response)
  },

  async getPolicies(registerId) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/policies?registerId=${registerId}`
    )
    return await handleFetchResponse(response)
  },

  async getCategories(registerId) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/categories/all?id=${registerId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getAllRegisters(status = 'active') {
    const response = await R3sFetch(
      `/api/1.0/riskregister/registers/all?status=${status || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardRegisters(page = 1, perPage = 5) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/registers/get?page=${
        page || ''
      }&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardItems(page = 1, perPage = 10) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/items/get?page=${page || ''}&perPage=${
        perPage || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async updateCategory(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/category/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async getRegister(id) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/register/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async createRiskRegister(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/register/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async downloadFilteredRegister(registerId, filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/register/download?id=${registerId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getAllItems(
    registerId,
    filters = null,
    page = 1,
    perPage = 20,
    sorting = null
  ) {
    // a registerId of "global" will fetch global templates
    const response = await R3sFetch(
      `/api/1.0/riskregister/items/all?id=${registerId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&page=${page || 1}&perPage=${
        perPage || 20
      }&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getAllTags() {
    const response = await R3sFetch(`/api/1.0/riskregister/tags/all`)
    return await handleFetchResponse(response)
  },

  async getAllScopeGroups() {
    const response = await R3sFetch(`/api/1.0/riskregister/scopes/all`)
    return await handleFetchResponse(response)
  },

  async getItem(itemId) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/get?id=${itemId}`
    )
    return await handleFetchResponse(response)
  },

  async createOrUpdateItem(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/item/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async updateItemOwners(itemId, userIds) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/owners/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: itemId, userIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async removeItemOwner(itemId, userId) {
    const response = await R3sFetch(`/api/1.0/riskregister/item/owner/remove`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId, userId }),
    })
    return await handleFetchResponse(response)
  },

  async updatePolicy(registerId, type, value, newText) {
    const response = await R3sFetch(`/api/1.0/riskregister/policy/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        risk_register_id: registerId,
        policy_type: type,
        value: value,
        policy_text: newText,
      }),
    })
    return await handleFetchResponse(response)
  },

  async updateItemComment(info) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/comment/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateItemFile(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/item/file/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async checkIfMappingExists(registerId, table, entityId) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/mapping/check?registerId=${registerId}&table=${table}&entityId=${entityId}`
    )
    return await handleFetchResponse(response)
  },

  async checkIfMappingExistsForRisk(riskId, table, entityId) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/mapping/check?riskId=${riskId}&table=${table}&entityId=${entityId}`
    )
    return await handleFetchResponse(response)
  },

  async mapItemToEntity(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/item/map`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async unmapItemFromEntity(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/item/unmap`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async bulkUploadItems(items) {
    const response = await R3sFetch(`/api/1.0/riskregister/items/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(items),
    })
    return await handleFetchResponse(response)
  },

  async updateItemOrdering(registerId, itemIds, startIndex = 0) {
    const response = await R3sFetch(`/api/1.0/riskregister/items/reorder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ registerId, itemIds, startIndex }),
    })
    return await handleFetchResponse(response)
  },

  async updateItemMetric(info) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/item/metric/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createJiraTickets(info) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/jira/tickets/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async generateRiskAssessmentReport(info) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/final/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async moveRisk(info) {
    const response = await R3sFetch(`/api/1.0/riskregister/risk/move`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async getRisksByIds(riskIds) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/risks/get/by/id?riskIds=${encodeURIComponent(
        JSON.stringify(riskIds)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async bulkSetAcceptableRisk(registerId, itemIds, newAcceptableRiskValue) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/items/update/acceptableRisk`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ registerId, itemIds, newAcceptableRiskValue }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getDashboardRiskCount(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/count?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardRisksByTreatmentDecision(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/by/treatment/decision?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardRiskAging(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/risk/aging?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardTopRisks(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/top/risks?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardRiskHistory(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/risk/history?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getAllRiskRegisterOwners() {
    const response = await R3sFetch(`/api/1.0/riskregister/items/owners/all`)
    return await handleFetchResponse(response)
  },

  async getDashboardOwnerCounts(filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/owner/counts?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardByXCounts(groupBy, filters) {
    const response = await R3sFetch(
      `/api/1.0/riskregister/dashboard/x/counts?groupBy=${groupBy}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },
}
