// import Utilities from '@risk3sixty/utilities'

export default {
  selfassessments: {
    allSelfAssessments: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },
    allSelfAssessmentTemplates: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },
    currentAssessment: {},
    currentAssessmentAndSections: {},
    showSelfAssessmentTemplates: false,
  },
}
