export function addDragoCodeEventListener(callback) {
  document.addEventListener('keydown', dragoCode(callback), false)
}

let keyCodeSequenceCache = correctSequence()

function dragoCode(callback) {
  return function evListener(e) {
    const code = e.keyCode
    const [next] = keyCodeSequenceCache.splice(0, 1)
    if (code !== next) keyCodeSequenceCache = correctSequence()

    if (keyCodeSequenceCache.length === 0) {
      callback()
      keyCodeSequenceCache = correctSequence()
    }
  }
}

function correctSequence() {
  const { i, m, u, s, t, b, r, e, a, k, y, o } = keyCodeMap()
  return [i, m, u, s, t, b, r, e, a, k, y, o, u]
}

function keyCodeMap() {
  return {
    i: 73,
    m: 77,
    u: 85,
    s: 83,
    t: 84,
    b: 66,
    r: 82,
    e: 69,
    a: 65,
    k: 75,
    y: 89,
    o: 79,
  }
}
