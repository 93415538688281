import ApiCompliance from '../../factories/ApiCompliance'

export default {
  async initComplianceCalendar({ dispatch }) {
    await Promise.all([
      dispatch('getCalendarEventStatuses'),
      dispatch('getCalendarEvents'),
    ])
  },

  async getCalendarEvents(
    { commit, dispatch, getters, state },
    notRecurring = false
  ) {
    const { events, colors } = await ApiCompliance.getComplianceCalendarEvents(
      state.compliance.calendar.showAllEvents,
      state.compliance.calendar.startDate,
      state.compliance.calendar.endDate,
      state.compliance.calendar.filters,
      notRecurring
    )
    commit('SET_CALENDAR_EVENTS', events)
    commit('SET_CALENDAR_EVENTS_RECENT_COLORS', colors)

    if (getters.isManager) {
      await dispatch('getCalendarTemplateEvents')
      await dispatch('getCalendarTemplateEventGroups')
    }
  },

  async downloadCalendarEvents({ state }) {
    const { filename } = await ApiCompliance.downloadComplianceCalendarEvents(
      state.compliance.calendar.showAllEvents,
      state.compliance.calendar.startDate,
      state.compliance.calendar.endDate,
      state.compliance.calendar.filters
    )

    return filename
  },

  async getCalendarTemplateEvents({ commit }) {
    const { events } = await ApiCompliance.getComplianceCalendarTemplateEvents()
    commit('SET_CALENDAR_TEMPLATE_EVENTS', events)
  },

  async getCalendarTemplateEventGroups({ commit }) {
    const { groups } =
      await ApiCompliance.getComplianceCalendarTemplateEventGroups()
    commit('SET_CALENDAR_TEMPLATE_GROUPS', groups)
  },

  async getCalendarEvent({ commit }, eventId) {
    const { event } = await ApiCompliance.getComplianceCalendarEvent(eventId)
    commit('SET_CALENDAR_CURRENT_EVENT', event)
  },

  async clearCurrentEvent({ commit }) {
    commit('SET_CALENDAR_CURRENT_EVENT', {})
  },

  async getCalendarEventStatuses({ commit, state }, reset = state.globalReset) {
    if (
      state.compliance.calendar.statuses &&
      state.compliance.calendar.statuses.length > 0 &&
      !reset
    )
      return

    const { statuses } =
      await ApiCompliance.getComplianceCalendarEventStatuses()
    commit('SET_CALENDAR_EVENT_STATUSES', statuses)
  },

  async createUpdateCalendarEvent({ dispatch }, event) {
    const { id } = await ApiCompliance.createUpdateComplianceCalendarEvent(
      event
    )
    await dispatch('initComplianceCalendar')

    return id
  },

  async createCalendarEventTemplate({ dispatch }, eventId) {
    const { id } = await ApiCompliance.createComplianceCalendarTemplate(eventId)
    await dispatch('initComplianceCalendar')

    return id
  },

  async createUpdateCalendarEventTemplateGroup({ dispatch }, group) {
    const { id } =
      await ApiCompliance.createUpdateComplianceCalendarTemplateGroup(group)
    await dispatch('initComplianceCalendar')

    return id
  },

  async mapCalendarEvent(_, { eventId, type, id, remove }) {
    await ApiCompliance.mapEventRecord(eventId, type, id, remove)
  },

  async deleteCalendarEvent({ dispatch }, eventId) {
    await ApiCompliance.deleteComplianceCalendarEvent(eventId)
    await dispatch('initComplianceCalendar')
  },

  async createUpdateCalendarEventComment({ dispatch }, params) {
    await ApiCompliance.createUpdateCalendarEventComment(params)
    await dispatch('getCalendarEvent', params.eventId)
  },

  async deleteCalendarEventComment({ dispatch }, params) {
    await ApiCompliance.deleteCalendarEventComment(
      params.eventId,
      params.commentId
    )
    await dispatch('getCalendarEvent', params.eventId)
  },

  async updateCalendarEventFile({ dispatch }, params) {
    await ApiCompliance.updateCalendarEventFile(params)
    await dispatch('getCalendarEvent', params.eventId)
  },

  async getCalendarIntegrations({ commit }) {
    const { types } = await ApiCompliance.getCalendarIntegrations()
    commit('SET_CALENDAR_INTEGRATIONS', types)
  },

  async syncCalendarEvents(
    _,
    {
      type,
      method,
      removeExternalEvents = false,
      userTimeZone = 'America/New_York',
    }
  ) {
    await ApiCompliance.syncCalendarEvents(
      type,
      method,
      removeExternalEvents,
      userTimeZone
    )
  },

  async getIntegrationCalendars(_, { type }) {
    const { calendars } = await ApiCompliance.getIntegrationCalendars(type)
    return calendars
  },

  async setIntegrationCalendar(_, { type, calendarId }) {
    const { calendars } = await ApiCompliance.setIntegrationCalendar(
      type,
      calendarId
    )
    return calendars
  },
}
