export default {
  dashboard: {
    colors: {
      primary: '#1c567d',
      blue: '#1c567d',
      darkred: '#c0392b',
      red: '#f45f3e',
      orange: '#f39c12',
      yellow: '#f3c012',
      green: '#47b658',
      gray: '#868e96',
      softblue: '#318ecb',
      purple: '#78479F',
      darkgray: '#343a40',
    },

    rgbaColors(transparency = 1) {
      return {
        primary: `rgba(28, 86, 125, ${transparency})`,
        blue: `rgba(28, 86, 125, ${transparency})`,
        darkred: `rgba(192, 57, 43, ${transparency})`,
        red: `rgba(244, 95, 62, ${transparency})`,
        orange: `rgba(243, 156, 18, ${transparency})`,
        yellow: `rgba(243, 192, 18, ${transparency})`,
        green: `rgba(71, 182, 88, ${transparency})`,
        gray: `rgba(134, 142, 150, ${transparency})`,
        softblue: `rgba(49, 142, 203, ${transparency})`,
        purple: `rgba(120, 71, 159, ${transparency})`,
        darkgray: `rgba(52, 58, 64, ${transparency})`,
      }
    },

    variantColors(rgba = false, transparency = 1) {
      if (!rgba) {
        return {
          ...this.colors,
          danger: this.colors.darkred,
          warning: this.colors.red,
          info: this.colors.orange,
          minimal: this.colors.yellow,
          indifferent: this.colors.softblue,
          success: this.colors.green,
        }
      } else {
        return {
          ...this.rgbaColors(transparency),
          danger: this.rgbaColors(transparency).darkred,
          warning: this.rgbaColors(transparency).red,
          info: this.rgbaColors(transparency).orange,
          minimal: this.rgbaColors(transparency).yellow,
          indifferent: this.rgbaColors(transparency).softblue,
          success: this.rgbaColors(transparency).green,
        }
      }
    },

    severityColors() {
      return {
        critical: this.colors.darkred,
        high: this.colors.red,
        medium: this.colors.orange,
        low: this.colors.green,
        informational: this.colors.gray,
      }
    },

    defaultWidgetColumns(addChecklist = false) {
      let defaultColumns = [
        { span: 8, component: 'AssessmentsListSummary' },
        { span: 4, component: 'AssessmentsRequestTaskList' },
        { span: 6, component: 'RiskRegisterListSummary' },
        { span: 6, component: 'RiskRegisterOpenItemsSummary' },
        { span: 4, component: 'VulnerabilitySeverityDonutSummary' },
        { span: 4, component: 'VulnerabilityOwnerSummary' },
        { span: 4, component: 'VulnerabilitySLASummaryTable' },
        { span: 5, component: 'VulnerabilityOpenTrendOverTimeSummary' },
        { span: 3, component: 'R3sContent' },
        { span: 2, component: 'OrganizationSettingsLink' },
        { span: 2, component: 'UserSettingsLink' },
        // { span: 12, component: 'PotentialFrameworks' },
      ]
      if (addChecklist) {
        // Add ProgramChecklist to the beginning of defaultColumns and change the span for AssessmentsListSummary to 4
        return [{ span: 12, component: 'ProgramChecklist' }]
      }
      return defaultColumns
    },
  },
}
