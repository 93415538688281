export function addMontoyaCodeEventListener(callback) {
  document.addEventListener('keydown', montoyaCode(callback), false)
}

let keyCodeSequenceCache = correctSequence()

function montoyaCode(callback) {
  return function evListener(e) {
    const code = e.keyCode
    const [next] = keyCodeSequenceCache.splice(0, 1)
    if (code !== next) keyCodeSequenceCache = correctSequence()

    if (keyCodeSequenceCache.length === 0) {
      callback()
      keyCodeSequenceCache = correctSequence()
    }
  }
}

function correctSequence() {
  const { t, h, e, m, o, n, y, a } = keyCodeMap()
  return [t, h, e, m, o, n, t, o, y, a]
}

function keyCodeMap() {
  return {
    t: 84,
    h: 72,
    e: 69,
    m: 77,
    o: 79,
    n: 78,
    y: 89,
    a: 65,
  }
}
