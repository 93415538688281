import emojis from 'emoji.json'

export default {
  assessments: {
    projects: [],
    additionalTemplates: [],
    hierarchy: [],

    currentProject: null,
    currentProjectAutomations: [],
    currentProjectCategories: {},
    currentProjectFilters: {},
    currentProjectCriteria: [],
    currentProjectTeam: [],
    currentRequest: {},
    currentRequestProcedures: null,
    currentRequestAssignments: [],
    currentRequestUploadUsers: [],
    currentRequestCategories: [],
    currentRequestChangeLogs: [],
    currentRequestComments: [],
    currentRequestControls: [],
    currentRequestAttachments: [],
    currentRequestTags: [],
    currentRequestCalendarEvents: [],
    currentRequestAutomations: [],
    currentRequestEvidenceObjects: [],
    currentProjectRequestMappingsCount: {},

    // current loaded control info
    currentControl: {},
    currentControlAssignments: [],
    currentControlAutomations: [],
    // currentControlCategories: [],
    currentControlDuplicates: [],
    currentControlFiles: [],
    currentControlQuestions: [],
    currentControlRequests: [],
    currentControlReviewComments: [],
    currentControlTags: [],
    currentControlTestInfo: [],

    currentFormalFindings: [],
    currentFormalFindingsStatuses: [],

    charting: {
      gapSummary: null,
      gapSummaryByCategory: null,
      hitrustPrisma: null,
      hitrustPrismaRef: [],
    },

    defaultControlTestResultOptions: [
      { text: 'Exception Noted', value: 'exceptions', variant: 'danger' },
      {
        text: 'No Exceptions Noted',
        value: 'no_exceptions',
        variant: 'success',
      },
      { text: 'Non-Occurrence', value: 'non_occurrence', variant: 'primary' },
    ],

    testingTables: {
      sampleMethodOptions: [
        { value: `na`, text: `N/A` },
        { value: `25`, text: `Population > 241; thus Auditor sampled 25` },
        { value: `10p`, text: `Population < 241; thus Auditor sampled 10%` },
        { value: `100p`, text: `Auditor Chose to Sample 100%` },
        { value: `2`, text: `Automated Control; Auditor sampled 2` },
      ],

      tickOptions: [
        {
          type: 'string',
          text: 'Clear',
          val: null,
          color: 'black',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-check',
          val: 'check',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-circle',
          val: 'circle',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-angle-right',
          val: 'ar',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-heart',
          val: 'heart',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-thumbs-up',
          val: 'thumbs-up',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-smile',
          val: 'smile',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-check-double',
          val: 'check-double',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'string',
          text: 'Yes',
          val: 'yes',
          color: 'green',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-times',
          val: 'x',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-question',
          val: 'question',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-square',
          val: 'square',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-exclamation',
          val: '!',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'icon',
          class: 'fa-frown',
          val: 'frown',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'string',
          text: 'No',
          val: 'no',
          color: 'red',
          archive: 'false',
        },
        {
          type: 'string',
          text: 'N/A',
          val: 'na',
          color: 'black',
          archive: 'false',
        },
      ].concat(
        emojis.slice(0, 100).map((e) => {
          return { type: 'string', text: e.char, val: e.codes, archive: 'true' }
        })
      ),
    },

    allRequestStatuses: [],
    allControlStatuses: [],
    updatableRequestStatuses: [],
    projectStatuses: {}, // [projectId]: { request: [ array of statuses... ], userRequest: [ array of statuses... ], control: [ array of statuses... ] }
    engagementTypes: [],
    projectGapTypes: {}, // [projectId]: [ array of types... ]

    keyContactRoles: {},

    frameworkKpis: [
      { completed: '0', engagement_type: 'soc2', total: '100' },
      { completed: '0', engagement_type: 'iso27001', total: '100' },
      { completed: '0', engagement_type: 'pci', total: '100' },
      { completed: '0', engagement_type: 'hipaa', total: '100' },
      { completed: '0', engagement_type: 'hitrust', total: '100' },
    ],

    subHeaderTabs(assessmentId, isInternalUser, currentProject) {
      return [
        {
          name: 'assessments',
          text: 'All Assessments',
          activeClassArgs: ['ProjectListViewContainer'],
          link: `/assessments`,
        },
        {
          name: 'planning',
          text: 'Planning',
          activeClassArgs: ['PlanningContainer'],
          link: `/assessments/planning/${assessmentId}`,
        },
        {
          name: 'dashboard',
          text: 'Dashboards',
          activeClassArgs: ['ChartingContainer'],
          link: `/assessments/dashboard/${assessmentId}`,
        },
        {
          name: 'reporting',
          showCondition: false,
          text: 'Status Reporting',
          activeClassArgs: ['StatusReportingContainer'],
          link: `/assessments/status/${assessmentId}`,
        },
        {
          name: 'findings',
          text: 'Formal Findings',
          activeClassArgs: ['FormalFindingsContainer'],
          link: `/assessments/findings/${assessmentId}`,
        },
        {
          name: 'automations',
          text: 'Automations',
          activeClassArgs: ['AutomationListContainer'],
          link: `/assessments/automations/${assessmentId}`,
        },
        {
          showCondition: isInternalUser || !!(currentProject || {}).is_internal,
          name: 'testing',
          text: 'Control Testing',
          activeClassArgs: ['ProjectContainer', this.tab === 'testing'],
          link: `/assessments/testing/${assessmentId}`,
        },
        {
          name: 'evidence',
          text: 'Evidence Requests',
          activeClassArgs: ['ProjectContainer', this.tab === 'evidence'],
          link: `/assessments/evidence/${assessmentId}`,
        },
        {
          showCondition: false,
          name: 'reporting',
          text: 'Reporting',
          activeClassArgs: ['ReportingContainer'],
          link: `/assessments/reporting/${assessmentId}`,
        },
      ]
    },
  },
}
