import Utilities from '@risk3sixty/utilities'

const paginateArray = Utilities.Arrays.paginateArray

export default {
  riskRegisterFrontendPaginatedItems(state) {
    const info = state.riskregister.frontendPaginatedData
    const riskSearch = state.riskregister.riskSearch
    let risks = [...state.riskregister.items.data]

    if (riskSearch) {
      risks = risks.slice().filter((r) => {
        return (
          (r.description &&
            r.description.toLowerCase().includes(riskSearch.toLowerCase())) ||
          (r.risk_description &&
            r.risk_description
              .toLowerCase()
              .includes(riskSearch.toLowerCase())) ||
          (r.id &&
            r.id.toString().toLowerCase().includes(riskSearch.toLowerCase()))
        )
      })
    }

    const { data, numberOfPages, currentPage, dataLength } = paginateArray(
      risks,
      info.perPage,
      info.currentPage
    )

    return {
      currentPage,
      data,
      numberPages: numberOfPages,
      perPage: info.perPage,
      totalCount: dataLength,
    }
  },
}
